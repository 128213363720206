import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button, { BUTTON_VARIANTS } from 'components/button'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { styles } from '../ntp/style'
import GrantFundsModal from './grant-funds-modal'

const { Dropdown } = components
const {
  CheckVerified02Icon,
  CheckCircleBrokenIcon,
  ChevronDownIcon,
  FileCheck01Icon,
  SendIcon,
  XCloseIcon,
} = assets

const InspectionButtons = ({
  canApprove,
  isApproved,
  isSubmitted,
  areFundsRequested,
  areFundsGranted,
  canSubmitForApproval,
  showDeclineButton,
  canDecline,
  onSubmit,
  onApprove,
  onDecline,
  onRequest,
  onGrant,
  approveLabel,
  declineLabel = 'Decline',
  requestLabel,
  grantLabel,
  hasSubmitAccess,
  hasApproveAccess,
  hasDeclineAccess,
  hasRequestAccess,
  hasGrantAccess,
  isPtoStep,
  grantTitleLabel,
  grantInputLabel,
}) => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showGrantModal, setShowGrantModal] = useState(false)
  const isTabletView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const onAction = (ev) => {
    const action = ev.target?.value?.id || ev
    switch (action) {
      case ACTIONS.SUBMIT_FOR_APPROVAL:
        setLoading(true)
        onSubmit().finally(() => setLoading(false))
        break
      case ACTIONS.APPROVE:
        setLoading(true)
        onApprove().finally(() => setLoading(false))
        break
      case ACTIONS.DECLINE:
        onDecline()
        break
      case ACTIONS.REQUEST_FUNDS:
        setLoading(true)
        onRequest().finally(() => setLoading(false))
        break
      case ACTIONS.GRANT_FUNDS:
        setShowGrantModal(true)
        break
      default:
        break
    }
  }

  const onGrantFunds = (amount) => {
    setLoading(true)
    onGrant(amount)
      .then((res) => {
        if (res) {
          setShowGrantModal(false)
        }
      })
      .finally(() => setLoading(false))
  }

  const _buildActions = () => {
    const actionButtons = []
    const actionDropdownOptions = []

    const submitBtnLabel = !isSubmitted
      ? translate('loanApplication.ntpStepsCommon.submitForApproval')
      : translate('loanApplication.ntpStepsCommon.alreadySubmitted')
    const submitBtnDisabled = loading || isSubmitted || !canSubmitForApproval

    if (hasSubmitAccess) {
      actionButtons.push(
        <Button
          disabled={submitBtnDisabled}
          loading={loading}
          onClick={() => onAction(ACTIONS.SUBMIT_FOR_APPROVAL)}
          startIcon={<FileCheck01Icon />}
        >
          {submitBtnLabel}
        </Button>
      )
      actionDropdownOptions.push({
        id: ACTIONS.SUBMIT_FOR_APPROVAL,
        key: 1,
        disabled: submitBtnDisabled,
        label: (
          <div style={styles.ntpActions}>
            <FileCheck01Icon sx={{ mr: 1, width: 20, height: 20 }} />
            {submitBtnLabel}
          </div>
        ),
      })
    }

    if (hasApproveAccess) {
      const approveBtnDisabled =
        loading || !isSubmitted || isApproved || !canApprove
      actionButtons.push(
        <Button
          disabled={approveBtnDisabled}
          loading={loading}
          onClick={() => onAction(ACTIONS.APPROVE)}
          startIcon={<SendIcon />}
        >
          {approveLabel}
        </Button>
      )
      actionDropdownOptions.push({
        id: ACTIONS.APPROVE,
        key: 2,
        disabled: approveBtnDisabled,
        label: (
          <div style={styles.ntpActions}>
            <SendIcon sx={{ mr: 1, width: 20, height: 20 }} />
            {approveLabel}
          </div>
        ),
      })
    }

    if (showDeclineButton && hasDeclineAccess) {
      const declineBtnDisabled = loading || !isSubmitted || !canDecline
      actionButtons.push(
        <Button
          disabled={declineBtnDisabled}
          loading={loading}
          onClick={() => onAction(ACTIONS.DECLINE)}
          startIcon={<XCloseIcon />}
        >
          {declineLabel}
        </Button>
      )
      actionDropdownOptions.push({
        id: ACTIONS.DECLINE,
        key: 5,
        disabled: declineBtnDisabled,
        label: (
          <div style={styles.ntpActions}>
            <XCloseIcon sx={{ mr: 1, width: 20, height: 20 }} />
            {declineLabel}
          </div>
        ),
      })
    }

    if (hasRequestAccess) {
      const requestBtnDisabled = loading || !isApproved || areFundsRequested
      actionButtons.push(
        <Button
          disabled={requestBtnDisabled}
          loading={loading}
          onClick={() => onAction(ACTIONS.REQUEST_FUNDS)}
          startIcon={<CheckCircleBrokenIcon />}
        >
          {requestLabel}
        </Button>
      )
      actionDropdownOptions.push({
        id: ACTIONS.REQUEST_FUNDS,
        disabled: requestBtnDisabled,
        key: 3,
        label: (
          <div style={styles.ntpActions}>
            <CheckCircleBrokenIcon sx={{ mr: 1, width: 20, height: 20 }} />
            {requestLabel}
          </div>
        ),
      })
    }

    if (hasGrantAccess) {
      const grantBtnDisabled = loading || !areFundsRequested || areFundsGranted
      actionButtons.push(
        <Button
          disabled={grantBtnDisabled}
          loading={loading}
          onClick={() => onAction(ACTIONS.GRANT_FUNDS)}
          startIcon={<CheckVerified02Icon />}
        >
          {grantLabel}
        </Button>
      )
      actionDropdownOptions.push({
        id: ACTIONS.GRANT_FUNDS,
        disabled: grantBtnDisabled,
        key: 4,
        label: (
          <div style={styles.ntpActions}>
            <CheckVerified02Icon sx={{ mr: 1, width: 20, height: 20 }} />
            {grantLabel}
          </div>
        ),
      })
    }

    return { actionButtons, actionDropdownOptions }
  }

  const { actionButtons, actionDropdownOptions } = _buildActions()

  return (
    <>
      {isTabletView ? (
        <div style={styles.ntpButtonsMobile}>
          {actionButtons.map((el) => el)}
        </div>
      ) : (
        <div style={styles.ntpButtons}>
          <Dropdown
            options={actionDropdownOptions}
            value={{}}
            customTrigger={
              <Button
                endIcon={<ChevronDownIcon />}
                onClick={() => {}}
                variant={BUTTON_VARIANTS.CONTAINED}
                e
              >
                {translate('loanApplication.ntpStepsCommon.actions')}
              </Button>
            }
            onChange={onAction}
          />
        </div>
      )}

      <GrantFundsModal
        showGrantModal={showGrantModal}
        setShowGrantModal={setShowGrantModal}
        titleLabel={
          grantTitleLabel ||
          translate('loanApplication.ntpStepsCommon.grantInspectionFunds')
        }
        loading={loading}
        onGrantFunds={onGrantFunds}
        fundingInputLabel={
          grantInputLabel ||
          translate(`loanApplication.ntpStepsCommon.inspectionFundingAmount`)
        }
        grantBtnLabel={
          grantTitleLabel ||
          translate('loanApplication.ntpStepsCommon.grantInspectionFunds')
        }
        isPto={isPtoStep}
      />
    </>
  )
}

InspectionButtons.propTypes = {
  canApprove: PropTypes.bool,
  isApproved: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  areFundsRequested: PropTypes.bool,
  areFundsGranted: PropTypes.bool,
  canSubmitForApproval: PropTypes.bool,
  showDeclineButton: PropTypes.bool,
  canDecline: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDecline: PropTypes.func,
  onRequest: PropTypes.func.isRequired,
  onGrant: PropTypes.func.isRequired,
  approveLabel: PropTypes.string.isRequired,
  declineLabel: PropTypes.string,
  requestLabel: PropTypes.string.isRequired,
  grantLabel: PropTypes.string.isRequired,
  hasSubmitAccess: PropTypes.bool.isRequired,
  hasApproveAccess: PropTypes.bool.isRequired,
  hasDeclineAccess: PropTypes.bool,
  hasRequestAccess: PropTypes.bool.isRequired,
  hasGrantAccess: PropTypes.bool.isRequired,
  isPtoStep: PropTypes.bool,
  grantTitleLabel: PropTypes.string,
  grantInputLabel: PropTypes.string,
}

const ACTIONS = {
  SUBMIT_FOR_APPROVAL: 'submitForApproval',
  APPROVE: 'approve',
  DECLINE: 'decline',
  REQUEST_FUNDS: 'requestFunds',
  GRANT_FUNDS: 'grantFunds',
}

export default InspectionButtons
