import glendaleSchema from './glendale.schema'
import keypointSchema from './keypoint.schema'
import firstLightSchema from './firstlight-federal.schema'
import mcCoySchema from './mccoy.schema'

export const MEMBERSHIP_SCHEMAS = {
  [process.env.REACT_APP_GLENDALE]: glendaleSchema,
  [process.env.REACT_APP_KEYPOINT]: keypointSchema,
  [process.env.REACT_APP_FIRSTLIGHT_FEDERAL]: firstLightSchema,
  [process.env.REACT_APP_MCCOY]: mcCoySchema,
}
