import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import TextField, { INPUT_TYPES } from 'components/input'
import DateInput from 'components/input-date'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Loader from 'components/loader'
import { useStore } from 'store'
import { downloadMultipleAttachments } from '../../loan-application/actions'

const { Dropdown } = components
const { FileSearchIcon } = assets

const ApplicantContent = ({
  isBorrower,
  identificationData,
  setIdentificationData,
  idTypes,
  stateList,
  loading,
  loanApplicationId,
  errors,
  setErrors,
}) => {
  const { t: translate } = useTranslation()
  const [downloading, setDownloading] = useState(false)
  const { dispatch } = useStore()

  const handleOnChange = (name, value) => {
    if (errors[name]) {
      setErrors({ ...errors, [name]: null })
    }
    setIdentificationData({ ...identificationData, [name]: value })
  }

  const handleAddressChange = (name, value) => {
    if (errors.address?.[name]) {
      setErrors({ ...errors, address: { ...errors.address, [name]: null } })
    }
    if (name === 'state') {
      value = value.label
    }
    setIdentificationData({
      ...identificationData,
      address: { ...identificationData.address, [name]: value },
    })
  }

  const downloadAttachments = (attachmentsIds) => {
    setDownloading(true)
    downloadMultipleAttachments(
      dispatch,
      loanApplicationId,
      attachmentsIds
    ).finally(() => setDownloading(false))
  }

  const selectedState = stateList.find(
    (state) => state.id === identificationData?.stateOfIssue?.id
  )
  const addressState = stateList.find(
    (state) => state.abbreviation === identificationData.address?.state
  )
  const selectedIdType = idTypes.find(
    (option) => option.id === identificationData?.idType
  )

  return (
    <div className="uw-hub-page__income-wrapper">
      <div className="uw-hub-page__income-title">
        <span>
          {translate(
            `loanApplication.common.${isBorrower ? 'borrower' : 'coborrower'}`
          )}
        </span>
        {identificationData.validPhotoIdAttachmentIds?.length > 0 ? (
          <Button
            onClick={() =>
              downloadAttachments(identificationData.validPhotoIdAttachmentIds)
            }
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            startIcon={downloading ? <Loader size={14} /> : <FileSearchIcon />}
            disabled={downloading}
          >
            {translate('uwHub.identification.preview')}
          </Button>
        ) : null}
      </div>
      <div className="uw-hub-page__income-grid">
        <Dropdown
          label={translate(
            'loanApplication.step1.identificationDetails.idType'
          )}
          options={idTypes}
          value={{
            id: identificationData?.idType,
            label: selectedIdType
              ? translate(selectedIdType.translationKey)
              : null,
          }}
          onChange={(e) => handleOnChange('idType', e.target.value?.id)}
          error={!!errors.idType}
          helperText={errors.idType}
          disabled={loading}
        />
        <TextField
          label={translate(
            'loanApplication.step1.identificationDetails.idNumber'
          )}
          onChange={(value) => handleOnChange('idNumber', value)}
          value={identificationData.idNumber}
          validate={() => errors.idNumber}
          disabled={loading}
        />
        <Dropdown
          label={translate(
            'loanApplication.step1.identificationDetails.issueState'
          )}
          options={stateList.map((state) => ({
            id: state.id,
            label: state.abbreviation,
          }))}
          value={{ id: selectedState?.id, label: selectedState?.abbreviation }}
          onChange={(e) => handleOnChange('stateOfIssue', e.target.value)}
          helperText={
            errors?.stateOfIssue && !Object.keys(errors?.stateOfIssue).length
              ? ''
              : errors?.stateOfIssue
          }
          error={
            errors?.stateOfIssue && !Object.keys(errors?.stateOfIssue).length
              ? false
              : !!errors.stateOfIssue
          }
          disabled={loading}
        />
        <DateInput
          value={identificationData.expirationDate}
          onChange={(value) => handleOnChange('expirationDate', value)}
          label={translate(
            'loanApplication.step1.identificationDetails.expiration'
          )}
          validate={() => errors?.expirationDate}
          disabled={loading}
          minDate={new Date()}
        />
        <DateInput
          value={identificationData.issueDate}
          onChange={(value) => handleOnChange('issueDate', value)}
          label={translate(
            'loanApplication.step1.identificationDetails.issueDate'
          )}
          validate={() => errors.issueDate}
          disabled={loading}
          maxDate={new Date()}
        />
        <DateInput
          value={identificationData.dateOfBirth}
          onChange={(value) => handleOnChange('dateOfBirth', value)}
          label={translate('userDetails.dob')}
          validate={() => errors.dateOfBirth}
          disabled={loading}
          maxDate={new Date()}
        />
        <TextField
          label={translate('userDetails.firstName')}
          type="string"
          value={identificationData.firstName}
          validate={() => errors.firstName}
          onChange={(value) => handleOnChange('firstName', value)}
          disabled={loading}
        />
        <TextField
          label={translate('userDetails.lastName')}
          type="string"
          value={identificationData.lastName}
          validate={() => errors.lastName}
          onChange={(value) => handleOnChange('lastName', value)}
          disabled={loading}
        />
        <TextField
          label={translate(
            'loanApplication.step1.identificationDetails.mothersMaidenName'
          )}
          type="string"
          value={identificationData.mothersMaidenName}
          validate={() => errors.mothersMaidenName}
          onChange={(value) => handleOnChange('mothersMaidenName', value)}
          disabled={loading}
        />
        <TextField
          label={translate('loanApplication.step1.address.address')}
          value={identificationData.address?.addressFirstLine || ''}
          validate={() => errors.address?.addressFirstLine}
          onChange={(val) => handleAddressChange('addressFirstLine', val)}
          disabled={loading}
        />
        <TextField
          label={translate('loanApplication.step1.address.address2')}
          value={identificationData.address?.addressSecondLine || ''}
          validate={() => errors.address?.addressSecondLine}
          onChange={(val) => handleAddressChange('addressSecondLine', val)}
          disabled={loading}
        />
        <TextField
          label={translate('loanApplication.step1.address.city')}
          value={identificationData.address?.city || ''}
          validate={() => errors.address?.city}
          onChange={(val) => handleAddressChange('city', val)}
          disabled={loading}
        />
        <Dropdown
          label={translate('loanApplication.step1.address.state')}
          options={stateList.map((state) => ({
            id: state.id,
            label: state.abbreviation,
          }))}
          value={{ id: addressState?.id, label: addressState?.abbreviation }}
          onChange={(e) => handleAddressChange('state', e.target.value)}
          error={!!errors.address?.state}
          helperText={errors.address?.state}
          disabled={loading}
        />
        <TextField
          label={translate('loanApplication.step1.address.zipCode')}
          value={identificationData.address?.zipCode || ''}
          validate={() => errors.address?.zipCode}
          onChange={(val) => {
            handleAddressChange('zipCode', val.slice(0, 5)) // limit to 5 digits, since maxLength doesn't work with type number
          }}
          disabled={loading}
          type={INPUT_TYPES.NUMBER}
        />
      </div>
    </div>
  )
}

ApplicantContent.propTypes = {
  identificationData: PropTypes.object.isRequired,
  setIdentificationData: PropTypes.func.isRequired,
  idTypes: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isBorrower: PropTypes.bool.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
}

export default ApplicantContent
