import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'loanApplications'

/** Initial state of the reducer */
const initialState = {
  list: [],
  itemCount: 0,
  loansApplicationsStates: [],
  loanApplicationTypes: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_LOANS_APPLICATIONS]: (state, { list, itemCount }) => {
    return { ...state, list: [...list], itemCount }
  },
  [ACTIONS.SET_LOANS_APPLICATIONS_STATES]: (state, { states }) => {
    return { ...state, loansApplicationsStates: [...states] }
  },
  [ACTIONS.APPEND_LOAN_APPLICATIONS]: (state, { newLoans }) => {
    return {
      ...state,
      list: [...state.list, ...newLoans],
    }
  },
  [ACTIONS.SET_LOAN_APPLICATION_TYPES]: (state, { loanApplicationTypes }) => {
    return { ...state, loanApplicationTypes: [...loanApplicationTypes] }
  },
}

export default { name, initialState, reduce }
