import { components } from '@ElementsCapitalGroup/enium-ui'
import { renderCell } from '../common/render-cell'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

export const columns = [
  columnHelper.accessor('data', {
    enableSorting: false,
    size: 90,
  }),
  columnHelper.accessor('application', {
    enableSorting: false,
    cell: (info) => renderCell(info.getValue()),
    tooltip: 'Source of truth for SSN',
  }),
  columnHelper.accessor('creditReport', {
    cell: (info) => renderCell(info.getValue()),
    enableSorting: false,
  }),
  columnHelper.accessor('solarContract', {
    cell: (info) => renderCell(info.getValue()),
    enableSorting: false,
  }),
  columnHelper.accessor('identification', {
    cell: (info) => renderCell(info.getValue()),
    enableSorting: false,
    tooltip: 'Source of truth for Name and DOB',
  }),
  columnHelper.accessor('propertyTitle', {
    cell: (info) => renderCell(info.getValue()),
    accessorKey: 'propertyTitle',
    enableSorting: false,
    tooltip: 'Source of truth for Address',
  }),
]
