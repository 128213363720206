import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

const { Breadcrumbs } = components

const OrganizationBreadcrumbs = ({
  orgId = null,
  organizationTree = [],
  finalItem,
}) => {
  const navigate = useNavigate()
  const isBeta = window.location.pathname.indexOf('-beta') > -1

  const breadcrumbs = useMemo(() => {
    const breadcrumbs = [
      { icon: <UsersSvg /> },
      { id: 'organizations', label: 'Organizations' },
    ]
    const parentOrgs = []
    let currentOrg = organizationTree.find((org) => org.guid === orgId)
    if (currentOrg?.isRoot) {
      breadcrumbs.push({ id: null, label: currentOrg.name })
    }
    while (currentOrg?.parentId) {
      if (currentOrg.guid !== orgId || !!finalItem) {
        parentOrgs.push(currentOrg)
      } else {
        parentOrgs.push({ name: currentOrg.name })
      }
      currentOrg = organizationTree.find(
        (org) => org.guid === currentOrg.parentId
      )
    }
    parentOrgs.reverse().forEach((org) => {
      breadcrumbs.push({ id: org.guid, label: org.name })
    })

    finalItem && breadcrumbs.push(finalItem)
    return breadcrumbs
  }, [orgId, organizationTree, finalItem])

  return (
    <Breadcrumbs
      sx={{ marginBottom: '32px' }}
      items={breadcrumbs}
      lastItemClickable={breadcrumbs.length < 3}
      onClick={(ev, item) => {
        if (item.id === 'organizations') {
          navigate(`/admin/organizations${isBeta ? '-beta' : ''}`)
        } else if (item.id) {
          navigate(`/admin/organization${isBeta ? '-beta' : ''}/${item.id}`)
        }
      }}
    />
  )
}

OrganizationBreadcrumbs.propTypes = {
  orgId: PropTypes.string,
  organizationTree: PropTypes.arrayOf(PropTypes.object),
  finalItem: PropTypes.shape({ label: PropTypes.string }),
}

export default OrganizationBreadcrumbs
