import Api from 'easy-fetch-api'
import { formatDate, mapCreatedAndLastModifiedDates } from 'common/date'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import {
  dateFormatServerWithTime,
  ORGANIZATION_TYPE_IDS,
} from 'common/constants'

import {
  getOrganizations,
  fetchLenderOrganizations,
} from 'modules/organizations/actions'
import { convertWordToTitleCase } from 'common/utils'
import { LOAN_APP_TYPES } from 'modules/loan-application/constants'
import moment from 'moment'

export const ACTIONS = {
  SET_LOANS_APPLICATIONS: 'loanApplications.setLoansApplications',
  APPEND_LOAN_APPLICATIONS: 'loanApplications.appendLoanApplications',
  SET_LOANS_APPLICATIONS_STATES: 'loanApplications.setLoansApplicationsStates',
  SET_LOAN_APPLICATION_TYPES: 'loanApplications.setLoanApplicationTypes',
}

/** Initialize Loan Apps - adjacent relevant data */
export const getPrerequisiteData = async (dispatch, userData) => {
  const fetchStates = () =>
    Api.get({ url: `/LoanApplication/loan-application-states` }).catch(
      console.error
    )

  if (hasAccess(userData, CLAIMS.CAN_VIEW_LENDERS)) {
    await fetchLenderOrganizations(dispatch)
  }

  return Promise.all([fetchStates(), getLoanApplicationTypes()]).then(
    ([states, loanApplicationTypes]) => {
      states &&
        dispatch({
          type: ACTIONS.SET_LOANS_APPLICATIONS_STATES,
          states: states,
        })

      loanApplicationTypes &&
        dispatch({
          type: ACTIONS.SET_LOAN_APPLICATION_TYPES,
          loanApplicationTypes,
        })
    }
  )
}

/** Get Org list for the Organization Filter dropdown */
export const getSalesOrganizationsForFilters = async () => {
  const orgRes = await getOrganizations(null, { itemsPerPage: 2000 })
  return orgRes.organizationUnits.filter(
    (o) => o.type.id === ORGANIZATION_TYPE_IDS.DEALER
  )
}

/** Fetch Loan Applications, filtered & paginated */
export const filterLoanApplications = async (
  dispatch,
  selectedLoanAppType,
  filters,
  loansPage,
  pageSize
) => {
  const filtersWithPage = { ...filters }
  if (filtersWithPage.searchBy?.length) {
    filtersWithPage.searchBy1 = filtersWithPage.searchBy[0]
    if (filtersWithPage.searchBy[1]) {
      filtersWithPage.searchBy2 = filtersWithPage.searchBy[1]
    }
    delete filtersWithPage.searchBy
  }

  filtersWithPage.itemsPerPage = pageSize
  filtersWithPage.pageNumber = loansPage
  if (
    filtersWithPage.dateCreated &&
    moment(filtersWithPage.dateCreated).isValid()
  ) {
    filtersWithPage.dateCreated = moment(filtersWithPage.dateCreated).format(
      'YYYY-MM-DD'
    )
  }

  if (filtersWithPage.activeTableFilters) {
    delete filtersWithPage.activeTableFilters
  }

  const applicationsRes =
    selectedLoanAppType === LOAN_APP_TYPES.RESIDENTIAL
      ? await fetchApplications(filtersWithPage)
      : await fetchCommercialApplications(filtersWithPage)
  const applications = applicationsRes.loanApplications || []

  const parsedApplications = Array.isArray(applications)
    ? fromRawApplicationsToTableFormat(applications)
    : []

  if (loansPage === 0) {
    dispatch({
      type: ACTIONS.SET_LOANS_APPLICATIONS,
      list: parsedApplications,
      itemCount: applicationsRes.itemCount,
    })
  } else {
    dispatch({
      type: ACTIONS.APPEND_LOAN_APPLICATIONS,
      newLoans: parsedApplications,
    })
  }

  return parsedApplications
}

export const getSalesReps = () =>
  Api.get({ url: '/Users/list-basic' }).catch((err) => {
    console.error(err)
    return []
  })

export const getLoanApplicationTypes = () =>
  Api.get({ url: '/LoanApplication/loan-application-types' }).catch((err) => {
    console.error(err)
    return []
  })

export const exportToCsv = (query = {}) =>
  Api.get({
    url: '/LoanApplication/download-loan-application-csv',
    query,
  }).catch(console.error)

export const fetchApplications = (query = {}) =>
  Api.get({ url: '/LoanApplication/list', query })
    .then((res) => {
      const { itemCount, loanApplications } = res
      return {
        itemCount,
        loanApplications: mapCreatedAndLastModifiedDates(loanApplications),
      }
    })
    .catch(console.error)

export const fetchCommercialApplications = (query = {}) =>
  Api.get({ url: '/LoanApplication/commercial/list', query })
    .then((res) => {
      const { itemCount, loanApplications } = res
      return {
        itemCount,
        loanApplications: mapCreatedAndLastModifiedDates(loanApplications),
      }
    })
    .catch(console.error)

/* Helper method that formats apps to the Table columns format */
const fromRawApplicationsToTableFormat = (rawApplications) => {
  return rawApplications.map((rawApp) => ({
    agingTier: rawApp?.agingTier?.friendlyName || '',
    customer:
      convertWordToTitleCase(rawApp.borrowerName || rawApp.legalCompanyName) ||
      '',
    loanApplicationId: rawApp.loanApplicationId,
    loanApplicationNumber: rawApp.loanApplicationNumber || '',
    organization: rawApp.salesOrganizationName || '',
    salesRepresentative: rawApp.salesRepresentativeName || '',
    state: rawApp?.loanApplicationState?.friendlyName || '',
    stateId: rawApp?.loanApplicationState?.id,
    dateCreated: rawApp.dateCreated
      ? formatDate(rawApp.dateCreated, dateFormatServerWithTime)
      : '',
    lastModified: rawApp.dateLastModified
      ? formatDate(rawApp.dateLastModified, dateFormatServerWithTime)
      : '',
    loanApplicationStatusId: rawApp?.loanApplicationStatus?.id,
    fullAddress: rawApp?.fullAddress || '',
    expiresInDays: rawApp?.expiresInDays,
    isPtoNotMandatory: rawApp?.isPtoNotMandatory,
  }))
}
