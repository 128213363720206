import { VALIDATION_TYPES } from 'components/validator'
import { CLAIMS } from './claims'

export const LOAN_PAGE_ERROR_MAP = {
  firstName: [VALIDATION_TYPES.REQUIRED],
  lastName: [VALIDATION_TYPES.REQUIRED],
  last4SSN: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.BIGGER_THAN_ZERO],
  yearlyIncome: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.POSITIVE_OR_ZERO_INTEGER,
  ],
  employmentStatus: [VALIDATION_TYPES.REQUIRED],
  phoneNumber: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.PHONE],
  emailAddress: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.EMAIL],
  preferredCommunicationMethodId: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.POSITIVE_OR_ZERO_INTEGER,
  ],
  additionalIncomeTypeId: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.POSITIVE_OR_ZERO_INTEGER,
  ],
  additionalIncomeAmount: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.POSITIVE_SAFE_INTEGER,
  ],
  addressFirstLine: [VALIDATION_TYPES.REQUIRED],
  city: [VALIDATION_TYPES.REQUIRED],
  zipCode: [VALIDATION_TYPES.REQUIRED],
  state: [VALIDATION_TYPES.REQUIRED],
  loanAmount: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.BIGGER_THAN_ZERO],
  citizenshipTypeId: [VALIDATION_TYPES.REQUIRED],
  militaryAffiliationId: [VALIDATION_TYPES.REQUIRED],
  residencyOccupationTypeId: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.POSITIVE_OR_ZERO_INTEGER,
  ],
  dateOfBirth: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.DATE],
}

export const LOAN_PAGE_ERROR_MAP_WITH_EMPLOYER_FIELDS = {
  ...LOAN_PAGE_ERROR_MAP,
  monthsEmployed: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
  jobTitle: [VALIDATION_TYPES.REQUIRED],
  employer: [VALIDATION_TYPES.REQUIRED],
}

export const SPECIAL_FIELDS_ERROR_MAP = {
  netAmount: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.BIGGER_THAN_ZERO],
  downPayment: [VALIDATION_TYPES.REQUIRED],
  batteryOnly: [VALIDATION_TYPES.REQUIRED],
}

export const LOAN_PRODUCT_ERROR_MAP = {
  achFee: [VALIDATION_TYPES.REQUIRED],
  rate: [VALIDATION_TYPES.REQUIRED],
  name: [VALIDATION_TYPES.REQUIRED],
  loanCode: [VALIDATION_TYPES.REQUIRED],
  description: [VALIDATION_TYPES.REQUIRED],
  lenderId: [VALIDATION_TYPES.REQUIRED],
  financeTypeId: [VALIDATION_TYPES.REQUIRED],
  promotionalTypeId: [VALIDATION_TYPES.REQUIRED],
  serviceCode: [VALIDATION_TYPES.REQUIRED],
}

export const SERVICE_ADDRESS_ERROR_MAP = {
  addressFirstLine: [VALIDATION_TYPES.REQUIRED],
  city: [VALIDATION_TYPES.REQUIRED],
  zipCode: [VALIDATION_TYPES.REQUIRED],
  state: [VALIDATION_TYPES.REQUIRED],
}

export const dateFormatUS = 'MM-DD-YYYY'
export const dobDateFormat = 'MM/DD/YYYY'
export const dateFormatUSWithTimestamps = 'MM-DD-YYYY HH:mm'
export const dateFormatUSWithTimestampsAM = 'MM-DD-YYYY hh:mm A'
export const dateFormatServer = 'YYYY-MM-DD'
export const dateFormatServerWithTime = 'YYYY-MM-DDTHH:mm:ss'

export const USER_GENERAL_DETAILS = {
  applicantId: undefined,
  firstName: '',
  lastName: '',
  loanAmount: '',
  last4SSN: '',
  yearlyIncome: '',
  employer: '',
  employmentStatus: '',
  monthsEmployed: '',
  jobTitle: '',
  militaryAffiliationId: '',
  phoneNumber: '',
  emailAddress: '',
  preferredCommunicationMethodId: '',
  citizenshipTypeId: '',
  hasCoBorrower: false,
  hasAdditionalIncome: false,
  sameWithServiceAddress: true,
  additionalIncomeTypeId: '',
  additionalIncomeAmount: '',
  residencyOccupationTypeId: '',
  dateOfBirth: null,
}

export const SPECIAL_FIELDS_DETAILS = {
  netAmount: '',
  downPayment: '',
  batteryOnly: false,
}

export const ADDRESS_DETAILS = {
  addressId: null,
  addressFirstLine: '',
  addressSecondLine: '',
  city: '',
  state: '',
  zipCode: '',
  county: '',
  country: '',
}

export const GENERAL_ERRORS = {
  firstName: null,
  lastName: null,
  last4SSN: null,
  yearlyIncome: null,
  employer: null,
  employmentStatus: null,
  monthsEmployed: null,
  jobTitle: null,
  phoneNumber: null,
  emailAddress: null,
  preferredCommunicationMethodId: null,
  additionalIncomeTypeId: null,
  additionalIncomeAmount: null,
  addressFirstLine: null,
  addressSecondLine: null,
  loanAmount: null,
  state: null,
  city: null,
  zipCode: null,
  militaryAffiliationId: null,
  citizenshipTypeId: null,
  residencyOccupationTypeId: null,
  dateOfBirth: null,
}

export const GENERAL_ADDRESS_ERRORS = {
  addressFirstLine: null,
  addressSecondLine: null,
  state: null,
  city: null,
  zipCode: null,
}

export const SPECIAL_FIELDS_ERRORS = {
  netAmount: null,
  downPayment: null,
}

export const APPLICANT_TYPE = {
  BORROWER: 0,
  COBORROWER: 1,
}

export const ORGANIZATION_TYPE_IDS = {
  CORPORATE: 1,
  DEALER: 2,
  INSTALLER: 3,
  LENDER: 4,
  ENIUM_X: 5,
}

export const AGING_TIERS = {
  NOT_AGING: 0,
  YOUNG: 1,
  MID_LIFE: 2,
  OLD: 3,
}

export const LOAN_PRODUCT_STATUSES = {
  ACTIVE: 0,
  IN_DEVELOPMENT: 1,
  INACTIVE: 2,
  ON_HOLD: 3,
  CANCELED: 4,
}

export const LOAN_SELECT_CRITERIAS = [
  {
    description: 'The loan amount must be changed',
    id: 0,
    name: 'Loan Amount',
    friendlyName: 'Loan Amount',
  },
  {
    description: 'Add a co-borrower',
    id: 1,
    name: 'Coborrower',
    friendlyName: 'Coborrower',
  },
  {
    id: 2,
    name: 'Loan Amount And/Or Coborrower',
    friendlyName: 'Loan Amount And/Or Coborrower',
  },
  {
    description: 'Property title mismatch',
    id: 3,
    name: 'Property Title',
    friendlyName: 'Property Title',
  },
  {
    id: 4,
    name: 'No Relation/No Fix',
    friendlyName: 'No Relation/No Fix',
  },
]

export const ADDRESS_TYPES = {
  LOCALITY: 'locality',
  SUBLOCALITY: 'sublocality',
  COUNTRY: 'country',
  POSTAL_CODE: 'postal_code',
  STREET_NUMBER: 'street_number',
  ROUTE: 'route',
  ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_LEVEL_2: 'administrative_area_level_2',
}

export const EXCLUDED_CLAIMS_FOR_SUPER_ADMIN = [
  CLAIMS.CAN_VIEW_USERS_FROM_CURRENT_AND_SUB_ORGANIZATIONS,
  CLAIMS.CAN_VIEW_SALES_ROLES,
  CLAIMS.CAN_VIEW_CURRENT_AND_SUB_ORGANIZATIONS,
]

export const ADDRESS_TYPES_TRANSLATIONS = {
  [ADDRESS_TYPES.LOCALITY]: 'loanApplication.step1.address.city',
  [ADDRESS_TYPES.COUNTRY]: 'loanApplication.step1.address.country',
  [ADDRESS_TYPES.POSTAL_CODE]: 'loanApplication.step1.address.zipCode',
  [ADDRESS_TYPES.STREET_NUMBER]: 'loanApplication.step1.address.streetNumber',
  [ADDRESS_TYPES.ROUTE]: 'loanApplication.step1.address.route',
  [ADDRESS_TYPES.ADMINISTRATIVE_AREA_LEVEL_1]:
    'loanApplication.step1.address.state',
  [ADDRESS_TYPES.ADMINISTRATIVE_AREA_LEVEL_2]:
    'loanApplication.step1.address.county',
}

// Auth-related
export const COOKIE_NAME = 'enium-v3'
export const BASIC_COOKIE_NAME = 'enium-v3-basic' // contains only minimal user data - used for websockets
export const LS_USER_DATA = 'enium-v3'
export const REFRESH_COOKIE_NAME = 'enium-v3-refresh'

// Screen breakpoints
export const LAPTOP_BREAKPOINT = 1440
export const DESKTOP_BREAKPOINT = 1024
export const TABLET_BREAKPOINT = 768
export const LARGE_MOBILE_BREAKPOINT = 480

export const ENIUM_X_ORGANIZATION_TYPE_ID = 5
export const LS_USER_PREFERENCES = 'enium-v3.user-preferences'
export const TFA_METHODS = {
  SMS: 0,
  EMAIL: 1,
}
