import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import TextField, { INPUT_TYPES } from 'components/input'
import Checkbox from 'components/checkbox'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { validate } from 'components/validator'
import Textarea from 'components/textarea'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'

import { styles } from '../style'
import { LANGUAGES } from '../../constants'
import { SALES_ORG_VALIDATION_MAP } from './constants'
import { getUsersMinimalData } from '../../actions'

const { Dropdown } = components
const accountManagerRoleId = process.env.REACT_APP_ACCOUNT_MANAGER_GUID

const SalesOrgAddEditForm = ({
  isNew,
  state,
  updateField,
  fixedOrgType,
  orgTypes,
  orgStatuses,
  onSave,
  onCancel,
  somethingChanged,
  userData,
}) => {
  const [errors, setErrors] = useState({})
  const [accountManagerOptions, setAccountManagerOptions] = useState([])

  const canSeeSensitiveData = hasAccess(
    userData,
    CLAIMS.CAN_DECRYPT_ORGANIZATIONS_SENSITIVE_DATA
  )

  const orgType =
    state.type || orgTypes.find((type) => type.id === fixedOrgType) || ''

  useEffect(() => {
    if (!accountManagerOptions.length) {
      getUsersMinimalData(null, { roleId: accountManagerRoleId }).then(
        (users) =>
          setAccountManagerOptions(
            users.map((user) => ({ id: user.guid, label: user.name }))
          )
      )
    }
  }, [])

  const validateAndSave = () => {
    const [isValid, errors] = validate(SALES_ORG_VALIDATION_MAP, state)
    setErrors(errors)
    if (isValid) {
      onSave(state)
    }
  }

  return (
    <>
      <div style={styles.organizations.grid}>
        <TextField
          label="Name"
          placeholder="Organization Name"
          value={state.name}
          onChange={updateField('name')}
          validate={() => errors.name}
        />
        <Dropdown
          disabled={!!fixedOrgType}
          label="Type"
          options={orgTypes}
          onChange={(ev) => updateField('type')(ev.target.value)}
          value={orgType}
        />
        <TextField
          label="Phone Number"
          type={INPUT_TYPES.PHONE}
          placeholder="Phone Number"
          value={state.phoneNumber}
          onChange={updateField('phoneNumber')}
          validate={() => errors.phoneNumber}
        />
        <TextField
          label="Address"
          placeholder="Address 1, Address 2, City, State"
          value={state.address}
          onChange={updateField('address')}
          validate={() => errors.address}
        />
        <TextField
          label="Bank ABA"
          placeholder={isNew ? '******' : ''}
          type={isNew ? INPUT_TYPES.TEXT : INPUT_TYPES.SENSITIVE}
          value={state.bankABA}
          onChange={updateField('bankABA')}
          validate={() => errors.bankABA}
          hideSecureValue={!canSeeSensitiveData}
          disabled={!canSeeSensitiveData}
        />
        <TextField
          label="Bank Account"
          placeholder={isNew ? '******' : ''}
          type={isNew ? INPUT_TYPES.TEXT : INPUT_TYPES.SENSITIVE}
          value={state.bankAccount}
          onChange={updateField('bankAccount')}
          validate={() => errors.bankAccount}
          hideSecureValue={!canSeeSensitiveData}
          disabled={!canSeeSensitiveData}
        />
        <TextField
          label="Tax ID"
          placeholder={isNew ? '******' : ''}
          type={isNew ? INPUT_TYPES.TEXT : INPUT_TYPES.SENSITIVE}
          value={state.taxId}
          onChange={updateField('taxId')}
          validate={() => errors.taxId}
          hideSecureValue={!canSeeSensitiveData}
          disabled={!canSeeSensitiveData}
        />
        <TextField
          label="Initial Funding"
          placeholder={isNew ? '******' : ''}
          type={isNew ? INPUT_TYPES.TEXT : INPUT_TYPES.SENSITIVE}
          value={state.initialFunding}
          onChange={updateField('initialFunding')}
          validate={() => errors.initialFunding}
          hideSecureValue={!canSeeSensitiveData}
          disabled={!canSeeSensitiveData}
        />
        <TextField
          label="ECG Limit"
          placeholder={isNew ? '******' : ''}
          type={isNew ? INPUT_TYPES.TEXT : INPUT_TYPES.SENSITIVE}
          value={state.ecgLimit}
          onChange={updateField('ecgLimit')}
          validate={() => errors.ecgLimit}
          hideSecureValue={!canSeeSensitiveData}
          disabled={!canSeeSensitiveData}
        />
        <Dropdown
          label="Status"
          options={orgStatuses}
          value={state.status || ''}
          onChange={(ev) => updateField('status')(ev.target.value)}
          error={Boolean(
            errors.status && typeof errors.status === 'string'
              ? errors.status
              : null
          )}
          helperText={
            errors.status && typeof errors.status === 'string'
              ? errors.status
              : null
          }
        />
        <Dropdown
          label="Language"
          options={LANGUAGES}
          value={state.language || ''}
          onChange={(ev) => updateField('language')(ev.target.value)}
        />
        <Dropdown
          label="Account Manager"
          options={accountManagerOptions}
          multiple={true}
          searchable={true}
          value={state.accountManagers || []}
          onChange={(ev) => updateField('accountManagers')(ev.target.value)}
          error={!!errors.accountManagers}
          helperText={errors.accountManagers}
        />
        <Textarea
          label="Email Addresses"
          placeholder="sample1@mail.com, sample2@mail.com"
          value={state.emailAddresses}
          onChange={updateField('emailAddresses')}
          validate={() => errors.emailAddresses}
        />
        <TextField
          label="Dealer Code"
          placeholder="Dealer Code"
          value={state.dealerCode}
          onChange={updateField('dealerCode')}
          validate={() => errors.dealerCode}
        />
      </div>

      <div style={styles.organizations.checkboxes}>
        <Checkbox
          checked={state.checking}
          onClick={(newValue) => updateField('checking')(newValue)}
          label="Checking"
        />
        <Checkbox
          checked={state.achForm}
          onClick={(newValue) => updateField('achForm')(newValue)}
          label="ACH Form"
        />
        <Checkbox
          checked={state.matureAccount}
          onClick={(newValue) => updateField('matureAccount')(newValue)}
          label="Mature Account"
        />
      </div>

      <div style={styles.organizations.buttons}>
        <Button
          color={BUTTON_COLORS.INHERIT}
          variant={BUTTON_VARIANTS.OUTLINED}
          style={{ marginRight: '16px' }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button disabled={!somethingChanged} onClick={validateAndSave}>
          {isNew ? 'Create Organization' : 'Save Changes'}
        </Button>
      </div>
    </>
  )
}

SalesOrgAddEditForm.propTypes = {
  isNew: PropTypes.bool,
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  orgTypes: PropTypes.array.isRequired,
  fixedOrgType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orgStatuses: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  somethingChanged: PropTypes.bool,
  userData: PropTypes.object.isRequired,
}

export default SalesOrgAddEditForm
