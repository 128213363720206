import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Loader from 'components/loader'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { repullCreditInfo } from '../../actions'

const { Dialog } = components
const TRANSUNION_NUMBER = process.env.REACT_APP_TRANSUNION_NO

const CreditFrozenModal = ({
  dispatch,
  loanApplicationId,
  setSelfServiceCriterias,
  onClose,
}) => {
  const [creditFrozenLoading, setCreditFrozenLoading] = useState(false)
  const { t: translate } = useTranslation()

  const handleOnClose = (event, reason) => {
    // Disable on background click for closing the Dialog
    if (reason !== 'closeButtonClicked') {
      return
    }
    onClose()
  }

  const onProceedClicked = () => {
    setCreditFrozenLoading(true)
    repullCreditInfo(dispatch, loanApplicationId)
      .then((result) => {
        if (result) {
          setSelfServiceCriterias(result)
        } else {
          window.location.reload()
        }
      })
      .catch(() => {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          message: `You don't have the necessary permissions to perform this action`,
        })
      })
      .finally(() => setCreditFrozenLoading(false))
  }

  return (
    <Dialog
      open={true}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '600px',
        },
      }}
      titleProps={{
        sx: {
          padding: 0,
          '& .MuiButtonBase-root': {
            top: '28px',
            right: '24px',
          },
        },
      }}
      disableEscapeKeyDown={true}
      onClose={handleOnClose}
      actions={
        <>
          {creditFrozenLoading && (
            <Loader size={30} style={{ marginRight: 'auto' }} />
          )}
          <Button
            onClick={onClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            disabled={creditFrozenLoading}
          >
            {translate('buttons.goBack')}
          </Button>
          <Button loading={creditFrozenLoading} onClick={onProceedClicked}>
            {translate('loanApplication.blocked.creditUnfrozen')}
          </Button>
        </>
      }
    >
      <div
        style={{
          paddingRight: '50px',
          fontSize: '0.875rem',
        }}
        dangerouslySetInnerHTML={{
          __html: translate(
            'loanApplication.blocked.contactTransunion'
          ).replace(
            '{1}',
            `<strong><a href="tel:${TRANSUNION_NUMBER}">${TRANSUNION_NUMBER}</a></strong>`
          ),
        }}
      ></div>
    </Dialog>
  )
}

CreditFrozenModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  setSelfServiceCriterias: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CreditFrozenModal
