import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { assets } from '@ElementsCapitalGroup/enium-ui'

import AddNewNote from 'components/loan-application/notes/add-new-note'
import NotesList from 'components/loan-application/notes/list'
import { getNotes } from 'modules/loan-application/actions'

import './index.scss'

const { NoteIcon } = assets

const Notes = ({ loanApplicationId }) => {
  const { t: translate } = useTranslation()
  const [notes, setNotes] = useState([])
  const [loading, setLoading] = useState(false)

  const getNotesFromServer = () => {
    setLoading(true)
    getNotes(loanApplicationId)
      .then(
        (notesRes) => notesRes && Array.isArray(notesRes) && setNotes(notesRes)
      )
      .finally(() => {
        setLoading(false)
      })
  }

  /** On mount, get data from the server */
  useEffect(() => {
    getNotesFromServer()
  }, [])

  return (
    <div>
      <div className="notes">
        <div className="notes__title">
          <NoteIcon sx={{ mr: 1 }} />
          {translate('loanApplication.ecgToolbox.notes.title')}
        </div>
        <NotesList
          notes={notes}
          updateNotesOnDeletion={setNotes}
          loading={loading}
          setLoading={setLoading}
        />

        <AddNewNote
          loanApplicationId={loanApplicationId}
          getNotes={getNotesFromServer}
        />
      </div>
    </div>
  )
}

Notes.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
}

export default Notes
