export const ACTION_TYPES = {
  SUBMIT: 'onSubmit',
  DECLINE: 'onDecline',
}

export const SCHEMA_TYPES = {
  WRAPPER: 'wrapper',
  HEADING: 'heading',
  PARAGRAPH: 'paragraph',
  HTML: 'html',
  CHECKBOX: 'checkbox',
  BUTTONS: 'buttons',
  INPUT: 'input',
  DROPDOWN: 'dropdown',
}
