import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useStore } from 'store'
import { components } from '@ElementsCapitalGroup/enium-ui'
import LazyLoader from 'components/lazy-loader'
import Loader, { LOADER_SIZE } from 'components/loader'
import EmptyList from 'components/empty-list'

import { AdminHistoryTab } from './admin-history-tab'
import { fetchFunctions, transformHistoryDataForTimeline } from './utils'
import { HISTORY_PAGE_SIZE, TABS } from './constants'

const { HistoryNavigation, Timeline } = components

const HistoryTracking = ({ id, className, type }) => {
  const [page, setPage] = useState(0)
  const [history, setHistory] = useState([])
  const [adminMenu, setAdminMenu] = useState(TABS[0].id)
  const [loading, setLoading] = useState(false)
  const { dispatch } = useStore()
  const isAdmin = type === HISTORY_TRACKING_TYPES.ADMIN

  const selectedFetchFunction = useMemo(() => {
    return isAdmin ? fetchFunctions[adminMenu] : fetchFunctions[type]
  }, [isAdmin, adminMenu, type])

  /** On mount, fetch data from the selected fetch function (for admin, this is handled in admin-history-tab) */
  useEffect(() => {
    if (!isAdmin && selectedFetchFunction) {
      setLoading(true)
      selectedFetchFunction({
        id,
        pageNumber: 0,
        itemsPerPage: HISTORY_PAGE_SIZE,
      })
        .then((res) => res && setHistory(transformHistoryDataForTimeline(res)))
        .finally(() => setLoading(false))
    }
  }, [isAdmin, selectedFetchFunction])

  const fetchNextPage = useCallback(
    (nextPage, pageSize) => {
      return selectedFetchFunction({
        id,
        pageNumber: nextPage,
        itemsPerPage: pageSize,
      }).then((newItems) => {
        const transformedNewItems = transformHistoryDataForTimeline(newItems)
        setHistory((prev) => [...prev, ...transformedNewItems])
        setPage(nextPage)
        return newItems
      })
    },
    [id, page]
  )

  if (!history.length && !loading && !isAdmin) {
    return (
      <EmptyList
        title="No History Logs Found"
        description="We couldn't find any data."
        cardStyling={{ border: 'none', boxShadow: 'none' }}
      />
    )
  }

  return (
    <div className={cx('history-tracking', className)}>
      <div className="history-tracking__wrapper">
        {isAdmin ? (
          <>
            <HistoryNavigation
              onTabChange={setAdminMenu}
              sx={{ paddingBottom: 0 }}
              items={TABS}
            />

            {selectedFetchFunction ? (
              <AdminHistoryTab
                key={adminMenu}
                dispatch={dispatch}
                fetchHistory={selectedFetchFunction}
              />
            ) : null}
          </>
        ) : (
          <>
            {loading && <Loader center={true} size={LOADER_SIZE.SMALL} />}
            <Timeline logs={history} />
            {history?.length > 0 ? (
              <LazyLoader fetchNextPage={fetchNextPage} page={page} />
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

export const HISTORY_TRACKING_TYPES = {
  ADMIN: 'ADMIN',
  USER_DETAILS: 'USER_DETAILS',
  LOAN_PRODUCT_DETAILS: 'LOAN_PRODUCT_DETAILS',
  AFP_FUNDING_HISTORY: 'AFP_FUNDING_HISTORY',
  UW_HUB_HISTORY: 'UW_HUB_HISTORY',
}

HistoryTracking.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(Object.values(HISTORY_TRACKING_TYPES)),
  className: PropTypes.string,
}

export default HistoryTracking
