import { checkType } from 'common/utils'
import moment from 'moment'

export const VALIDATION_TYPES = {
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  REQUIRED: 'required',
  PASSWORD: 'password',
  BIGGER_THAN_ZERO: 'bigger_than_zero',
  EQUAL_TO_NINE: 'equal_to_nine',
  DOUBLE: 'double',
  POSITIVE_OR_ZERO_INTEGER: 'positive_or_zero_integer',
  POSITIVE_SAFE_INTEGER: 'positive_safe_integer',
  DATE: 'date',
  EIN: 'ein',
}

export const ERRORS = {
  REQUIRED: 'This is a required field',
  EMAIL: 'Invalid email format',
  PHONE: 'Invalid phone number format',
  BIGGER_THAN_ZERO: 'Number must be bigger than 0',
  PASSWORD:
    'Password must be at least 8 characters, and contain 1 uppercase letter, 1 lowercase letter, 1 digit and 1 special character',
  DOUBLE: 'Invalid double number format',
  POSITIVE_OR_ZERO_INTEGER: 'Invalid positive or zero integer',
  POSITIVE_SAFE_INTEGER: 'Invalid positive safe integer',
  DATE: 'Invalid date format',
  EQUAL_TO_NINE: 'Number must have exactly 9 digits',
}

export const PASSWORD_REGEX =
  /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[$@$!_%*?&+\-~|{}()[\]:;.,<>/]).{8,})$/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Validate Phone Number
 * @param {String} phoneNumber
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber) => {
  const regex = /^[0-9]{10}$/
  return regex.test(phoneNumber)
}

/**
 * Validate EIN Number
 * @param {String} einNumber
 * @returns {boolean}
 */
export const isValidEIN = (einNumber) => {
  const regex = /^\d{9}$/
  return regex.test(einNumber)
}

/**
 * Validate email
 * @param {String} email
 * @returns {boolean}
 */
export const isValidEmail = (email) => {
  return EMAIL_REGEX.test(email)
}

/**
 * Checks if a password is valid
 *  - min 8 char, 1 uppercase, 1 lowercase, 1 special character
 * @param string {String} input
 * @return {boolean}
 */
export const isValidPassword = (string) => {
  try {
    return PASSWORD_REGEX.test(string)
  } catch (e) {
    return false
  }
}

export const isValidDouble = (string) => {
  try {
    const regex = /^[\d.,']+$/
    return regex.test(string)
  } catch (e) {
    return false
  }
}

export const isValidInteger = (string) => {
  try {
    const regex = /^[-+]?\d*$/
    return regex.test(string)
  } catch (e) {
    return false
  }
}

/**
 * Is Empty checker
 * @param elem {String|Number|Object|Boolean}
 * @returns {boolean}
 */
export const isEmpty = (elem) => {
  return (
    elem === null ||
    elem === undefined ||
    !elem.toString().trim() ||
    JSON.stringify(elem) === '{}'
  )
}

/**
 * Validate SSN for a user
 * @param {String} ssn
 * @param {Boolean} last4Only - if true, ensures the length is equal to 4
 * @return {boolean}
 */
export const validateSSN = (ssn, last4Only = false) => {
  if (isEmpty(ssn)) {
    return false
  }
  if (last4Only) {
    return ssn.toString().length === 4
  }
  return true
}

/**
 * Generic Validate function
 * @param errorMap {Object} Field MAP to be validated.
 * @param state {Object} object or state to validate against
 *
 * Example: { email: FIELD_TYPES.EMAIL, name: FIELD_TYPES.REQUIRED, ...etc }
 * Also supports nested items like "contact.email" : FIELD_TYPES.EMAIL
 * @returns {*[]}
 */
export const validate = (errorMap, state) => {
  let isValid = true

  // Construct initial empty error map
  let errors = {}
  Object.keys(state).forEach((fieldName) => {
    if (checkType(state[fieldName]) === 'object') {
      errors[fieldName] = {}
    } else {
      errors[fieldName] = null
    }
  })

  // Iterate through all the required checks
  Object.keys(errorMap).forEach((fieldKey) => {
    const fieldType = errorMap[fieldKey]
    let fieldValue = state[fieldKey]
    // Case for nested field
    if (fieldKey.indexOf('.') > -1) {
      const [field, subField] = fieldKey.split('.')
      fieldValue = state[field][subField]
    }

    // Validate the field against one or more field types
    if (Array.isArray(fieldType)) {
      fieldType.forEach((type) => {
        ;[isValid, errors] = _checkField(
          fieldKey,
          fieldValue,
          type,
          isValid,
          errors
        )

        // If there was an error on the crt check don't go through the rest of them
        if (errors[fieldKey]) {
          return true
        }
      })
    } else {
      ;[isValid, errors] = _checkField(
        fieldKey,
        fieldValue,
        fieldType,
        isValid,
        errors
      )
    }
  })

  return [isValid, errors]
}

/**
 * Checks a field for validity
 * @param fieldKey {String} key of the field
 * @param fieldValue {String} value
 * @param type {(*)} type of the field (one of {@type VALIDATION_TYPES})
 * @param isValid {Boolean}
 * @param errors {Object} map for errors
 * @returns {*[]}
 * @private
 */
function _checkField(fieldKey, fieldValue, type, isValid, errors) {
  let errorFound = null

  switch (type) {
    case VALIDATION_TYPES.REQUIRED:
      if (isEmpty(fieldValue)) {
        errorFound = ERRORS.REQUIRED
      } else if (Array.isArray(fieldValue) && fieldValue.length === 0) {
        errorFound = ERRORS.REQUIRED
      }
      break

    case VALIDATION_TYPES.PHONE:
      if (fieldValue && !isValidPhoneNumber(fieldValue)) {
        errorFound = 'Invalid phone number format'
      }
      break

    case VALIDATION_TYPES.EIN:
      if (fieldValue && !isValidEIN(fieldValue)) {
        errorFound = 'Invalid EIN number format'
      }
      break

    case VALIDATION_TYPES.EMAIL:
      if (fieldValue && !isValidEmail(fieldValue)) {
        errorFound = 'Invalid email format'
      }
      break

    case VALIDATION_TYPES.PASSWORD:
      if (fieldValue && !isValidPassword(fieldValue)) {
        errorFound = ERRORS.PASSWORD
      }
      break
    case VALIDATION_TYPES.BIGGER_THAN_ZERO:
      if (fieldValue && fieldValue <= 0) {
        errorFound = ERRORS.BIGGER_THAN_ZERO
      }
      break

    case VALIDATION_TYPES.EQUAL_TO_NINE:
      if (fieldValue && fieldValue.length !== 9) {
        errorFound = ERRORS.EQUAL_TO_NINE
      }
      break

    case VALIDATION_TYPES.DOUBLE:
      if (fieldValue && !isValidDouble(fieldValue)) {
        errorFound = ERRORS.DOUBLE
      }
      break

    case VALIDATION_TYPES.POSITIVE_SAFE_INTEGER:
      const parsedValue = parseFloat(fieldValue)
      if (
        !isValidDouble(fieldValue) ||
        !Number.isSafeInteger(parsedValue) ||
        parsedValue <= 0
      ) {
        errorFound = ERRORS.POSITIVE_SAFE_INTEGER
      }
      break

    case VALIDATION_TYPES.POSITIVE_OR_ZERO_INTEGER:
      if (!isValidInteger(fieldValue) || fieldValue < 0) {
        errorFound = ERRORS.POSITIVE_OR_ZERO_INTEGER
      }
      break

    case VALIDATION_TYPES.DATE:
      if (
        !moment(fieldValue?.toString()).isValid() ||
        !moment().isAfter(moment(fieldValue?.toString()))
      ) {
        errorFound = ERRORS.DATE
      }
      break

    default:
      break
  }

  if (errorFound) {
    // Case for nested field
    if (fieldKey.indexOf('.') > -1) {
      const [field, subField] = fieldKey.split('.')
      const errorField = { ...errors[field], [subField]: errorFound }
      return [false, { ...errors, [field]: errorField }]
    }

    // Case for regular field
    return [false, { ...errors, [fieldKey]: errorFound }]
  }

  // No error found on this field
  return [isValid, errors]
}
