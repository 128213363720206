import moment from 'moment'
import { dateFormatUS } from 'common/constants'
import { sumFloat } from 'common/number'

export const formatEffectiveDate = (effectiveDate, format = dateFormatUS) => {
  const date = moment(effectiveDate).format(format)
  if (date === '01-01-0001') {
    return '-'
  }
  return date
}

export const getFundingDetailsObject = (apps) => {
  return {
    totalFunding: apps
      .map((app) => app.dueEnium)
      .reduce((total, amount) => sumFloat(total, amount), 0),
    totalDeposit: apps
      .map((app) => app.dealerDeposit)
      .reduce((total, amount) => sumFloat(total, amount), 0),
  }
}
