import Api from 'easy-fetch-api'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { mapCreatedAndLastModifiedDates } from 'common/date'

export const fetchLoanDocumentsList = (
  lenderId,
  pageNumber,
  itemsPerPage,
  filters = {}
) => {
  return getAllEnvelopes({ lenderId, pageNumber, itemsPerPage, ...filters })
}

export const getAllEnvelopes = (filters) => {
  return Api.get({
    url: `/Envelopes/list`,
    query: {
      pageNumber: filters?.pageNumber,
      itemsPerPage: filters?.itemsPerPage,
      sortBy: filters?.sortBy,
      searchBy: filters?.searchBy ? `name ${filters.searchBy}` : undefined,
    },
  })
    .then((res) => {
      const { envelopeDefinitions, itemCount } = res

      return {
        envelopeDefinitions:
          mapCreatedAndLastModifiedDates(envelopeDefinitions),
        itemCount,
      }
    })
    .catch(console.error)
}

export const fetchSingleEnvelope = (envelopeId) => {
  return Api.get({
    url: `/Envelopes/${envelopeId}`,
  }).catch(console.error)
}

export const fetchDocuments = (envelopeId) => {
  return Api.get({
    url: `/Envelopes/${envelopeId}/documents`,
  })
}

export const createEnvelope = (data) => {
  return Api.post({
    url: '/Envelopes',
    data: data,
  }).catch(console.error)
}

export const updateLoanDocument = (data) => {
  return Api.patch({
    url: '/Envelopes',
    data: data,
  }).catch(console.error)
}

export const deleteLoanDocument = (envelopeId, dispatch) => {
  return Api.delete({
    url: `/Envelopes/${envelopeId}`,
  })
    .then((response) => {
      showNotification(dispatch, {
        message: `Successfully deleted stipulation template.`,
      })
      return response
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        message: `An error occurred while deleting stipulation template. Please refresh the page and try again. ${error.title}`,
      })
    )
}

export const updateDocumentTokens = (data) => {
  return Api.patch({
    url: `/Envelopes/tokens`,
    data: data,
  }).catch(console.error)
}

export const associateStatesWithEnvelopes = (data, dispatch) => {
  return Api.post({
    url: `/Envelopes/associate-states`,
    data: data,
  })
    .then(() => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: `Successfully associated states.`,
      })
    })
    .catch(() =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while associate states.`,
      })
    )
}

export const copyTokens = (data, dispatch) => {
  return Api.post({
    url: `/Envelopes/copy-tokens`,
    data: data,
  })
    .then(() => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: `Successfully copied tokens.`,
      })
    })
    .catch(() =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while copying the doc tokens. Please refresh the page and try again.`,
      })
    )
}

export const getDocumentTypes = () =>
  Api.get({
    url: '/Envelopes/list-document-types',
  })

export const getDocumentTokens = () => {
  return Api.get({
    url: `/Envelopes/list-document-token-types`,
  }).catch(console.error)
}

export const getDocumentRoles = () => {
  return Api.get({
    url: '/Envelopes/list-document-role-types',
  }).catch((error) => console.error(error))
}
