import { useMemo, useState } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'

import CollapsibleSection from 'components/collapsible-section'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import LoanApplicationIcons from 'components/loan-app-icons/index'
import { STATE_IDS } from 'modules/loan-application/constants'
import { formatAddress } from 'common/utils'
import { getNtpTypeId } from 'modules/loan-application/ntp/helpers'
import { NTP_SECTIONS_IDS } from 'modules/loan-application/ntp/constants'
import { approveNTP } from 'modules/loan-application/actions'
import { UW_HUB_ACTIONS } from 'modules/uw-hub/actions'
import { columns } from './columns'

import './style.scss'

const { Chip } = components
const { ChevronDownIcon, ChevronUpIcon } = assets

const LoanApplicationDetails = ({
  loanApplicationId,
  data,
  canApproveStipulations,
}) => {
  const { dispatch } = useStore()
  const { t: translate } = useTranslation()
  const [
    isLoanApplicationStateDialogOpen,
    setIsLoanApplicationStateDialogOpen,
  ] = useState(false)
  const canChangeState = useMemo(() => {
    return (
      data.loanApplicationStateId === STATE_IDS.NTPDocsPendingApproval ||
      data.loanApplicationStateId === STATE_IDS.NTPDocsReSubmitted ||
      data.loanApplicationStateId ===
        STATE_IDS.InstallCompleteDocsPendingApproval ||
      data.loanApplicationStateId === STATE_IDS.InstallCompleteDocsReSubmitted
    )
  }, [data.loanApplicationStateId])

  const getChipLabel = (value) => {
    return (
      <span className="uw-hub-page__loan-app-details-loan-product-content">
        <span>{translate('uwHub.loanApplicationDetails.loanProduct')}</span>
        <span>{value ?? '-'}</span>
      </span>
    )
  }

  const getBorrowersNames = () => {
    let names = data.borrowerName
    if (data.coBorrowerName) {
      names += ` & ${data.coBorrowerName}`
    }

    return names
  }

  const isInInitialNTPState = useMemo(() => {
    return (
      getNtpTypeId(data.loanApplicationStateId) === NTP_SECTIONS_IDS.INITIAL
    )
  }, [data.loanApplicationStateId])

  const moveToNextState = () => {
    approveNTP(dispatch, isInInitialNTPState, false, loanApplicationId)
      .then((result) => {
        if (result) {
          setIsLoanApplicationStateDialogOpen(!isLoanApplicationStateDialogOpen)

          dispatch({
            type: UW_HUB_ACTIONS.SET_LOAN_APPLICATION_STATE,
            payload: result.loanApplicationState.id,
          })
        }
      })
      .catch(() => {})
  }

  return (
    <div className="uw-hub-page__loan-app-details">
      <div className="uw-hub-page__loan-app-details__wrapper">
        <div className="uw-hub-page__loan-app-details-icons">
          <Chip
            className="uw-hub-page__loan-app-details-loan-product"
            label={getChipLabel(data.loanProductSignature)}
          />
          <LoanApplicationIcons
            loanAmountDecreased={data.loanAmountDecreased}
            loanAmountIncreased={data.loanAmountIncreased}
            hasReversal={data.hasReversal}
            cancelled={data.cancelled}
            isOnHold={data.isOnHold}
            isDuplicateAddress={data.isDuplicateAddress}
            isDuplicateSsn={data.isDuplicateSsn}
            agingTier={data.agingTier.id}
            isAddressOverriden={data.isAddressOverriddenFromPropertyTitle}
            isAdverseActionNoticesDelivered={
              data.isAdverseActionNoticeEmailSent
            }
          />
        </div>
        <div className="uw-hub-page__loan-app-details-state-details">
          <div className="uw-hub-page__loan-app-details-applicants">
            <span className="uw-hub-page__loan-app-details-applicants-name">
              {getBorrowersNames()}
            </span>
            <span className="uw-hub-page__loan-app-details-service-address">
              {formatAddress(data.serviceAddress)}
            </span>
          </div>
          <div className="uw-hub-page__loan-app-details-state">
            <Button
              color={BUTTON_COLORS.SECONDARY}
              sx={{ pointerEvents: 'none' }}
            >
              {translate(
                'uwHub.loanApplicationDetails.loanApplicationCurrentState'
              )}
              :{' '}
              {translate(
                `loanApplication.statusCard.loanApplicationState.name.${data.loanApplicationStateId}`
              )}
            </Button>
            <div className="uw-hub-page__loan-app-details-next-state-button">
              <Button
                color={BUTTON_COLORS.INHERIT}
                variant={BUTTON_VARIANTS.OUTLINED}
                sx={{ pointerEvents: !canChangeState ? 'none' : 'auto' }}
                endIcon={
                  canChangeState ? (
                    !isLoanApplicationStateDialogOpen ? (
                      <ChevronDownIcon />
                    ) : (
                      <ChevronUpIcon />
                    )
                  ) : null
                }
                onFocusVisible={() => {}}
                onClick={() =>
                  setIsLoanApplicationStateDialogOpen(
                    !isLoanApplicationStateDialogOpen
                  )
                }
              >
                {translate(
                  `loanApplication.statusCard.loanApplicationState.actionDescription.nextStage`
                )}
                {translate(
                  `loanApplication.statusCard.loanApplicationState.residentialActionDescription.${data.loanApplicationStateId}`
                )}
              </Button>
              {canChangeState && isLoanApplicationStateDialogOpen && (
                <div className="uw-hub-page__loan-app-details-next-state-dialog">
                  <Button
                    color={BUTTON_COLORS.INHERIT}
                    variant={BUTTON_VARIANTS.OUTLINED}
                    disableElevation
                    onFocusVisible={() => {}}
                    onClick={moveToNextState}
                    disabled={!canApproveStipulations}
                  >
                    {isInInitialNTPState
                      ? translate(
                          'uwHub.loanApplicationDetails.approveInitialStipulation'
                        )
                      : translate(
                          'uwHub.loanApplicationDetails.approveFinalStipulation'
                        )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CollapsibleSection
        title={translate('uwHub.loanApplicationDetails.title')}
        columns={columns}
        data={[
          {
            loanAmount: data.loanAmount,
            applicationNumber: data.loanApplicationNumber,
            lender: data.loanProductLenderName,
            salesOrganization: data.selectedSalesOrganization,
            salesRep: data.salesRep,
            status: data.loanApplicationStatusId,
          },
        ]}
      ></CollapsibleSection>
    </div>
  )
}

LoanApplicationDetails.propTypes = {
  loanApplicationId: PropTypes.string,
  data: PropTypes.shape({
    borrowerName: PropTypes.string,
    coBorrowerName: PropTypes.string,
    serviceAddress: PropTypes.object,
    loanAmount: PropTypes.number,
    loanApplicationStateId: PropTypes.number,
    loanApplicationStatusId: PropTypes.number,
    loanProductSignature: PropTypes.string,
    loanApplicationNumber: PropTypes.string,
    loanProductLenderName: PropTypes.string,
    selectedSalesOrganization: PropTypes.string,
    salesRep: PropTypes.string,
    loanAmountDecreased: PropTypes.bool,
    loanAmountIncreased: PropTypes.bool,
    hasReversal: PropTypes.bool,
    cancelled: PropTypes.bool,
    isOnHold: PropTypes.bool,
    isDuplicateAddress: PropTypes.bool,
    isDuplicateSsn: PropTypes.bool,
    agingTier: PropTypes.bool,
    isAddressOverriddenFromPropertyTitle: PropTypes.bool,
    isAdverseActionNoticeEmailSent: PropTypes.bool,
  }),
  canApproveStipulations: PropTypes.bool.isRequired,
}

export default LoanApplicationDetails
