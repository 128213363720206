import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import './index.scss'

const ELEMENTS_COUNT = 18

const { PlusIcon, MessageChatCircleIcon, MinusSquareIcon } = assets

const Faq = () => {
  const { t: translate, i18n } = useTranslation()

  const [expandedItems, setExpandedItems] = useState({})

  const toggleExpand = (index) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index],
    }))
  }

  const faqElements = Array.from(Array(ELEMENTS_COUNT).keys()).map((index) => {
    const key = `loanApplication.ecgToolbox.faqModal.${index}`

    return {
      question: i18n.exists(`${key}.question`)
        ? translate(`${key}.question`)
        : null,
      answer: i18n.exists(`${key}.answer`) ? translate(`${key}.answer`) : null,
      optionsList: i18n.exists(`${key}.optionsList`)
        ? translate(`${key}.optionsList`)
        : null,
    }
  })

  return (
    <div>
      <div className="faq__header">
        <MessageChatCircleIcon sx={{ mr: 1 }} />
        {translate('loanApplication.ecgToolbox.faq')}
      </div>
      <div className="sales-toolbox__faq-container">
        {faqElements.map((faqElement, index) => {
          const isExpanded = expandedItems[index]

          return (
            <div key={index} className="faq__wrapper">
              {faqElement.question && (
                <div className="faq__question">
                  <div className="faq__title">{faqElement.question}</div>
                  {/* TODO: Replace with correct icons */}
                  {isExpanded ? (
                    <MinusSquareIcon
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => toggleExpand(index)}
                    />
                  ) : (
                    <PlusIcon
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => toggleExpand(index)}
                    />
                  )}
                </div>
              )}
              {faqElement.answer && isExpanded && (
                <div className="faq__text">
                  {faqElement.answer}

                  {faqElement.optionsList && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: faqElement.optionsList,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Faq
