import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { assets, colors, components } from '@ElementsCapitalGroup/enium-ui'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import DeleteModal from 'components/modal/delete-modal'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import StatusComponent, { STATUSES } from 'components/status'
import { ExpandableCard } from 'components/expandable-card'

import { AGREEMENTS_STATUSES } from 'modules/loan-application/constants'

import styles from './style.module.scss'

const { Chip } = components

const LenderAgreementCard = ({
  agreementText,
  onAgreementChange,
  loading,
  collapsed,
  viewOnly,
  status,
}) => {
  const { t: translate } = useTranslation()
  const [declineModalOpen, setDeclineModalOpen] = useState(false)
  const { AlertTriangleThickIcon } = assets
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const collapsibleItems = [
    <div className={styles.cardContent} key={0}>
      <div className={styles.cardContentContainer}>
        <div
          className={styles.cardText}
          dangerouslySetInnerHTML={{ __html: agreementText }}
        />
      </div>
      <AccessWrapper claims={CLAIMS.CAN_ACCEPT_AGREEMENTS}>
        <div className="form-buttons__glendale">
          <Button
            size={BUTTON_SIZES.SMALL}
            sx={{ ml: isMobileView ? 0 : '8px' }}
            onClick={() => onAgreementChange(AGREEMENTS_STATUSES.ACCEPTED)}
            loading={loading}
            disabled={viewOnly}
          >
            Accept Lender Agreement
          </Button>
          <Button
            size={BUTTON_SIZES.SMALL}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.ERROR}
            onClick={() => setDeclineModalOpen(true)}
            disabled={loading || viewOnly}
          >
            {translate('buttons.decline')}
          </Button>
        </div>
      </AccessWrapper>

      <DeleteModal
        isOpen={declineModalOpen}
        setModalOpen={setDeclineModalOpen}
        onSubmitModal={() => {
          setDeclineModalOpen(false)
          onAgreementChange(AGREEMENTS_STATUSES.DECLINED)
        }}
        title={translate('loanApplication.step3.declineLenderAgreement.title')}
        confirmationQuestion={translate(
          'loanApplication.step3.declineLenderAgreement.text',
          { phone_number: process.env.REACT_APP_ENIUM_PHONE_NO }
        )}
        confirmButtonText={translate('buttons.decline')}
      />
    </div>,
  ]

  return (
    <ExpandableCard
      sx={{ mb: '16px' }}
      headerProps={{
        sx: {
          backgroundColor: '#ffffff',
          padding: isMobileView ? '16px' : '24px',
        },
      }}
      expandableListStyle={{
        '& .MuiListItem-root': {
          padding: '0',
        },
      }}
      shouldExpandOnHeaderClick
      items={[]}
      collapsibleItems={collapsibleItems}
      isCollapsable={true}
      isCollapsed={collapsed}
      alwaysCollapsible={true}
      title={
        <div className={styles.cardTitleBigger}>
          <span>{translate('loanApplication.step3.lenderAgreementTitle')}</span>

          <div className={styles.status}>
            {status.name === AGREEMENTS_STATUSES.ACTION_REQUIRED ? (
              <Chip
                size="small"
                color={'warning'}
                label={status.description}
                status={STATUSES.ON_HOLD}
                icon={
                  <AlertTriangleThickIcon
                    sx={{
                      color: colors.yellow[500],
                      width: '12px',
                      height: '12px',
                    }}
                    color={colors.yellow[500]}
                  />
                }
              />
            ) : (
              <StatusComponent
                status={
                  status.name === AGREEMENTS_STATUSES.ACCEPTED
                    ? STATUSES.ACTIVE
                    : STATUSES.INACTIVE
                }
                label={status.description}
              />
            )}
          </div>
        </div>
      }
    />
  )
}

LenderAgreementCard.propTypes = {
  agreementText: PropTypes.string.isRequired,
  onAgreementChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  collapsed: PropTypes.bool,
  viewOnly: PropTypes.bool,
  status: PropTypes.object.isRequired,
}

export default LenderAgreementCard
