import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, colors, assets } from '@ElementsCapitalGroup/enium-ui'
import { useNavigate } from 'react-router-dom'
import { formatInUSFormat } from 'common/number'
import ChangeAmountModal from './change-amount-modal'
import ChangePropertyTitleModal from './change-property-title-name-modal'
import AddCoBorrowerModal from './add-coborrower-modal'
import './style.scss'

const { Dialog, IconButton, Divider } = components
const { CurrencyDollarIcon, FolderXIcon, Users02Icon } = assets

const LoanBlockedModal = ({
  loanApplicationId,
  loanFormData,
  dispatch,
  selfServiceCriterias,
  handleGetSelfServiceCriterias,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isChangeAmountOpen, setIsChangeAmountOpen] = useState(false)
  const [isChangeNameOpen, setIsChangeNameOpen] = useState(false)
  const [isAddCoBorrowerOpen, setIsAddCoBorrowerOpen] = useState(false)
  const navigate = useNavigate()
  const { t: translate } = useTranslation()
  const currentAmount = loanFormData.borrowerGeneralDetails.loanAmount
  const { firstName, lastName } = loanFormData.borrowerGeneralDetails
  const needsNewLoanAmount = useMemo(() => {
    return selfServiceCriterias.selfServiceCriterias?.find(
      (itm) => itm.criteriaSelfServiceType.id === 0
    )
  }, [selfServiceCriterias])

  const needsCoBorrower = useMemo(() => {
    return selfServiceCriterias.selfServiceCriterias?.find(
      (itm) => itm.criteriaSelfServiceType.id === 1
    )
  }, [selfServiceCriterias])

  const needsCoBorrowerOrNewLoanAmount = useMemo(() => {
    return selfServiceCriterias.selfServiceCriterias?.find(
      (itm) => itm.criteriaSelfServiceType.id === 2
    )
  }, [selfServiceCriterias])

  const needsPropertyTitleNameChange = useMemo(() => {
    return selfServiceCriterias.selfServiceCriterias?.find(
      (itm) => itm.criteriaSelfServiceType.id === 3
    )
  }, [selfServiceCriterias])

  const maxAmount = useMemo(() => {
    return (
      selfServiceCriterias.selfServiceCriterias?.find(
        (itm) =>
          itm.criteriaSelfServiceType.id === 0 ||
          itm.criteriaSelfServiceType.id === 2
      )?.requestedValue || 0
    )
  }, [selfServiceCriterias])

  const propertyTitleName = useMemo(() => {
    return (
      selfServiceCriterias.selfServiceCriterias?.find(
        (itm) => itm.criteriaSelfServiceType.id === 3
      )?.requestedValue || ''
    )
  }, [selfServiceCriterias])

  return (
    <>
      <ChangeAmountModal
        dispatch={dispatch}
        loanApplicationId={loanApplicationId}
        isOpen={isChangeAmountOpen}
        maxAmount={maxAmount}
        onClose={() => {
          setIsChangeAmountOpen(false)
          setIsOpen(true)
        }}
        handleGetSelfServiceCriterias={handleGetSelfServiceCriterias}
      />

      <ChangePropertyTitleModal
        dispatch={dispatch}
        loanApplicationId={loanApplicationId}
        isOpen={isChangeNameOpen}
        propertyTitleName={propertyTitleName}
        handleGetSelfServiceCriterias={handleGetSelfServiceCriterias}
        borrowerInfo={loanFormData.borrowerGeneralDetails}
        onClose={() => {
          setIsChangeNameOpen(false)
          setIsOpen(true)
        }}
      />

      <AddCoBorrowerModal
        dispatch={dispatch}
        loanApplicationId={loanApplicationId}
        loanFormData={loanFormData}
        isOpen={isAddCoBorrowerOpen}
        handleGetSelfServiceCriterias={handleGetSelfServiceCriterias}
        onClose={() => {
          setIsAddCoBorrowerOpen(false)
          setIsOpen(true)
        }}
      />

      <Dialog
        open={isOpen}
        sx={{
          backdropFilter: 'blur(10px)',
        }}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '600px',
            '& .MuiDialogTitle-root': {
              alignItems: 'center',
              fontSize: '1.125rem',
              paddingBottom: '24px',
            },
          },
        }}
        title={translate('loanApplication.blocked.title')}
        disableEscapeKeyDown={true}
        onClose={() => {
          navigate('/applications')
        }}
      >
        <div className="loan-blocked__text" style={{ fontSize: '1rem' }}>
          {translate('loanApplication.blocked.description')}
        </div>

        <div
          className="loan-blocked__option__title"
          style={{ marginTop: '32px' }}
        >
          {translate('loanApplication.blocked.options')}
        </div>
        {(needsNewLoanAmount || needsCoBorrowerOrNewLoanAmount) && (
          <>
            <div className="loan-blocked__option">
              <div>
                <IconButton
                  variant={'outlined'}
                  className="loan-blocked__option__svg"
                >
                  <CurrencyDollarIcon sx={{ color: colors.grey[700] }} />
                </IconButton>
              </div>
              <div>
                <div className="loan-blocked__option__title">
                  {currentAmount < maxAmount
                    ? translate('loanApplication.blocked.loanAmountTooLow')
                    : translate('loanApplication.blocked.loanAmountTooHigh')}
                </div>
                <div className="loan-blocked__text">
                  {translate('loanApplication.blocked.loanAmount1')}
                  <span className="loan-blocked__link">
                    ${formatInUSFormat(currentAmount, 0)}.
                  </span>
                </div>
                <div
                  className="loan-blocked__text"
                  style={{ marginTop: '12px' }}
                >
                  {translate('loanApplication.blocked.loanAmount2')}
                  <span className="loan-blocked__link">
                    ${formatInUSFormat(maxAmount, 0)}.
                  </span>
                </div>
              </div>
              <div className="loan-blocked__option__action">
                <span
                  className="loan-blocked__link"
                  onClick={() => {
                    setIsChangeAmountOpen(true)
                    setIsOpen(false)
                  }}
                >
                  {translate('loanApplication.blocked.changeAmount')}
                </span>
              </div>
            </div>

            <Divider />
          </>
        )}
        {(needsCoBorrower || needsCoBorrowerOrNewLoanAmount) && (
          <>
            <div className="loan-blocked__option">
              <div>
                <IconButton
                  size="48px"
                  variant={'outlined'}
                  className="loan-blocked__option__svg"
                >
                  <Users02Icon sx={{ color: colors.grey[700] }} />
                </IconButton>
              </div>
              <div>
                <div className="loan-blocked__option__title">
                  {translate('loanApplication.blocked.addCoBorrower')}
                </div>
                <div className="loan-blocked__text">
                  {translate('loanApplication.blocked.addCoBorrowerText')}
                </div>
              </div>
              <div className="loan-blocked__option__action">
                <span
                  className="loan-blocked__link"
                  onClick={() => {
                    setIsAddCoBorrowerOpen(true)
                    setIsOpen(false)
                  }}
                >
                  {translate('loanApplication.blocked.addCoBorrowerCta')}
                </span>
              </div>
            </div>
            <Divider />
          </>
        )}
        {needsPropertyTitleNameChange && (
          <div className="loan-blocked__option">
            <div>
              <IconButton
                variant={'outlined'}
                className="loan-blocked__option__svg"
              >
                <FolderXIcon sx={{ color: colors.grey[700] }} />
              </IconButton>
            </div>
            <div>
              <div className="loan-blocked__option__title">
                {translate('loanApplication.blocked.propertyMismatch')}
              </div>
              <div className="loan-blocked__text">
                {translate('loanApplication.blocked.propertyText')
                  .replace('{1}', `"${firstName} ${lastName}"`)
                  .replace('{2}', `"${propertyTitleName}"`)}
              </div>
            </div>
            <div className="loan-blocked__option__action">
              <span
                className="loan-blocked__link"
                onClick={() => {
                  setIsChangeNameOpen(true)
                  setIsOpen(false)
                }}
              >
                {translate('loanApplication.blocked.adjustName')}
              </span>
            </div>
          </div>
        )}

        <div
          className="loan-blocked__text"
          style={{ marginTop: '8px', fontSize: '1rem' }}
        >
          {translate('loanApplication.blocked.contact1')}
          <a
            href={translate('loanApplication.contactUs.phoneNumberTelValue')}
            style={{ color: colors.blue[500], fontWeight: 600 }}
          >
            {translate('loanApplication.contactUs.phoneNumberValue')}
          </a>{' '}
          {translate('loanApplication.blocked.contact2')}
          <a
            href={translate(
              'loanApplication.contactUs.textPhoneNumberTelValue'
            )}
            style={{ color: colors.blue[500], fontWeight: 600 }}
          >
            {translate('loanApplication.contactUs.textPhoneNumberValue')}
          </a>
        </div>
      </Dialog>
    </>
  )
}

LoanBlockedModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  loanFormData: PropTypes.object.isRequired,
  selfServiceCriterias: PropTypes.object,
  handleGetSelfServiceCriterias: PropTypes.func.isRequired,
}

export default LoanBlockedModal
