import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { STEP_KEY_NAMES } from 'modules/loan-application/constants'
import { getStatesToSteps } from 'modules/loan-application/states'

import { useMediaQuery } from 'common/hooks'

import { styles } from './style'

const { Dialog } = components
const { ArrowNarrowRightIcon } = assets

const LegendModal = ({ isOpen, onClose, currentState }) => {
  const { t: translate } = useTranslation()
  const { state } = useStore()
  const { loanFormData } = state.flow

  const isPtoNotMandatory = loanFormData.isPtoNotMandatory

  const statesToSteps = getStatesToSteps({
    isPtoNotMandatory,
    isCommercialApp: false,
  })

  const lastCompletedStep = statesToSteps[currentState?.name]

  const [activeStep, setActiveStep] = useState(lastCompletedStep)
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const stepsConfig = {
    0: {
      subtitleKey: 'loanApplication.legendModal.0.subtitle',
      content: [
        {
          titleKey: 'loanApplication.legendModal.0.applicationStarted.title',
          listKey: 'loanApplication.legendModal.0.applicationStarted.list',
        },
      ],
    },
    1: {
      subtitleKey: 'loanApplication.legendModal.1.subtitle',
      content: [
        {
          titleKey: 'loanApplication.legendModal.1.loanSelected.title',
          listKey: 'loanApplication.legendModal.1.loanSelected.list',
        },
      ],
    },
    2: {
      subtitleKey: 'loanApplication.legendModal.2.subtitle',
      content: [
        {
          titleKey: 'loanApplication.legendModal.2.agreementsAccepted.title',
          listKey: 'loanApplication.legendModal.2.agreementsAccepted.list',
        },
        {
          titleKey: 'loanApplication.legendModal.2.applicationAuthorized.title',
          listKey: 'loanApplication.legendModal.2.applicationAuthorized.list',
        },
      ],
    },
    3: {
      subtitleKey: 'loanApplication.legendModal.3.subtitle',
      content: [
        {
          titleKey: 'loanApplication.legendModal.3.documentsGenerated.title',
          listKey: 'loanApplication.legendModal.3.documentsGenerated.list',
        },
        {
          titleKey: 'loanApplication.legendModal.3.documentsSigned.title',
          listKey: 'loanApplication.legendModal.3.documentsSigned.list',
        },
      ],
    },
    4: {
      subtitleKey: 'loanApplication.legendModal.4.subtitle',
      content: [
        {
          titleKey:
            'loanApplication.legendModal.4.ntpDocsPendingApproval.title',
          listKey: 'loanApplication.legendModal.4.ntpDocsPendingApproval.list',
        },
        {
          titleKey: 'loanApplication.legendModal.4.ntpFundsRequested.title',
          listKey: 'loanApplication.legendModal.4.ntpFundsRequested.list',
        },
        {
          titleKey: 'loanApplication.legendModal.4.ntpFundsGranted.title',
          listKey: 'loanApplication.legendModal.4.ntpFundsGranted.list',
        },
      ],
    },
    5: {
      subtitleKey: 'loanApplication.legendModal.5.subtitle',
      content: [
        {
          titleKey: 'loanApplication.legendModal.5.finalInspection.title',
          listKey: 'loanApplication.legendModal.5.finalInspection.list',
        },
      ],
    },
    6: {
      subtitleKey: 'loanApplication.legendModal.6.subtitle',
      content: [
        {
          titleKey: 'loanApplication.legendModal.6.ptoDocsPending.title',
          listKey: 'loanApplication.legendModal.6.ptoDocsPending.list',
        },
        {
          titleKey: 'loanApplication.legendModal.6.ptoFundsRequested.title',
          listKey: 'loanApplication.legendModal.6.ptoFundsRequested.list',
        },
        {
          titleKey: 'loanApplication.legendModal.6.ptoFundsGranted.title',
          listKey: 'loanApplication.legendModal.6.ptoFundsGranted.list',
        },
      ],
    },
  }

  const renderListContent = (content) =>
    content.map((item, index) => (
      <div key={index}>
        <span style={{ color: 'black', fontSize: '14px', fontWeight: '500' }}>
          {translate(item.titleKey)}
        </span>
        <div
          style={styles.listContent}
          dangerouslySetInnerHTML={{
            __html: translate(item.listKey),
          }}
        ></div>
      </div>
    ))

  const renderStepContent = (step) => {
    const stepData = stepsConfig?.[step]

    return (
      <div>
        <div style={styles.stepSubtitle}>
          {translate(stepData?.subtitleKey)}
        </div>
        {stepData?.content && renderListContent(stepData.content)}
      </div>
    )
  }

  const handleOnClose = (event, reason) => {
    if (reason !== 'closeButtonClicked') {
      return
    }
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      title={'Loan Application - Progress Tracker'}
      sx={{ backdropFilter: 'blur(10px)' }}
      PaperProps={{ sx: styles.dialogPaper }}
      disableEscapeKeyDown={true}
      onClose={handleOnClose}
    >
      <div style={styles.dialogContainer(isTabletView, isMobileView)}>
        <div style={styles.stepContainer(isTabletView, isMobileView)}>
          {STEP_KEY_NAMES.map((itm, key) => (
            <div
              key={key}
              style={styles.stepItem(isTabletView, activeStep, key)}
              onClick={() => setActiveStep(key)}
            >
              <div>
                <div style={styles.currentNextHeader}>
                  {lastCompletedStep === key
                    ? translate(
                        'loanApplication.statusCard.loanApplicationState.current'
                      )
                    : lastCompletedStep > key
                    ? 'Completed'
                    : translate(
                        'loanApplication.statusCard.loanApplicationState.upcoming'
                      )}
                </div>
                <div style={styles.stepTitle}>{translate(itm)}</div>
                {isMobileView && activeStep === key && (
                  <div style={styles.mobileStepContent}>
                    {renderStepContent(activeStep)}
                  </div>
                )}

                {isTabletView && key < STEP_KEY_NAMES.length - 1 && (
                  <div style={styles.arrowWrapper}>
                    <div style={styles.arrow}>
                      <ArrowNarrowRightIcon
                        sx={{
                          transform: 'rotate(90deg)',
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {!isTabletView && key < STEP_KEY_NAMES.length - 1 && (
                <div style={styles.arrow}>
                  <ArrowNarrowRightIcon />
                </div>
              )}
            </div>
          ))}
        </div>
        {!isMobileView && (
          <div style={styles.contentWrapper(isTabletView)}>
            {renderStepContent(activeStep)}
          </div>
        )}
      </div>
    </Dialog>
  )
}

LegendModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  maxAmount: PropTypes.number.isRequired,
  currentState: PropTypes.object.isRequired,
  handleGetSelfServiceCriterias: PropTypes.func.isRequired,
}

export default LegendModal
