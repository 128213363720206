import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import { ExpandableCard } from 'components/expandable-card'
import EvaluationSession from './session'
import EvaluationHistory from './history'
import { expandableCard } from '../common/styles'

import './style.scss'

const Evaluation = ({ evaluationSessions, selectedLoanProductId }) => {
  const { t: translate } = useTranslation()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const currentEvaluationSession = useMemo(() => {
    return evaluationSessions?.[evaluationSessions.length - 1]
  }, [evaluationSessions])

  const collapsibleItems = [
    <div key={0} className="uw-hub-page__evaluation-content">
      <EvaluationSession
        selectedLoanProductId={selectedLoanProductId}
        evaluationSession={currentEvaluationSession}
      />
      <EvaluationHistory
        evaluationSessionsHistory={evaluationSessions?.slice(0, -1)}
      />
    </div>,
  ]

  return (
    <div className="uw-hub-page__evaluation">
      <ExpandableCard
        headerProps={expandableCard.headerProps(isMobileView)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={translate('uwHub.evaluation.title')}
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        isCollapsed={isTabletView}
        alwaysCollapsible={true}
        collapsibleItemsStyles={{
          sx: {
            padding: isMobileView ? 0 : '24px',
          },
        }}
      />
    </div>
  )
}

Evaluation.propTypes = {
  evaluationSessions: PropTypes.object.isRequired,
  selectedLoanProductId: PropTypes.string.isRequired,
}

export default Evaluation
