import React from 'react'
import PropTypes from 'prop-types'
import HistoryTracking, {
  HISTORY_TRACKING_TYPES,
} from 'components/history-tracking'
import './style.scss'

const FundingHistoryTracking = ({ loanApp }) => {
  return (
    <div className="afp__expanded">
      <HistoryTracking
        id={loanApp.dailyFundingLoanApplicationId}
        type={HISTORY_TRACKING_TYPES.AFP_FUNDING_HISTORY}
      />
    </div>
  )
}

FundingHistoryTracking.propTypes = {
  loanApp: PropTypes.object.isRequired,
}

export default FundingHistoryTracking
