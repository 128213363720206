import ValidationCheck from './validation-check'

export function renderCell(data) {
  if (!data || (!data.value && data.dataValidationId === undefined)) {
    return '-'
  }

  return (
    <div className="align-center" style={{ textWrap: 'wrap' }}>
      {data.dataValidationId !== undefined && (
        <ValidationCheck
          dataValidationId={data.dataValidationId}
          reason={data.validationReason}
        />
      )}
      {data.value ? data.value : '-'}
    </div>
  )
}
