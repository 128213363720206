import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ExpandableCard } from 'components/expandable-card'
import Input from 'components/input'
import { formatInUSFormat, sumFloat } from 'common/number'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import {
  DEPRECIATION_RATES,
  ROW_TYPES,
  ROWS,
  SUBTOTAL_DEFAULT,
} from './constants'
import { styles } from '../style'

const { Tooltip } = components
const { HelpCircleIcon, PlusIcon, TrashIcon } = assets

const SelfEmployedSections = ({
  years,
  sections,
  onSectionChange,
  onSectionAdd,
  onSectionRemove,
}) => {
  const [isCollapsed, setIsCollapsed] = useState([])
  useEffect(() => {
    setIsCollapsed(sections.map((idx) => isCollapsed[idx] || false))
  }, [sections.length])

  /** Generic field change (ex section name) */
  const onSectionFieldChange = (sectionIdx, fieldName) => (value) => {
    onSectionChange(sectionIdx, { ...sections[sectionIdx], [fieldName]: value })
  }

  /** Row numeric value change -> also re-calculates sub-totals */
  const onRowFieldChange = (sectionIdx, yearIndex, rowId) => (value) => {
    if (!value) {
      value = 0
    }
    const section = sections[sectionIdx]
    const sectionValues = section.rowValues
    const updatedRowValues = {
      ...sectionValues,
      [rowId]: {
        ...(sectionValues?.[rowId] || {}),
        [yearIndex]: value,
      },
    }
    // Special case for rows 10 & 11
    if (rowId === 10) {
      const depreciationRate = DEPRECIATION_RATES[years[yearIndex]] || 0
      updatedRowValues['11b'] = {
        ...updatedRowValues['11b'],
        [yearIndex]: (parseFloat(value) * depreciationRate).toFixed(2),
      }
    }

    // Calculate Sub-total
    const subTotal = { ...SUBTOTAL_DEFAULT }
    if (!section.noSubtotal) {
      years.forEach((year, yearIndex) => {
        Object.entries(updatedRowValues).forEach(([rowId, rowValues]) => {
          const operation = ROWS[rowId].isNegative ? -1 : 1
          if (
            [
              ROWS[10].id,
              ROWS[36].id,
              ROWS[47].id,
              ROWS[59].id,
              ROWS[60].id,
            ].includes(rowId.toString())
          ) {
            return
          }
          const value = parseFloat(rowValues[yearIndex] || 0) * operation
          subTotal[yearIndex] = sumFloat(subTotal[yearIndex], value)
        })
      })
    }

    let form1065Subtotal = { ...SUBTOTAL_DEFAULT }
    let form1120Subtotal = { ...SUBTOTAL_DEFAULT }
    let form1120CorporationSubtotal = { ...SUBTOTAL_DEFAULT }

    // Calculate partial sub-totals for Partnership and Corporation
    if (section.partialSubtotals) {
      section.partialSubtotals = section.partialSubtotals.map((subtotal) => {
        const rowsToConsider = ROWS[subtotal.id].rows
        const subTotalValue = { ...SUBTOTAL_DEFAULT }
        rowsToConsider.forEach((rowId) => {
          const rowValues = updatedRowValues[rowId] || {}
          if (!Object.keys(rowValues).length) {
            return
          }
          years.forEach((year, yearIndex) => {
            const operation = ROWS[rowId].isNegative ? -1 : 1
            const value = parseFloat(rowValues[yearIndex] || 0) * operation
            subTotalValue[yearIndex] = sumFloat(subTotalValue[yearIndex], value)
          })
        })

        if (subtotal.id === ROWS.form_1065_subtotal.id) {
          form1065Subtotal = subTotalValue
        } else if (subtotal.id === ROWS.form_1120_subtotal.id) {
          form1120Subtotal = subTotalValue
        } else if (subtotal.id === ROWS.form_1120_corporation_subtotal.id) {
          form1120CorporationSubtotal = subTotalValue
        }
        return { ...subtotal, id: subtotal.id, value: subTotalValue }
      })
    }

    // Special case for rows 36b
    if (form1065Subtotal[yearIndex] && updatedRowValues[36]?.[yearIndex]) {
      const row36Value = parseFloat(updatedRowValues[36][yearIndex] || 0)
      updatedRowValues['36b'] = {
        ...updatedRowValues['36b'],
        [yearIndex]: (
          (parseFloat(form1065Subtotal[yearIndex]) * row36Value) /
          100
        ).toFixed(2),
      }
    }
    // Special case for rows 47b
    if (form1120Subtotal[yearIndex] && updatedRowValues[47]?.[yearIndex]) {
      const row36Value = parseFloat(updatedRowValues[47][yearIndex] || 0)
      updatedRowValues['47b'] = {
        ...updatedRowValues['47b'],
        [yearIndex]: (
          (parseFloat(form1120Subtotal[yearIndex]) * row36Value) /
          100
        ).toFixed(2),
      }
    }
    // Special case for rows 60b
    if (
      form1120CorporationSubtotal[yearIndex] &&
      updatedRowValues[59]?.[yearIndex]
    ) {
      const row59Value = parseFloat(updatedRowValues[59][yearIndex] || 0)
      const row60Value = parseFloat(updatedRowValues[60]?.[yearIndex] || 0)
      const row60bValue =
        (parseFloat(form1120CorporationSubtotal[yearIndex]) * row59Value) / 100

      updatedRowValues['60b'] = {
        ...updatedRowValues['60b'],
        [yearIndex]: (row60bValue - row60Value).toFixed(2),
      }
    }

    onSectionChange(sectionIdx, {
      ...sections[sectionIdx],
      rowValues: updatedRowValues,
      partialSubtotals: section.partialSubtotals,
      subTotal,
    })
  }

  const _renderRowId = (row) => {
    if (row.type === ROW_TYPES.HEADER) {
      return <div style={styles.selfEmployed.headerRow}>{row.label}</div>
    }
    if (row.type === ROW_TYPES.SUBTOTAL) {
      return null
    }
    return (
      <>
        <div
          style={{
            ...styles.selfEmployed.rowLabel,
            marginRight: '20px',
            width: '27px',
          }}
        >
          {!row.hideId ? `#${row.id}` : ''}
        </div>
        <div style={styles.selfEmployed.rowLabel}>{row.label}</div>
        {row.tooltip && (
          <Tooltip title={row.tooltip}>
            <span style={styles.selfEmployed.tooltip}>
              <HelpCircleIcon style={styles.selfEmployed.svg} />
            </span>
          </Tooltip>
        )}
      </>
    )
  }

  const _renderRow = (section, sectionIdx, row, rowId) => {
    if (row.type === ROW_TYPES.HEADER) {
      return null
    }
    if (row.type === ROW_TYPES.SUBTOTAL) {
      return (
        <div
          style={{
            ...styles.selfEmployed.rowInputs,
            margin: '24px 0 0 auto',
          }}
        >
          {_renderSubtotal(
            section.partialSubtotals.find((el) => el.id === row.id)?.value
          )}
        </div>
      )
    }

    return (
      <div style={styles.selfEmployed.rowInputs}>
        {years.map((year, yearIndex) => {
          let rowValue = section.rowValues?.[rowId]?.[yearIndex] || ''
          if (rowId === '11a') {
            rowValue = DEPRECIATION_RATES[year]
          }
          return (
            <div key={yearIndex}>
              {row.isReadOnly ? (
                <div style={styles.selfEmployed.rowReadOnly}>
                  ${rowValue || 0}
                </div>
              ) : (
                <Input
                  type={row.type}
                  value={rowValue}
                  placeholder={rowPlaceholder[row.type] || ''}
                  onChange={onRowFieldChange(sectionIdx, yearIndex, rowId)}
                  label={
                    row.type === ROW_TYPES.PERCENTAGE
                      ? 'Percentage'
                      : 'Total Earnings'
                  }
                  error={row.isNegative}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const _renderSubtotal = (subTotal) => {
    return (
      <div style={styles.selfEmployed.subTotal}>
        {years.map((year, yearIndex) => (
          <div
            key={yearIndex}
            style={{ ...styles.selfEmployed.rowReadOnly, paddingTop: 0 }}
          >
            <span style={{ marginRight: '16px' }}>Subtotal</span> $
            {formatInUSFormat(subTotal?.[yearIndex] || 0)}
          </div>
        ))}
      </div>
    )
  }

  // Render each section
  return sections.map((section, sectionIdx) => (
    <div key={sectionIdx}>
      {section.title && (
        <div style={{ margin: '24px 0 16px' }}>
          <div style={styles.selfEmployed.sectionTitle}>{section.title}</div>
          <div style={styles.selfEmployed.sectionDescription}>
            {section.subTitle}
          </div>
        </div>
      )}
      <ExpandableCard
        title={
          <div style={styles.selfEmployed.sectionTitle}>
            <div>
              {section.label}
              {section.description && (
                <div style={styles.selfEmployed.sectionDescription}>
                  {section.description}
                </div>
              )}
            </div>
            {section.canAddMultiple && (
              <div style={styles.selfEmployed.addMore}>
                <div
                  style={{
                    ...styles.plusTab,
                    ...styles.selfEmployed.addMoreItem,
                  }}
                  onClick={() => onSectionAdd(section.type, sectionIdx)}
                >
                  <PlusIcon sx={styles.plusTabSvg} />
                </div>
                {sections.filter((s) => s.type === section.type).length > 1 && (
                  <div
                    style={{
                      ...styles.plusTab,
                      ...styles.selfEmployed.addMoreItem,
                    }}
                    onClick={() => onSectionRemove(sectionIdx)}
                  >
                    <TrashIcon sx={styles.plusTabSvg} />
                  </div>
                )}
              </div>
            )}
          </div>
        }
        alwaysCollapsible={true}
        isCollapsable={true}
        isCollapsed={isCollapsed[sectionIdx]}
        headerProps={{
          sx: { ...styles.expandableCard.header },
        }}
        sx={styles.card}
        items={[]}
        collapsibleItems={[
          <div key={sectionIdx} style={{ width: '100%' }}>
            {section.hasNameField ? (
              <Input
                value={section.name || ''}
                onChange={onSectionFieldChange(sectionIdx, 'name')}
                label="Name"
              />
            ) : null}

            {/* Section Groups */}
            {section.rows.map((group, groupIdx) => (
              <div
                key={`${sectionIdx}-${groupIdx}`}
                style={styles.selfEmployed.section}
              >
                {/* Section rows */}
                {group.map((rowId) => {
                  const row = ROWS[rowId]

                  return (
                    <div
                      key={`${sectionIdx}-${groupIdx}-${rowId}`}
                      style={styles.selfEmployed.row}
                    >
                      {_renderRowId(row)}
                      {_renderRow(section, sectionIdx, row, rowId)}
                    </div>
                  )
                })}
                <hr style={styles.selfEmployed.sectionHr} />
              </div>
            ))}

            {/*  Section Sub-total */}
            {!section.noSubtotal && _renderSubtotal(section.subTotal)}
          </div>,
        ]}
      ></ExpandableCard>
    </div>
  ))
}

const rowPlaceholder = {
  [ROW_TYPES.CURRENCY]: '$',
  [ROW_TYPES.PERCENTAGE]: '%',
  [ROW_TYPES.NUMBER]: '',
}

SelfEmployedSections.propTypes = {
  years: PropTypes.array.isRequired,
  sections: PropTypes.array.isRequired,
  onSectionChange: PropTypes.func.isRequired,
  onSectionAdd: PropTypes.func.isRequired,
  onSectionRemove: PropTypes.func.isRequired,
}

export default SelfEmployedSections
