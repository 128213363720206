import React from 'react'
import PropTypes from 'prop-types'
import { colors, components } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'

const { Chip } = components

export const STATUSES = {
  UNDEFINED: 'default',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ON_HOLD: 'on-hold',
  PARTIAL: 'partial',
}

const Status = ({ status = STATUSES.UNDEFINED, label, textKey }) => {
  const { t: translate } = useTranslation()
  const text = textKey ? translate(textKey) : label || status

  return (
    <Chip
      size="small"
      label={text}
      color={STATUS_TO_CHIP_MAPPING[status]}
      icon={
        <div
          style={{
            background: COLOR_MAPPING[status],
            borderRadius: '50%',
            width: '8px',
            height: '8px',
            marginLeft: '6px',
          }}
        ></div>
      }
    />
  )
}

const COLOR_MAPPING = {
  [STATUSES.UNDEFINED]: colors.grey[500],
  [STATUSES.ACTIVE]: colors.green[500],
  [STATUSES.INACTIVE]: colors.red[500],
  [STATUSES.ON_HOLD]: colors.yellow[500],
  [STATUSES.PARTIAL]: colors.blue[700],
}

const STATUS_TO_CHIP_MAPPING = {
  [STATUSES.ACTIVE]: 'success',
  [STATUSES.INACTIVE]: 'error',
  [STATUSES.ON_HOLD]: 'warning',
}

Status.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUSES)),
  label: PropTypes.string,
  textKey: PropTypes.string,
}

export default Status
