import React, { useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import PropTypes from 'prop-types'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import withScrolling from 'react-dnd-scrolling'

import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { VALIDATION_TYPES, validate } from 'components/validator'
import { history } from 'components/history'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

import { setLoading } from 'modules/global/actions'
import {
  components,
  assets,
  hooks,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'
import { useStore } from 'store'
import TextField from 'components/input'
import TextArea from 'components/textarea'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { TemplateContent } from './template-content'

import {
  updateTemplate as updateTemplateOnServer,
  fetchDefinitions,
  fetchTemplate,
  deleteTemplate,
  createTemplate,
} from './actions'

import './index.scss'
import '../../../styles/admin.scss'

const { Button, Dialog, Dropdown, IconButton, Breadcrumbs } = components
const { Unstable_Grid: Grid } = unstable_components
const { TrashIcon, CheckIcon } = assets
const { useBoolean } = hooks

const StipulationTemplatesSettings = ({ isCreate }) => {
  const { dispatch } = useStore()

  const [templateToRender, setTemplateToRender] = useState({
    ...mockedTemplateState,
  })
  const [stipulationDefinitions, setStipulationDefinitions] = useState([])
  const [isCommercial, setIsCommercial] = useState(false)
  const [isOpen, { off, on }] = useBoolean()
  const [errors, setErrors] = useState({})
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [selected, setSelected] = useState({
    initialStipulations: [],
    finalStipulations: [],
    commercialStipulations: [],
  })
  const [stipulationType, setStipulationType] = useState(templateOptions?.[0])
  const ScrollingComponent = withScrolling('div')

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(dispatch, true)
    if (!isCreate) {
      fetchTemplate(id)
        .then((res) => {
          setTemplateToRender(res)
          setIsCommercial(
            !!(
              res.commercialStipulations?.length &&
              !(
                res.initialStipulations?.length || res.finalStipulations?.length
              )
            )
          )
        })
        .finally(() => {
          handleFetchDefinitions()
        })
    } else {
      handleFetchDefinitions()
    }
  }, [isCreate])

  useEffect(() => {
    setIsCommercial(stipulationType.id === 1)
  }, [stipulationType])

  const handleFetchDefinitions = () => {
    fetchDefinitions()
      .then(setStipulationDefinitions)
      .finally(() => {
        setLoading(dispatch, false)
      })
  }

  const validateTemplate = (template) => {
    let [isValid, errors] = validate(TEMPLATE_ERROR_MAP, template)
    errors.decisions = []

    const validateDecisions = (stipulations) => {
      let [isValid, errors] = [true, stipulations.map(() => ({}))]
      stipulations.forEach((elem, idx) => {
        const [innerValid, innerErrors] = validate(STIPULATION_ERROR_MAP, elem)
        if (!innerValid) {
          isValid = false
          errors[idx] = innerErrors
        }
        if (elem.stipulations) {
          const [childValid, childErrors] = validateDecisions(elem.stipulations)
          if (!childValid) {
            isValid = false
            errors[idx] = { ...errors[idx], stipulations: childErrors }
          }
        }
      })
      return [isValid, errors]
    }

    if (templateToRender.isCommercial) {
      const [validCommercialStipulations, commercialStipulationsErr] =
        validateDecisions(template.commercialStipulations)
      if (!validCommercialStipulations) {
        isValid = false
        errors = {
          ...errors,
          commercialStipulations: commercialStipulationsErr,
        }
      }
    } else {
      const [validInitialStipulations, initialStipulationsErr] =
        validateDecisions(template.initialStipulations)
      if (!validInitialStipulations) {
        isValid = false
        errors = { ...errors, initialStipulations: initialStipulationsErr }
      }

      const [validFinalStipulations, finalStipulationsErr] = validateDecisions(
        template.finalStipulations
      )
      if (!validFinalStipulations) {
        isValid = false
        errors = { ...errors, finalStipulations: finalStipulationsErr }
      }
    }

    return [isValid, errors]
  }

  const addNewTemplate = async () => {
    const [isValid, errors] = validateTemplate(templateToRender)

    if (!isValid) {
      return setErrors(errors)
    }

    const isCommercialTemplate = templateToRender.isCommercial

    const initialStipulationsIds = templateToRender.initialStipulations.map(
      (item) => item.definition
    )

    const finalStipulationsIds = templateToRender.finalStipulations.map(
      (item) => item.definition
    )
    const commercialStipulationsIds =
      templateToRender.commercialStipulations.map((item) => item.definition)

    const formattedTemplate = {
      ...templateToRender,
      initialStipulations: isCommercialTemplate ? [] : initialStipulationsIds,
      finalStipulations: isCommercialTemplate ? [] : finalStipulationsIds,
      commercialStipulations: isCommercialTemplate
        ? commercialStipulationsIds
        : [],
    }

    setLoading(dispatch, true)
    const res = await createTemplate(formattedTemplate, dispatch)
      .then(() => {
        navigate('/admin/stipulation-templates')
      })
      .finally(() => {
        setLoading(dispatch, false)
      })

    if (!res?.stipulationTemplateId) {
      return
    }
  }

  const editTemplate = () => {
    const [isValid, validationErrors] = validate(
      TEMPLATE_ERROR_MAP,
      templateToRender
    )

    if (isValid) {
      setLoading(dispatch, true)

      const initialStipulationsIds = templateToRender.initialStipulations.map(
        (item) => item.guid
      )
      const finalStipulationsIds = templateToRender.finalStipulations.map(
        (item) => item.guid
      )

      const commercialStipulationsIds =
        templateToRender.commercialStipulations.map((item) => item.guid)

      const isCommercialTemplate =
        !initialStipulationsIds.length && !finalStipulationsIds.length

      const formattedTemplate = {
        ...templateToRender,
        initialStipulations: isCommercialTemplate ? [] : initialStipulationsIds,
        finalStipulations: isCommercialTemplate ? [] : finalStipulationsIds,
        commercialStipulations: isCommercialTemplate
          ? commercialStipulationsIds
          : [],
      }

      updateTemplateOnServer(formattedTemplate, dispatch)
        .then(() => {
          navigate('/admin/stipulation-templates')
        })
        .finally(() => {
          setLoading(dispatch, false)
        })
    } else {
      setErrors(validationErrors)
    }
  }

  const updateTemplateField = (field, value) => {
    setTemplateToRender({
      ...templateToRender,
      [field]: value,
    })
  }

  const updateTemplates = (newTemplate) => {
    setTemplateToRender(newTemplate)
  }

  const removeTemplate = () => {
    deleteTemplate(templateToRender?.stipulationTemplateId, dispatch).finally(
      () => {
        history.push('/admin/stipulation-templates')
      }
    )
  }

  const items = useMemo(() => {
    const baseItems = [
      { icon: <UsersSvg /> },
      { label: 'Stipulation Templates', href: '/admin/stipulation-templates' },
      { label: isCreate ? 'Add New Stipulation' : 'Edit Stipulation' },
    ]

    return baseItems
  }, [isCreate])

  return (
    <DndProvider
      backend={isMobileView ? TouchBackend : HTML5Backend}
      options={isMobileView ? { enableMouseEvents: true } : {}}
    >
      <Breadcrumbs items={items} onClick={() => {}} sx={{ mb: 2 }} />

      <ScrollingComponent className="stipulations__wrapper">
        <span className="stipulations__title">Template Details</span>
        <div className="stipulations__buttons">
          {!isCreate && (
            <IconButton
              variant="outlined"
              rounded
              onClick={on}
              sx={{ marginRight: 2 }}
            >
              <TrashIcon />
            </IconButton>
          )}
          <Button
            onClick={() => {
              !isCreate ? editTemplate() : addNewTemplate()
            }}
            startIcon={<CheckIcon />}
            fullWidth
          >
            {!isCreate ? 'Save Changes' : 'Create Template'}
          </Button>
        </div>
      </ScrollingComponent>
      <Dialog
        open={isOpen}
        onClose={off}
        title="Are you sure you want to remove this?"
        actions={
          <>
            <Button
              onClick={() => {
                off()
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                removeTemplate()
                off()
              }}
              color="primary"
            >
              Confirm
            </Button>
          </>
        }
      />
      <Grid container gap={24}>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            value={templateToRender?.name}
            onChange={(value) => updateTemplateField('name', value)}
            label={'Template Title'}
            validate={() => errors?.name}
            fullWidth
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Version Number'}
            fullWidth
            validate={() => errors?.version}
            value={templateToRender?.version}
            onChange={(value) => updateTemplateField('version', value)}
            css={{ marginRight: 5, marginBottom: isMobileView ? 16 : 0 }}
          />
        </Grid>

        {isCreate && (
          <Grid item mobile={12} tablet={12} desktop={4}>
            <Dropdown
              label="Select stipulation type"
              onChange={(ev) => setStipulationType(ev.target.value)}
              value={stipulationType || ''}
              options={templateOptions}
              key={templateOptions}
              fullWidth
            />
          </Grid>
        )}
        <Grid item>
          <TextArea
            label={'Description'}
            rows={isMobileView ? 2 : 4}
            fullWidth
            onChange={(value) => updateTemplateField('details', value)}
            value={templateToRender?.details}
            resize="vertical"
          />
        </Grid>
        {stipulationDefinitions.length > 0 &&
          (templateToRender?.commercialStipulations.length > 0 ||
            templateToRender?.initialStipulations.length) && (
            <Grid item>
              <TemplateContent
                template={templateToRender}
                key={templateToRender}
                updateTemplate={updateTemplates}
                stipulationDefinitions={stipulationDefinitions}
                canSelectCommercialStipulation={true}
                isCommercial={isCommercial}
                selected={selected}
                setSelected={setSelected}
                errors={errors}
              />
            </Grid>
          )}
      </Grid>
    </DndProvider>
  )
}

const TEMPLATE_ERROR_MAP = {
  name: VALIDATION_TYPES.REQUIRED,
  version: VALIDATION_TYPES.REQUIRED,
}

export default StipulationTemplatesSettings

const mockedTemplateState = {
  details: '',
  finalStipulations: [
    {
      guid: uuidv4(),
      definition: '',
    },
  ],
  friendlyName: 'Mocked Friendly Name',
  initialStipulations: [
    {
      guid: uuidv4(),
      definition: '',
    },
  ],
  commercialStipulations: [
    {
      guid: uuidv4(),
      definition: '',
    },
  ],
  name: '',
  scope: 'Mocked Scope',
  version: '',
}

const STIPULATION_ERROR_MAP = {
  definition: VALIDATION_TYPES.REQUIRED,
}

const templateOptions = [
  {
    id: 0,
    label: 'Residential',
  },
  {
    id: 1,
    label: 'Commercial',
  },
]

StipulationTemplatesSettings.propTypes = {
  isCreate: PropTypes.func,
}
