import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
import {
  fetchSingleLoanProduct,
  updateLoanProduct,
} from 'modules/loan-product/actions'
import { validate } from 'components/validator/index'
import { LOAN_PRODUCT_ERROR_MAP } from 'common/constants'
import { components } from '@ElementsCapitalGroup/enium-ui'
import HistoryTracking, {
  HISTORY_TRACKING_TYPES,
} from 'components/history-tracking/index'
import LoanProductDetails from 'modules/loan-product/loan-product-details/index'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { setLoading } from 'modules/global/actions'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

import { useStore } from 'store'

import './index.scss'

const { HistoryNavigation, Breadcrumbs } = components

const LoanProductSettings = () => {
  const { id } = useParams()
  const { state, dispatch } = useStore()
  const userData = state.session.userData
  const isViewOnly = useMemo(
    () => !hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING),
    [userData]
  )

  const TABS = [
    { label: 'Product Details', id: 'productDetails' },
    { label: 'History', id: 'history' },
  ]

  const [loanProductDetails, setLoanProductDetails] = useState({})
  const [allErrors, setErrors] = useState({
    achFee: null,
    loanCode: null,
    description: null,
    name: null,
    serviceCode: null,
  })

  const [needsRefetch, setNeedsRefetch] = useState(true)

  useEffect(() => {
    needsRefetch &&
      fetchSingleLoanProduct(id)
        .then(setLoanProductDetails)
        .finally(() => setNeedsRefetch(false))
  }, [needsRefetch])

  const [tab, setTab] = useState('productDetails')

  const handleSubmit = () => {
    const [isServiceAddressValid, serviceAddressErrors] = validate(
      LOAN_PRODUCT_ERROR_MAP,
      {
        ...loanProductDetails,
      }
    )

    if (isServiceAddressValid) {
      setLoading(dispatch, true)

      loanProductDetails['decisioningTemplateId'] =
        loanProductDetails?.decisioningTemplate?.decisioningTemplateId

      updateLoanProduct(loanProductDetails, dispatch).then(() => {
        setLoading(dispatch, false)
      })
    } else {
      setErrors(serviceAddressErrors)
    }
  }

  if (Object.keys(loanProductDetails).length === 0) {
    return null
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { icon: <UsersSvg /> },
          { label: 'Loan Product', href: '/loan-product' },
          { label: 'Edit Loan Product' },
        ]}
        onClick={() => {}}
        sx={{ padding: '32px 32px 0' }}
      />
      <div className="loan-product__selector">
        <HistoryNavigation
          onTabChange={(value) => {
            setTab(value)
          }}
          sx={{ paddingBottom: 0 }}
          items={TABS}
        />
      </div>
      {tab === 'productDetails' && (
        <LoanProductDetails
          className="loan-product__settings-form"
          loanProductDetails={loanProductDetails}
          callback={handleSubmit}
          allErrors={allErrors}
          edit={true}
          setAllErrors={setErrors}
          setNeedsRefetch={setNeedsRefetch}
          setLoanProductDetails={setLoanProductDetails}
          isViewOnly={isViewOnly}
        />
      )}

      {tab === 'history' && (
        <div>
          <HistoryTracking
            className="loan-product__settings"
            id={id}
            type={HISTORY_TRACKING_TYPES.LOAN_PRODUCT_DETAILS}
          />
        </div>
      )}
    </>
  )
}

LoanProductSettings.propTypes = {
  setIsLoanCreateVisible: PropTypes.func,
  isLoanCreateVisible: PropTypes.bool,
}

export default LoanProductSettings
