import { components, assets } from '@ElementsCapitalGroup/enium-ui'

const { FeaturedIconColors } = components

const {
  FolderIcon,
  CalendarMinusIcon,
  Repeat04Icon,
  CheckIcon,
  XCloseIcon,
  CurrencyDollarIcon,
  CalendarCheckIcon,
  FileCheck01Icon,
  FileCheck02Icon,
  Banknote03Icon,
  BarChartSquareUpIcon,
} = assets

export const DASHBOARD_INFO_TYPES = {
  TOTAL_APPLICATIONS: 'TOTAL_APPLICATIONS',
  APPS_IN_PROGRESS: 'APPS_IN_PROGRESS',
  COMPLETED_APPLICATIONS: 'COMPLETED_APPLICATIONS',
  DECLINED_APPLICATIONS: 'DECLINED_APPLICATIONS',
  AVERAGE_LOAN_AMOUNT: 'AVERAGE_LOAN_AMOUNT',
  AVEREAGE_LOAN_TERM: 'AVEREAGE_LOAN_TERM',
  EXPIRED_APPLICATIONS: 'EXPIRED_APPLICATIONS',
  PTO_SUBMITTED: 'PTO_SUBMITTED',
  FINAL_FUNDS_GRANTED: 'FINAL_FUNDS_GRANTED',
  FUNDING_YTD: 'FUNDING_YTD',
  FUNDING_MTD: 'FUNDING_MTD',
}

export const DASHBOARD_CARDS_INFO = {
  TOTAL_APPLICATIONS: {
    title: 'Total Applications',
    icon: <FolderIcon />,
    color: FeaturedIconColors.DISABLED,
    name: 'totalApplications',
  },
  APPS_IN_PROGRESS: {
    title: 'Applications in Progress',
    icon: <Repeat04Icon />,
    color: FeaturedIconColors.WARNING,
    name: 'inProgressApplications',
  },
  COMPLETED_APPLICATIONS: {
    title: 'Completed Applications',
    icon: <CheckIcon />,
    color: FeaturedIconColors.SUCCESS,
    name: 'completedApplications',
  },
  DECLINED_APPLICATIONS: {
    title: 'Declined Applications',
    icon: <XCloseIcon />,
    color: FeaturedIconColors.ERROR,
    name: 'declinedApplications',
  },
  AVERAGE_LOAN_AMOUNT: {
    title: 'Average Loan Amount',
    icon: <CurrencyDollarIcon />,
    color: FeaturedIconColors.PRIMARY,
    name: 'averageLoanAmount',
    isCurrency: true,
  },
  AVEREAGE_LOAN_TERM: {
    title: 'Average Loan Term',
    icon: <CalendarCheckIcon />,
    color: FeaturedIconColors.PRIMARY,
    name: 'averageLoanTerm',
    isYears: true,
  },
  EXPIRED_APPLICATIONS: {
    title: 'Expired Applications',
    icon: <CalendarMinusIcon />,
    color: FeaturedIconColors.PRIMARY,
    name: 'expiredApplications',
    reverseRestricted: true,
  },
  PTO_SUBMITTED: {
    title: 'PTO Submitted',
    icon: <FileCheck01Icon />,
    color: FeaturedIconColors.PRIMARY,
    restricted: true,
    name: 'ptoSubmittedApplications',
  },
  FINAL_FUNDS_GRANTED: {
    title: 'Final Funds Granted',
    icon: <FileCheck02Icon />,
    color: FeaturedIconColors.PRIMARY,
    restricted: true,
    name: 'totalFinalFundsGrantedAmount',
    isCurrency: true,
  },
  FUNDING_YTD: {
    title: 'Funding YTD',
    icon: <Banknote03Icon />,
    color: FeaturedIconColors.PRIMARY,
    restricted: true,
    name: 'fundingYearToDay',
    isCurrency: true,
  },
  FUNDING_MTD: {
    title: 'Funding MTD',
    icon: <BarChartSquareUpIcon />,
    color: FeaturedIconColors.PRIMARY,
    restricted: true,
    name: 'fundingMonthToDay',
    isCurrency: true,
  },
}
