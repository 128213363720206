import { colors, theme } from '@ElementsCapitalGroup/enium-ui'
const {
  lightTheme: { typography },
} = theme

const borderPrimary = '#D0D5DD'

const paper = {
  boxShadow: 'none',
  border: `1px solid ${borderPrimary}`,
  padding: '16px',
  borderRadius: '12px',
}

const attachmentsWrapper = {
  marginTop: '12px',
}

const actionButtons = {
  display: 'flex',
  gap: '16px',
  marginTop: '24px',
}

const subtitle = {
  fontSize: typography.body1.fontSize,
  fontWeight: typography.fontWeightBold,
  marginBottom: '16px',
  color: colors.grey[700],
}

const declineReasonDate = {
  fontSize: typography.body2.fontSize,
  fontWeight: typography.fontWeightBold,
  color: colors.red[600],
  marginTop: '12px',
}

const declineModalSubtitle = {
  fontSize: typography.body2.fontSize,
  color: colors.grey[600],
  fontWeight: typography.fontWeightMedium,
}

export const styles = {
  paper,
  attachmentsWrapper,
  actionButtons,
  subtitle,
  declineReasonDate,
  declineModalSubtitle,
}
