import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Loader from 'components/loader'
import { useStore } from 'store'
import ApplicantDetailsSection from '../common/applicant-details-section'
import { UW_HUB_ACTIONS, downloadLoanDocuments } from '../actions'
import { renderCell } from '../common/render-cell'

const { createColumnHelper } = components
const { FileSearchIcon } = assets
const columnHelper = createColumnHelper()

const General = ({ loanApplicationId, data }) => {
  const { dispatch } = useStore()
  const [downloading, setDownloading] = useState(false)
  const { t: translate } = useTranslation()

  const downloadLoanDocumentsAttachments = () => {
    setDownloading(true)
    downloadLoanDocuments(dispatch, loanApplicationId).finally(() =>
      setDownloading(false)
    )
  }

  const _renderDownloadLoanDocumentsButton = () => (
    <Button
      onClick={downloadLoanDocumentsAttachments}
      variant={BUTTON_VARIANTS.OUTLINED}
      color={BUTTON_COLORS.INHERIT}
      startIcon={downloading ? <Loader size={14} /> : <FileSearchIcon />}
      disabled={downloading}
      sx={{
        marginRight: '12px',
      }}
    >
      {translate('uwHub.general.downloadLoanDocuments')}
    </Button>
  )

  const columns = [
    columnHelper.accessor('militaryAffiliation', {
      enableSorting: false,
      cell: (info) => renderCell(info.getValue()),
    }),
    columnHelper.accessor('citizenship', {
      enableSorting: false,
      cell: (info) => renderCell(info.getValue()),
    }),
    columnHelper.accessor('occupancy', {
      cell: (info) => renderCell(info.getValue()),
      enableSorting: false,
    }),
    columnHelper.accessor('propertyType', {
      cell: (info) => renderCell(info.getValue()),
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      cell: (cell) => _renderDownloadLoanDocumentsButton(),
      enableSorting: false,
    }),
  ]

  return (
    <ApplicantDetailsSection
      titleKey="uwHub.general.title"
      loanApplicationId={loanApplicationId}
      data={data}
      columns={columns}
      updateApprovalStatusType={UW_HUB_ACTIONS.SET_GENERAL_DATA}
      loading={downloading}
      hasAttachments={true}
    />
  )
}

General.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default General
