import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useStore } from 'store'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import {
  dateFormatUS,
  dateFormatUSWithTimestampsAM,
  DESKTOP_BREAKPOINT,
} from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import { formatEffectiveDate } from 'modules/automated-funding/utils'
import { ExpandableCard } from 'components/expandable-card'
import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/button'
import LoanDecisionCheckList from '../loan-check-list'
import { LOAN_DECISION_STATUS } from '../../constants'

import styles from '../style.module.scss'
import './style.scss'
import LoanCardChip from '../loan-card/loan-card-chip'

const EvaluationSessionsHistory = ({
  evaluationSessionsHistory,
  dtiClicked,
}) => {
  const { t: translate } = useTranslation()
  const translations = {
    noHistoricalLoans: translate('loanApplication.step2.noHistoricalLoans'),
    evaluation: translate('loanApplication.step2.evaluation'),
    viewDTI: translate('loanApplication.step2.viewDTI'),
  }
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const { state } = useStore()
  const { userData } = state.session

  const sessionsPreDate = useMemo(() => {
    return evaluationSessionsHistory.reduce((obj, crt) => {
      const date = moment(crt.dateCreated).format(dateFormatUS)
      if (!obj[date]) {
        obj[date] = []
      }
      obj[date].push(crt)
      return obj
    }, {})
  }, [evaluationSessionsHistory])

  if (!Object.keys(sessionsPreDate).length) {
    return (
      <div className="select-loan__evaluation">
        {translations.noHistoricalLoans}
      </div>
    )
  }

  return (
    <div className="select-loan__evaluation always-enabled">
      {Object.entries(sessionsPreDate).map(([date, evaluations]) => (
        <React.Fragment key={date}>
          {evaluations.map((evaluation) =>
            evaluation.evaluatedLoanProducts.map((el, elIdx) => {
              const isSuccessful =
                el.decisionResultId === LOAN_DECISION_STATUS.PASS

              const lastDateModified = formatEffectiveDate(
                evaluation.dateCreated,
                dateFormatUSWithTimestampsAM
              )

              const collapsibleItems = [
                <LoanDecisionCheckList
                  key={elIdx}
                  userData={userData}
                  checks={el.evaluatedLoanDecisions}
                  actionsEnabled={false}
                />,
              ]
              return (
                <ExpandableCard
                  sx={{
                    mb: '16px',
                  }}
                  headerProps={{
                    sx: {
                      backgroundColor: '#ffffff',
                      '&.MuiCardHeader-root': isTabletView
                        ? { padding: '20px 24px' }
                        : {},
                    },
                  }}
                  key={elIdx}
                  collapsibleItems={collapsibleItems}
                  isCollapsable={true}
                  alwaysCollapsible={true}
                  isCollapsed={true}
                  actionButtonsClassName="select-loan__evaluation__arrow"
                  title={
                    <div
                      className={styles.cardTitle}
                      style={{
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div className="select-loan__evaluation__title">
                        {el.loanProduct.name}
                        <span>{lastDateModified}</span>
                      </div>

                      <LoanCardChip isFailed={!isSuccessful} style={{}} />

                      {dtiClicked && (
                        <AccessWrapper claims={CLAIMS.CAN_VIEW_DTI}>
                          <Button
                            onClick={(ev) => dtiClicked(ev, el)}
                            size={BUTTON_SIZES.SMALL}
                            variant={BUTTON_VARIANTS.TEXT}
                            style={{ marginLeft: 'auto' }}
                          >
                            {translations.viewDTI}
                          </Button>
                        </AccessWrapper>
                      )}
                    </div>
                  }
                  collapsibleItemsStyles={{
                    disablePadding: true,
                  }}
                />
              )
            })
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

EvaluationSessionsHistory.propTypes = {
  evaluationSessionsHistory: PropTypes.array.isRequired,
  dtiClicked: PropTypes.func,
}

export default EvaluationSessionsHistory
