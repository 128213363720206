import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'

const { Tooltip } = components
const { AlertCircleIcon } = assets

const UWDeclineTooltip = ({ data }) => {
  const { t: translate } = useTranslation()
  return data.uwDeclineReason ? (
    <Tooltip
      title={
        <div className="uw-hub-page__income-card-tooltip-title">
          <span>{translate('uwHub.income.declineReason')}</span>
          <span>{data.uwDeclineReason}</span>
        </div>
      }
      placement="top"
    >
      <span style={{ height: '20px', marginLeft: '10px' }}>
        <AlertCircleIcon
          sx={{
            color: colors.red[600],
            width: '20px',
            height: '20px',
          }}
        />
      </span>
    </Tooltip>
  ) : null
}

UWDeclineTooltip.propTypes = { data: PropTypes.object.isRequired }

export default UWDeclineTooltip
