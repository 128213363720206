import React from 'react'
import { useTranslation } from 'react-i18next'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import './index.scss'

const Support = () => {
  const { t: translate } = useTranslation()
  const { MessageChatCircleIcon } = assets
  return (
    <>
      <div>
        <div className="income-estimator__title">
          <MessageChatCircleIcon sx={{ mr: 1 }} />
          {translate('loanApplication.ecgToolbox.support.supportTitle')}
        </div>
        <div className="support__cards">
          <div className="support__card">
            <div className="support__text-wrapper">
              <MessageChatCircleIcon sx={{ mr: 1 }} />
              <span className="support__text">
                {translate(
                  'loanApplication.ecgToolbox.support.phoneNumberText'
                )}
              </span>
            </div>
            <span className="support__value">
              {translate('loanApplication.ecgToolbox.support.phoneNumberValue')}
            </span>
          </div>
          <div className="support__card">
            <div className="support__text-wrapper">
              <MessageChatCircleIcon sx={{ mr: 1 }} />
              <span className="support__text-wrapper">
                {translate('loanApplication.ecgToolbox.support.textNoticeText')}
              </span>
            </div>
            <span className="support__value">
              {translate('loanApplication.ecgToolbox.support.textNoticeValue')}
            </span>
          </div>
          <div className="support__card" style={{ marginRight: 0 }}>
            <div className="support__text-wrapper">
              <MessageChatCircleIcon sx={{ mr: 1 }} />
              <span className="support__text">
                {translate(
                  'loanApplication.ecgToolbox.support.availabilityText'
                )}
              </span>
            </div>
            <span className="support__value" style={{ textAlign: 'center' }}>
              {translate(
                'loanApplication.ecgToolbox.support.availabilityValue1'
              )}
              <br />
              {translate(
                'loanApplication.ecgToolbox.support.availabilityValue2'
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Support
