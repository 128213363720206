import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextField from 'components/input'
import { dateFormatServer } from 'common/constants'
import InputDate from 'components/input-date'

const ExpandedRowLenderSettings = ({ data, onSave, onCancel }) => {
  const { loanCode, salesOrg } = data
  const [state, setState] = useState({
    lenderFundingSettingsId: null,
    serviceCode: '',
    discount: '',
    effectiveDate: '',
  })

  useEffect(() => {
    setState({
      ...state,
      lenderFundingSettingsId: data.lenderFundingSettingsId,
      serviceCode: data.serviceCode,
      discount: data.discount,
      effectiveDate: moment(data.effectiveDate).format(dateFormatServer),
    })
  }, [data])

  const updateField = (name) => (value) => setState({ ...state, [name]: value })

  return (
    <div className="afp__expanded">
      <div className="afp__expanded__header">
        <div className="afp__expanded__title">
          <p>{loanCode}</p>
          <span>{salesOrg}</span>
        </div>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button onClick={() => onSave(state)}>Save Changes</Button>
      </div>
      <div className="afp__grid-inputs">
        <TextField
          label="Service Code"
          value={state.serviceCode}
          onChange={updateField('serviceCode')}
        />
        <TextField
          label="Discount"
          value={state.discount}
          onChange={updateField('discount')}
          type="number"
        />
        <InputDate
          label="Effective Date"
          value={state.effectiveDate}
          onChange={updateField('effectiveDate')}
          serverDateFormat="YYYY-MM-DD"
        />
      </div>
    </div>
  )
}

ExpandedRowLenderSettings.propTypes = {
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ExpandedRowLenderSettings
