import PropTypes from 'prop-types'
import { assets, colors } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'

import { TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import StatusComponent, { STATUSES } from 'components/status'
import { ExpandableCard } from 'components/expandable-card'
import LoanDecisionCheckList from 'modules/loan-application/select-loan/loan-check-list'

const { CheckSquareIcon } = assets

const EvaluatedLoanProduct = ({
  evaluatedLoanProduct,
  selectedLoanProductId,
  userData,
}) => {
  const { t: translate } = useTranslation()

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const mobileStyles = {
    borderRadius: 0,
    border: 'none',
  }

  const collapsibleItems = [
    <LoanDecisionCheckList
      key={0}
      userData={userData}
      checks={evaluatedLoanProduct.evaluatedLoanDecisions}
      actionsEnabled={false}
    />,
  ]

  return (
    <ExpandableCard
      headerProps={{
        sx: {
          backgroundColor: '#ffffff',
          padding: isMobileView ? '16px' : '16px 24px',
        },
      }}
      title={
        <div className="uw-hub-page__evaluation-loan-header">
          <span>{evaluatedLoanProduct.loanProduct.name}</span>
          <div>
            {evaluatedLoanProduct.loanProductId === selectedLoanProductId && (
              <CheckSquareIcon
                sx={{ color: colors.green[600], marginRight: '12px' }}
              />
            )}
            <StatusComponent
              status={
                evaluatedLoanProduct.isPassingEvaluation
                  ? STATUSES.ACTIVE
                  : STATUSES.INACTIVE
              }
              label={
                evaluatedLoanProduct.isPassingEvaluation
                  ? translate('global.passed')
                  : translate('global.failed')
              }
            />
          </div>
        </div>
      }
      collapsibleItems={collapsibleItems}
      isCollapsable={true}
      isCollapsed={true}
      alwaysCollapsible={true}
      collapsibleItemsStyles={{
        sx: {
          padding: 0,
        },
      }}
      sx={isMobileView ? mobileStyles : {}}
    />
  )
}

EvaluatedLoanProduct.propTypes = {
  evaluatedLoanProduct: PropTypes.object.isRequired,
  selectedLoanProductId: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
}

export default EvaluatedLoanProduct
