import { SCHEMA_TYPES, ACTION_TYPES } from 'components/form-builder/constants'
import { BUTTON_COLORS } from 'components/button'

const COUNTY_OPTIONS = [
  'loanApplication.step3.membership.mcCoy.countyOptions.Alachua',
  'loanApplication.step3.membership.mcCoy.countyOptions.Baker',
  'loanApplication.step3.membership.mcCoy.countyOptions.Bay',
  'loanApplication.step3.membership.mcCoy.countyOptions.Bradford',
  'loanApplication.step3.membership.mcCoy.countyOptions.Brevard',
  'loanApplication.step3.membership.mcCoy.countyOptions.Broward',
  'loanApplication.step3.membership.mcCoy.countyOptions.Calhoun',
  'loanApplication.step3.membership.mcCoy.countyOptions.Charlotte',
  'loanApplication.step3.membership.mcCoy.countyOptions.Citrus',
  'loanApplication.step3.membership.mcCoy.countyOptions.Clay',
  'loanApplication.step3.membership.mcCoy.countyOptions.Collier',
  'loanApplication.step3.membership.mcCoy.countyOptions.Columbia',
  'loanApplication.step3.membership.mcCoy.countyOptions.Desoto',
  'loanApplication.step3.membership.mcCoy.countyOptions.Dixie',
  'loanApplication.step3.membership.mcCoy.countyOptions.Duval',
  'loanApplication.step3.membership.mcCoy.countyOptions.Escambia',
  'loanApplication.step3.membership.mcCoy.countyOptions.Flagler',
  'loanApplication.step3.membership.mcCoy.countyOptions.Franklin',
  'loanApplication.step3.membership.mcCoy.countyOptions.Gadsden',
  'loanApplication.step3.membership.mcCoy.countyOptions.Gilchrist',
  'loanApplication.step3.membership.mcCoy.countyOptions.Glades',
  'loanApplication.step3.membership.mcCoy.countyOptions.Gulf',
  'loanApplication.step3.membership.mcCoy.countyOptions.Hamilton',
  'loanApplication.step3.membership.mcCoy.countyOptions.Hardee',
  'loanApplication.step3.membership.mcCoy.countyOptions.Hendry',
  'loanApplication.step3.membership.mcCoy.countyOptions.Hernando',
  'loanApplication.step3.membership.mcCoy.countyOptions.Highlands',
  'loanApplication.step3.membership.mcCoy.countyOptions.Hillsborough',
  'loanApplication.step3.membership.mcCoy.countyOptions.Holmes',
  'loanApplication.step3.membership.mcCoy.countyOptions.Indian River',
  'loanApplication.step3.membership.mcCoy.countyOptions.Jackson',
  'loanApplication.step3.membership.mcCoy.countyOptions.Jefferson',
  'loanApplication.step3.membership.mcCoy.countyOptions.Lafayette',
  'loanApplication.step3.membership.mcCoy.countyOptions.Lake',
  'loanApplication.step3.membership.mcCoy.countyOptions.Lee',
  'loanApplication.step3.membership.mcCoy.countyOptions.Leon',
  'loanApplication.step3.membership.mcCoy.countyOptions.Levy',
  'loanApplication.step3.membership.mcCoy.countyOptions.Liberty',
  'loanApplication.step3.membership.mcCoy.countyOptions.Madison',
  'loanApplication.step3.membership.mcCoy.countyOptions.Manatee',
  'loanApplication.step3.membership.mcCoy.countyOptions.Marion',
  'loanApplication.step3.membership.mcCoy.countyOptions.Martin',
  'loanApplication.step3.membership.mcCoy.countyOptions.Miami-Dade',
  'loanApplication.step3.membership.mcCoy.countyOptions.Monroe',
  'loanApplication.step3.membership.mcCoy.countyOptions.Nassau',
  'loanApplication.step3.membership.mcCoy.countyOptions.Okaloosa',
  'loanApplication.step3.membership.mcCoy.countyOptions.Okeechobee',
  'loanApplication.step3.membership.mcCoy.countyOptions.Orange',
  'loanApplication.step3.membership.mcCoy.countyOptions.Osceola',
  'loanApplication.step3.membership.mcCoy.countyOptions.Palm Beach',
  'loanApplication.step3.membership.mcCoy.countyOptions.Pasco',
  'loanApplication.step3.membership.mcCoy.countyOptions.Pinellas',
  'loanApplication.step3.membership.mcCoy.countyOptions.Polk',
  'loanApplication.step3.membership.mcCoy.countyOptions.Putnam',
  'loanApplication.step3.membership.mcCoy.countyOptions.Saint Johns',
  'loanApplication.step3.membership.mcCoy.countyOptions.Saint Lucie',
  'loanApplication.step3.membership.mcCoy.countyOptions.Santa Rosa',
  'loanApplication.step3.membership.mcCoy.countyOptions.Sarasota',
  'loanApplication.step3.membership.mcCoy.countyOptions.Seminole',
  'loanApplication.step3.membership.mcCoy.countyOptions.Sumter',
  'loanApplication.step3.membership.mcCoy.countyOptions.Suwannee',
  'loanApplication.step3.membership.mcCoy.countyOptions.Taylor',
  'loanApplication.step3.membership.mcCoy.countyOptions.Union',
  'loanApplication.step3.membership.mcCoy.countyOptions.Volusia',
  'loanApplication.step3.membership.mcCoy.countyOptions.Wakulla',
  'loanApplication.step3.membership.mcCoy.countyOptions.Walton',
  'loanApplication.step3.membership.mcCoy.countyOptions.Washington',
]

const QUALIFICATION_OPTIONS = [
  'loanApplication.step3.membership.mcCoy.qualificationOptions.live',
  'loanApplication.step3.membership.mcCoy.qualificationOptions.work',
  'loanApplication.step3.membership.mcCoy.qualificationOptions.worship',
  'loanApplication.step3.membership.mcCoy.qualificationOptions.attendSchool',
  'loanApplication.step3.membership.mcCoy.qualificationOptions.volunteer',
  'loanApplication.step3.membership.mcCoy.qualificationOptions.doNotQualify',
]

export default {
  titleKey: 'loanApplication.step3.membership.mcCoy.title',
  fields: {
    qualification: '',
    county: '',
  },
  sections: [
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'scrollable',
      sections: [
        {
          type: SCHEMA_TYPES.DROPDOWN,
          field: 'qualification',
          labelKey: 'loanApplication.step3.membership.mcCoy.qualification',
          options: QUALIFICATION_OPTIONS,
        },
        {
          type: SCHEMA_TYPES.DROPDOWN,
          options: COUNTY_OPTIONS,
          field: 'county',
          labelKey: 'loanApplication.step3.membership.mcCoy.county',
        },
      ],
    },
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'sticky',
      sections: [
        {
          type: SCHEMA_TYPES.BUTTONS,
          buttons: [
            {
              action: ACTION_TYPES.SUBMIT,
              labelKey: 'buttons.accept',
              disabled: (state) => !state.qualification || !state.county,
            },
            {
              action: ACTION_TYPES.DECLINE,
              labelKey: 'buttons.decline',
              className: 'margin-left-10',
              color: BUTTON_COLORS.ERROR,
            },
          ],
        },
      ],
    },
  ],
}
