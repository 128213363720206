import React, { useState } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

import { createLoanProduct } from 'modules/loan-product/actions'
import { validate } from 'components/validator/index'
import { LOAN_PRODUCT_ERROR_MAP, LOAN_PRODUCT_STATUSES } from 'common/constants'
import { setLoading } from 'modules/global/actions'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

import LoanProductDetails from '../loan-product-details/index'

import '../index.scss'

const { Breadcrumbs } = components

const LoanProductCreate = () => {
  const { dispatch } = useStore()

  const [loanProductDetails, setLoanProductDetails] = useState(
    initialLoanProductDetailsData
  )

  const [allErrors, setErrors] = useState(initialErrorsData)

  const handleSubmit = () => {
    const [isServiceAddressValid, serviceAddressErrors] = validate(
      LOAN_PRODUCT_ERROR_MAP,
      {
        ...loanProductDetails,
      }
    )
    if (isServiceAddressValid) {
      setLoading(dispatch, true)
      createLoanProduct(loanProductDetails, dispatch)
        .then(() => {
          setLoanProductDetails(initialLoanProductDetailsData)
          setErrors(initialErrorsData)
        })
        .finally(() => setLoading(dispatch, false))
    } else {
      setErrors(serviceAddressErrors)
    }
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { icon: <UsersSvg /> },
          { label: 'Loan Product', href: '/loan-product' },
          { label: 'Add Loan Product' },
        ]}
        onClick={() => {}}
        sx={{ padding: '0 32px' }}
      />
      <LoanProductDetails
        loanProductDetails={loanProductDetails}
        key={loanProductDetails}
        callback={handleSubmit}
        allErrors={allErrors}
        edit={false}
        setAllErrors={setErrors}
        setLoanProductDetails={setLoanProductDetails}
      />
    </>
  )
}

LoanProductCreate.propTypes = {
  callback: PropTypes.func.isRequired,
}

export default LoanProductCreate

const initialLoanProductDetailsData = {
  isCommercial: false,
  name: '',
  loanCode: '',
  loanStatusId: LOAN_PRODUCT_STATUSES.IN_DEVELOPMENT,
  description: '',
  termInMonths: 0,
  promoPeriodInMonths: 0,
  rate: '',
  achFee: '',
  promotionalTypeId: '',
  interestRateFee: 0,
  interestRateDiscount: 0,
  minAmount: 0,
  maxAmount: 0,
  financeTypeId: '',
  paydownPercentage: 0,
  lenderId: '',
  dateCreated: '',
  goLiveDate: '',
}

const initialErrorsData = {
  achFee: null,
  rate: null,
  loanCode: null,
  description: null,
  name: null,
  lenderId: null,
  promotionalTypeId: null,
  financeTypeId: null,
  loanStatusId: null,
}
