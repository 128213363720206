import React from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import { formatIntToCurrency } from 'modules/loan-application/utils'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'

import { styles } from './style'

const { Avatar, Divider } = components

export const SalesReps = ({ salesList }) => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const renderList = () =>
    salesList.map((item, index) => (
      <React.Fragment key={index}>
        <div style={styles.listItem}>
          <div style={styles.listItem.name}>
            <Avatar
              sx={{ width: 32, height: 32, fontSize: '0.75rem' }}
            >{`${item.firsName} ${item.lastName}`}</Avatar>
            {`${item.firstName} ${item.lastName}`}
          </div>
          <div style={styles.amount}>{formatIntToCurrency(item.amount)}</div>
        </div>
        {index < salesList.length - 1 && <Divider />}
      </React.Fragment>
    ))

  return (
    <>
      <div style={styles.subtitle}>Top 10 - Sales Reps</div>
      <div
        style={{
          ...styles.list,
          padding: isMobileView ? 0 : styles.list.padding,
        }}
      >
        {renderList()}
      </div>
    </>
  )
}

SalesReps.propTypes = {
  salesList: PropTypes.array.isRequired,
}
