import React, { useState, useEffect } from 'react'
import {
  authenticate,
  changePhoneNumber,
  login,
  requestPhoneNumber,
} from 'modules/session/actions'
import { useStore } from 'store'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import Loader from 'components/loader'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import { formatPhoneNumber } from 'common/utils'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT, TFA_METHODS } from 'common/constants'

import OTPInput from 'react-otp-input'

import { styles } from './style'

const { Dialog, IconButton } = components
const { LockIcon } = assets

export const TwoFactorPinSetup = ({
  onClose,
  emailAddress,
  changePhoneNumberToken,
  setChangePhoneNumberToken,
  newPhoneNumber,
  currentPhoneNumber,
  password,
  setIsCongratulations,
  setIsPinSetup,
  tfaMethod,
}) => {
  const [otp, setOtp] = useState('')
  const { dispatch } = useStore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && otp.length === 6 && !loading) {
        onSubmit(event)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [otp, loading])

  const onSubmit = (ev) => {
    ev.preventDefault()
    setLoading(true)

    if (changePhoneNumberToken) {
      changePhoneNumber(
        emailAddress,
        otp,
        changePhoneNumberToken,
        newPhoneNumber,
        dispatch
      )
        .then((res) => {
          if (res.status === 403) {
            setError('Code is invalid. Please try again')
            return
          }
          if (res.token) {
            setIsPinSetup(false)
            setIsCongratulations(true)
          } else {
            setError('Something went wrong. Please try again.')
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      login(emailAddress, otp, null, dispatch, navigate)
        .then((res) => {
          if (!res.token) {
            if (res.status === 403) {
              setError('Code is invalid. Please try again')
            } else {
              setError('Something went wrong. Please try again.')
            }
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleResend = () => {
    setLoading(true)
    if (changePhoneNumberToken) {
      requestPhoneNumber(emailAddress, password, newPhoneNumber)
        .then((res) => {
          if (!res) {
            setError('Something went wrong. Please try again.')
          }
          if (res.is2FACodeSent) {
            setChangePhoneNumberToken(res.changePhoneNumberToken)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      authenticate(emailAddress, password)
        .then((res) => {
          if (res) {
            showNotification(dispatch, {
              message: `Successfully re-sent code.`,
              type: NOTIFICATION_TYPES.POSITIVE,
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const _renderPhoneOrEmail = () => {
    if (tfaMethod === TFA_METHODS.SMS) {
      return (
        <span style={styles.phoneNumber}>
          {changePhoneNumberToken
            ? formatPhoneNumber(newPhoneNumber).replace(/(\d{4})$/, '****')
            : formatPhoneNumber(currentPhoneNumber).replace(/(\d{4})$/, '****')}
          .
        </span>
      )
    } else {
      return <span style={styles.phoneNumber}>{emailAddress}.</span>
    }
  }

  return (
    <Dialog
      open={true}
      title={
        <div style={styles.pinSetUpIcon}>
          <IconButton
            variant={'outlined'}
            sx={{
              borderRadius: '10px',
              marginRight: '8px',
              ':hover': {
                background: 'transparent',
                cursor: 'pointer',
              },
            }}
          >
            <LockIcon />
          </IconButton>
        </div>
      }
      onClose={onClose}
      PaperProps={{
        sx: {
          margin: isMobileView ? '12px' : 'inherit',
        },
      }}
    >
      <div style={styles.pinSetUpTitle}>
        {changePhoneNumberToken
          ? 'Set up 2-Factor Authentication'
          : '2-Factor Authentication'}
      </div>
      <div style={styles.pinSetUpText}>
        A verification code has been sent to {_renderPhoneOrEmail()}
      </div>
      <span style={styles.pinSetUpPinText}>Verification Code </span>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        separator={'-'}
        shouldAutoFocus={true}
        renderInput={(props) => (
          <input {...props} placeholder="0" className="otp-input" />
        )}
        inputStyle={
          isMobileView
            ? styles.mobilePinSetUpInputStyle
            : styles.pinSetUpInputStyle
        }
        containerStyle={styles.pinSetUpContainerStyle}
      />
      {error && <div className="error">{error}</div>}

      <div style={styles.pinSetUpResendLink}>
        Didn&apos;t get a code?
        {loading ? (
          <Loader size={30} style={{ marginLeft: '12px' }} />
        ) : (
          <div
            style={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Button
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.TEXT}
              size={BUTTON_SIZES.SMALL}
              style={styles.resendButton}
              onClick={() => handleResend()}
            >
              Resend
            </Button>
          </div>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '24px',
          marginBottom: '12px',
        }}
      >
        {/* {changePhoneNumberToken && (
          // <Button
          //   disabled={loading}
          //   style={{ width: '100%', marginRight: '12px' }}
          //   onClick={handleSkipForNow}
          //   variant={BUTTON_VARIANTS.OUTLINED}
          //   color={BUTTON_COLORS.INHERIT}
          // >
          //   Setup Later
          // </Button>
        )} */}

        <Button
          onClick={onSubmit}
          style={{ width: '100%' }}
          disabled={otp.length < 6 || loading}
        >
          Proceed
        </Button>
      </div>
    </Dialog>
  )
}

TwoFactorPinSetup.propTypes = {
  onClose: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  changePhoneNumberToken: PropTypes.string,
  newPhoneNumber: PropTypes.string,
  changePhoneNumberCode: PropTypes.string,
  password: PropTypes.string,
  currentPhoneNumber: PropTypes.string,
  setIsCongratulations: PropTypes.func,
  setIsPinSetup: PropTypes.func.isRequired,
  setChangePhoneNumberToken: PropTypes.func.isRequired,
  tfaMethod: PropTypes.number.isRequired,
}
