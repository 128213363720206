import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Checkbox from 'components/checkbox'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { styles } from './styles'

const { Dialog, Dropdown } = components

const ModalAssignLoans = ({
  isOpen,
  onModalClose,
  onConfirm,
  allLoans,
  defaultSelectedLoans,
}) => {
  const [selectedLoans, setSelectedLoans] = useState([])

  const removeLoan = (id) =>
    setSelectedLoans((prev) => prev.filter((loan) => loan.guid !== id))

  useEffect(() => {
    defaultSelectedLoans?.length &&
      setSelectedLoans(structuredClone(defaultSelectedLoans))
  }, [defaultSelectedLoans])

  const onClose = () => {
    setSelectedLoans([])
    onModalClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '600px',
        },
      }}
      actions={
        <>
          <Button
            sx={{ width: '192px' }}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: '192px' }}
            onClick={() => {
              onConfirm(selectedLoans)
              onClose()
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <Dropdown
        options={allLoans}
        label="Assign Loans"
        value={selectedLoans}
        onChange={(ev) => {
          setSelectedLoans(ev.target.value)
        }}
        multiple={true}
        searchable={true}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
              overflow: 'auto',
            },
          },
        }}
      />
      {selectedLoans.map((loan) => (
        <div key={loan.guid} style={{ width: '100%' }}>
          <Checkbox
            onClick={() => removeLoan(loan.guid)}
            label={loan.label}
            checked={true}
            formControlLabelProps={{ sx: styles.checkboxProps }}
            labelProps={styles.checkboxLabelProps}
          />
        </div>
      ))}
    </Dialog>
  )
}

ModalAssignLoans.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  allLoans: PropTypes.array.isRequired,
  defaultSelectedLoans: PropTypes.array,
}

export default ModalAssignLoans
