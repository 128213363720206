import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TextField, { INPUT_TYPES } from 'components/input'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { useEffectOnUpdate } from 'common/hooks'
import { validate, VALIDATION_TYPES } from 'components/validator'

import styles from './style.module.scss'

const { Dropdown } = components

/** Auto-pay Information Form */
const AutoPayInformationForm = forwardRef(
  (
    {
      achData,
      accountTypes,
      canSeeACHInfo,
      onSubmit,
      disabled,
      hasWithdrawDateField,
    },
    ref
  ) => {
    const { t: translate } = useTranslation()

    const isEdit = achData.achVerificationAttempts > 0
    const [achDetails, setAchDetails] = useState({ ...achData })
    const [errors, setErrors] = useState({})

    const [ACH_FORM_ERROR_MAP, setAchFormErrorMap] = useState({
      firstName: VALIDATION_TYPES.REQUIRED,
      lastName: VALIDATION_TYPES.REQUIRED,
      routingNumber: [
        VALIDATION_TYPES.REQUIRED,
        VALIDATION_TYPES.POSITIVE_SAFE_INTEGER,
        VALIDATION_TYPES.EQUAL_TO_NINE,
      ],
      accountNumber: VALIDATION_TYPES.REQUIRED,
      bankName: VALIDATION_TYPES.REQUIRED,
      accountTypeId: VALIDATION_TYPES.REQUIRED,
    })

    useEffect(() => {
      if (hasWithdrawDateField) {
        setAchFormErrorMap((errorMap) => ({
          ...errorMap,
          preferredWithdrawDay: [
            VALIDATION_TYPES.REQUIRED,
            VALIDATION_TYPES.POSITIVE_SAFE_INTEGER,
          ],
        }))
      }
    }, [hasWithdrawDateField])

    useEffectOnUpdate(() => {
      setAchDetails(achData)
    }, [achData])

    /* Generic onChange handler */
    const onChange = (fieldName, fieldValue) => {
      setAchDetails({ ...achDetails, [fieldName]: fieldValue })
      setErrors({ ...errors, [fieldName]: null })
    }

    useImperativeHandle(ref, () => ({
      /** Validate form and submit if valid */
      validateAndSubmit() {
        const [isValid, errors] = validate(ACH_FORM_ERROR_MAP, achDetails)
        if (isValid) {
          onSubmit(achDetails)
        } else {
          setErrors(errors)
        }
      },
    }))

    const accountTypesOptions = accountTypes.map((accountType) => ({
      label: translate(accountType.translationKey),
      id: accountType.id,
    }))

    const selectedAccountType = accountTypes.find(
      (accountType) => accountType.id === achDetails.accountTypeId
    )?.translationKey

    return (
      <>
        <div className={styles.informationForm}>
          <TextField
            label={translate('userDetails.firstName')}
            value={achDetails.firstName}
            validate={() => errors.firstName}
            onChange={(value) => onChange('firstName', value)}
            type={isEdit ? INPUT_TYPES.SENSITIVE : INPUT_TYPES.TEXT}
            hideSecureValue={!canSeeACHInfo}
            disabled={disabled}
          />
          <TextField
            label={translate('userDetails.lastName')}
            value={achDetails.lastName}
            validate={() => errors.lastName}
            onChange={(value) => onChange('lastName', value)}
            type={isEdit ? INPUT_TYPES.SENSITIVE : INPUT_TYPES.TEXT}
            hideSecureValue={!canSeeACHInfo}
            disabled={disabled}
          />

          <TextField
            label={translate('loanApplication.step3.autoPay.routingNumber')}
            value={achDetails.routingNumber}
            validate={() =>
              errors.routingNumber ? 'Invalid routing number' : null
            }
            maxLength={9}
            onChange={(value) => onChange('routingNumber', value)}
            type={isEdit ? INPUT_TYPES.SENSITIVE : INPUT_TYPES.TEXT}
            hideSecureValue={!canSeeACHInfo}
            disabled={disabled}
          />
          <TextField
            label={translate('loanApplication.step3.autoPay.accountNumber')}
            value={achDetails.accountNumber}
            validate={() => errors.accountNumber}
            onChange={(value) => onChange('accountNumber', value)}
            type={isEdit ? INPUT_TYPES.SENSITIVE : INPUT_TYPES.TEXT}
            hideSecureValue={!canSeeACHInfo}
            disabled={disabled}
          />

          <TextField
            label={translate('loanApplication.step3.autoPay.bankName')}
            value={achDetails.bankName}
            validate={() => errors.bankName}
            onChange={(value) => onChange('bankName', value)}
            hideSecureValue={!canSeeACHInfo}
            type={isEdit ? INPUT_TYPES.SENSITIVE : INPUT_TYPES.TEXT}
            disabled={disabled}
          />
          <Dropdown
            label={translate('loanApplication.step3.autoPay.accountType')}
            error={!!errors.accountTypeId}
            helperText={errors.accountTypeId}
            isSensitiveInfo={isEdit}
            hideSecureValue={!canSeeACHInfo}
            disabled={disabled}
            options={accountTypesOptions}
            onChange={(e) => onChange('accountTypeId', e.target.value.id)}
            value={{
              label: translate(selectedAccountType),
              id: achDetails.accountTypeId,
            }}
          />

          {hasWithdrawDateField && (
            <TextField
              label={translate('loanApplication.step3.preferredWithdrawDay')}
              value={
                achDetails.preferredWithdrawDay
                  ? achDetails.preferredWithdrawDay.toString()
                  : ''
              }
              validate={() => errors.preferredWithdrawDay}
              onChange={(value) => onChange('preferredWithdrawDay', value)}
              type={isEdit ? INPUT_TYPES.SENSITIVE : INPUT_TYPES.TEXT}
              hideSecureValue={!canSeeACHInfo}
              disabled={disabled}
            />
          )}
        </div>
        <div>
          {achDetails.errors?.map((error, idx) => (
            <div key={idx} className={styles.achErrors}>
              {error.message || error}
            </div>
          ))}
        </div>
      </>
    )
  }
)

AutoPayInformationForm.propTypes = {
  achData: PropTypes.object.isRequired,
  accountTypes: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  canSeeACHInfo: PropTypes.bool,
  disabled: PropTypes.bool,
  hasWithdrawDateField: PropTypes.bool,
}

export default AutoPayInformationForm
