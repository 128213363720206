import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { providers } from '@ElementsCapitalGroup/enium-ui'
import { history, saveLocationToRedirectTo } from 'components/history'
import { hasAccess } from 'common/access'
import useSession from 'modules/session'
import { createRoot } from 'react-dom/client'

import AuthWrapper from 'pages/wrappers/auth-wrapper'
import NonAuthWrapper from 'pages/wrappers/non-auth-wrapper'

import PlaygroundPage from 'pages/playground'
import LoginPage from 'pages/login'
import ConfirmEmailUpdate from 'pages/confirm-email-update'
import ForgotPasswordPage from 'pages/forgot-password'
import ResetPasswordPage from 'pages/reset-password'
import NotFoundPage from 'pages/not-found'
import LoanAppPage from 'pages/loan-application'
import AdminAusTemplates from 'pages/admin/aus-templates'
import AdminOrganizations from 'pages/admin/organizations'
import AdminOrganizationPage from 'pages/admin/organization-details'
import CreateUserPage from 'pages/admin/user/create-user'
import EditUserPage from 'pages/admin/user/edit-user'
import AdminRolesManagement from 'pages/admin/roles-management'
import AdminStipulationTemplates from 'pages/admin/stipulation-templates'
import AdminAusTemplatesSettings from 'pages/admin/aus-templates-settings'
import AdminStipulationTemplatesSettings from 'pages/admin/stipulation-templates-settings'
import AdminHistoryPage from 'pages/admin/history-tracking'
import { EditTranslationsPage } from 'pages/admin/edit-translations'
import LoanDocumentPage from 'pages/admin/loan-document'
import OutageMessages from 'pages/admin/outage-messages'
import LoanProductPage from 'pages/loan-product'
import LoanProductEditPage from 'pages/loan-product-edit'
import LoanApplicationsPage from 'pages/loan-applications'
import DashboardPage from 'pages/dashboard'
import UwHubPage from 'pages/uw-hub'
import LoanDocumentEditPage from 'pages/loan-document-edit'
import SetPasswordPage from 'pages/set-password'
import Notifications from 'pages/notifications'
import AFPDailyFundingPage from 'pages/automated-funding/daily-funding'
import BulkFunding from 'pages/automated-funding/funding'
import AFPFundingHistoryPage from 'pages/automated-funding/funding-history'
import AFPLenderPage from 'pages/automated-funding/lender-settings'
import AFPDealerPage from 'pages/automated-funding/dealer-settings'
import AFPWiresPage from 'pages/automated-funding/wires'
import AFPApprovalPage from 'pages/automated-funding/approval'
import { ReleaseNotesPage } from 'pages/release-notes'
import LoanProductCreatePage from 'pages/loan-product-create'
import LoanDocumentCreatePage from 'pages/loan-document-create'
import AdminUsers from 'pages/admin/users'
import AdminRolesSettings from 'pages/admin/roles-settings'
import AdminUserDetails from 'pages/admin/user/user-details'
import DefaultPage from 'pages/default'

import { TABS as ADMIN_TABS } from 'modules/admin/constants'
import { StateProvider } from './store'
import { VIEW_LOAN_APP, CLAIMS } from './common/claims'

const container = document.getElementById('root')
const root = createRoot(container)
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const NOT_FOUND_ROUTE = '/404'
const { ThemeProvider } = providers

root.render(
  <StateProvider>
    <Router history={history}>
      <ThemeProvider>
        <Sentry.ErrorBoundary fallback={<NotFoundPage />}>
          <SentryRoutes>
            {/* Not found page */}
            <Route
              path="/404"
              element={<PublicRoute component={NotFoundPage} />}
            />

            {/* Auth flow */}
            <Route
              path="/login"
              exact
              element={<PublicRoute component={LoginPage} />}
            />
            <Route
              path="/forgot-password"
              exact
              element={<PublicRoute component={ForgotPasswordPage} />}
            />
            <Route
              path="/reset-password/"
              element={<PublicRoute component={ResetPasswordPage} />}
            />

            <Route
              path="/set-password/"
              exact
              element={<PublicRoute component={SetPasswordPage} />}
            />

            <Route
              path="/email-update/"
              exact
              element={<PublicRoute component={ConfirmEmailUpdate} />}
            />

            {/* Loan Application */}
            <Route
              path="/loan-application/:loanApplicationId/uw-hub"
              exact
              element={<PrivateRoute component={UwHubPage} />}
            />
            <Route
              path="/loan-application/:loanApplicationId/:stepPath"
              exact
              element={
                <PrivateRoute
                  component={LoanAppPage}
                  requiredClaims={VIEW_LOAN_APP}
                />
              }
            />

            <Route
              path="/loan-application/:loanApplicationId"
              exact
              element={
                <PrivateRoute
                  component={LoanAppPage}
                  requiredClaims={VIEW_LOAN_APP}
                />
              }
            />

            {/* Loan Product */}
            <Route
              path="/loan-product"
              exact
              element={
                <PrivateRoute
                  component={LoanProductPage}
                  requiredClaims={ADMIN_TABS.PRODUCTS.access}
                />
              }
            />
            <Route
              path="/loan-product/:id"
              exact
              element={
                <PrivateRoute
                  component={LoanProductEditPage}
                  requiredClaims={ADMIN_TABS.PRODUCTS.access}
                />
              }
            />
            <Route
              path="/loan-product/add-new-product"
              exact
              element={<PrivateRoute component={LoanProductCreatePage} />}
            />

            <Route
              path="/dashboard"
              exact
              element={
                <PrivateRoute
                  component={DashboardPage}
                  requiredClaims={[CLAIMS.CAN_VIEW_DEALER_DASHBOARD]}
                />
              }
            />
            <Route
              path="/applications"
              exact
              element={<PrivateRoute component={LoanApplicationsPage} />}
            />
            <Route
              path="/notifications"
              exact
              element={
                <PrivateRoute
                  component={Notifications}
                  requiredClaims={[CLAIMS.CAN_VIEW_NOTIFICATIONS]}
                />
              }
            />

            {/* Admin BETA - temporary routes that will be removed once PD-2204 is done */}
            <Route
              path="/admin/organizations-beta"
              exact
              element={
                <PrivateRoute
                  component={AdminOrganizations}
                  requiredClaims={ADMIN_TABS.ORGANIZATIONS.access}
                />
              }
            />
            <Route
              path="/admin/organization-beta/:id"
              exact
              element={
                <PrivateRoute
                  component={AdminOrganizationPage}
                  requiredClaims={ADMIN_TABS.ORGANIZATIONS.access}
                />
              }
            />
            <Route
              path="/admin/organization-beta/:id/new-user"
              exact
              element={
                <PrivateRoute
                  component={CreateUserPage}
                  requiredClaims={[
                    ...ADMIN_TABS.ORGANIZATIONS.access,
                    CLAIMS.CAN_EDIT_USERS,
                  ]}
                />
              }
            />
            <Route
              path="/admin/organization-beta/:id/user/:userId"
              exact
              element={
                <PrivateRoute
                  component={EditUserPage}
                  requiredClaims={[
                    ...ADMIN_TABS.ORGANIZATIONS.access,
                    CLAIMS.CAN_EDIT_USERS,
                  ]}
                />
              }
            />

            {/* Admin */}
            <Route
              path="/admin/organizations"
              exact
              element={
                <PrivateRoute
                  component={AdminOrganizations}
                  requiredClaims={ADMIN_TABS.ORGANIZATIONS.access}
                />
              }
            />
            <Route
              path="/admin/organization/:id"
              exact
              element={
                <PrivateRoute
                  component={AdminOrganizationPage}
                  requiredClaims={ADMIN_TABS.ORGANIZATIONS.access}
                />
              }
            />
            <Route
              path="/admin/organization/:id/new-user"
              exact
              element={
                <PrivateRoute
                  component={CreateUserPage}
                  requiredClaims={[
                    ...ADMIN_TABS.ORGANIZATIONS.access,
                    CLAIMS.CAN_EDIT_USERS,
                  ]}
                />
              }
            />
            <Route
              path="/admin/organization/:id/user/:userId"
              exact
              element={
                <PrivateRoute
                  component={EditUserPage}
                  requiredClaims={[
                    ...ADMIN_TABS.ORGANIZATIONS.access,
                    CLAIMS.CAN_EDIT_USERS,
                  ]}
                />
              }
            />
            <Route
              path="/admin/loan-document"
              exact
              element={
                <PrivateRoute
                  component={LoanDocumentPage}
                  requiredClaims={ADMIN_TABS.DOCUMENTS.access}
                />
              }
            />
            <Route
              path="/admin/loan-document/create"
              exact
              element={
                <PrivateRoute
                  component={(props) => (
                    <LoanDocumentCreatePage
                      {...props}
                      isCreate={true}
                      requiredClaims={ADMIN_TABS.DOCUMENTS.access}
                    />
                  )}
                />
              }
            />
            <Route
              path="/admin/loan-document/:id"
              exact
              element={
                <PrivateRoute
                  component={LoanDocumentEditPage}
                  requiredClaims={ADMIN_TABS.DOCUMENTS.access}
                />
              }
            />
            <Route
              path="/admin/aus-templates"
              exact
              element={
                <PrivateRoute
                  component={AdminAusTemplates}
                  requiredClaims={ADMIN_TABS.AUS_TEMPLATES.access}
                />
              }
            />
            <Route
              path="/admin/aus-templates/:id"
              exact
              element={
                <PrivateRoute
                  component={AdminAusTemplatesSettings}
                  requiredClaims={ADMIN_TABS.AUS_TEMPLATES.access}
                />
              }
            />
            <Route
              path="/admin/aus-templates/add-new-template"
              exact
              element={
                <PrivateRoute
                  component={(props) => (
                    <AdminAusTemplatesSettings
                      {...props}
                      isCreate={true}
                      requiredClaims={ADMIN_TABS.STIPULATION_TEMPLATES.access}
                    />
                  )}
                />
              }
            />
            <Route
              path="/admin/stipulation-templates"
              exact
              element={
                <PrivateRoute
                  component={AdminStipulationTemplates}
                  requiredClaims={ADMIN_TABS.STIPULATION_TEMPLATES.access}
                />
              }
            />

            <Route
              path="/admin/stipulation-templates/:id"
              exact
              element={
                <PrivateRoute
                  component={AdminStipulationTemplatesSettings}
                  requiredClaims={ADMIN_TABS.STIPULATION_TEMPLATES.access}
                />
              }
            />

            <Route
              path="/admin/stipulation-templates/add-new-template"
              exact
              element={
                <PrivateRoute
                  component={(props) => (
                    <AdminStipulationTemplatesSettings
                      {...props}
                      isCreate={true}
                      requiredClaims={ADMIN_TABS.STIPULATION_TEMPLATES.access}
                    />
                  )}
                />
              }
            />

            <Route
              path="/admin/roles"
              exact
              element={
                <PrivateRoute
                  component={AdminRolesManagement}
                  requiredClaims={ADMIN_TABS.ROLES.access}
                />
              }
            />

            <Route
              path="/admin/roles/:id"
              exact
              element={
                <PrivateRoute
                  component={AdminRolesSettings}
                  requiredClaims={ADMIN_TABS.ROLES.access}
                />
              }
            />
            <Route
              path="/admin/roles/add-new-role"
              exact
              element={
                <PrivateRoute
                  component={(props) => (
                    <AdminRolesSettings {...props} isCreate={true} />
                  )}
                />
              }
            />

            <Route
              path="/admin/edit-translations/:id"
              exact
              element={
                <PrivateRoute
                  component={EditTranslationsPage}
                  requiredClaims={ADMIN_TABS.EDIT_TRANSLATIONS.access}
                />
              }
            />
            <Route
              path="/admin/history"
              exact
              element={
                <PrivateRoute
                  component={AdminHistoryPage}
                  requiredClaims={ADMIN_TABS.HISTORY.access}
                />
              }
            />
            <Route
              path="/admin/users"
              exact
              element={
                <PrivateRoute
                  component={AdminUsers}
                  requiredClaims={ADMIN_TABS.USERS.access}
                />
              }
            />
            <Route
              path="/admin/users/:userId/edit-user"
              exact
              element={
                <PrivateRoute
                  component={(props) => (
                    <EditUserPage {...props} isUserModule />
                  )}
                  requiredClaims={[CLAIMS.CAN_EDIT_USERS]}
                />
              }
            />
            <Route
              path="/admin/users/:userId"
              exact
              element={
                <PrivateRoute
                  component={(props) => (
                    <AdminUserDetails {...props} isUserModule />
                  )}
                  requiredClaims={[CLAIMS.CAN_VIEW_USERS]}
                />
              }
            />
            <Route
              path="/admin/users/add-new-user"
              exact
              element={
                <PrivateRoute
                  component={(props) => (
                    <CreateUserPage {...props} isUserModule />
                  )}
                  requiredClaims={[CLAIMS.CAN_ADD_USERS]}
                />
              }
            />
            <Route
              path="/outage-messages"
              exact
              element={
                <PrivateRoute
                  component={OutageMessages}
                  requiredClaims={ADMIN_TABS.OUTAGE_MESSAGES.access}
                />
              }
            />

            {/* Automated Funding Process */}
            <Route
              path="/afp"
              exact
              element={<Navigate to="/afp/daily" replace />}
            />
            <Route
              path="/afp/daily"
              exact
              element={
                <PrivateRoute
                  component={AFPDailyFundingPage}
                  requiredClaims={[CLAIMS.CAN_FUND_LOAN_APPLICATIONS]}
                />
              }
            />
            <Route
              path="/afp/bulk-funding"
              exact
              element={
                <PrivateRoute
                  component={BulkFunding}
                  requiredClaims={[CLAIMS.CAN_FUND_LOAN_APPLICATIONS]}
                />
              }
            />
            <Route
              path="/afp/history"
              exact
              requiredClaims={[CLAIMS.CAN_FUND_LOAN_APPLICATIONS]}
              element={<PrivateRoute component={AFPFundingHistoryPage} />}
            />
            <Route
              path="/afp/lender"
              exact
              element={
                <PrivateRoute
                  component={AFPLenderPage}
                  requiredClaims={[CLAIMS.CAN_FUND_LOAN_APPLICATIONS]}
                />
              }
            />
            <Route
              path="/afp/dealer"
              exact
              element={
                <PrivateRoute
                  component={AFPDealerPage}
                  requiredClaims={[CLAIMS.CAN_FUND_LOAN_APPLICATIONS]}
                />
              }
            />
            <Route
              path="/afp/wires"
              exact
              element={
                <PrivateRoute
                  component={AFPWiresPage}
                  requiredClaims={[CLAIMS.CAN_FUND_LOAN_APPLICATIONS]}
                />
              }
            />
            <Route
              path="/afp/approval"
              exact
              element={
                <PrivateRoute
                  component={AFPApprovalPage}
                  requiredClaims={[CLAIMS.CAN_FUND_LOAN_APPLICATIONS]}
                />
              }
            />

            {/* Other */}
            <Route
              path="/playground"
              exact
              element={<PublicRoute component={PlaygroundPage} />}
            />

            {/* DefaultPage Page - Loan apps or Dashboard */}
            <Route
              path="/"
              exact
              element={<PrivateRoute component={DefaultPage} />}
            />

            {/* Release Notes */}
            <Route
              path="/release-notes"
              exact
              element={<PrivateRoute component={ReleaseNotesPage} />}
            />

            {/* Generic route handler */}
            <Route
              path="*"
              element={<Navigate to={NOT_FOUND_ROUTE} replace />}
            />
          </SentryRoutes>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </Router>
  </StateProvider>
)

/**
 * Private route wrapper. Ensures private routes are accessed only by Authenticated & Allowed users
 * It checks for "requiredClaims" dynamically based on the route
 * If no "requiredClaims" are found for this route, anyone logged-in can access
 *
 * @param options
 * @param {ReactDOM} options.component - component to render
 * @param {Array} options.requiredClaims
 */
function PrivateRoute({ component: Component, requiredClaims }) {
  const [isLoggedIn, userData, isCheckingToken] = useSession()

  if (isCheckingToken) {
    return null
  }

  // If not authenticated -> redirect to login and save current route
  if (!isLoggedIn || !userData) {
    saveLocationToRedirectTo(
      `${window.location.pathname}${window.location.search}`
    )

    return <Navigate to="/login" replace />
  }

  // If no access based on Claims -> redirect

  const access = hasAccess(userData, requiredClaims)
  if (!access) {
    return <Navigate to={NOT_FOUND_ROUTE} replace />
  }

  // Else render the content
  return (
    <AuthWrapper requiredClaims={requiredClaims}>
      <Component />
    </AuthWrapper>
  )
}

// eslint-disable-next-line react/prop-types
function PublicRoute({ component: Component }) {
  return (
    <NonAuthWrapper>
      <Component />
    </NonAuthWrapper>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  requiredClaims: PropTypes.array,
}

Route.propTypes = {
  component: PropTypes.any,
}
