import { components, colors, theme } from '@ElementsCapitalGroup/enium-ui'
import { formatIntToCurrency } from 'modules/loan-application/utils'

import PropTypes from 'prop-types'
import { DASHBOARD_CARDS_INFO, DASHBOARD_INFO_TYPES } from '../contants'

const {
  lightTheme: { typography },
} = theme

const { Card, FeaturedIcon, FeaturedIconSizes } = components

export const InfoCard = ({ title, content, infoType }) => {
  const renderContent = () => {
    if (!content) {
      return 'N/A'
    }

    if (DASHBOARD_CARDS_INFO[infoType]?.isCurrency) {
      return formatIntToCurrency(content)
    }

    if (DASHBOARD_CARDS_INFO[infoType]?.isYears) {
      return `${content} years`
    }

    return content
  }

  return (
    <Card>
      <FeaturedIcon
        icon={DASHBOARD_CARDS_INFO[infoType]?.icon}
        color={DASHBOARD_CARDS_INFO[infoType]?.color}
        variant={'ripple'}
        size={FeaturedIconSizes.LG}
      />
      <div
        style={{
          color: colors.grey[600],
          fontSize: typography.body2.fontSize,
          fontWeight: typography.fontWeightBold,
          marginTop: '24px',
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: colors.grey[900],
          fontSize: typography.h4.fontSize,
          fontWeight: typography.fontWeightBold,
        }}
      >
        {renderContent()}
      </div>
    </Card>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  infoType: PropTypes.oneOf(Object.values(DASHBOARD_INFO_TYPES)).isRequired,
}
