import { ACTIONS } from './actions'
import { INITIAL_CONFIG_STATE } from './constants'

const initialState = {
  loading: false,
  list: [],
  userAssociations: [],
  config: { ...INITIAL_CONFIG_STATE },
}

/** Name of the reducer */
const name = 'outageMessages'

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
  [ACTIONS.LOAD_ALL_DATA]: (state, { list, userAssociations, config }) => ({
    ...state,
    list,
    userAssociations,
    config,
    loading: false,
  }),
  [ACTIONS.LOAD_MESSAGES]: (state, { list }) => ({ ...state, list }),
  [ACTIONS.CONFIG_UPDATED]: (state, { config }) => ({ ...state, config }),
}

export default { name, initialState, reduce }
