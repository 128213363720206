import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'
import { updateSalesRepresentative } from 'modules/loan-application/actions'
import { VALIDATION_TYPES, validate } from 'components/validator'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button/index'
import Loader from 'components/loader'
import {
  getOrganizations,
  getUsersMinimalData,
} from 'modules/organizations/actions'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'

import { ORGANIZATION_TYPE_IDS } from 'common/constants'

import styles from './style.module.scss'

const { Dialog, Autocomplete } = components

const SalesRepsModal = ({
  userData,
  loanData,
  setIsModalOpen,
  areInputsDisabled,
  isModalOpen,
  dispatch,
}) => {
  const { t: translate } = useTranslation()

  const hasOrgAccess = hasAccess(userData, CLAIMS.CAN_VIEW_ORGANIZATIONS)
  const canChangeOrg = hasAccess(userData, CLAIMS.CAN_CHANGE_DEALERS)
  const [organizations, setOrganizations] = useState([])
  const [salesRep, setSalesRep] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [selectedSalesRep, setSelectedSalesRep] = useState({})

  const [errors, setErrors] = useState({})

  const salesRepOptions = useMemo(() => {
    return salesRep.map((rep) => {
      return {
        ...rep,
        id: rep.guid,
        label: rep.value,
      }
    })
  }, [salesRep, organizations])

  const organizationsOptions = useMemo(() => {
    return organizations.map((org) => {
      return {
        ...org,
        id: org.guid,
        label: org.value,
      }
    })
  }, [organizations])

  useEffect(() => {
    if (hasOrgAccess) {
      getOrganizations(null, { itemsPerPage: 1000 }).then((res) => {
        const { organizationUnits } = res
        const salesOrganizations = organizationUnits
          .filter((o) => o.type.id === ORGANIZATION_TYPE_IDS.DEALER)
          .map((item) => {
            return {
              value: item.name,
              guid: item.guid,
            }
          })

        setOrganizations(salesOrganizations)

        const selectedOrg = salesOrganizations.find((item) => {
          return item.guid === loanData.salesOrganizationId
        })

        if (selectedOrg) {
          setSelectedOrganization(selectedOrg)
        } else {
          setSelectedOrganization('')
        }
      })
    }
  }, [isModalOpen])

  useEffect(() => {
    if (selectedOrganization) {
      getUsersMinimalData(selectedOrganization.guid).then((res) => {
        setSalesRep(
          res.map((item) => {
            if (item.guid === loanData.salesRepresentativeId) {
              setSelectedSalesRep({
                value: loanData.salesRepresentativeName,
                guid: loanData.salesRepresentativeId,
                phoneNumber: item.phoneNumber,
                email: item.emailAddress,
              })
            }
            return {
              value: item.name,
              guid: item.guid,
              phoneNumber: item.phoneNumber,
              email: item.emailAddress,
            }
          })
        )
      })
    }
  }, [selectedOrganization, isModalOpen])

  const handleSubmit = () => {
    const organizationId = selectedOrganization.guid
    const salesRepId = selectedSalesRep.guid

    const [isValid, errors] = validate(
      {
        organizationId: [VALIDATION_TYPES.REQUIRED],
        salesRepId: [VALIDATION_TYPES.REQUIRED],
      },
      { organizationId, salesRepId }
    )

    if (!isValid) {
      return setErrors(errors)
    } else {
      updateSalesRepresentative(dispatch, {
        loanApplicationId: loanData.loanApplicationId,
        salesOrganizationId:
          selectedOrganization.guid || loanData.salesOrganizationId,
        salesRepresentativeId:
          selectedSalesRep.guid || loanData.salesRepresentativeI,
      }).then(() => setIsModalOpen(false))
    }
  }

  return (
    <>
      <Dialog
        open={true}
        onClose={() => setIsModalOpen(false)}
        title={translate(
          'loanApplication.statusCard.summaryModal.salesRepDetails'
        )}
        actions={
          <>
            <Button
              onClick={() => setIsModalOpen(false)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button disabled={!canChangeOrg} onClick={handleSubmit}>
              {translate('buttons.save')}
            </Button>
          </>
        }
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '800px',
          },
        }}
        titleProps={{
          sx: {
            paddingBottom: '24px',
          },
        }}
        actionsProps={{
          sx: {
            padding: '0 24px 24px',
          },
        }}
      >
        {salesRepOptions.length < 1 || organizationsOptions.length < 1 ? (
          <Loader center />
        ) : (
          <div>
            {hasOrgAccess ? (
              <>
                <div className={styles.salesRepCard}>
                  <Autocomplete
                    label={translate(
                      'loanApplication.statusCard.summaryModal.salesRepName'
                    )}
                    options={salesRepOptions}
                    value={
                      selectedSalesRep
                        ? { ...selectedSalesRep, label: selectedSalesRep.value }
                        : ''
                    }
                    onChange={(e, selected) => {
                      setSelectedSalesRep(selected)
                    }}
                    error={errors.salesRepId}
                    disabled={
                      !selectedOrganization ||
                      !canChangeOrg ||
                      areInputsDisabled
                    }
                  />

                  <TextField
                    label={translate(
                      'loanApplication.statusCard.summaryModal.salesRepresentativeEmail'
                    )}
                    value={selectedSalesRep?.email}
                    disabled
                    onChange={() => {}}
                  />

                  <TextField
                    label={translate(
                      'loanApplication.statusCard.summaryModal.salesRepresentativePhoneNumber'
                    )}
                    value={selectedSalesRep?.phoneNumber}
                    disabled
                    onChange={() => {}}
                  />

                  <Autocomplete
                    label={translate(
                      'loanApplication.statusCard.summaryModal.salesOrganization'
                    )}
                    options={organizationsOptions}
                    value={
                      selectedOrganization
                        ? {
                            ...selectedOrganization,
                            label: selectedOrganization.value,
                          }
                        : ''
                    }
                    onChange={(e, selected) => {
                      setSelectedOrganization(selected)
                    }}
                    error={errors.salesOrganizationId}
                    disabled={areInputsDisabled}
                  />
                </div>
              </>
            ) : null}
          </div>
        )}
      </Dialog>
    </>
  )
}

SalesRepsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  loanData: PropTypes.object.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  areInputsDisabled: PropTypes.bool,
  isModalOpen: PropTypes.func,
}

export default SalesRepsModal
