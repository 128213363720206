import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import TextField from 'components/input'
import SearchBar from 'components/search-bar/index'
import { useTranslation } from 'react-i18next'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import {
  components,
  unstable_components,
  providers,
} from '@ElementsCapitalGroup/enium-ui'

import ApplicantInfoAddress from '../../service-details'

const { ThemeProvider } = providers
const { Paper, Autocomplete, Checkbox } = components
const { Unstable_Grid: Grid } = unstable_components

const LoanAppTopSection = ({
  isEdit,
  organizations,
  isCorporateOrg,
  borrowerGeneralDetails,
  coBorrowerGeneralDetails,
  setBorrowerGeneralDetails,
  allErrors,
  setAllErrors,
  onAddressSelected,
  hasServiceAddrErrors,
  serviceAddress,
  setServiceAddress,
  organization,
  setOrganization,
  organizationHasSpecialFields,
  specialFieldsDetails,
  setSpecialFieldsDetails,
  toggleBypassAddressValidation,
  bypassValidateAddress,
  areInputsDisabled,
  allLoanSignatures,
  loanSignatures,
  setLoanSignatures,
}) => {
  const { t: translate } = useTranslation()

  const translations = {
    loanAmount: translate('loanApplication.step1.loanAmount'),
    clickHere: translate('loanApplication.step1.clickHere'),
    salesOrganisation: translate('loanApplication.step1.salesOrganisation'),
    couldNotFind: translate('loanApplication.step1.couldNotFind'),
    serviceAddress: translate('loanApplication.step1.address.serviceAddress'),
    batteryOnly: translate('loanApplication.step1.batteryOnly'),
    downPayment: translate('loanApplication.step1.downPayment'),
    netAmount: translate('loanApplication.step1.netAmount'),
    bypassAddressValidation: translate(
      'loanApplication.step1.bypassAddressValidation'
    ),
    searchLoanProducts: translate('loanApplication.step1.searchLoanProducts'),
  }

  const organizationsOptions = useMemo(() => {
    return organizations.map((org) => {
      return {
        ...org,
        id: org.guid,
        label: org.name,
      }
    })
  }, [organizations])

  const organizationValue = useMemo(() => {
    if (!organization) {
      return null
    }

    const selected = organizations.find((org) => org.guid === organization)
    return {
      ...selected,
      id: selected?.guid,
      label: selected?.name,
    }
  }, [organization, organizations])

  const renderServiceAddress = () => (
    <Paper className="paper">
      <Grid container flexDirection="column" gap={24}>
        <Grid item className="applicant-info__form-title">
          {translate('loanApplication.step1.address.serviceAddress')}
        </Grid>
        <ApplicantInfoAddress
          addressDetails={serviceAddress}
          setAddressDetails={setServiceAddress}
          errors={allErrors}
          setErrors={setAllErrors}
          coBorrowerSameAddress={
            coBorrowerGeneralDetails.sameWithServiceAddress
          }
          borrowerSameAddress={borrowerGeneralDetails.sameWithServiceAddress}
          onAddressSelected={onAddressSelected}
          areInputsDisabled={areInputsDisabled}
          hasServiceAddrErrors={hasServiceAddrErrors}
        />

        <AccessWrapper claims={CLAIMS.CAN_BYPASS_ADDRESS_VALIDATION}>
          <Checkbox
            label={translations.bypassAddressValidation}
            onClick={toggleBypassAddressValidation}
            uncentered
            checked={bypassValidateAddress}
          />
        </AccessWrapper>
      </Grid>
    </Paper>
  )

  const _renderSpecialFields = () => (
    <>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          label={translations.netAmount}
          validate={() => allErrors.specialFields.netAmount}
          fullWidth
          type="currency"
          fixedDecimalScale={true}
          value={specialFieldsDetails.netAmount || NaN}
          onChange={(e) => {
            setSpecialFieldsDetails({
              ...specialFieldsDetails,
              netAmount: e,
            })
            setAllErrors({
              ...allErrors,
              specialFields: { ...allErrors.specialFields, netAmount: null },
            })
          }}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={4}>
        <TextField
          label={translations.downPayment}
          validate={() => allErrors.specialFields.downPayment}
          fullWidth
          type="currency"
          fixedDecimalScale={true}
          value={specialFieldsDetails.downPayment || NaN}
          onChange={(e) => {
            setSpecialFieldsDetails({
              ...specialFieldsDetails,
              downPayment: e,
            })
            setAllErrors({
              ...allErrors,
              specialFields: { ...allErrors.specialFields, downPayment: null },
            })
          }}
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} desktop={12}>
        <div style={{ marginLeft: '4px' }}>
          <Checkbox
            label={translations.batteryOnly}
            onClick={() =>
              setSpecialFieldsDetails((prev) => ({
                ...prev,
                batteryOnly: !specialFieldsDetails.batteryOnly,
              }))
            }
            checked={!!specialFieldsDetails.batteryOnly}
            disabled={areInputsDisabled}
          />
        </div>
      </Grid>
    </>
  )

  return (
    <>
      <Paper className="paper">
        <Grid container gap={24} className={cx('applicant-info__loan-details')}>
          <Grid item className="applicant-info__form-title">
            {translate('loanApplication.step1.loanDetails')}
          </Grid>
          <Grid item mobile={12}>
            <SearchBar
              fullWidth
              label={translate('loanApplication.step1.loanProducts')}
              placeholder={translations.searchLoanProducts}
              searchData={allLoanSignatures}
              initialValue={loanSignatures}
              onChange={(selected) => {
                setLoanSignatures(selected)
                setAllErrors({ ...allErrors, loanSignatures: null })
              }}
              error={allErrors.loanSignatures}
              disabled={!allLoanSignatures.length || areInputsDisabled}
              multiple={true}
            />
          </Grid>

          {isCorporateOrg && (
            <Grid item mobile={12}>
              <ThemeProvider>
                <Autocomplete
                  label={translations.salesOrganisation}
                  options={organizationsOptions}
                  value={organizationValue}
                  onChange={(e, selected) => {
                    setOrganization(selected?.guid)
                    setAllErrors({ ...allErrors, organization: null })
                  }}
                  error={allErrors.organization}
                  disabled={isEdit || areInputsDisabled}
                />
              </ThemeProvider>
            </Grid>
          )}

          <Grid
            container
            gap={24}
            alignItems="center"
            style={{ width: '100%' }}
          >
            <Grid
              item
              mobile={12}
              tablet={12}
              desktop={organizationHasSpecialFields ? 4 : 12}
            >
              <TextField
                label={translations.loanAmount}
                validate={() => allErrors.borrower.loanAmount}
                fullWidth
                type="currency"
                fixedDecimalScale={true}
                value={borrowerGeneralDetails.loanAmount || NaN}
                onChange={(e) => {
                  setBorrowerGeneralDetails({
                    ...borrowerGeneralDetails,
                    loanAmount: e,
                  })
                  setAllErrors({
                    ...allErrors,
                    borrower: { ...allErrors.borrower, loanAmount: null },
                  })
                }}
                disabled={areInputsDisabled}
              />
            </Grid>

            {organizationHasSpecialFields && _renderSpecialFields()}
          </Grid>
        </Grid>
      </Paper>

      {renderServiceAddress()}
    </>
  )
}

LoanAppTopSection.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  organizations: PropTypes.array.isRequired,
  isCorporateOrg: PropTypes.bool.isRequired,
  borrowerGeneralDetails: PropTypes.object.isRequired,
  coBorrowerGeneralDetails: PropTypes.object.isRequired,
  setBorrowerGeneralDetails: PropTypes.func.isRequired,
  allErrors: PropTypes.object.isRequired,
  setAllErrors: PropTypes.func.isRequired,
  onAddressSelected: PropTypes.func.isRequired,
  hasServiceAddrErrors: PropTypes.bool.isRequired,
  serviceAddress: PropTypes.object.isRequired,
  setServiceAddress: PropTypes.func.isRequired,
  organization: PropTypes.string,
  setOrganization: PropTypes.func.isRequired,
  organizationHasSpecialFields: PropTypes.bool,
  specialFieldsDetails: PropTypes.object.isRequired,
  setSpecialFieldsDetails: PropTypes.func.isRequired,
  toggleBypassAddressValidation: PropTypes.func.isRequired,
  bypassValidateAddress: PropTypes.bool.isRequired,
  areInputsDisabled: PropTypes.bool.isRequired,
  allLoanSignatures: PropTypes.array.isRequired,
  loanSignatures: PropTypes.array,
  setLoanSignatures: PropTypes.func.isRequired,
}

export default LoanAppTopSection
