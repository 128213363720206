import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { typography },
} = theme

const loanDocuments = {
  width: '100%',
}

const headerWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '18px',
}

const header = {
  fontSize: typography.h4.fontSize,
  fontWeight: typography.fontWeightBold,
  color: colors.grey[900],
}

const docsLoadingWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '20px',
}

const headerMobile = {
  ...header,
  fontSize: typography.h5.fontSize,
}

const subHeader = {
  fontSize: typography.body2.fontSize,
  color: colors.grey[600],
}

const subHeaderMobile = {
  ...subHeader,
  fontSize: typography.body2.fontSize,
}

const cardTitle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
}

const cardButtons = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '4px',
}

const cardButtonsDocs = {
  ...cardButtons,
  flexWrap: 'wrap',
}

const recipientName = {
  fontSize: '0.875rem',
  fontWeight: 500,
  color: 'black',
  lineHeight: 1.5,
}

const recipientEmail = {
  color: colors.grey[600],
  fontWeight: 400,
  fontSize: '0.75rem',
}

const envelopeButton = {
  cursor: 'pointer',
  marginLeft: '24px',
  width: '20px',
  height: '20px',
}

const envelopeButtonWrapper = {
  display: 'flex',
  alignItems: 'center',
  color: colors.grey[600],
}

const mobileCardHeader = {
  padding: '20px 16px',
  justifyContent: 'space-between',
  fontSize: '0.875rem',
  color: 'black',
  fontWeight: '500',
}

const mobileCardContent = {
  padding: '16px',
  fontSize: '0.75rem',
  color: colors.grey[600],
  fontWeight: 500,
  display: 'grid',
  gridGap: '16px',
}

export const styles = {
  loanDocuments,
  headerWrapper,
  header,
  subHeader,
  headerMobile,
  subHeaderMobile,
  cardTitle,
  cardButtons,
  cardButtonsDocs,
  docsLoadingWrapper,
  recipientName,
  recipientEmail,
  envelopeButton,
  envelopeButtonWrapper,
  mobileCardHeader,
  mobileCardContent,
}
