import { STATUSES } from 'components/status'

export const STATUS_ID_MAPPING = {
  1: STATUSES.ON_HOLD,
  2: STATUSES.ACTIVE,
  3: STATUSES.INACTIVE,
  4: STATUSES.UNDEFINED,
  5: STATUSES.UNDEFINED,
}

export const LANGUAGES = [
  { id: 0, label: 'English' },
  { id: 1, label: 'Spanish' },
]

export const ORGS_PAGE_SIZE = 20
export const USERS_PAGE_SIZE = 20

export const USER_STATUS = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  SUSPENDED: 'Suspended',
  INACTIVE: 'Inactive',
}
export const USER_STATUS_IDS = {
  1: USER_STATUS.PENDING,
  2: USER_STATUS.ACTIVE,
  3: USER_STATUS.SUSPENDED,
  4: USER_STATUS.INACTIVE,
}
export const USER_STATUS_ID_MAPPING = {
  [USER_STATUS.PENDING]: STATUSES.ON_HOLD,
  [USER_STATUS.ACTIVE]: STATUSES.ACTIVE,
  [USER_STATUS.SUSPENDED]: STATUSES.UNDEFINED,
  [USER_STATUS.INACTIVE]: STATUSES.INACTIVE,
}
