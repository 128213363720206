import PropTypes from 'prop-types'
import { assets, colors, components } from '@ElementsCapitalGroup/enium-ui'

import { DATA_VALIDATION_IDS } from 'modules/uw-hub/constants'

const { CheckIcon, XCloseIcon, AlertCircleIcon } = assets
const { Tooltip } = components

const ValidationCheck = ({ dataValidationId, reason }) => {
  return (
    <>
      {dataValidationId === DATA_VALIDATION_IDS.Valid && (
        <CheckIcon
          sx={{
            color: colors.green[600],
            marginRight: '6px',
            width: '20px',
            height: '20px',
          }}
        />
      )}
      {dataValidationId === DATA_VALIDATION_IDS.Invalid && (
        <XCloseIcon
          sx={{
            color: colors.red[600],
            marginRight: '6px',
            width: '20px',
            height: '20px',
          }}
        />
      )}
      {dataValidationId === DATA_VALIDATION_IDS.ManualCheck && (
        <Tooltip title={reason} placement="top" className="align-center">
          <AlertCircleIcon
            sx={{
              color: colors.yellow[500],
              marginRight: '6px',
              width: '20px',
              height: '20px',
            }}
          />
          &nbsp;
        </Tooltip>
      )}
    </>
  )
}

ValidationCheck.propTypes = {
  dataValidationId: PropTypes.number.isRequired,
  reason: PropTypes.string,
}

export default ValidationCheck
