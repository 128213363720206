import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { formatInUSFormat, sumFloat } from 'common/number'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Checkbox from 'components/checkbox'
import { styles } from '../style'
import {
  CORPORATION_SUMMARY,
  MONTH_OPTIONS,
  PARTNERSHIP_SUMMARY,
  S_CORPORATION_SUMMARY,
} from './constants'

const { Card, Dropdown } = components

const CashFlowSummary = ({ sections, years }) => {
  const [calculations, setCalculations] = useState({
    income: [], // array -> for each section 1 item of type {months, income}
    total: 0,
  })
  // Holds for each section the # of months + exclude flag
  const [summaryData, setSummaryData] = useState([])

  let firstPartnershipSection = sections.findIndex(
    (section) => section.type === 'partnership'
  )
  if (firstPartnershipSection === -1) {
    firstPartnershipSection = sections.length
  }

  let firstCorporationSection = sections.findIndex(
    (section) => section.type === 'corporation'
  )
  if (firstCorporationSection === -1) {
    firstCorporationSection = sections.length
  }

  const personalSections = sections.slice(0, firstPartnershipSection)
  const partnershipSections = sections.slice(
    firstPartnershipSection,
    firstCorporationSection
  )
  const corporationSections = sections.slice(firstCorporationSection)

  /** Calculate income for each section, and total */
  const performCalculations = useCallback(
    debounce((sections, extraData, years) => {
      let total = 0
      const income = []
      sections.forEach((section, sectionIndex) => {
        let totalIncome = 0
        let totalMonths = 0

        if (section.partialSubtotals) {
          const sectionIncome = { 0: 0, 1: 0 }
          let sectionQualifyingIncome = 0
          let extraSections = []
          if (section.type === 'partnership') {
            extraSections = PARTNERSHIP_SUMMARY
          } else if (section.type === 's_corporation') {
            extraSections = S_CORPORATION_SUMMARY
          } else if (section.type === 'corporation') {
            extraSections = CORPORATION_SUMMARY
          }
          income.push({})
          extraSections.forEach((partial) => {
            let partialIncome = 0
            let partialMonths = 0
            const partialIncomePerYear = { 0: 0, 1: 0 }
            years.forEach((year, yearIndex) => {
              const data = extraData[sectionIndex]?.[partial.id]?.[yearIndex]
              const months = data?.months || 0
              const exclude = data?.exclude || false
              partialMonths += months
              let subTotal = 0
              if (!exclude) {
                if (typeof partial.id === 'string') {
                  const partialSubtotal = section.partialSubtotals.find(
                    (el) => el.id === partial.id
                  )
                  if (partialSubtotal.takeSubtotalFromRow) {
                    subTotal =
                      section.rowValues[partialSubtotal.takeSubtotalFromRow]?.[
                        yearIndex
                      ]
                  } else {
                    subTotal = partialSubtotal?.value?.[yearIndex]
                  }
                } else {
                  subTotal = section.rowValues[partial.id]?.[yearIndex]
                }
              }

              partialIncome = sumFloat(partialIncome, subTotal)
              partialIncomePerYear[yearIndex] = sumFloat(
                partialIncomePerYear[yearIndex],
                subTotal
              )
              sectionIncome[yearIndex] = sumFloat(
                sectionIncome[yearIndex],
                partialIncomePerYear[yearIndex]
              )
            })

            let qualifyingIncome =
              partialMonths !== 0 ? partialIncome / partialMonths : 0
            qualifyingIncome = parseFloat(qualifyingIncome.toFixed(2))
            sectionQualifyingIncome += qualifyingIncome
            income[sectionIndex] = {
              ...income[sectionIndex],
              [partial.id]: { months: partialMonths, income: qualifyingIncome },
              sectionIncome,
              sectionQualifyingIncome,
            }

            total = sumFloat(total, qualifyingIncome)
          })
        } else {
          years.forEach((year, yearIndex) => {
            const months = extraData[sectionIndex]?.[yearIndex]?.months || 0
            totalMonths += months
            let subTotal = section.subTotal[yearIndex]
            if (extraData[sectionIndex]?.[yearIndex]?.exclude) {
              subTotal = 0
            }
            totalIncome = sumFloat(totalIncome, subTotal)
          })

          let qualifyingIncome =
            totalMonths !== 0 ? totalIncome / totalMonths : 0
          qualifyingIncome = parseFloat(qualifyingIncome.toFixed(2))
          income.push({ months: totalMonths, income: qualifyingIncome })

          total = sumFloat(total, qualifyingIncome)
        }
      })

      setCalculations({ total, income })
    }, 100),
    []
  )

  /** On mount & on changes perform calculations */
  useEffect(() => {
    performCalculations(sections, summaryData, years)
  }, [sections, summaryData, years])

  /** Each time sections change, make sure there are enough month & exclude values for all sections */
  useEffect(() => {
    const newSummaryData = []
    sections.forEach((section, sectionIdx) => {
      const existingData = summaryData[sectionIdx]
      newSummaryData[sectionIdx] = {}
      years.forEach((year, yearIndex) => {
        const months = typeof existingData === 'number' ? existingData : 12
        const exclude = existingData?.exclude || false
        newSummaryData[sectionIdx][yearIndex] = { months, exclude }
      })

      let extraSections = []
      if (section.type === 'partnership') {
        extraSections = PARTNERSHIP_SUMMARY
      } else if (section.type === 's_corporation') {
        extraSections = S_CORPORATION_SUMMARY
      } else if (section.type === 'corporation') {
        extraSections = CORPORATION_SUMMARY
      }
      extraSections.forEach((partial) => {
        const existingData = summaryData[sectionIdx]?.[partial.id]
        newSummaryData[sectionIdx][partial.id] = {}
        years.forEach((year, yearIndex) => {
          const months = typeof existingData === 'number' ? existingData : 12
          const exclude = existingData?.exclude || false
          newSummaryData[sectionIdx][partial.id][yearIndex] = {
            months,
            exclude,
          }
        })
      })
    })
    setSummaryData(newSummaryData)
  }, [sections.length, years])

  const onSectionMonthChange = (sectionIdx, yearIndex, partialId) => (ev) => {
    const newSectionMonths = [...summaryData]
    const value = ev.target.value.id
    if (!partialId) {
      newSectionMonths[sectionIdx][yearIndex].months = parseInt(value)
    } else {
      newSectionMonths[sectionIdx][partialId][yearIndex].months =
        parseInt(value)
    }
    if (value !== 0) {
      if (!partialId) {
        newSectionMonths[sectionIdx][yearIndex].exclude = false
      } else {
        newSectionMonths[sectionIdx][partialId][yearIndex].exclude = false
      }
    }
    setSummaryData(newSectionMonths)
  }

  const onSectionExcludeChange = (sectionIdx, yearIndex, partialId) => () => {
    const newSectionMonths = [...summaryData]
    const data = !partialId
      ? newSectionMonths[sectionIdx][yearIndex]
      : newSectionMonths[sectionIdx][partialId][yearIndex]
    const exclude = !data.exclude
    data.exclude = exclude
    data.months = exclude ? 0 : 12
    setSummaryData(newSectionMonths)
  }

  const _renderSectionSummary = (sectionIdx) => {
    const totalMonths = years.reduce((acc, year, yearIndex) => {
      const monthsForYear = MONTH_OPTIONS.find((el) => {
        return el.id === summaryData[sectionIdx]?.[yearIndex]?.months
      })
      return acc + monthsForYear?.id || 0
    }, 0)

    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={sectionIdx}>
        <div style={styles.selfEmployed.summaryRowTitle}>
          {sections[sectionIdx].label}
          &nbsp;
          {sections[sectionIdx].name || ''}
        </div>

        {years.map((year, yearIndex) => {
          return _renderSectionYearSummary(sectionIdx, yearIndex)
        })}
        <div
          style={{
            ...styles.selfEmployed.summaryRow,
            ...styles.selfEmployed.summaryRowBorder,
            ...styles.selfEmployed.summaryRowTotal,
          }}
        >
          <div style={styles.selfEmployed.summaryCell}>
            <span style={styles.selfEmployed.summaryCellTitle}>
              Qualifying Income
            </span>
            <span>
              $
              {formatInUSFormat(calculations.income?.[sectionIdx]?.income || 0)}
            </span>
          </div>
          <div style={styles.selfEmployed.summaryCell}>
            <span style={styles.selfEmployed.summaryCellTitle}>
              Total months
            </span>
            <span>{totalMonths}</span>
          </div>
        </div>
      </div>
    )
  }

  const _renderPartialSummary = (sectionIdx, partial) => {
    const section = sections[sectionIdx]
    const partialId = partial.id
    const totalMonths = years.reduce((acc, year, yearIndex) => {
      const monthsForYear = MONTH_OPTIONS.find((el) => {
        return (
          el.id === summaryData[sectionIdx]?.[partialId]?.[yearIndex]?.months
        )
      })
      return acc + monthsForYear?.id || 0
    }, 0)

    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={partialId}>
        <div style={styles.selfEmployed.summaryRowTitle}>
          {partial.label}
          {section.name ? ` - ${section.name}` : ''}
        </div>

        {years.map((year, yearIndex) => {
          return _renderSectionYearSummary(sectionIdx, yearIndex, partialId)
        })}

        <div
          style={{
            ...styles.selfEmployed.summaryRow,
            ...styles.selfEmployed.summaryRowBorder,
            ...styles.selfEmployed.summaryRowTotal,
          }}
        >
          <div style={styles.selfEmployed.summaryCell}>
            <span style={styles.selfEmployed.summaryCellTitle}>
              Qualifying Income
            </span>
            <span>
              $
              {formatInUSFormat(
                calculations.income?.[sectionIdx]?.[partialId]?.income || 0
              )}
            </span>
          </div>
          <div style={styles.selfEmployed.summaryCell}>
            <span style={styles.selfEmployed.summaryCellTitle}>
              Total months
            </span>
            <span>{totalMonths}</span>
          </div>
        </div>
      </div>
    )
  }

  const _renderSectionSubtotal = (sectionIdx) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={sectionIdx}>
        <div style={styles.selfEmployed.summaryRowTitle}>
          {sections[sectionIdx].label} Subtotal
        </div>
        {years.map((year, yearIndex) => (
          <div
            key={yearIndex}
            style={{
              ...styles.selfEmployed.summaryRow,
              ...(yearIndex === 1
                ? { ...styles.selfEmployed.summaryRowBorder }
                : {}),
            }}
          >
            <div style={styles.selfEmployed.summaryCell}>
              <span style={styles.selfEmployed.summaryCellTitle}>Subtotal</span>
              $
              {formatInUSFormat(
                calculations.income[sectionIdx]?.sectionIncome?.[yearIndex] || 0
              )}
            </div>
          </div>
        ))}
        <div
          style={{
            ...styles.selfEmployed.summaryRow,
            ...styles.selfEmployed.summaryRowBorder,
            ...styles.selfEmployed.summaryRowTotal,
          }}
        >
          <div style={styles.selfEmployed.summaryCell}>
            <span style={styles.selfEmployed.summaryCellTitle}>
              Qualifying Income
            </span>
            <span>
              $
              {formatInUSFormat(
                calculations.income[sectionIdx]?.sectionQualifyingIncome || 0
              )}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const _renderSectionYearSummary = (sectionIdx, yearIndex, partialId) => {
    const section = sections[sectionIdx]
    const data = partialId
      ? summaryData[sectionIdx]?.[partialId]?.[yearIndex]
      : summaryData[sectionIdx]?.[yearIndex]
    if (!data) {
      return null
    }
    const selectedMonths = MONTH_OPTIONS.find((el) => {
      return el.id === data?.months
    })
    let subTotal = section.subTotal[yearIndex]
    if (partialId) {
      if (typeof partialId === 'string') {
        const partialSubtotal = section.partialSubtotals.find(
          (el) => el.id === partialId
        )
        if (partialSubtotal.takeSubtotalFromRow) {
          subTotal =
            section.rowValues[partialSubtotal.takeSubtotalFromRow]?.[
              yearIndex
            ] || 0
        } else {
          subTotal = partialSubtotal?.value?.[yearIndex]
        }
      } else {
        subTotal = section.rowValues[partialId]?.[yearIndex]
      }
    }

    return (
      <div
        style={{
          ...styles.selfEmployed.summaryRow,
          ...(yearIndex === 1
            ? { ...styles.selfEmployed.summaryRowBorder }
            : {}),
        }}
      >
        <div style={styles.selfEmployed.summaryCell}>
          <span style={styles.selfEmployed.summaryCellTitle}>Subtotal</span>
          <span>${formatInUSFormat(subTotal)}</span>
        </div>
        <div style={{ maxWidth: '128px' }}>
          <Dropdown
            key={sectionIdx}
            value={selectedMonths || {}}
            onChange={onSectionMonthChange(sectionIdx, yearIndex, partialId)}
            options={MONTH_OPTIONS}
            fullWidth={false}
          />
        </div>
        <Checkbox
          onClick={onSectionExcludeChange(sectionIdx, yearIndex, partialId)}
          checked={data?.exclude}
          label="Exclude"
        />
      </div>
    )
  }

  return (
    <Card
      title={
        <div style={styles.selfEmployed.sectionTitle}>
          <div style={{ flexGrow: 1 }}>
            Cash Flow Analysis Summary
            <div style={styles.selfEmployed.sectionDescription}>
              You can modify the total number of months and select which
              Qualifying Income(s) to be included.
            </div>
          </div>
          {years.length > 1 ? (
            <>
              {years.map((year, yearIndex) => (
                <div
                  key={yearIndex}
                  style={{
                    ...styles.selfEmployed.summaryRow,
                    ...(yearIndex === 1
                      ? { ...styles.selfEmployed.summaryRowBorder }
                      : { justifyContent: 'flex-end' }),
                  }}
                >
                  {year}
                </div>
              ))}
              <div style={styles.selfEmployed.summaryRowTotal}></div>
            </>
          ) : null}
        </div>
      }
      sx={styles.card}
    >
      <div style={{ ...styles.selfEmployed.sectionTitle, marginTop: '20px' }}>
        Personal Cash Flow Subtotals
      </div>
      <div style={styles.selfEmployed.sectionSummary}>
        {personalSections.map((section, sectionIdx) =>
          _renderSectionSummary(sectionIdx)
        )}
      </div>

      <div style={styles.selfEmployed.sectionTitle}>
        Partnership & S Corporation Cash Flow Subtotals
      </div>
      <div style={styles.selfEmployed.sectionSummary}>
        {partnershipSections.map((section, sectionIdx) => (
          <div key={sectionIdx} style={styles.selfEmployed.sectionSummary}>
            {section.type === 'partnership'
              ? PARTNERSHIP_SUMMARY.map((partial) =>
                  _renderPartialSummary(
                    sectionIdx + personalSections.length,
                    partial
                  )
                )
              : S_CORPORATION_SUMMARY.map((partial) =>
                  _renderPartialSummary(
                    sectionIdx + personalSections.length,
                    partial
                  )
                )}
            {_renderSectionSubtotal(sectionIdx + personalSections.length)}
          </div>
        ))}
      </div>

      <div style={styles.selfEmployed.sectionTitle}>
        Corporation Cash Flow Subtotals
      </div>
      <div style={styles.selfEmployed.sectionSummary}>
        {corporationSections.map((section, sectionIdx) => (
          <div key={sectionIdx} style={styles.selfEmployed.sectionSummary}>
            {CORPORATION_SUMMARY.map((partial) =>
              _renderPartialSummary(
                sectionIdx +
                  personalSections.length +
                  partnershipSections.length,
                partial
              )
            )}
            {_renderSectionSubtotal(
              sectionIdx + personalSections.length + partnershipSections.length
            )}
          </div>
        ))}
      </div>

      <div style={styles.selfEmployed.subTotal}>
        Average Monthly Cash Flow (Total) $
        {formatInUSFormat(calculations.total)}
      </div>
    </Card>
  )
}

CashFlowSummary.propTypes = {
  sections: PropTypes.array.isRequired,
  years: PropTypes.array.isRequired,
}

export default CashFlowSummary
