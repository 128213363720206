import PropTypes from 'prop-types'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import HistoryTracking, {
  HISTORY_TRACKING_TYPES,
} from 'components/history-tracking'
import { ExpandableCard } from 'components/expandable-card'

import { expandableCard } from '../common/styles'

import './style.scss'

const UWHistory = ({ loanApplicationId }) => {
  const isDesktopView = useMediaQuery(`(min-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const collapsibleItems = [
    <div className="uw-hub-page__history" key={0}>
      <HistoryTracking
        id={loanApplicationId}
        type={HISTORY_TRACKING_TYPES.UW_HUB_HISTORY}
      />
    </div>,
  ]

  return (
    <div className="uw-hub-page__history-wrapper">
      <ExpandableCard
        headerProps={expandableCard.headerProps(isMobileView)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={'History Tracking'}
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        isCollapsed={!isDesktopView}
        alwaysCollapsible={true}
      />
    </div>
  )
}

UWHistory.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
}

export default UWHistory
