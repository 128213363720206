import { CLAIMS } from 'common/claims'
import { formatInUSFormat } from 'common/number'
import StatusComponent, { STATUSES } from 'components/status'
import { components } from '@ElementsCapitalGroup/enium-ui'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

export const AF_PAGE_SIZE = 10

export const APPROVAL_STATUS = {
  NONE: 0,
  PENDING: 1,
  APPROVED: 2,
  DECLINED: 3,
}

export const FUNDING_STATUS = {
  NONE: 0,
  PENDING: 1,
  FUNDED: 2,
}

export const DAILY_FUNDING_STATUS_ID_MAPPING = {
  1: STATUSES.UNDEFINED,
  2: STATUSES.ACTIVE,
  3: STATUSES.INACTIVE,
}

export const FUNDING_TABS = {
  DAILY_FUNDING: {
    name: 'Daily Funding',
    url: '/afp/daily',
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  APPROVAL: {
    name: 'Approval',
    url: '/afp/approval',
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  WIRES: {
    name: 'Wires',
    url: '/afp/wires',
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  FUNDING_HISTORY: {
    name: 'Funding History',
    url: '/afp/history',
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  LENDER_SETTINGS: {
    name: 'Lender Settings',
    url: '/afp/lender',
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  DEALER_SETTINGS: {
    name: 'Dealer Settings',
    url: '/afp/dealer',
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
}

export const addToDayColumns = [
  columnHelper.accessor('loanApplicationNumber', {
    header: 'Application #',
  }),
  columnHelper.accessor('borrowerName', {
    header: 'Borrower Name',
  }),
  columnHelper.accessor('lenderName', {
    header: 'Lender',
  }),
  columnHelper.accessor('fundingStatus', {
    header: 'Funding Status',
    id: 'fundingStatus',
    cell: (cell) => cell.row.original.loanApplicationState?.friendlyName || '',
  }),
]

export const dailyFundingColumns = [
  columnHelper.accessor('fundingType', {
    header: 'Funding Type',
    id: 'fundingType',
    cell: (cell) => cell.row.original.fundingType.friendlyName,
  }),
  columnHelper.accessor('applicationNumber', {
    header: 'Application #',
  }),
  columnHelper.accessor('borrowerName', {
    header: 'Borrower Name',
    width: 100,
  }),
  columnHelper.accessor('loanAmount', {
    header: 'Loan Amount',
    width: 100,
    id: 'loanAmount',
    cell: (cell) => `$${formatInUSFormat(cell.row.original.loanAmount)}`,
  }),
  columnHelper.accessor('fundingAmount', {
    header: 'Funding Amount',
    width: 100,
    id: 'fundingAmount',
    cell: (cell) => `$${formatInUSFormat(cell.row.original.fundingAmount)}`,
  }),
  columnHelper.accessor('dueEnium', {
    header: 'Due Enium',
    width: 100,
    id: 'dueEnium',
    cell: (cell) => `$${formatInUSFormat(cell.row.original.dueEnium)}`,
  }),
  columnHelper.accessor('dealerDeposit', {
    header: 'Dealer Deposit',
    id: 'dealerDeposit',
    width: 100,
    cell: (cell) => `$${formatInUSFormat(cell.row.original.dealerDeposit)}`,
  }),
  columnHelper.accessor('lenderName', {
    header: 'Lender',
    enableSorting: true,
  }),
  columnHelper.accessor('dealerName', {
    header: 'Dealer',
  }),
  columnHelper.accessor('dealerFeePercentage', {
    header: 'Dealer Fee %',
    id: 'dealerFeePercentage',
    width: 100,
    cell: (cell) => cell.row.original.dealerFeePercentage,
  }),
  columnHelper.accessor('dealerFee', {
    header: 'Dealer Fee $',
    id: 'dealerFee',
    width: 100,
    cell: (cell) => `$${formatInUSFormat(cell.row.original.dealerFee)}`,
  }),
  columnHelper.accessor('extraFeePercentage', {
    header: 'Extra Fee %',
    id: 'extraFeePercentage',
    width: 100,
    cell: (cell) => cell.row.original.extraFeePercentage,
  }),
  columnHelper.accessor('extraFee', {
    header: 'Extra Fee $',
    id: 'extraFee',
    width: 100,
    cell: (cell) => `$${formatInUSFormat(cell.row.original.extraFee)}`,
  }),
  columnHelper.accessor('lenderDiscountPercentage', {
    header: 'Lender Discount %',
    id: 'lenderDiscountPercentage',
    width: 100,
    cell: (cell) => cell.row.original.lenderDiscountPercentage,
  }),
  columnHelper.accessor('lenderDiscount', {
    header: 'Lender Discount $',
    id: 'lenderDiscount',
    width: 100,
    cell: (cell) => `$${formatInUSFormat(cell.row.original.lenderDiscount)}`,
  }),
  columnHelper.accessor('extraDiscountPercentage', {
    header: 'Extra Discount %',
    id: 'extraDiscountPercentage',
    width: 100,
    cell: (cell) => cell.row.original.extraDiscountPercentage,
  }),
  columnHelper.accessor('extraDiscount', {
    header: 'Extra Discount $',
    id: 'extraDiscount',
    width: 100,
    cell: (cell) => `$${formatInUSFormat(cell.row.original.extraDiscount)}`,
  }),
  columnHelper.accessor('stageFundingFeePercentage', {
    header: 'Stage Funding Fee %',
    id: 'stageFundingFeePercentage',
    width: 100,
    cell: (cell) => cell.row.original.stageFundingFeePercentage,
  }),
  columnHelper.accessor('stageFundingFee', {
    header: 'Stage Funding Fee $',
    id: 'stageFundingFee',
    width: 100,
    cell: (cell) => `$${formatInUSFormat(cell.row.original.stageFundingFee)}`,
  }),
]

export const approvalColumns = [
  ...dailyFundingColumns.slice(0, 2),
  columnHelper.accessor('approvalStatus', {
    header: 'Status',
    id: 'approvalStatus',
    cell: (cell) => {
      const statusId = cell?.row.original.approvalStatus.id
      if (!statusId) {
        return ''
      }

      return (
        <StatusComponent
          status={DAILY_FUNDING_STATUS_ID_MAPPING[statusId]}
          label={cell.row.original.approvalStatus.friendlyName}
        />
      )
    },
  }),
  ...dailyFundingColumns.slice(2),
]
