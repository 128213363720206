import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import { formatInUSFormat } from 'common/number'
import ExpandedWires from './expanded-row-contents'
import WiresCustomUpload from './custom-upload'
import { downloadNachaFile, regenerateWire, uploadNachaFile } from '../actions'
import { formatEffectiveDate } from '../utils'
import './style.scss'

const { EyeIcon, ArrowSquareUpIcon, RefreshSingleArrowIcon, UploadIcon } =
  assets

const { createColumnHelper, Tooltip } = components
const columnHelper = createColumnHelper()

const WIRE_STATUSES = [
  { id: undefined, value: 'Status' },
  { id: 0, value: 'Wire Pending' },
  { id: 1, value: 'Wire Received' },
]

const WiresModule = ({ list, pageCount, onFetchList, dispatch }) => {
  const [filters, setFilters] = useState({
    sortBy: undefined,
    pageNumber: 0,
  })
  const [uploadForWire, setUploadForWire] = useState(null)
  const [loading, setLoading] = useState(false)

  /** On search/pagination/filters change, re-fetch the list */
  useEffect(() => {
    setLoading(true)
    onFetchList(filters).finally(() => setLoading(false))
  }, [filters])

  const onDownloadWire = (wireId) => {
    downloadNachaFile(wireId)
  }

  const onRegenerateWire = (wireId) => {
    setLoading(true)
    regenerateWire(wireId, dispatch).finally(() => setLoading(false))
  }

  const onUploadWire = (file) => {
    setLoading(true)
    uploadNachaFile(uploadForWire, file, dispatch)
      .then((response) => {
        response && setUploadForWire(null)
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    columnHelper.accessor('lender', {
      header: 'Lender',
      id: 'lender',
      cell: (cell) => cell.row.original.lender?.name || ' - ',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      id: 'wireStatus',
      cell: (cell) => cell.row.original.wireStatus?.friendlyName,
      filterableheader: {
        options: WIRE_STATUSES,
        selected: filters.wireStatusId,
        onSelect: (selectedId) => {
          setFilters({ ...filters, wireStatusId: selectedId })
        },
      },
    }),
    columnHelper.accessor('applications', {
      header: 'Applications',
      id: 'applications',
      cell: (cell) => cell.row.original.dailyFundingLoanApplications?.length,
    }),
    columnHelper.accessor('dueEnium', {
      header: 'Due Enium',
      id: 'dueEnium',
      cell: (cell) => {
        const totalFundingAmount =
          cell.row.original.dailyFundingLoanApplications?.reduce(
            (acc, curr) => acc + curr.dueEnium,
            0
          )
        return `$${formatInUSFormat(totalFundingAmount)}`
      },
    }),
    columnHelper.accessor('dateCreated', {
      header: 'Date Created',
      cell: (cell) => formatEffectiveDate(cell.row.original.dateCreated),
    }),
    columnHelper.accessor('amountReceived', {
      header: 'Amount Received',
      cell: (cell) => `$${formatInUSFormat(cell.row.original.amountReceived)}`,
    }),
    columnHelper.accessor('actions', {
      header: 'Nacha Actions',
      id: 'nachaActions',
      cell: (cell) => (
        <div className="wires__actions flex-center">
          <Tooltip title="Upload">
            <div className="disabled">
              <UploadIcon />
            </div>
          </Tooltip>
          <Tooltip title="Custom Upload">
            <div onClick={() => setUploadForWire(cell.row.original.guid)}>
              <ArrowSquareUpIcon />
            </div>
          </Tooltip>
          <Tooltip title="Preview">
            <div onClick={() => onDownloadWire(cell.row.original.guid)}>
              <EyeIcon />
            </div>
          </Tooltip>
          <Tooltip title="Upload">
            <div onClick={() => onRegenerateWire(cell.row.original.guid)}>
              <RefreshSingleArrowIcon />
            </div>
          </Tooltip>
        </div>
      ),
    }),
  ]

  const onTableFiltersChanged = ({ pageIndex, sort }) => {
    setFilters({ ...filters, pageNumber: pageIndex, sortBy: sort })
  }

  const wires = useMemo(() => {
    return list.map((wire, idx) => ({
      ...wire,
      $children: () => <ExpandedWires data={wire} />,
    }))
  }, [list])

  return (
    <div className="wires">
      <Table
        title="Wires"
        data={wires}
        columns={columns}
        hasPagination={true}
        fetchData={onTableFiltersChanged}
        totalPages={pageCount}
        dataLoading={loading}
      />

      <WiresCustomUpload
        isOpen={!!uploadForWire}
        onUpload={onUploadWire}
        onClose={() => setUploadForWire(null)}
        dispatch={dispatch}
      />
    </div>
  )
}

WiresModule.propTypes = {
  list: PropTypes.array.isRequired,
  pageCount: PropTypes.number.isRequired,
  onFetchList: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default WiresModule
