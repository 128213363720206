import Api from 'easy-fetch-api'

export const fetchAUSTemplatesGuidAndName = (query = { itemsPerPage: 500 }) =>
  Api.get({
    url: '/DecisioningTemplate/list-name-guid',
    query,
  })

export const fetchDocumentsTemplatesGuidAndName = () =>
  Api.get({ url: '/Envelopes/list/simple' })

/** Get list of templates */
export const fetchTemplates = (filters) =>
  Api.get({
    url: '/DecisioningTemplate/list',
    query: {
      pageNumber: filters?.pageNumber,
      itemsPerPage: filters?.itemsPerPage,
      sortBy: filters?.sortBy,
      searchBy: filters?.searchBy ? `name ${filters.searchBy}` : undefined,
    },
  })
