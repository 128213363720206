import { colors } from '@ElementsCapitalGroup/enium-ui'

const paper = {
  boxShadow: 'none',
  border: `1px solid ${colors.grey[200]}`,
  padding: '16px',
  borderRadius: '12px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F9FAFB',
  marginBottom: '24px',
}

const wrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'center',
}

const attachmentInfoWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '12px',
  marginTop: '2px',
  flexWrap: 'wrap',
}

const attachmentInfo = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  overflow: 'hidden',
  marginLeft: '12px',
}

const attachmentIcon = {
  cursor: 'pointer',
  marginLeft: '2px',
}

const attachmentName = {
  fontSize: '0.875rem',
  color: colors.grey[700],
  fontWeight: 500,
  maxWidth: '200px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const attachmentDate = {
  fontSize: '0.875rem',
  fontWeight: 400,
  color: colors.grey[600],
}

export const styles = {
  paper,
  wrapper,
  attachmentInfo,
  attachmentInfoWrapper,
  attachmentName,
  attachmentDate,
  attachmentIcon,
}
