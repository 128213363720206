import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'

import AdminPageWrapper from 'modules/admin'

import { getUserById } from 'modules/users/actions'
import { ReactComponent as UsersSvg } from 'assets/users.svg'
import UsersDetails from 'modules/users/users-details'

const AdminUserDetails = () => {
  const { Breadcrumbs } = components

  const { userId } = useParams()

  const [userDetails, setUserDetails] = useState({})

  const items = [
    { icon: <UsersSvg />, href: '/admin/users' },
    { label: 'User Details' },
  ]

  useEffect(() => {
    getUserById(userId).then(setUserDetails)
  }, [])

  return (
    <AccessWrapper claims={[CLAIMS.CAN_VIEW_USERS]}>
      <AdminPageWrapper>
        <Breadcrumbs items={items} onClick={() => {}} sx={{ mb: 2 }} />
        <UsersDetails userData={userDetails} />
      </AdminPageWrapper>
    </AccessWrapper>
  )
}

export default AdminUserDetails
