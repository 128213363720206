import { components } from '@ElementsCapitalGroup/enium-ui'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

export const formatAddress = (address) => {
  if (address === null || address === undefined) {
    return '-'
  }

  const { streetAddress, city, county, state, zip } = address
  const definedProperties = [streetAddress, city, county, state, zip].filter(
    (property) => property !== undefined
  )

  if (definedProperties.length === 0) {
    return '-'
  }

  return definedProperties.join(', ')
}

export const columns = [
  columnHelper.accessor('ownerName', {
    accessorKey: 'ownerName',
    enableSorting: false,
    size: 100,
  }),
  columnHelper.accessor('propertyType', {
    accessorKey: 'propertyType',
    enableSorting: false,
    size: 100,
  }),
  columnHelper.accessor('landUse', {
    accessorKey: 'landUse',
    enableSorting: false,
    size: 150,
  }),
  columnHelper.accessor('address', {
    accessorKey: 'address',
    enableSorting: false,
    cell: ({ row }) => {
      const { address } = row.original

      return formatAddress(address)
    },
  }),
]
