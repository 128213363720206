import React, { useState, useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { useClickOutside } from 'common/hooks'

import { ReactComponent as DownArrow } from 'assets/down-arrow.svg'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'

import './index.scss'

function Dropdown({
  selected,
  setSelected,
  label,
  options = [],
  className,
  error,
  placeholder,
  customSvg,
  disabled,
  isSearchable,
  isSensitiveInfo,
  hideSensitiveData,
  returnEntireObject,
  displayedValueClass,
  displayUpwards,
}) {
  const ref = useRef()
  const searchRef = useRef()
  const { t: translate } = useTranslation()
  const [search, setSearch] = useState('')
  const [showContent, setShowContent] = useState(!isSensitiveInfo)
  const [isExpanded, setIsExpanded] = useState(false)
  const useObjects = useMemo(
    () => Object.prototype.hasOwnProperty.call(options[0] || '', 'value'),
    [options]
  )
  const filteredOptions = useMemo(() => {
    if (!options) {
      return []
    }
    return options.filter((option) => {
      // For Object values like custom HTML or something else there's no way for us to filter the items
      if (typeof option.value !== 'string') {
        return true
      }
      return option.value.toLowerCase().includes(search.toLowerCase())
    })
  }, [useObjects, search, options])
  const hideContent = isSensitiveInfo && !showContent
  let selectedValue = selected
  if (useObjects) {
    const selectedElem = options.find(
      (el) => el?.id === selected || el?.guid === selected
    )
    selectedValue =
      selectedElem?.selectedValue || selectedElem?.translationKey
        ? translate(selectedElem.translationKey)
        : selectedElem?.value
  }

  /** Triggered when the dropdown is expanded/collapsed */
  const onExpanded = (expanded) => {
    if (expanded) {
      setTimeout(() => searchRef && searchRef.current?.focus(), 100)
    }
    setIsExpanded(expanded)
  }

  const onShowContentToggle = (ev) => {
    ev.stopPropagation()
    setShowContent(!showContent)
  }

  /** Triggered when an option is selected */
  const onOptionSelected = (option) => () => {
    setSelected(!useObjects || returnEntireObject ? option : getId(option))
    setIsExpanded(false)
  }

  /** For regular string options this method ensures that the selected option exists in the list of options - else reset the state */
  useEffect(() => {
    if (
      !useObjects &&
      selected &&
      options?.length &&
      !options.some((el) => el === selected)
    ) {
      setSelected(null)
    }
  }, [selected, useObjects, options])

  const labelClass = cx('dropdown__label')
  const wrapperClass = cx('dropdown__wrapper', className, {
    'dropdown__wrapper--has-error': !!error,
    'dropdown__wrapper--disabled': disabled || options.length === 0,
  })

  const onClickOutside = () => onExpanded(false)
  useClickOutside(ref, onClickOutside)

  let valueToDisplay =
    selectedValue || placeholder || translate('global.selectOption')
  let svgToDisplay = customSvg || <DownArrow />
  if (hideContent) {
    valueToDisplay = '******'
    svgToDisplay = null
  }

  return (
    <div ref={ref} className={wrapperClass}>
      {label && <span className={labelClass}>{label}</span>}
      <div className="dropdown" role="listbox" tabIndex={0}>
        <div
          className="dropdown__btn"
          onClick={() => !disabled && !hideContent && onExpanded(!isExpanded)}
        >
          <div
            className={cx('dropdown__selected', {
              'dropdown__selected--default': !selectedValue,
            })}
          >
            <div className={displayedValueClass && displayedValueClass}>
              {valueToDisplay}
            </div>
          </div>
          <div></div>

          {isSensitiveInfo && !hideSensitiveData && (
            <EyeIcon
              onClick={onShowContentToggle}
              className="input__prefix input__prefix--eye"
            />
          )}
          {svgToDisplay}
        </div>

        <div
          className={cx('dropdown__content', {
            'dropdown__content--expanded': isExpanded,
            'dropdown__content--upwards': displayUpwards,
          })}
        >
          <div>
            {isSearchable && (
              <input
                ref={searchRef}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="dropdown__search"
                placeholder="Search for options"
              />
            )}

            {isExpanded &&
              filteredOptions.map((option, key) => (
                <div
                  role="option"
                  aria-selected="true"
                  tabIndex={0}
                  key={key}
                  onClick={onOptionSelected(option)}
                  className="dropdown__item"
                >
                  <div
                    className={option.labelClassName && option.labelClassName}
                  >
                    {useObjects
                      ? option.translationKey
                        ? translate(option.translationKey)
                        : option.value
                      : option}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function getId(option) {
  if (option?.id || option?.id === 0) {
    return option.id
  }
  if (option?.guid || option?.guid === 0) {
    return option.guid
  }
  return undefined
}

Dropdown.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Matches the ID of the option (for object-type options)
  setSelected: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // ID of the Option
        value: PropTypes.any, // Value for the option (what gets displayed)
        translationKey: PropTypes.any, // Value for the option translation key (determines what gets displayed)
        selectedValue: PropTypes.any, // If present it overrides the "value" property when this option is the one selected
        labelClassName: PropTypes.string, // If present wrap the value with a label of that color
      }),
    ])
  ),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  customSvg: PropTypes.node,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isSensitiveInfo: PropTypes.bool,
  hideSensitiveData: PropTypes.bool,
  returnEntireObject: PropTypes.bool,
  displayedValueClass: PropTypes.string,
  displayUpwards: PropTypes.bool,
}

export default Dropdown
