const black = '#000'

export const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    fontWeight: '800',
    color: black,
    marginTop: '12px',
    fontSize: '1.125',
  },

  text: {
    marginTop: '4px',
    marginBottom: '24px',
    fontSize: '1.125rem',
  },
}
