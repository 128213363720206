import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { assets } from '@ElementsCapitalGroup/enium-ui'

const { ChevronDownIcon, ChevronUpIcon } = assets

const ModalSideApplicant = ({ keyMessages, explanations, name }) => {
  const { t: translate } = useTranslation()
  const [isKeyMessagesDisplayed, setIsKeyMessagesDisplayed] = useState(false)
  const [isVisionAlertsDisplayed, setIsVisionAlertsDisplayed] = useState(false)

  const keyMessagesCount = keyMessages.length || 0
  const visionAlertsCount = explanations.length || 0

  return (
    <div className="side-applicant__paper">
      <div className="side-applicant__title">{name}</div>
      <div className="side-applicant__key-wrapper">
        <div style={{ textAlign: 'left' }}>
          <div
            className="side-applicant__key-title"
            onClick={() => setIsKeyMessagesDisplayed(!isKeyMessagesDisplayed)}
          >
            {translate(
              'loanApplication.ecgToolbox.identityVerification.keyMessages'
            )}{' '}
            ({keyMessagesCount})
            {keyMessagesCount ? (
              isKeyMessagesDisplayed ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              )
            ) : null}
          </div>

          {isKeyMessagesDisplayed
            ? keyMessages.map((message, index) => (
                <div key={index} className="side-applicant__key-message">
                  {`${message.code} ${message.text}`}
                </div>
              ))
            : null}
        </div>

        <span
          className="side-applicant__key-title"
          onClick={() => setIsVisionAlertsDisplayed(!isVisionAlertsDisplayed)}
        >
          {translate(
            'loanApplication.ecgToolbox.identityVerification.idVisionAlerts'
          )}{' '}
          ({visionAlertsCount})
          {visionAlertsCount ? (
            isVisionAlertsDisplayed ? (
              <ChevronUpIcon />
            ) : (
              <ChevronDownIcon />
            )
          ) : null}
        </span>

        {isVisionAlertsDisplayed
          ? explanations.map((message, index) => (
              <div key={index} className="side-applicant__key-message">
                {`${message.code} ${message.text}`}
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

ModalSideApplicant.propTypes = {
  keyMessages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  explanations: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
}

export default ModalSideApplicant
