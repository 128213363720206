import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { assets, colors } from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'
import { useEffectOnUpdate } from 'common/hooks'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

import OrganizationsTable from '../../table'
import { ORGS_PAGE_SIZE } from '../../constants'
import { deleteOrganization } from '../../actions'
import { styles } from '../style'

const { SearchLgIcon, PlusIcon } = assets

const OrgDetailsSubOrganizations = ({
  dispatch,
  data,
  onSubOrgDelete,
  onNewSubOrg,
  onEditSubOrg,
}) => {
  const [allData, setAllData] = useState(data)
  const [subOrgs, setSubOrgs] = useState({
    list: null,
    itemCount: 0,
  })
  const [dataLoading, setDataLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { list } = subOrgs
  const isSearch = searchText.trim().length > 0
  const tableRef = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    setSubOrgs({
      list: allData.slice(0, ORGS_PAGE_SIZE),
      itemCount: allData.length,
    })
  }, [allData])

  useEffectOnUpdate(() => {
    setAllData(
      data.filter((org) =>
        org.name.toLowerCase().includes(searchText.toLowerCase())
      )
    )
  }, [data, searchText])

  const getSubOrgsPage = ({ pageIndex }) => {
    setSubOrgs({
      list: data.slice(
        pageIndex * ORGS_PAGE_SIZE,
        (pageIndex + 1) * ORGS_PAGE_SIZE
      ),
      itemCount: data.length,
    })
    return Promise.resolve()
  }

  const onRemoveSubOrgs = (ev, rowNumber, rows) => {
    const promises = []
    setDataLoading(true)
    rows.forEach((row) => {
      promises.push(deleteOrganization(dispatch, row.guid))
    })
    Promise.all(promises)
      .then(() => {
        resetSearch()
        for (let i = 0; i < rows.length; i++) {
          onSubOrgDelete(rows[i].guid)
        }
      })
      .finally(() => setDataLoading(false))
  }

  const resetSearch = () => {
    setSearchText('')
    tableRef.current?.setPageIndex?.(0)
  }

  return (
    <>
      <div style={styles.subOrgs.searchBar}>
        <TextField
          onChange={setSearchText}
          value={searchText}
          placeholder={'Search'}
          type="search"
          style={styles.subOrgs.searchInput}
          startIcon={
            <SearchLgIcon fontSize="small" sx={{ stroke: colors.grey[400] }} />
          }
        />

        <Button
          color={BUTTON_COLORS.INHERIT}
          variant={BUTTON_VARIANTS.OUTLINED}
          onClick={onNewSubOrg}
          style={styles.subOrgs.createNewBtn}
          startIcon={<PlusIcon />}
        >
          Create Sub Organization
        </Button>
      </div>

      <OrganizationsTable
        tableRef={tableRef}
        title="Sub Organizations"
        organizations={subOrgs.list || []}
        orgCount={subOrgs.itemCount}
        fetchData={getSubOrgsPage}
        onRowClick={(e, row) => {
          resetSearch()
          navigate(`/admin/organization/${row.original.guid}`)
        }}
        onEdit={(ev, rows) => {
          onEditSubOrg(rows[0].guid)
        }}
        onRemove={onRemoveSubOrgs}
        dataLoading={dataLoading || !list}
        noDataText={isSearch ? 'No data found' : 'No Sub Organizations Yet'}
        noDataSubText={
          isSearch
            ? `We couldn't find any data matching your criteria.`
            : 'You don’t have any Sub Organizations yet. Feel free to add one!'
        }
        noDataActionText={
          isSearch ? 'Reset search & filters' : '+ Add Sub Organization'
        }
        noDataActionOnClick={isSearch ? resetSearch : onNewSubOrg}
        handleSortByChanged={() => {}}
      />
    </>
  )
}

OrgDetailsSubOrganizations.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  onSubOrgDelete: PropTypes.func.isRequired,
  onNewSubOrg: PropTypes.func.isRequired,
  onEditSubOrg: PropTypes.func.isRequired,
}

export default OrgDetailsSubOrganizations
