import PropTypes from 'prop-types'

import CreditReportApplicantDetails from './credit-report-applicant-details'

const CreditReportsContent = ({ data }) => {
  return (
    <div className="uw-hub-page__credit-reports-content">
      <CreditReportApplicantDetails
        applicantType="borrower"
        applicantId={data.borrowerId}
        lenderId={data.lenderId}
        softScore={data.borrowerSoftCreditFicoScore}
        hardScore={data.borrowerHardCreditFicoScore}
      />
      {data.coBorrowerId && (
        <CreditReportApplicantDetails
          applicantType="coBorrower"
          applicantId={data.coBorrowerId}
          lenderId={data.lenderId}
          softScore={data.coBorrowerSoftCreditFicoScore}
          hardScore={data.coBorrowerHardCreditFicoScore}
        />
      )}
    </div>
  )
}

CreditReportsContent.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CreditReportsContent
