import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'components/modal'

const StipulationsRemove = ({
  stipulationId,
  isOpen,
  onClose,
  onRemoveStipulation,
}) => {
  const { t: translate } = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={translate('loanApplication.ntpStepsCommon.removeStipulationTitle')}
      confirmButton={translate('buttons.delete')}
      cancelButton={translate('buttons.cancel')}
      onConfirm={() => onRemoveStipulation(stipulationId)}
    >
      {translate('loanApplication.ntpStepsCommon.removeStipulationText')}
    </Modal>
  )
}

StipulationsRemove.propTypes = {
  stipulationId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRemoveStipulation: PropTypes.func.isRequired,
}

export default StipulationsRemove
