import { colors } from '@ElementsCapitalGroup/enium-ui'

export const loanCardStyle = {
  card: {
    borderRadius: '8px',
    background: 'white',
    boxShadow:
      '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
  },
  selectedCard: {
    border: `2px solid ${colors.blue[500]}`,
  },
  hoverCard: {
    background: colors.blue[25],
  },
  title: {
    padding: '20px 24px',
    width: '100%',
    color: colors.blue[700],
    fontSize: '0.875rem',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
  results: {
    padding: '24px',
  },
  resultsTablet: {
    padding: '20px 24px',
  },
  resultsMobile: {
    padding: '16px',
  },
  resultsTop: {
    wrapper: {
      background: colors.grey[50],
      border: `1px solid ${colors.grey[100]}`,
      padding: '8px',
      borderRadius: '8px',
      marginTop: 0,
    },
    asLowAs: {
      fontSize: '0.75rem',
    },
    disclaimer: {
      fontSize: '0.75rem',
      marginTop: '4px',
    },
    amount: {
      fontSize: '1.5rem',
      marginTop: '4px',
    },
  },
  resultsBottom: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '8px',
    marginTop: '24px',
  },
  resultsBottomMobile: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '16px',
  },
  resultsBottomItem: {
    padding: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: colors.grey[900],
  },
  resultsBottomItemMobile: {
    padding: '8px 0',
  },
  resultsBottomItemHeader: {
    width: '100%',
    fontSize: '0.75rem',
    fontWeight: 500,
    color: colors.grey[600],
    marginBottom: '4px',
  },
  resultsFooter: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '8px',
    height: '40px',
  },
  resultsFooterMobile: {
    flexWrap: 'wrap',
    height: 'auto',
    justifyContent: 'space-around',
  },
  links: {
    color: colors.blue[700],
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  button: {
    marginLeft: 'auto',
  },
  buttonMobile: {
    width: '100%',
    marginTop: '24px',
    padding: '8px',
  },
  chip: {
    marginLeft: 'auto',
  },
}

export const resultsTopHover = {
  ...loanCardStyle.resultsTop,
  wrapper: {
    ...loanCardStyle.resultsTop.wrapper,
    border: `1px solid #D5D9EB`,
    background: colors.blue[50],
  },
}
