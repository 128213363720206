import React from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { styles } from './styles'

const { Dialog } = components

const ModalRemoveStates = ({
  isOpen,
  statesWithoutAssociations,
  onModalClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onModalClose}
      PaperProps={styles.modalProps}
      title="Submit States"
      actions={
        <>
          <Button
            sx={{ width: '192px' }}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            onClick={onModalClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: '192px' }}
            onClick={() => {
              onConfirm()
              onModalClose()
            }}
          >
            Submit
          </Button>
        </>
      }
    >
      <div>
        The following states don’t have any Loan Products associated and will be
        removed:
      </div>
      <ul>
        {statesWithoutAssociations.map((state) => (
          <li key={state.id}>{state.name}</li>
        ))}
      </ul>
      <div>Do you wish to continue?</div>
    </Dialog>
  )
}

ModalRemoveStates.propTypes = {
  isOpen: PropTypes.bool,
  statesWithoutAssociations: PropTypes.array.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default ModalRemoveStates
