import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { unstable_components } from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'
import LocationSearchInput from 'components/location-search-input'

const { Unstable_Grid: Grid } = unstable_components

const ApplicantInfoAddress = ({
  addressDetails,
  setAddressDetails,
  borrowerSameAddress,
  coBorrowerSameAddress,
  errors,
  setErrors,
  errorObjectName = 'serviceAddress',
  onAddressSelected,
  areInputsDisabled,
  hasServiceAddrErrors,
}) => {
  const { t: translate } = useTranslation()
  const handleOnChange = (value, name) => {
    setAddressDetails({ ...addressDetails, [name]: value })
    handleErrors(name)
  }

  const handleErrors = (name) => {
    setErrors({
      ...errors,
      [errorObjectName]: { ...errors[errorObjectName], [name]: null },
      coBorrower: coBorrowerSameAddress
        ? { ...errors.coBorrower, [name]: null }
        : errors.coBorrower,
      borrower: borrowerSameAddress
        ? { ...errors.borrower, [name]: null }
        : errors.borrower,
    })
  }

  return (
    <Grid container gap={24}>
      <Grid item mobile={12} desktop={8}>
        <LocationSearchInput
          defaultValue={addressDetails.addressFirstLine || ''}
          callback={onAddressSelected}
          onSearchInputChange={(val) => {
            handleOnChange(val, 'addressFirstLine')
          }}
          label={translate('loanApplication.step1.address.address')}
          placeholder={translate(
            'loanApplication.step1.address.addressPlaceholder'
          )}
          errorMessage={
            hasServiceAddrErrors
              ? translate('loanApplication.step1.address.errors.invalidAddress')
              : errors?.[errorObjectName]?.addressFirstLine || null
          }
          disabled={areInputsDisabled}
        />
      </Grid>
      <Grid item mobile={12} desktop={4}>
        <TextField
          fullWidth
          label={translate('loanApplication.step1.address.address2')}
          placeholder={translate(
            'loanApplication.step1.address.address2Placeholder'
          )}
          value={addressDetails?.addressSecondLine || ''}
          onChange={(val) => handleOnChange(val, 'addressSecondLine')}
        />
      </Grid>
      <Grid item mobile={12} desktop={4}>
        <TextField
          fullWidth
          label={translate('loanApplication.step1.address.city')}
          placeholder={translate('loanApplication.step1.address.city')}
          validate={() => errors?.[errorObjectName]?.city}
          value={addressDetails?.city || ''}
          onChange={(val) => handleOnChange(val, 'city')}
        />
      </Grid>
      <Grid item mobile={12} desktop={4}>
        <TextField
          fullWidth
          label={translate('loanApplication.step1.address.state')}
          placeholder={translate('loanApplication.step1.address.state')}
          validate={() => errors?.[errorObjectName]?.state}
          value={addressDetails?.state || ''}
          onChange={(val) => handleOnChange(val?.toUpperCase(), 'state')}
        />
      </Grid>
      <Grid item mobile={12} desktop={4}>
        <TextField
          fullWidth
          type="number"
          label={translate('loanApplication.step1.address.zipCode')}
          placeholder={translate('loanApplication.step1.address.zipCode')}
          validate={() => errors?.[errorObjectName]?.zipCode}
          value={addressDetails.zipCode || ''}
          onChange={(val) => {
            handleOnChange(val.slice(0, 5), 'zipCode') // limit to 5 digits, since maxLength doesn't work with type number
          }}
        />
      </Grid>
    </Grid>
  )
}

ApplicantInfoAddress.propTypes = {
  addressDetails: PropTypes.object.isRequired,
  setAddressDetails: PropTypes.func.isRequired,
  billing: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  borrowerSameAddress: PropTypes.bool,
  coBorrowerSameAddress: PropTypes.bool,
  errorObjectName: PropTypes.string,
  onAddressSelected: PropTypes.func.isRequired,
  areInputsDisabled: PropTypes.bool,
  hasServiceAddrErrors: PropTypes.bool,
}

export default ApplicantInfoAddress
