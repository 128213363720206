export const USER_STATUS_MAP = {
  PENDING: 1,
  ACTIVE: 2,
  SUSPENDED: 3,
  INACTIVE: 4,
}

export const USER_STATUS_OPTIONS = [
  {
    id: USER_STATUS_MAP.PENDING,
    label: 'Pending',
  },
  {
    id: USER_STATUS_MAP.ACTIVE,
    label: 'Active',
  },
  {
    id: USER_STATUS_MAP.SUSPENDED,
    label: 'Suspended',
  },
  {
    id: USER_STATUS_MAP.INACTIVE,
    label: 'Inactive',
  },
]

export const AVAILABLE_USER_STATUS_OPTIONS = [
  {
    id: USER_STATUS_MAP.ACTIVE,
    label: 'Active',
  },
  {
    id: USER_STATUS_MAP.SUSPENDED,
    label: 'Suspended',
  },
]
