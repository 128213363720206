import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import HistoryTracking, {
  HISTORY_TRACKING_TYPES,
} from 'components/history-tracking'
import StatusComponent from 'components/status'

import {
  USER_STATUS_ID_MAPPING,
  USER_STATUS_IDS,
} from 'modules/organizations/constants'
import { formatPhoneNumber } from 'common/utils'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import Table from 'components/table'
import { useStore } from 'store'
import { styles } from '../style'

const { createColumnHelper, Card, Avatar, IconButton } = components

const { Edit01Icon } = assets

const UsersDetails = ({ userData, loading }) => {
  const { state } = useStore()
  const navigate = useNavigate()
  const { userData: currentUser } = state.session

  const { userRoles: roles, allOrganizations } = state.orgManagement

  const columnHelper = createColumnHelper()

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'User',
        size: 150,
        cell: ({ row }) => {
          const { fullName } = row.original
          return (
            <div style={styles.users.orgDetailsUser}>
              <Avatar>{fullName}</Avatar>
              <div style={styles.users.orgDetailsUserDetails}>
                <div style={styles.users.orgDetailsUserName}>{fullName}</div>
              </div>
            </div>
          )
        },
      }),
      columnHelper.accessor('emailAddress', {
        header: 'Email Address',
        size: 150,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        size: 100,
        cell: ({ row }) => {
          const status = USER_STATUS_IDS[row.original.statusId]
          return (
            <StatusComponent
              status={USER_STATUS_ID_MAPPING[status]}
              label={status}
            />
          )
        },
      }),
      columnHelper.accessor('phoneNumber', {
        header: 'Phone Number',
        cell: ({ row }) => formatPhoneNumber(row.original.phoneNumber),
      }),
    ]
  }, [])

  const columnsForRoles = useMemo(() => {
    return [
      columnHelper.accessor('roleName', {
        header: 'Role',
        cell: ({ row }) => {
          return row.original.roleName
        },
      }),
      columnHelper.accessor('organziation', {
        header: 'Organization',
        cell: ({ row }) => {
          return row.original.organizationName
        },
      }),
    ]
  }, [allOrganizations, roles])

  return (
    <div className="org-user">
      <div style={styles.userDetails.header}>
        <h1 className="admin-page__header">User Details</h1>
        <IconButton
          variant="outlined"
          rounded
          onClick={() =>
            navigate(`/admin/users/${userData.userGuid}/edit-user`)
          }
          sx={{ marginRight: 2, color: 'black' }}
        >
          <Edit01Icon />
        </IconButton>
      </div>
      <Table
        title="Details"
        data={[userData]}
        columns={columns}
        dataLoading={loading}
      />
      <div style={styles.userDetails.table}>
        <Table
          title="Roles"
          data={userData.associations}
          columns={columnsForRoles}
          dataLoading={loading}
        />
      </div>

      {hasAccess(currentUser, CLAIMS.CAN_VIEW_LOAN_APPLICATION_HISTORY) && (
        <Card title="History tracking" className="org-user__card">
          {userData.userGuid && (
            <HistoryTracking
              id={userData.userGuid}
              type={HISTORY_TRACKING_TYPES.USER_DETAILS}
            />
          )}
        </Card>
      )}
    </div>
  )
}

UsersDetails.propTypes = {
  userData: PropTypes.object,
  loading: PropTypes.bool,
}

export default UsersDetails
