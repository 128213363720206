import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import { isEmpty } from 'components/validator'
import AdminPageWrapper from 'modules/admin'
import OrganizationDetails from 'modules/organizations/organization-details'
import OrganizationBreadcrumbs from 'modules/organizations/organization-details/breadcrumbs'
import OrgAddEditForm from 'modules/organizations/organization-details/new-and-edit-form'
import {
  getOrganizationByGuid,
  getOrganizationsTree,
  getRoles,
  editOrganization,
  deleteOrganization,
  getTypes,
  getStatuses,
  addOrganization,
} from 'modules/organizations/actions'
import {
  setLoading as setGlobalLoading,
  showNotification,
} from 'modules/global/actions'
import { useStore } from 'store'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

const OrganizationDetailsPage = () => {
  const { state, dispatch } = useStore()
  const { id: orgId } = useParams()
  const navigate = useNavigate()
  const [orgDetails, setOrgDetails] = useState({})
  const {
    userRoles,
    organizationTree,
    organizationTypes,
    organizationStatuses,
  } = state.orgManagement
  const { userData } = state.session
  const isNew = orgId === 'new'
  const isBeta = window.location.pathname.indexOf('-beta') > -1

  /** On mount, fetch org info required on org details */
  useEffect(() => {
    setGlobalLoading(dispatch, true)
    const requiredPromises = []
    if (!isNew) {
      requiredPromises.push(getOrganizationByGuid(orgId).then(setOrgDetails))
    }
    if (!organizationTree.length) {
      requiredPromises.push(getOrganizationsTree(dispatch))
    }
    if (!userRoles.length) {
      getRoles(dispatch)
    }
    if (!organizationTypes.length) {
      getTypes(dispatch)
    }
    if (!organizationStatuses.length) {
      getStatuses(dispatch)
    }
    Promise.all(requiredPromises).finally(() =>
      setGlobalLoading(dispatch, false)
    )
  }, [orgId])

  const orgTypes = useMemo(
    () => organizationTypes.map((type) => ({ id: type.id, label: type.name })),
    [organizationTypes]
  )
  const orgStatuses = useMemo(
    () =>
      organizationStatuses.map((type) => ({ id: type.id, label: type.name })),
    [organizationStatuses]
  )

  const rootOrg = useMemo(() => {
    return organizationTree[0] || {}
  }, [organizationTree])

  const reFetchOrgDetails = () => {
    setGlobalLoading(dispatch, true)
    getOrganizationByGuid(orgId)
      .then(setOrgDetails)
      .finally(() => setGlobalLoading(dispatch, false))
  }

  const onEditOrganization = (data) => {
    setGlobalLoading(dispatch, true)
    return editOrganization(data)
      .then((res) => {
        reFetchOrgDetails()
        return res
      })
      .catch(() => setGlobalLoading(dispatch, false))
  }

  const onCreateOrganization = (data) => {
    setGlobalLoading(dispatch, true)
    return addOrganization(data)
      .then((res) => {
        if (isNew) {
          setGlobalLoading(dispatch, false)
        } else {
          reFetchOrgDetails()
        }
        return res
      })
      .catch(() => setGlobalLoading(dispatch, false))
  }

  const onDeleteSelf = () => {
    setGlobalLoading(dispatch, true)
    deleteOrganization(dispatch, orgId).then(() => {
      setGlobalLoading(dispatch, false)
      navigate('/admin/organizations')
    })
  }

  const onNewOrganization = (data) => {
    onCreateOrganization({ ...data, parentId: rootOrg.guid })
      .then((response) => {
        if (response) {
          showNotification(dispatch, {
            title: `Successfully created organization "${response.name}"`,
          })
          closeNewOrgForm()
        }
      })
      .catch((err) => {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: `An error occurred while creating the organization. ${err.title}`,
        })
      })
  }

  const closeNewOrgForm = () => {
    navigate(`/admin/organizations${isBeta ? '-beta' : ''}`)
  }

  return (
    <AccessWrapper
      claims={[
        CLAIMS.CAN_EDIT_CORPORATE_ORGANIZATIONS,
        CLAIMS.CAN_EDIT_SALES_ORGANIZATIONS,
        CLAIMS.CAN_EDIT_LENDER_ORGANIZATIONS,
      ]}
    >
      <AdminPageWrapper>
        {!isEmpty(orgDetails) && (
          <>
            <OrganizationBreadcrumbs
              orgId={orgId}
              organizationTree={organizationTree}
            />
            <OrganizationDetails
              orgId={orgId}
              orgData={orgDetails}
              onAddOrganization={onCreateOrganization}
              onEditOrganization={onEditOrganization}
              onSubOrgDelete={reFetchOrgDetails}
              onDeleteSelf={onDeleteSelf}
              userData={userData}
              orgTypes={orgTypes}
              orgStatuses={orgStatuses}
              setLoading={(val) => setGlobalLoading(dispatch, val)}
            />
          </>
        )}
        {isNew && (
          <>
            <OrganizationBreadcrumbs
              finalItem={{ label: 'Create Organization' }}
            />
            <OrgAddEditForm
              userData={userData}
              orgTypes={orgTypes}
              orgStatuses={orgStatuses}
              parentOrg={rootOrg}
              isNew={isNew}
              onSave={onNewOrganization}
              onCancel={closeNewOrgForm}
            />
          </>
        )}
      </AdminPageWrapper>
    </AccessWrapper>
  )
}

OrganizationDetailsPage.propTypes = {}

export default OrganizationDetailsPage
