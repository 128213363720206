import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'
import { ReactComponent as CongratsSVG } from './congrats.svg'
import './style.scss'

const CongratsStep = () => {
  const { t: translate } = useTranslation()
  return (
    <div className="congrats-step">
      <CongratsSVG />
      <h2>{translate('loanApplication.step8.title')}</h2>
      <h3>{translate('loanApplication.step8.description')}</h3>

      <Link to="/">
        <Button sx={{ mb: '24px' }}>{translate('buttons.home')}</Button>
      </Link>
    </div>
  )
}

export default CongratsStep
