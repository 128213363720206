import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import SearchAndFilterModule from 'components/search-filter-module'
import { useEffectOnUpdate } from 'common/hooks'
import { dateFormatUS } from 'common/constants'
import ExpandedRowLenderFees from './fees-expanded-row-contents'
import { formatEffectiveDate } from '../utils'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

const LenderDepositFees = ({
  list,
  revFeeList,
  lateFeeList,
  pageCount,
  fundingTypes,
  onFetchList,
  onUpdate,
  loading,
}) => {
  const [filters, setFilters] = useState({
    sortBy: undefined,
    searchBy: undefined,
    pageNumber: 0,
  })
  const tableRef = useRef(null)
  const lateFeesStringified = JSON.stringify(lateFeeList)
  const revFeesStringified = JSON.stringify(revFeeList)

  const columns = useMemo(
    () => [
      columnHelper.accessor('lenderName', {
        header: 'Lender Name',
        accessor: 'lenderName',
      }),
      columnHelper.accessor('contract', {
        header: 'Contract Date',
        id: 'contractDate',
        cell: (cell) =>
          moment(cell.row.original.contractDate).format(dateFormatUS),
        width: 100,
      }),
      columnHelper.accessor('effectiveDate', {
        header: 'Effective Date',
        id: 'effectiveDate',
        cell: (cell) => formatEffectiveDate(cell.row.original.effectiveDate),
        width: 100,
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        id: 'type',
        cell: (cell) => cell.row.original.lenderFeeType?.friendlyName,
        width: 80,
      }),
      columnHelper.accessor('savingsBorrower', {
        header: 'Savings Borrower',
        accessor: 'savingsBorrower',
        width: 80,
      }),
      columnHelper.accessor('savingsCoBorrower', {
        header: 'Savings Co-Borrower',
        accessor: 'savingsCoBorrower',
        width: 80,
      }),
      columnHelper.accessor('donationCoBorrower', {
        header: 'Donation Co-Borrower',
        accessor: 'donationCoBorrower',
        width: 80,
      }),
      columnHelper.accessor('donationBorrower', {
        header: 'Donation Borrower',
        accessor: 'donationBorrower',
        width: 80,
      }),
      columnHelper.accessor('stageFundingFee', {
        header: 'Stage Funding Fee',
        accessor: 'stageFundingFee',
        width: 80,
      }),
      columnHelper.accessor('timing', {
        header: 'Timing',
        id: 'timing',
        cell: (cell) => cell.row.original.timing?.friendlyName,
        width: 100,
      }),
      columnHelper.accessor('reversalFees', {
        header: 'Rev Fees',
        id: 'reversalFees',
        cell: (cell) => {
          const revFeeHtml = getFeesForLender(cell.row.original, revFeeList)
            ?.map((el) => `${el.numberOfDays} days - ${el.percentageApplied} %`)
            .join('<br />')
          return <div dangerouslySetInnerHTML={{ __html: revFeeHtml }} />
        },
      }),
      columnHelper.accessor('lateFees', {
        header: 'Late Fees',
        id: 'lateFees',
        cell: (cell) => {
          const lateFeeHtml = getFeesForLender(cell.row.original, lateFeeList)
            ?.map((el) => `${el.numberOfDays} days - ${el.percentageApplied} %`)
            .join('<br />')
          return <div dangerouslySetInnerHTML={{ __html: lateFeeHtml }} />
        },
      }),
      columnHelper.accessor('lssCode', {
        header: 'LSS Code',
        id: 'lssCode',
        cell: (cell) => cell.row.original.lssCode || '-',
        width: 100,
      }),
      columnHelper.accessor('lssPortfolio', {
        header: 'LSS Portofolio',
        id: 'lssPortfolio',
        cell: (cell) => cell.row.original.lssPortfolio || '-',
      }),
    ],
    [JSON.stringify(list), revFeesStringified, lateFeesStringified]
  )

  /** On search/pagination/filters change, re-fetch the list */
  useEffectOnUpdate(() => {
    onFetchList(filters)
  }, [filters])

  const onSearch = (payload) => setFilters({ ...filters, ...payload })

  const onTableFiltersChanged = ({ pageIndex, sort }) => {
    setFilters({ ...filters, pageNumber: pageIndex, sortBy: sort })
  }

  /** On row save we have to re-fetch the list */
  const onRowSave = (state, localRevFees, localLateFees, idx) => {
    onUpdate(state, localRevFees, localLateFees).then(() => {
      setFilters({
        sortBy: undefined,
        searchBy: undefined,
        pageNumber: 0,
      })
      tableRef.current?.table?.getRow(idx).toggleExpanded()
      tableRef.current?.setPageIndex(0)
    })
  }

  const fees = useMemo(() => {
    return list.map((fee, idx) => ({
      ...fee,
      $children: () => (
        <ExpandedRowLenderFees
          onSave={(state, localRevFees, localLateFees) =>
            onRowSave(state, localRevFees, localLateFees, idx)
          }
          data={fee}
          onCancel={() => {
            tableRef.current?.table?.getRow(idx).toggleExpanded()
          }}
          revFees={getFeesForLender(fee, revFeeList)}
          lateFees={getFeesForLender(fee, lateFeeList)}
          fundingTypes={fundingTypes}
        />
      ),
    }))
  }, [list, tableRef, revFeesStringified, lateFeesStringified])

  return (
    <div className="lender-settings">
      <div className="afp__header">
        <SearchAndFilterModule
          fieldsToSearch={['multiple']}
          placeholder="Search"
          noDefaultExtraOptions={true}
          callback={onSearch}
        />
      </div>
      <Table
        title="Deposits/Fees"
        ref={tableRef}
        data={fees}
        columns={columns}
        hasPagination={true}
        totalPages={pageCount}
        fetchData={onTableFiltersChanged}
        showColumnSelector={true}
        defaultFilterableColumnIds={defaultFilterableColumnIds}
        dataLoading={loading}
      />
    </div>
  )
}

const defaultFilterableColumnIds = [
  'lenderName',
  'contractDate',
  'effectiveDate',
  'type',
  'stageFundingFee',
  'timing',
  'reversalFees',
  'lateFees',
  'lssCode',
  'lssPortfolio',
]

function getFeesForLender(lenderObj, feesArray) {
  return [...feesArray].filter((el) => el.organizationId === lenderObj.lenderId)
}

LenderDepositFees.propTypes = {
  list: PropTypes.array.isRequired,
  revFeeList: PropTypes.array.isRequired,
  lateFeeList: PropTypes.array.isRequired,
  pageCount: PropTypes.number.isRequired,
  fundingTypes: PropTypes.array.isRequired,
  onFetchList: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default LenderDepositFees
