import { GLOBAL_ACTIONS } from './actions'

const initialState = {
  loading: false,
  notifications: [],
  messageTypes: {},
  prefillHappened: false,
  borrowerPrefillData: {},
  isLoanCalculatorOpen: false,
}

/** Name of the reducer */
const name = 'global'

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [GLOBAL_ACTIONS.SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
  [GLOBAL_ACTIONS.SHOW_NOTIFICATION]: (state, { notification }) => ({
    ...state,
    notifications: [...state.notifications, notification],
  }),
  [GLOBAL_ACTIONS.HIDE_NOTIFICATION]: (state, { id }) => {
    const idx = state.notifications.findIndex((el) => el.id === id)
    if (idx > -1) {
      const updated = [...state.notifications]
      updated.splice(idx, 1)
      return { ...state, notifications: updated }
    }
    return { ...state }
  },
  [GLOBAL_ACTIONS.GET_MESSAGE_TYPES]: (state, { messageTypes }) => ({
    ...state,
    messageTypes: messageTypes.reduce(
      (obj, crt) => ({ ...obj, [crt.id]: crt }),
      {}
    ),
  }),
  [GLOBAL_ACTIONS.PREFILL_USER_DATA]: (
    state,
    { sessionId, applicantIndex, data }
  ) => {
    const fieldName =
      applicantIndex === 0 ? 'borrowerPrefillData' : 'coBorrowerPrefillData'
    return {
      ...state,
      loading: false,
      prefillHappened: true,
      [fieldName]: { ...data, sessionId },
    }
  },
  [GLOBAL_ACTIONS.RESET_PREFILL_DATA]: (state) => {
    return {
      ...state,
      prefillHappened: false,
      borrowerPrefillData: {},
      coBorrowerPrefillData: {},
    }
  },
  [GLOBAL_ACTIONS.INITIALIZE_PREFILL_DATA]: (state) => {
    return { ...state, prefillHappened: false }
  },
  [GLOBAL_ACTIONS.RESET_PREFILL_FLAG]: (state) => {
    return { ...state, prefillHappened: false }
  },
  [GLOBAL_ACTIONS.TOGGLE_LOAN_CALCULATOR]: (state, { isOpen }) => ({
    ...state,
    isLoanCalculatorOpen: isOpen,
  }),
}

export default { name, initialState, reduce }
