import { reverseObject } from '../../common/utils'

export const LOAN_APP_TYPES = {
  RESIDENTIAL: 'Residential',
  COMMERCIAL: 'Commercial',
}

export const LOAN_APP_TYPES_ID = {
  RESIDENTIAL: 1,
  COMMERCIAL: 2,
}

export const STEP_PATH = {
  RESIDENTIAL: 'new-loan-app',
  COMMERCIAL: 'new-commercial-loan-app',
}

export const STEPS_MAP = {
  NEW_LOAN_APPLICATION: 0,
  SELECT_LOAN: 1,
  LENDER_AGREEMENT: 2,
  LOAN_DOCUMENTS: 3,
  NTP: 4,
  NTP_INSPECTION: 5,
  PTO_SUBMISSION: 6,
  CONGRATS: 7,
}

export const STEPS_MAP_COMMERCIAL = {
  NEW_LOAN_APPLICATION: 0,
  SELECT_LOAN: 1,
  LENDER_AGREEMENT: 2,
  COMMERCIAL_NTP: 3,
  CONGRATS: 4,
}

export const STEPS_MAP_REVERSED = reverseObject(STEPS_MAP)
export const STEPS_MAP_REVERSED_COMMERCIAL = reverseObject(STEPS_MAP_COMMERCIAL)

// To see the STATES TO STEPS mapping check {@see /modules/loan-application/states/index.js}

export const STATES_ORDER = {
  ApplicationStarted: 0,
  ApplicationExpired: 0,
  ConditionalApproval: 1,
  EvaluationFailed: 1,
  LoanSelected: 2,
  AgreementsAccepted: 3,
  ApplicationAuthorized: 4,
  DocumentsGenerated: 5,
  DocumentsSigned: 6,
  NTPDocsRejected: 6,
  NTPDocsReSubmitted: 7,
  NTPDocsPendingApproval: 7,
  NTPDocsApproved: 8,
  NTPFundsRequested: 9,
  NTPFundsGranted: 10,
  InstallCompleteDocsRejected: 10,
  InstallCompleteDocsReSubmitted: 11,
  InstallCompleteDocsPendingApproval: 11,
  InstallCompleteDocsApproved: 12,
  InstallCompleteFundsRequested: 13,
  InstallCompleteFundsGranted: 14,
  FinalInspectionDocsRejected: 14,
  FinalInspectionDocsPendingApproval: 15,
  FinalInspectionDocsReSubmitted: 15,
  FinalInspectionDocsApproved: 16,
  FinalInspectionFundsRequested: 17,
  FinalInspectionFundsGranted: 18,
  PTODocsRejected: 18,
  PTODocsPendingApproval: 19,
  PTODocsApproved: 20,
  PTOFundsRequested: 21,
  PTOFundsGranted: 22,
}

export const STATE_IDS = {
  ApplicationStarted: 0,
  ApplicationExpired: 20,
  ConditionalApproval: 1,
  EvaluationFailed: 15,
  LoanSelected: 2,
  AgreementsAccepted: 3,
  ApplicationAuthorized: 4,
  DocumentsGenerated: 5,
  DocumentsSigned: 6,
  NTPDocsPendingApproval: 7,
  NTPDocsApproved: 8,
  NTPFundsRequested: 9,
  NTPFundsGranted: 10,
  InstallCompleteDocsPendingApproval: 11,
  InstallCompleteDocsApproved: 12,
  InstallCompleteFundsRequested: 13,
  InstallCompleteFundsGranted: 14,
  CommercialDocumentationReview: 21,
  CommercialStipulationsApproved: 22,
  PTODocsPendingApproval: 23,
  PTODocsRejected: 24,
  PTODocsApproved: 25,
  NTPDocsRejected: 26,
  NTPDocsReSubmitted: 27,
  InstallCompleteDocsRejected: 28,
  InstallCompleteDocsReSubmitted: 29,
  FinalInspectionDocsPendingApproval: 30,
  FinalInspectionDocsRejected: 31,
  FinalInspectionDocsReSubmitted: 32,
  FinalInspectionDocsApproved: 33,
  FinalInspectionFundsRequested: 34,
  FinalInspectionFundsGranted: 35,
  PTOFundsRequested: 36,
  PTOFundsGranted: 37,
}

export const STATES_ORDER_COMMERCIAL = {
  ApplicationStarted: 0,
  EvaluationFailed: 1,
  LoanSelected: 2,
  AgreementsAccepted: 3,
  ApplicationAuthorized: 4,
  CommercialDocumentationReview: 21,
  CommercialStipulationsApproved: 22,
  ApplicationExpired: 20,
}

export const STATE_ID_TO_NAME = {
  ...reverseObject(STATE_IDS),
}

export const STATE_ID_TO_NAME_COMMERCIAL = {
  ...reverseObject(STATES_ORDER_COMMERCIAL),
}

export const STEP_URLS = {
  NEW_LOAN_APPLICATION: {
    path: STEP_PATH.RESIDENTIAL,
    idx: 0,
  },
  SELECT_LOAN: {
    path: 'select-loan',
    idx: 1,
  },
  LENDER_AGREEMENT: {
    path: 'lender-agreement',
    idx: 2,
  },
  LOAN_DOCUMENTS: { path: 'loan-documents', idx: 3 },
  NTP: { path: 'ntp', idx: 4 },
  NTP_INSPECTION: { path: 'inspection', idx: 5 },
  PTO_SUBMISSION: { path: 'pto-submission', idx: 6 },
  CONGRATS: { path: 'congratulations', idx: 7 },
}

export const STEP_KEY_NAMES = [
  'loanApplication.navigation.step1',
  'loanApplication.navigation.step2',
  'loanApplication.navigation.step3',
  'loanApplication.navigation.step4',
  'loanApplication.navigation.step5Combined',
  'loanApplication.navigation.step5Inspection',
  'loanApplication.navigation.step8',
]

export const STEP_URLS_COMMERCIAL = {
  NEW_LOAN_APPLICATION: {
    path: STEP_PATH.COMMERCIAL,
    idx: 0,
  },
  SELECT_LOAN: {
    path: 'select-loan',
    idx: 1,
  },
  LENDER_AGREEMENT: {
    path: 'lender-agreement',
    idx: 2,
  },
  COMMERCIAL_NTP: { path: 'commercial-ntp', idx: 3 },
  CONGRATS: { path: 'congratulations', idx: 4 },
}
export const IDENTIFICATION_INFO = {
  number: '', // ID Number
  idIssueState: {},
  expirationDate: '',
  issuanceDate: '',
  dateOfBirth: '',
  motherMaidenName: '',
  identificationDataId: undefined,
  applicantId: undefined,
  idType: {},
}

export const INCOME_INFO = {
  borrowerActualIncome: 0,
  borrowerStatedIncome: 0,
  borrowerActualDTI: 0,
  borrowerStatedDTI: 0,
  borrowerFico: 0,
  coBorrowerActualIncome: 0,
  coBorrowerStatedIncome: 0,
  coBorrowerActualDTI: 0,
  coBorrowerStatedDTI: 0,
  coBorrowerFico: 0,
  combinedActualIncome: 0,
  combinedrActualDTI: 0,
  combinedStatedIncome: 0,
  combinedStatedDTI: 0,
  combinedFico: 0,
}
export const SOLAR_INFO = {
  pricePerWatt: 0,
  systemSize: 0,
  batteryCount: 0,
  insuranceType: '',
  nonSolarAmount: 0,
}

export const ACH_FORM = {
  achId: null,
  achVerificationAttempts: 0,
  status: {},
  firstName: '',
  lastName: '',
  routingNumber: '',
  accountNumber: '',
  bankName: '',
  accountTypeId: '',
  preferredWithdrawDay: null,
}

export const ACH_STATUS = {
  NOT_STARTED: 0,
  COMPLETE: 1,
  INCOMPLETE: 2,
  NEEDS_REVIEW: 3,
}

export const LOAN_DECISION_STATUS = {
  FAIL: 0,
  PASS: 1,
}

export const AGREEMENT_TYPES = {
  LENDER: 'lender',
  ACH: 'ach',
  MEMBERSHIP: 'membership',
}

export const AMORTIZATION_TYPES = {
  UNDEFINED: 0,
  NONE: 1,
  SIMPLE: 2,
  MINIMUM_PAYMENT: 3,
  INTEREST_RATE_FEE: 4,
  INTEREST_RATE_DISCOUNT: 5,
  MINIMUM_PAYMENT_WITH_DISCOUNTED_INTREST_RATE: 6,
  WORST_CASE: 7,
}

export const ENVELOPE_STATUS = {
  CREATED: 0,
  SENT: 1,
  SIGNED: 3,
  DECLINED: 4,
  COMPLETED: 5,
}

export const DEFAULT_LOAN_FORM_DATA = {
  loanApplicationId: '',
  salesOrganizationId: '',
  salesOrganizationName: '',
  salesRepresentativeId: '',
  salesRepresentativeName: '',
  serviceAddress: {},
  selectedLoanSignatures: [],
  borrowerGeneralDetails: {
    billingAddress: {},
  },
  coBorrowerGeneralDetails: {
    billingAddress: {},
  },
  incomeData: {},
  propertyTitleReportId: '',
  isOnHold: false,
  loanAmountDecreased: false,
  loanAmountIncreased: false,
  cancelled: false,
  creditExpired: false,
  hasReversal: false,
  agingTier: {},
  isDuplicateAddress: false,
  isDuplicateSsn: false,
  bypassAddressValidation: false,
  loanApplicationNumber: '',
  selectedLoanProductId: '',
  selectedLoanProduct: {},
}

export const DEFAULT_COMMERCIAL_LOAN_FORM_DATA = {
  loanApplicationId: '',
  salesOrganizationId: '',
  salesOrganizationName: '',
  salesRepresentativeId: '',
  salesRepresentativeName: '',
  serviceAddress: {},
  building: {},
  businessOwners: [],
}

export const LOAN_STATUSES_COLOR_CLASSNAME = {
  Active: 'loan-status--active',
  Canceled: 'loan-status--canceled',
  OnHold: 'loan-status--on-hold',
  Declined: 'loan-status--declined',
  LoanComplete: 'loan-status--loan-complete',
  ActionRequired: 'loan-status--action-required',
}

export const LOAN_APPLICATION_STATUSES = {
  Active: 0,
  LoanComplete: 1,
  Declined: 2,
  OnHold: 3,
  Canceled: 4,
  ActionRequired: 5,
}

export const AGREEMENTS_STATUSES = {
  ACTION_REQUIRED: 'ActionRequired',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
}

export const STIPULATION_CATEGORY_IDS = {
  ID_SCAN: 1,
  VERIFICATION_OF_INCOME: 2,
}

export const PTO_STATUS_IDS = {
  SUBMITTED: 1,
  APPROVED: 2,
  DECLINED: 3,
}

export const PULL_TYPES = {
  SOFT: 2,
  HARD: 1,
}

export const EVALUATION_IDS = {
  FAILED: 0,
  APPROVED: 1,
  BRANCH: 2,
}

export const ATTACHMENT_STATUS_IDS = {
  APPROVED: 0,
  DECLINED: 1,
  NEEDS_REVIEW: 2,
}
