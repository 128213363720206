import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'
import DateInput from 'components/input-date'
import { useTranslation } from 'react-i18next'
import { APPLICANT_TYPE } from 'common/constants'

const { Dropdown } = components

/**
 * Applicant Identification Form
 */
const IdentificationInfo = ({
  canEditFields,
  applicantTypeId,
  identificationData,
  setIdentificationData,
  idTypes,
  stateList,
  errors,
  setErrors,
}) => {
  const { t: translate } = useTranslation()
  const applicantText =
    applicantTypeId === APPLICANT_TYPE.BORROWER
      ? translate('loanApplication.step1.borrower')
      : translate('loanApplication.step1.coBorrower')

  const handleOnChange = (name, value) => {
    if (errors[name]) {
      setErrors({ ...errors, [name]: null })
    }
    setIdentificationData({ ...identificationData, [name]: value })
  }

  const handleAddressChange = (name, value) => {
    if (errors.address?.[name]) {
      setErrors({ ...errors, address: { ...errors.address, [name]: null } })
    }
    if (name === 'state') {
      value = value.label
    }
    setIdentificationData({
      ...identificationData,
      address: { ...identificationData.address, [name]: value },
    })
  }

  const idTypesOptions = useMemo(
    () =>
      idTypes.map(
        (v) => ({
          ...v,
          label: translate(v.translationKey),
        }),
        [idTypes]
      ),
    [idTypes]
  )

  const selectedState = stateList.find(
    (state) => state.id === identificationData?.idIssueState?.id
  )
  const selectedAddressState = stateList.find(
    (state) => state.abbreviation === identificationData?.address?.state
  )
  const selectedIdType = idTypesOptions.find(
    (option) => option.id === identificationData?.idType?.id
  )

  return (
    <>
      <div
        className="applicant-info__form-title"
        style={{ marginBottom: '24px' }}
      >
        {`${applicantText} ${translate(
          'loanApplication.step1.identification'
        )}`}
      </div>
      <div className="loan-form__grid">
        <Dropdown
          label={translate(
            'loanApplication.step1.identificationDetails.idType'
          )}
          options={idTypesOptions}
          value={{
            id: identificationData?.idType?.id,
            label: selectedIdType
              ? translate(selectedIdType.translationKey)
              : null,
          }}
          onChange={(e) => handleOnChange('idType', e.target.value)}
          error={!!errors.idType}
          helperText={errors.idType}
          disabled={!canEditFields}
        />
        <TextField
          label={translate(
            'loanApplication.step1.identificationDetails.idNumber'
          )}
          onChange={(value) => handleOnChange('number', value)}
          value={identificationData.number}
          validate={() => errors.number}
          disabled={!canEditFields}
        />
        <Dropdown
          label={translate(
            'loanApplication.step1.identificationDetails.issueState'
          )}
          options={stateList.map((state) => ({
            id: state.id,
            label: state.abbreviation,
          }))}
          value={{ id: selectedState?.id, label: selectedState?.abbreviation }}
          onChange={(e) => handleOnChange('idIssueState', e.target.value)}
          error={!!errors.idIssueState}
          helperText={errors.idIssueState}
          disabled={!canEditFields}
        />
        <DateInput
          value={identificationData.expirationDate}
          onChange={(value) => handleOnChange('expirationDate', value)}
          label={translate(
            'loanApplication.step1.identificationDetails.expiration'
          )}
          validate={() => errors.expirationDate}
          disabled={!canEditFields}
          minDate={new Date()}
        />
        <DateInput
          value={identificationData.issuanceDate}
          onChange={(value) => handleOnChange('issuanceDate', value)}
          label={translate(
            'loanApplication.step1.identificationDetails.issueDate'
          )}
          validate={() => errors.issuanceDate}
          disabled={!canEditFields}
          maxDate={new Date()}
        />
        <DateInput
          value={identificationData.dateOfBirth}
          onChange={(value) => handleOnChange('dateOfBirth', value)}
          label={translate('userDetails.dob')}
          validate={() => errors.dateOfBirth}
          disabled={!canEditFields}
          maxDate={new Date()}
        />
        <TextField
          label={translate(
            'loanApplication.step1.identificationDetails.mothersMaidenName'
          )}
          type="string"
          value={identificationData.motherMaidenName}
          validate={() => errors.motherMaidenName}
          onChange={(value) => handleOnChange('motherMaidenName', value)}
          disabled={!canEditFields}
        />
        <TextField
          label={translate('loanApplication.step1.address.address')}
          type="string"
          value={identificationData.address?.addressFirstLine}
          validate={() => errors.address?.addressFirstLine}
          onChange={(value) => handleAddressChange('addressFirstLine', value)}
          disabled={!canEditFields}
        />
        <TextField
          label={translate('loanApplication.step1.address.address2')}
          type="string"
          value={identificationData.address?.addressSecondLine}
          validate={() => errors.address?.addressSecondLine}
          onChange={(value) => handleAddressChange('addressSecondLine', value)}
          disabled={!canEditFields}
        />
        <TextField
          label={translate('loanApplication.step1.address.city')}
          type="string"
          value={identificationData.address?.city}
          validate={() => errors.address?.city}
          onChange={(value) => handleAddressChange('city', value)}
          disabled={!canEditFields}
        />
        <Dropdown
          label={translate('loanApplication.step1.address.state')}
          options={stateList.map((state) => ({
            id: state.id,
            label: state.abbreviation,
          }))}
          value={{
            id: selectedAddressState?.id,
            label: selectedAddressState?.abbreviation,
          }}
          onChange={(e) => handleAddressChange('state', e.target.value)}
          error={!!errors.address?.state}
          helperText={errors.address?.state}
          disabled={!canEditFields}
        />
        <TextField
          label={translate('loanApplication.step1.address.zipCode')}
          type="string"
          value={identificationData.address?.zipCode}
          validate={() => errors.address?.zipCode}
          onChange={
            (val) => handleAddressChange('zipCode', val.slice(0, 5)) // limit to 5 digits, since maxLength doesn't work with type number
          }
          disabled={!canEditFields}
        />
      </div>
    </>
  )
}

IdentificationInfo.propTypes = {
  canEditFields: PropTypes.bool.isRequired,
  applicantTypeId: PropTypes.number.isRequired,
  identificationData: PropTypes.object.isRequired,
  setIdentificationData: PropTypes.func.isRequired,
  idTypes: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
}

export default IdentificationInfo
