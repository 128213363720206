import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Loader from 'components/loader'
import LenderAgreementCard from 'modules/loan-application/lender-agreement/lender-agreement-card'
import AutoPayCard from 'modules/loan-application/lender-agreement/auto-pay-card'
import MembershipCard from 'modules/loan-application/lender-agreement/membership-card'
import {
  getLenderAgreement,
  updateLenderAgreement,
  getAchAgreement,
  getAccountTypes,
  submitAchData,
  goToStep,
  generateEnvelopes,
  authorizeLoanDocuments,
  getLoanApplication,
  getMembershipVerbiage,
  updateMembershipAgreement,
  listEnvelopesByApplication,
  authorizeLoanDocumentsCommercial,
  getAgreementStatuses,
} from 'modules/loan-application/actions'
import {
  STATES_ORDER,
  STEPS_MAP,
  AGREEMENT_TYPES,
  STEPS_MAP_COMMERCIAL,
  STATES_ORDER_COMMERCIAL,
} from 'modules/loan-application/constants'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { MEMBERSHIP_SCHEMAS } from 'modules/loan-application/membership'
import Button, { BUTTON_VARIANTS } from 'components/button'
import LoadingDocuments from 'modules/loan-application/loan-documents/loading-documents'
import './index.scss'

const { ArrowRightIcon } = assets

const LenderAgreement = ({
  loanApplicationId,
  achData,
  selectedLoanProduct,
  loanFormData,
  scrollToTop,
  userData,
  dispatch,
  currentState,
  viewOnly,
  isCommercialApp,
}) => {
  const navigate = useNavigate()
  const stepsMap = isCommercialApp ? STEPS_MAP_COMMERCIAL : STEPS_MAP
  const statesOrder = isCommercialApp ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  const areAgreementsAccepted =
    statesOrder[currentState.name] >= statesOrder.AgreementsAccepted
  const [loading, setLoading] = useState(true)
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [loadingInnerType, setLoadingInnerType] = useState('')
  const [accountTypes, setAccountTypes] = useState([])
  const [lenderAgreement, setLenderAgreement] = useState({
    isAccepted: null,
    text: '',
  })
  const [agreementStatuses, setAgreementStatuses] = useState([])
  const [achAgreement, setAchAgreement] = useState('')
  const [membershipAgreement, setMembershipAgreement] = useState({
    isAccepted: null,
    text: '',
    hasMembershipVerbiage: null,
    data: {},
  })
  const canGenerateDocuments = hasAccess(
    userData,
    CLAIMS.CAN_GENERATE_DOCUMENTS
  )

  const { t: translate } = useTranslation()

  /** On mount fetch the Agreements */
  useEffect(() => {
    Promise.allSettled([
      getAgreementStatuses(),
      getLenderAgreement(loanApplicationId),
      getAchAgreement(loanApplicationId),
      getAccountTypes(),
      getMembershipVerbiage(loanApplicationId),
    ])
      .then(
        ([
          agreementStatuses,
          lenderRes,
          achRes,
          accountTypes,
          membershipVerbiage,
        ]) => {
          if (!lenderRes.value || !achRes.value) {
            return navigate('/applications')
          }

          setAgreementStatuses(agreementStatuses.value)

          const lenderAgreementStatus = agreementStatuses.value.find(
            (status) =>
              status.name === lenderRes.value.lenderAgreementStatus.name
          )

          setLenderAgreement({
            isAccepted: lenderRes.value.isAccepted,
            text: lenderRes.value.lenderAgreement || '',
            status: {
              description: lenderAgreementStatus?.description,
              name: lenderAgreementStatus?.name,
            },
          })

          setAchAgreement(achRes.value.achAgreement || '')
          setAccountTypes(accountTypes.value)

          const membership = membershipVerbiage?.value

          const membershipAgreementStatus = agreementStatuses.value.find(
            (status) =>
              status.name === membership.membershipApplicationStatus.name
          )

          if (membership) {
            setMembershipAgreement({
              ...membershipAgreement,
              ...membership,
              text: membership.content || '',
              status: {
                description: membershipAgreementStatus.description,
                name: membershipAgreementStatus.name,
              },
            })
          }
        }
      )
      .finally(() => setLoading(false))

    /** On unmount, use the abort controller to abort the Generate Documents API call */
    return () => {
      setLoading(false)
    }
  }, [])

  /**
   * Generic Agreement change handler
   * On Agreement change save the new information to the server
   * @return Promise<Object>
   */
  const onAgreementChange = (lenderAgreementStatus, agreementType, data) => {
    setLoadingInnerType(agreementType)
    const agreementStatusId = agreementStatuses.find(
      (itm) => itm.name === lenderAgreementStatus
    )?.id
    let promise
    if (agreementType === AGREEMENT_TYPES.LENDER) {
      promise = updateLenderAgreement(
        dispatch,
        loanApplicationId,
        agreementStatusId
      )
        .then((res) => {
          setLenderAgreement({
            ...lenderAgreement,
            status: res.lenderAgreementStatus,
          })

          return res
        })
        .catch((e) => {
          console.error(e)
        })
    } else if (agreementType === AGREEMENT_TYPES.ACH) {
      promise = submitAchData(dispatch, loanApplicationId, data)
    } else {
      promise = updateMembershipAgreement(
        dispatch,
        loanApplicationId,
        agreementStatusId,
        data
      )
        .then((res) => {
          setMembershipAgreement({
            ...membershipAgreement,
            status: res.membershipApplicationStatus,
          })
          return res
        })
        .finally(() =>
          getMembershipVerbiage(loanApplicationId).then((membership) =>
            setMembershipAgreement({
              ...membershipAgreement,
              ...membership,
              text: membership.content || '',
              status: membership.membershipApplicationStatus,
            })
          )
        )
    }
    promise.finally(() => setLoadingInnerType(''))
    return promise
  }

  /**
   * Generates Envelopes and then moves to Loan Documents
   * If the documents were already generated it just moves to the loan docs step
   */
  const moveToLoanDocuments = async () => {
    setLoadingDocuments(true)
    scrollToTop()

    const existingEnvelopes = await listEnvelopesByApplication(
      null,
      loanApplicationId
    )
    if (existingEnvelopes?.length > 0) {
      goToStep(dispatch, navigate, stepsMap.LOAN_DOCUMENTS, isCommercialApp)
      return
    }

    const authorized = await authorizeLoanDocuments(loanApplicationId)
    if (!authorized) {
      return setLoadingDocuments(false)
    }

    // Generate envelopes
    generateEnvelopes({
      dispatch,
      lenderId: selectedLoanProduct.lenderId,
      envelopeDefinitionIds: selectedLoanProduct.envelopeDefinitions.map(
        (el) => el.envelopeDefinitionId
      ),
      loanFormData,
      loanApplicationId,
    })
      .then(async (result) => {
        await getLoanApplication(dispatch, loanApplicationId)
        result && goToStep(dispatch, navigate, stepsMap.LOAN_DOCUMENTS)
      })
      .catch(async (e) => {
        await getLoanApplication(dispatch, loanApplicationId)
      })
      .finally(() => setLoadingDocuments(false))
  }

  const moveToCommercialNTPs = async () => {
    setLoading(true)

    const authorized = await authorizeLoanDocumentsCommercial(loanApplicationId)
    await getLoanApplication(dispatch, loanApplicationId)

    setLoading(false)
    if (!authorized) {
      return
    }

    goToStep(dispatch, navigate, stepsMap.COMMERCIAL_NTP, true)
  }

  const handleClickContinue = () => {
    if (isCommercialApp) {
      moveToCommercialNTPs()
      return
    }

    moveToLoanDocuments()
  }

  if (loading) {
    return (
      <div className="lender-agreement">
        <Loader size={130} center={true} />
      </div>
    )
  }
  if (loadingDocuments) {
    return <LoadingDocuments />
  }

  const isLenderAccepted = lenderAgreement.status.name === 'Accepted'
  const customSchema = MEMBERSHIP_SCHEMAS[membershipAgreement.lenderId]
  const hasMembership =
    membershipAgreement.hasMembershipApplicationVerbiage || customSchema

  return (
    <div className="lender-agreement">
      <LenderAgreementCard
        agreementText={lenderAgreement.text}
        status={lenderAgreement.status}
        onAgreementChange={(value) =>
          onAgreementChange(value, AGREEMENT_TYPES.LENDER)
        }
        collapsed={false}
        loading={loadingInnerType === AGREEMENT_TYPES.LENDER}
        viewOnly={viewOnly}
      />
      <div className="always-enabled">
        <AutoPayCard
          isCommercialApp={isCommercialApp}
          achData={achData}
          agreementText={achAgreement}
          onSubmit={(data) => {
            if (data) {
              onAgreementChange(true, AGREEMENT_TYPES.ACH, data)
            } else if (!hasMembership) {
              moveToLoanDocuments()
            }
          }}
          loading={loadingInnerType === AGREEMENT_TYPES.ACH}
          accountTypes={accountTypes}
          lenderId={selectedLoanProduct?.lenderId}
          viewOnly={viewOnly}
        />
      </div>
      {hasMembership && (
        <div className="always-enabled">
          <MembershipCard
            status={membershipAgreement.status}
            onAgreementChange={(value, data) =>
              onAgreementChange(value, AGREEMENT_TYPES.MEMBERSHIP, data)
            }
            membershipData={membershipAgreement}
            customSchema={customSchema}
            collapsed={false}
            loading={loadingInnerType === AGREEMENT_TYPES.MEMBERSHIP}
            disabled={!isLenderAccepted}
            viewOnly={viewOnly}
          />
        </div>
      )}
      {areAgreementsAccepted && canGenerateDocuments && (
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={handleClickContinue}
            variant={BUTTON_VARIANTS.TEXT}
            endIcon={<ArrowRightIcon />}
          >
            {isCommercialApp
              ? translate('loanApplication.step3.continueToCommercialNTP')
              : translate('loanApplication.step3.continueToLoanDocs')}
          </Button>
        </div>
      )}
    </div>
  )
}

LenderAgreement.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  achData: PropTypes.object.isRequired,
  selectedLoanProduct: PropTypes.object.isRequired,
  loanFormData: PropTypes.object.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  currentState: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
  isCommercialApp: PropTypes.bool,
}

export default LenderAgreement
