import { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'

import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextField, { INPUT_TYPES } from 'components/input'
import { UW_HUB_ACTIONS, updateSolarContractsData } from '../actions'

const { LoadingIndicator } = components
const { CheckIcon, FileSearchIcon } = assets

const SolarContractsContent = ({
  loanApplicationId,
  data,
  downloadSolarContractAttachments,
  downloadingSolarContract,
}) => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const { t: translate } = useTranslation()
  const { dispatch } = useStore()
  const [isLoading, setIsLoading] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  const handleOnChange = (name, value) => {
    const { [name]: currentValue } = data

    if (JSON.stringify(currentValue) === value) {
      return
    }

    setHasChanges(true)
    dispatch({
      type: UW_HUB_ACTIONS.SET_SOLAR_CONTRACTS_DATA,
      payload: { ...data, [name]: value },
    })
  }

  const updateData = () => {
    setIsLoading(true)
    updateSolarContractsData(dispatch, loanApplicationId, data)
      .then(() => {
        setHasChanges(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="uw-hub-page__solar-contracts-wrapper">
      <div className="uw-hub-page__solar-contracts-row">
        <div className="uw-hub-page__solar-contracts-col">
          <span className="uw-hub-page__solar-contracts-label">
            {translate('uwHub.solarContracts.borrowerNames')}
          </span>
          <span>{data.borrowerNames?.join(', ') ?? '-'}</span>
        </div>
        <div className="uw-hub-page__solar-contracts-col">
          <span className="uw-hub-page__solar-contracts-label">
            {translate('uwHub.solarContracts.address')}
          </span>
          <span>{data.address ?? '-'}</span>
        </div>
      </div>
      <div className="uw-hub-page__solar-contracts-grid">
        <div className="uw-hub-page__solar-contracts-col">
          <span className="uw-hub-page__solar-contracts-label">
            {translate('uwHub.solarContracts.loanAmount') ?? '-'}
          </span>
          <span>{data.loanAmount}</span>
        </div>
        <TextField
          type={INPUT_TYPES.CURRENCY}
          label={translate('uwHub.solarContracts.nonSolarAmount')}
          value={data.nonSolarAmount}
          onChange={(val) => handleOnChange('nonSolarAmount', val)}
          prefix="$"
        />
        <TextField
          label={translate('uwHub.solarContracts.batteryCount')}
          onChange={(val) => handleOnChange('batteryCount', val)}
          value={data.batteryCount}
          type="number"
        />
        <TextField
          label={translate('uwHub.solarContracts.sizeKw')}
          onChange={(val) => handleOnChange('kwSize', val)}
          value={data.kwSize}
          type="number"
          decimalScale={3}
        />
        <TextField
          label={translate('uwHub.solarContracts.ppw')}
          onChange={(val) => handleOnChange('ppw', val)}
          value={data.ppw}
          type="currency"
          fixedDecimalScale={true}
          prefix="$"
        />
        <TextField
          label={translate('uwHub.solarContracts.insuranceType')}
          value={data.insuranceType}
          onChange={(val) => handleOnChange('insuranceType', val)}
        />
      </div>
      <div className="uw-hub-page__solar-contracts-buttons">
        {data.attachmentIds.length > 0 && isMobileView && (
          <Button
            onClick={() => downloadSolarContractAttachments()}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            startIcon={
              downloadingSolarContract ? (
                <LoadingIndicator size={14} />
              ) : (
                <FileSearchIcon />
              )
            }
            disabled={downloadingSolarContract}
          >
            {translate('uwHub.solarContracts.previewSolarContract')}
          </Button>
        )}
        <Button
          onClick={() => updateData()}
          variant={BUTTON_VARIANTS.PRIMARY}
          color={BUTTON_COLORS.PRIMARY}
          startIcon={isLoading ? <LoadingIndicator size={14} /> : <CheckIcon />}
          disabled={!hasChanges || isLoading}
        >
          {translate('global.saveChanges')}
        </Button>
      </div>
    </div>
  )
}

SolarContractsContent.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  downloadSolarContractAttachments: PropTypes.func.isRequired,
  downloadingSolarContract: PropTypes.bool.isRequired,
}

export default SolarContractsContent
