import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Checkbox from 'components/checkbox'
import { styles } from './styles'

const { Dialog, Dropdown } = components

const ModalAssignStates = ({ isOpen, onModalClose, onConfirm, allStates }) => {
  const [states, setStates] = useState([])
  const removeState = (stateId) =>
    setStates((prev) => prev.filter((state) => state.id !== stateId))

  const onClose = () => {
    setStates([])
    onModalClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={styles.modalProps}
      actions={
        <>
          <Button
            sx={{ width: '192px' }}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: '192px' }}
            onClick={() => {
              onConfirm(states)
              onClose()
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <Dropdown
        options={allStates?.map((el) => ({ ...el, label: el.friendlyName }))}
        label="Add States"
        value={states}
        onChange={(ev) => setStates(ev.target.value)}
        multiple={true}
        searchable={true}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 400,
              overflow: 'auto',
            },
          },
        }}
      />
      {states.map((state) => (
        <div key={state.id} style={{ width: '100%' }}>
          <Checkbox
            onClick={() => removeState(state.id)}
            label={state.label}
            checked={true}
            formControlLabelProps={{ sx: styles.checkboxProps }}
            labelProps={styles.checkboxLabelProps}
          />
        </div>
      ))}
    </Dialog>
  )
}

ModalAssignStates.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  allStates: PropTypes.array.isRequired,
  defaultSelectedStates: PropTypes.array,
}

export default ModalAssignStates
