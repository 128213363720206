import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { debounce } from 'lodash'

import { useEffectOnUpdate } from 'common/hooks'
import { components } from '@ElementsCapitalGroup/enium-ui'
import './style.scss'

export const PAGE_SIZE = 20

/**
 * Lazy Loader Component
 *
 * Keeps a hidden element at the bottom and if it detects it gets close to the bottom of the page it starts loading more items
 * In order to do so it calls the fetchNextPage callback with the next page to be fetched and the pageSize
 * This needs to be a promise in order to detect if there are items left to load or not
 *
 * @param {Object} options
 * @param {Number} [options.page]
 * @param {Number} [options.pageSize]
 * @param {Function} options.fetchNextPage - needs to be a Promise because we need to read the results
 * @param {Object} [options.filters] - extra filters to be passed to the list method
 * @param {Node} [options.scrollTarget] - scrollTarget wrapper to scroll on
 */
const LazyLoader = ({
  page = 0,
  pageSize = PAGE_SIZE,
  fetchNextPage,
  filters = {},
  scrollTarget = document.body,
}) => {
  const [loading, setLoading] = useState(false)
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false)
  const ref = useRef()
  const { LoadingIndicator } = components

  /** On mount add scroll event listener on the scrollTarget to detect how much we scrolled */
  useEffect(() => {
    scrollTarget?.removeEventListener('scroll', debouncedLoadMore)
    scrollTarget?.addEventListener('scroll', debouncedLoadMore)
    return () => {
      scrollTarget?.removeEventListener('scroll', debouncedLoadMore)
    }
  }, [scrollTarget, loading, loadMoreDisabled, page, JSON.stringify(filters)])

  /** On filters change, reset disabled flag */
  useEffect(() => {
    setLoadMoreDisabled(false)
  }, [JSON.stringify(filters)])

  useEffectOnUpdate(() => {
    if (page === 0) {
      setLoadMoreDisabled(false)
    }
  }, [page])

  /** Method that checks scroll position and decides if we should load more items or not */
  const loadMore = () => {
    if (loading || loadMoreDisabled) {
      return
    }

    // Checks if we are close to the bottom of a specified node element
    const isCloseToBottom = () => {
      if (!ref.current) {
        return false
      }
      const position = ref.current.getBoundingClientRect()
      return position.bottom > 0 && position.top - window.innerHeight <= 700
    }

    // If we are close to the bottom, fetch next page
    if (isCloseToBottom()) {
      setLoading(true)
      fetchNextPage(page + 1, pageSize, filters)
        .then((list) => {
          if (!list || list.length < pageSize) {
            setLoadMoreDisabled(true)
          }
        })
        .finally(() => setLoading(false))
    }
  }

  /** Debounce the onScroll method s.t. it does not trigger tens of times */
  const debouncedLoadMore = debounce(loadMore, 50)

  return (
    <div
      className={cx('lazy-loader', {
        'lazy-loader--disabled': loadMoreDisabled,
      })}
    >
      <div ref={ref}></div>
      {loading && (
        <div className="lazy-loader__inner">
          <LoadingIndicator size={50} />
        </div>
      )}
    </div>
  )
}

LazyLoader.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  fetchNextPage: PropTypes.func.isRequired,
  filters: PropTypes.object,
  scrollTarget: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
}

export default LazyLoader
