import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import TextField, { INPUT_TYPES } from 'components/input'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { validate } from 'components/validator'
import Textarea from 'components/textarea'
import Checkbox from 'components/checkbox'
import { useStore } from 'store'

import { styles } from '../style'
import { LENDER_ORG_VALIDATION_MAP } from './constants'
import { getFicoVersions, getStates } from '../../actions'

const { Dropdown } = components

const LenderOrgAddEditForm = ({
  isNew,
  state,
  updateField,
  fixedOrgType,
  orgTypes,
  orgStatuses,
  parentOrg,
  onSave,
  onCancel,
  somethingChanged,
}) => {
  const {
    state: {
      orgManagement: { ficoVersions },
    },
    dispatch,
  } = useStore()
  const [usStates, setUsStates] = useState([])
  const [errors, setErrors] = useState({})
  const [showNotes, setShowNotes] = useState(false)
  const orgType =
    state.type || orgTypes.find((type) => type.id === fixedOrgType) || ''

  useEffect(() => {
    if (!usStates.length) {
      getStates().then((states) =>
        setUsStates(states.map((el) => ({ ...el, label: el.name })))
      )
    }
    if (!ficoVersions.length) {
      getFicoVersions(dispatch)
    }
  }, [])

  const validateAndSave = () => {
    const [isValid, errors] = validate(LENDER_ORG_VALIDATION_MAP, state)
    setErrors(errors)
    if (isValid) {
      onSave(state)
    }
  }

  return (
    <>
      <div style={styles.organizations.grid}>
        <TextField
          label="Name"
          placeholder="Organization Name"
          value={state.name}
          onChange={updateField('name')}
          validate={() => errors.name}
        />
        <Dropdown
          disabled={!!fixedOrgType}
          label="Type"
          options={orgTypes}
          onChange={(ev) => updateField('type')(ev.target.value)}
          value={orgType}
        />
        {isNew ? (
          <Dropdown
            label="Corporate Organization"
            options={[{ id: parentOrg.guid, label: parentOrg.name }]}
            disabled={true}
            onChange={() => {}}
            value={{ id: parentOrg.guid, label: parentOrg.name }}
          />
        ) : null}
        <TextField
          label="Phone Number"
          type={INPUT_TYPES.PHONE}
          placeholder="Phone Number"
          value={state.phoneNumber}
          onChange={updateField('phoneNumber')}
          validate={() => errors.phoneNumber}
        />
        <TextField
          label="Address"
          placeholder="Address 1, Address 2, City, State"
          value={state.address}
          onChange={updateField('address')}
          validate={() => errors.address}
        />
        <Dropdown
          label="FICO Version"
          options={ficoVersions.map((fico) => ({
            id: fico.id,
            label: fico.friendlyName,
          }))}
          value={state.ficoVersion}
          onChange={(ev) => updateField('ficoVersion')(ev.target.value)}
          error={Boolean(
            errors.ficoVersion && typeof errors.ficoVersion === 'string'
              ? errors.ficoVersion
              : null
          )}
          helperText={
            errors.ficoVersion && typeof errors.ficoVersion === 'string'
              ? errors.ficoVersion
              : null
          }
        />
        <TextField
          label="DTI Percentage"
          placeholder="DTI Percentage"
          value={state.dtiPercentage}
          onChange={updateField('dtiPercentage')}
        />
        <TextField
          label="LSS Code"
          placeholder="LSS Code"
          value={state.lssCode}
          onChange={updateField('lssCode')}
        />
        <TextField
          label="LSS Portfolio"
          placeholder="LSS Portfolio"
          value={state.lssPortfolio}
          onChange={updateField('lssPortfolio')}
        />
        <TextField
          label="AFP Documents Lender Path"
          placeholder="AFP Documents Lender Path"
          value={state.afpLenderDocumentsPath}
          onChange={updateField('afpLenderDocumentsPath')}
        />
        <TextField
          label="AFP Funding Service Code"
          placeholder="AFP Funding Service Code"
          value={state.fundingServiceCode}
          onChange={updateField('fundingServiceCode')}
        />
        <TextField
          label="Fallback Hard Pull Credentials Name"
          value={state.fallbackHardPullCredentialsName}
          onChange={updateField('fallbackHardPullCredentialsName')}
          validate={() => errors.fallbackHardPullCredentialsName}
        />
        <TextField
          label="Hard Pull Credentials Name"
          value={state.hardPullCredentialsName}
          onChange={updateField('hardPullCredentialsName')}
          validate={() => errors.hardPullCredentialsName}
        />
        <TextField
          label="Applicant Decisioning Name"
          value={state.applicantDecisioningName}
          onChange={updateField('applicantDecisioningName')}
          validate={() => errors.applicantDecisioningName}
        />
        <TextField
          label="Minimum Threshold"
          type={INPUT_TYPES.CURRENCY}
          value={state.minimumIncomeThreshold}
          onChange={updateField('minimumIncomeThreshold')}
          validate={() => errors.minimumIncomeThreshold}
        />
        <Dropdown
          label="Status"
          options={orgStatuses}
          value={state.status || ''}
          onChange={(ev) => updateField('status')(ev.target.value)}
          error={Boolean(
            errors.status && typeof errors.status === 'string'
              ? errors.status
              : null
          )}
          helperText={
            errors.status && typeof errors.status === 'string'
              ? errors.status
              : null
          }
        />
        <Dropdown
          label="States"
          options={usStates}
          multiple={true}
          searchable={true}
          value={state.organizationStates.map((el) => ({
            ...el,
            label: el.name,
          }))}
          onChange={(ev) => updateField('organizationStates')(ev.target.value)}
        />
      </div>
      <div style={{ ...styles.organizations.grid, marginTop: '25px' }}>
        <Textarea
          label="Email Addresses"
          placeholder="sample1@mail.com, sample2@mail.com"
          value={state.emailAddresses}
          onChange={updateField('emailAddresses')}
          validate={() => errors.emailAddresses}
        />
        <TextField
          label="Application Expiration In Days"
          type={INPUT_TYPES.NUMBER}
          value={state.applicationExpirationInDays}
          onChange={updateField('applicationExpirationInDays')}
          validate={() => errors.applicationExpirationInDays}
        />
        <TextField
          label="Application Expiration Extension In Days"
          type={INPUT_TYPES.NUMBER}
          value={state.applicationExpirationExtensionInDays}
          onChange={updateField('applicationExpirationExtensionInDays')}
          validate={() => errors.applicationExpirationExtensionInDays}
        />
      </div>

      <div style={styles.organizations.checkboxes}>
        <Checkbox
          checked={state.skipInitialFundingPeriod}
          onClick={(newValue) =>
            updateField('skipInitialFundingPeriod')(newValue)
          }
          label="Skip Initial Funding Period"
        />

        <Checkbox
          checked={state.useLoanPaymentInDti}
          onClick={(newValue) => updateField('useLoanPaymentInDti')(newValue)}
          label="Use Loan Payment In DTI"
        />

        <Checkbox
          checked={state.isPtoNotMandatory}
          onClick={(newValue) => updateField('isPtoNotMandatory')(newValue)}
          label="PTO Not Mandatory"
        />
      </div>

      {showNotes && (
        <div style={{ ...styles.organizations.grid, marginBottom: '24px' }}>
          <Textarea
            label="Lender Agreement"
            placeholder="Lender Agreement"
            value={state.lenderAgreement}
            onChange={updateField('lenderAgreement')}
          />
          <Textarea
            label="Available Zip Codes"
            placeholder="Available Zip Codes"
            value={state.availableZIPCodes}
            onChange={updateField('availableZIPCodes')}
          />
          <Textarea
            label="Auto Pay Opt Out Verbiage"
            placeholder="Auto Pay Opt Out Verbiage"
            value={state.achOptOutVerbiage}
            onChange={updateField('achOptOutVerbiage')}
          />
          <Textarea
            label="ACH Agreement"
            placeholder="ACH Agreement"
            value={state.achAgreement}
            onChange={updateField('achAgreement')}
          />
          <Textarea
            label="Tradeline Names"
            placeholder="Tradeline Names"
            value={state.tradelineNames}
            onChange={updateField('tradelineNames')}
          />
          <Textarea
            label="Membership Application Verbiage"
            placeholder="Membership Application Verbiage"
            value={state.membershipApplicationVerbiage}
            onChange={updateField('membershipApplicationVerbiage')}
          />
        </div>
      )}

      <div style={styles.organizations.buttons}>
        <Button
          color={BUTTON_COLORS.INHERIT}
          variant={BUTTON_VARIANTS.OUTLINED}
          style={{ marginRight: '16px' }}
          onClick={() => setShowNotes(!showNotes)}
        >
          {showNotes ? 'Hide' : 'Show'} Notes
        </Button>
        <Button
          color={BUTTON_COLORS.INHERIT}
          variant={BUTTON_VARIANTS.OUTLINED}
          style={{ marginRight: '16px' }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button disabled={!somethingChanged} onClick={validateAndSave}>
          {isNew ? 'Create Organization' : 'Save Changes'}
        </Button>
      </div>
    </>
  )
}

LenderOrgAddEditForm.propTypes = {
  isNew: PropTypes.bool,
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  fixedOrgType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orgTypes: PropTypes.array.isRequired,
  orgStatuses: PropTypes.array.isRequired,
  parentOrg: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  somethingChanged: PropTypes.bool,
}

export default LenderOrgAddEditForm
