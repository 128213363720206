import React from 'react'
import AdminWrapper from 'modules/admin'
import PropTypes from 'prop-types'
import AusTemplatesSettings from 'modules/aus-templates/aus-templates-settings'

const AdminAusTemplatesSettings = ({ isCreate }) => (
  <AdminWrapper>
    <AusTemplatesSettings isCreate={isCreate} />
  </AdminWrapper>
)

export default AdminAusTemplatesSettings

AdminAusTemplatesSettings.propTypes = {
  isCreate: PropTypes.bool,
}
