import React from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'

import EvaluatedLoanProduct from './evaluated-loan-product'

const EvaluationSession = ({ evaluationSession, selectedLoanProductId }) => {
  const { state } = useStore()
  const { userData } = state.session

  return (
    <div className="uw-hub-page__evaluation-session">
      {evaluationSession?.evaluatedLoanProducts?.map((evaluatedLoanProduct) => {
        return (
          <div
            className="uw-hub-page__evaluation-session-item"
            key={evaluatedLoanProduct.evaluatedLoanProductId}
          >
            <EvaluatedLoanProduct
              evaluatedLoanProduct={evaluatedLoanProduct}
              selectedLoanProductId={selectedLoanProductId}
              userData={userData}
            />
          </div>
        )
      })}
    </div>
  )
}

EvaluationSession.propTypes = {
  evaluationSession: PropTypes.object.isRequired,
  selectedLoanProductId: PropTypes.string.isRequired,
}

export default EvaluationSession
