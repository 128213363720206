import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_SIZES } from 'components/button'
import Modal from 'components/modal'
import { ReactComponent as Arrow } from 'assets/down-arrow.svg'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { AccessWrapper, hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import Loader from 'components/loader'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BlueClipperIcon } from 'assets/blue-clipper.svg'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import NotOptedInAch from './not-opted-in-ach'
import LoadingDocuments from './loading-documents'
import {
  voidEnvelope,
  emailEnvelopes,
  generateEnvelopes,
  goToStep,
  checkForEnvelopeUpdates,
  getLoanApplication,
  resetFlow,
  getLoanAppStatuses,
  downloadDocuments,
  reAttachEnvelopes,
  getOldCompletedEnvelopes,
  sendCompletedEnvelopes,
} from '../actions'
import {
  STATES_ORDER,
  STEPS_MAP,
  STEPS_MAP_COMMERCIAL,
  STATES_ORDER_COMMERCIAL,
} from '../constants'

import { RegenerateModal } from './regenerate-modal'
import { styles } from './style'
import { _isEnvelopeSigned } from './utils'
import EnvelopeTable from './envelopes-table'

const { IconButton, Tooltip } = components

const LoanDocuments = ({
  loanApplicationId,
  envelopes,
  loanFormData,
  selectedLoanProduct,
  currentState,
  dispatch,
  achData,
  userData,
  isCommercialApp,
  viewOnly,
}) => {
  const [loading, setLoading] = useState(true)
  const [autoPayModalOpen, setAutoPayModalOpen] = useState(false)
  const [voidDocumentId, setVoidDocumentId] = useState(null)
  const [oldCompletedEnvelopes, setOldCompletedEnvelopes] = useState([])
  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false)
  const statesOrder = isCommercialApp ? STATES_ORDER_COMMERCIAL : STATES_ORDER
  const loanDocumentsSigned =
    statesOrder[currentState] >= statesOrder.DocumentsSigned
  const canGenerateDocuments = hasAccess(
    userData,
    CLAIMS.CAN_GENERATE_DOCUMENTS
  )
  const canOptInAch = hasAccess(userData, CLAIMS.CAN_OPT_IN_ACH)
  const { t: translate } = useTranslation()
  const navigate = useNavigate()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const nextStep = isCommercialApp
    ? STEPS_MAP_COMMERCIAL.COMMERCIAL_NTP
    : STEPS_MAP.NTP
  /**
   * If all Envelopes have been signed, auto-redirect to the NTP step
   * In case the user did not enroll in ACH, prompt a modal for opting in first
   */
  const allEnvelopesSigned = () => {
    if (!achData.achId && canOptInAch) {
      setAutoPayModalOpen(true)
    } else {
      goToStep(dispatch, navigate, nextStep, isCommercialApp)
    }
  }

  const initializeComponent = async () => {
    const params = new URLSearchParams(window.location.search)
    await getLoanApplication(dispatch, loanApplicationId)

    getOldCompletedEnvelopes(loanApplicationId).then((oldEnvelopes) =>
      setOldCompletedEnvelopes(oldEnvelopes)
    )

    // Check for envelope updates
    await checkForUpdates().then((envelopes) => {
      if (params.get('event') === 'signing_complete') {
        // If all Envelopes associated with the selected loan product have been signed (and there are none still voided), auto-redirect to the NTP step
        const currentLenderEnvelopes = envelopes.envelopesToRegenerate?.filter(
          (_) =>
            selectedLoanProduct.envelopeDefinitions.some(
              (ed) => ed.envelopeDefinitionId === _.envelopeDefinitionId
            )
        )
        if (
          currentLenderEnvelopes?.length === 0 &&
          envelopes.updatedEnvelopesDto.every(_isEnvelopeSigned)
        ) {
          allEnvelopesSigned()
        }

        // Remove the Query Param from the URL
        window.history.pushState(
          {},
          '',
          window.location.origin + window.location.pathname
        )
      }
    })
  }
  /**
   * On mount, get loan app details
   * If we have the event=signing_complete URL param it means we just came after a DocuSign signing
   * If we have the event=signing_complete -> auto-redirect to NTP if all docs are signed
   */
  useEffect(() => {
    initializeComponent()

    return () => {
      resetFlow(dispatch)
      setLoading(false)
      getLoanAppStatuses(dispatch)
    }
  }, [])

  /* Fetch updated envelopes */
  const checkForUpdates = () => {
    setLoading(true)
    return checkForEnvelopeUpdates(dispatch, loanApplicationId).finally(() =>
      setLoading(false)
    )
  }

  /* Method to re-send an envelope via email */
  const reSendEnvelope = (envelopeId) => {
    return emailEnvelopes(envelopeId)
      .then(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.POSITIVE,
          title: translate('loanApplication.step4.notifications.emailSent'),
        })
      )
      .catch(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate(
            'loanApplication.step4.notifications.errorSendingEmail'
          ),
        })
      )
  }

  const sendCompletedEnvelope = (envelopeId) => {
    return sendCompletedEnvelopes(envelopeId)
      .then(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.POSITIVE,
          title: translate('loanApplication.step4.notifications.emailSent'),
        })
      )
      .catch(() =>
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: translate(
            'loanApplication.step4.notifications.errorSendingEmail'
          ),
        })
      )
  }

  /* Method to re-generate envelopes */
  const reGenerate = (selectedEnvelopesIds) => {
    setLoading(true)
    return generateEnvelopes({
      dispatch,
      lenderId: selectedLoanProduct.lenderId,
      envelopeDefinitionIds: selectedEnvelopesIds,
      loanFormData,
      loanApplicationId,
    })
      .then(async () => {
        await getLoanApplication(dispatch, loanApplicationId)
        await checkForUpdates()
      })
      .catch(() => setLoading(false))
  }

  /* Void a document (envelope) */
  const voidDocument = async (envelopeId) => {
    setLoading(true)
    try {
      await voidEnvelope(loanApplicationId, envelopeId)
      await checkForUpdates()
      /* If Loan Documents were previously signed, re-fetch the Loan App to ensure we have up-to-date data */
      if (loanDocumentsSigned) {
        await getLoanApplication(dispatch, loanApplicationId)
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const handleReAttachDocs = async () => {
    if (!oldCompletedEnvelopes.length) {
      return
    }

    const envelopesToReAttach = oldCompletedEnvelopes.map((e) => e.envelopeId)
    setLoading(true)

    try {
      await reAttachEnvelopes(loanApplicationId, envelopesToReAttach)
      await checkForUpdates()
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
  }

  if (loading) {
    return <LoadingDocuments />
  }

  if (!envelopes) {
    return null
  }

  return (
    <div style={styles.loanDocuments}>
      {canGenerateDocuments && (
        <div
          style={
            isTabletView
              ? { ...styles.headerWrapper, padding: '0 24px' }
              : styles.headerWrapper
          }
        >
          <div>
            <div
              style={
                isTabletView
                  ? { ...styles.header, fontSize: '1.125rem' }
                  : styles.header
              }
            >
              {translate('loanApplication.navigation.step4')}
            </div>
            <div style={styles.subHeader}>
              {translate('loanApplication.step4.note')}
            </div>
          </div>

          {regenerateModalOpen && (
            <RegenerateModal
              onClose={() => setRegenerateModalOpen(false)}
              onSubmit={reGenerate}
              envelopes={envelopes?.envelopesToRegenerate}
            />
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AccessWrapper claims={CLAIMS.CAN_RE_ATTACH_DOCUMENTS}>
              {loading ? (
                <Loader noLogo size={30} />
              ) : (
                <span
                  className={cx('re-attach-docs', {
                    're-attach-docs--disabled': !oldCompletedEnvelopes.length,
                  })}
                  onClick={handleReAttachDocs}
                >
                  <Tooltip
                    bottom={true}
                    title={translate(
                      'loanApplication.step4.reAttachLastSignedDocuments'
                    )}
                  >
                    <IconButton size={BUTTON_SIZES.SMALL}>
                      <BlueClipperIcon width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
            </AccessWrapper>

            <Button
              onClick={() => setRegenerateModalOpen(true)}
              style={{ marginLeft: '8px' }}
              disabled={!envelopes?.envelopesToRegenerate?.length}
            >
              {translate('loanApplication.step4.regenerate')}
            </Button>
          </div>
        </div>
      )}

      {envelopes?.updatedEnvelopesDto?.map((envelope, key) => {
        return (
          <EnvelopeTable
            key={key}
            envelope={envelope}
            onEmailClicked={() => {
              if (_isEnvelopeSigned(envelope)) {
                return sendCompletedEnvelope(envelope.envelopeId)
              } else {
                return reSendEnvelope(envelope.envelopeId)
              }
            }}
            onDownloadClicked={() => downloadDocuments(loanApplicationId)}
            onVoidClicked={voidDocument}
            viewOnly={viewOnly}
          />
        )
      })}

      {loanDocumentsSigned && (
        <div
          className="flow__continue"
          onClick={() =>
            goToStep(dispatch, navigate, nextStep, isCommercialApp)
          }
        >
          {translate('loanApplication.step4.continueToNTP')} <Arrow />
        </div>
      )}

      <NotOptedInAch
        dispatch={dispatch}
        isOpen={autoPayModalOpen}
        onClose={() => goToStep(dispatch, navigate, nextStep, isCommercialApp)}
        loanApplicationId={loanApplicationId}
        achData={achData}
        lenderId={selectedLoanProduct?.lenderId}
        title={translate('loanApplication.step4.timeToEnroll')}
      />

      <Modal
        isOpen={!!voidDocumentId}
        onClose={() => setVoidDocumentId(null)}
        title={translate('loanApplication.step3.areYouSure')}
        onConfirm={() => {
          setVoidDocumentId(null)
          voidDocument(voidDocumentId)
        }}
        confirmButton={translate('buttons.yes')}
        cancelButton={translate('buttons.cancel')}
      ></Modal>
    </div>
  )
}

LoanDocuments.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  envelopes: PropTypes.shape({
    envelopesToRegenerate: PropTypes.array,
    updatedEnvelopesDto: PropTypes.array,
  }),
  selectedLoanProduct: PropTypes.object.isRequired,
  loanFormData: PropTypes.object.isRequired,
  currentState: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  achData: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  isCommercialApp: PropTypes.bool,
  viewOnly: PropTypes.bool,
}

export default LoanDocuments
