import React, { useEffect } from 'react'
import Modal from 'components/modal'
import { useEffectOnUpdate, useMediaQuery } from 'common/hooks'
import { useStore } from 'store'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import Loader, { LOADER_SIZE } from 'components/loader'
import LoanCalculator from 'components/loan-calculator'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import {
  STEPS_MAP_REVERSED,
  STEPS_MAP_REVERSED_COMMERCIAL,
  STEP_URLS,
  STEP_URLS_COMMERCIAL,
  LOAN_APP_TYPES_ID,
} from 'modules/loan-application/constants'
import OutageMessagesAlert from 'modules/outage-messages/alert'
import {
  getSolarContractsData,
  getDataReviewData,
} from 'modules/uw-hub/actions'
import { TABLET_BREAKPOINT } from 'common/constants'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import {
  showNotification,
  hideNotification,
  checkAccessToNotification,
  getMessageTypes,
  prefillUserDataFromStripe,
  setLoading as setGlobalLoading,
  toggleLoanCalculator,
} from './actions'
import {
  SERVER_TO_CLIENT_MAP,
  NOTIFICATION_ENTITY_TYPE_IDS,
  NOTIFICATION_TYPES,
} from './notifications'
import { useConnection } from './socket'
import './styles.scss'

/**
 * Handles globally-related data such as the global Loader and Notifications
 * @constructor
 */
const {
  AlertTriangleIcon,
  CheckCircleIcon,
  CheckCircleBrokenIcon,
  ArrowRightIcon,
} = assets

const GlobalModule = () => {
  const navigate = useNavigate()
  const { state, dispatch } = useStore()
  const { userData } = state.session
  const { loading, notifications, messageTypes, isLoanCalculatorOpen } =
    state.global
  const location = useLocation()
  const { loanApplicationId } = useParams()

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const iconStyling = {
    mr: 1,
    ml: 1,
    mt: !isMobileView ? '-4px' : 0,
    width: '16px',
  }

  const connection = useConnection()
  const userId =
    userData?.associations?.length && userData?.associations[0]?.userId

  useEffect(() => {
    if (window.location.pathname !== '/login' && userData?.organizationId) {
      getMessageTypes(dispatch)
    }
  }, [userData?.organizationId])

  /**
   * Open Socket connection and listen for server-side messages
   * When a message comes we check if it should be displayed or not for the current user
   */

  useEffectOnUpdate(async () => {
    connection?.on('ReceiveMessage', (serverMessage = {}) => {
      try {
        const messageObj = messageTypes?.[serverMessage.messageTypeId]

        /* ID verification */
        if (messageObj.id === 18) {
          // ID Verification Successful
          if (userId === serverMessage.userId) {
            prefillUserDataFromStripe(dispatch, serverMessage.entityId)
          }

          return
        }

        if (messageObj.id === 17) {
          setGlobalLoading(dispatch, false)
          // ID Verification Failed
          if (userId === serverMessage.userId) {
            showNotification(dispatch, {
              type: NOTIFICATION_TYPES.NEGATIVE,
              title: messageObj.message,
            })
          }

          return
        }

        if (
          messageObj.id === 21 &&
          location.pathname.indexOf('/uw-hub') !== null &&
          loanApplicationId === serverMessage.entityId
        ) {
          getSolarContractsData(dispatch, loanApplicationId)
            .then(() => {
              showNotification(dispatch, {
                type: NOTIFICATION_TYPES.POSITIVE,
                title: messageObj.message,
              })
            })
            // fetch data review section, it is dependent on solar contracts data
            .then(() => getDataReviewData(dispatch, loanApplicationId))
        }

        const isCommercialApp =
          messageObj.loanApplicationTypeId === LOAN_APP_TYPES_ID.COMMERCIAL
        const stepUrls = isCommercialApp ? STEP_URLS_COMMERCIAL : STEP_URLS
        const stepsMapReversed = isCommercialApp
          ? STEPS_MAP_REVERSED_COMMERCIAL
          : STEPS_MAP_REVERSED

        const step =
          stepUrls[stepsMapReversed[messageObj.loanApplicationStepId]] || ''

        const redirectUrl =
          messageObj.entityTypeId ===
          NOTIFICATION_ENTITY_TYPE_IDS.LOAN_APPLICATION
            ? messageObj.loanApplicationStepId
              ? `/loan-application/${serverMessage?.entityId}/${step.path}`
              : `/loan-application/${serverMessage?.entityId}`
            : ''
        const hoverable = !!redirectUrl

        if (messageObj && checkAccessToNotification(userData, serverMessage)) {
          const { message, notificationType } = messageObj
          showNotification(dispatch, {
            type: SERVER_TO_CLIENT_MAP[notificationType.name],
            title:
              messageObj.loanApplicationStepId !== null &&
              messageObj.loanApplicationStepId !== undefined
                ? `${message} for loan application with GUID: ${serverMessage?.entityId}. Step Id: ${messageObj.loanApplicationStepId}`
                : message,
            redirectUrl,
            hoverable,
            autoHide: false,
          })
        }
      } catch (e) {
        console.error('Error on socket message', serverMessage, e)
      }
    })
  }, [JSON.stringify(messageTypes)])

  // Function for redirecting the user to the correct step when clicking on the notification
  const handleRedirect = (url) => {
    navigate(url)
  }

  return (
    <>
      {/* Render Global Loader */}
      <Modal isOpen={loading} containerClass="global-loader">
        <Loader size={LOADER_SIZE.XLARGE} />
      </Modal>

      {/* Render Global Notifications */}
      <div className="global-notifications">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className={`${
              notification.hoverable ? 'global-notification--hoverable' : ''
            } global-notification global-notification--${notification.type}`}
            onClick={() => {
              if (notification.redirectUrl) {
                handleRedirect(notification.redirectUrl)
              }
            }}
          >
            <div className="global-notification__content">
              {notification.type === 'warning' && (
                <AlertTriangleIcon size={16} sx={iconStyling} />
              )}
              {notification.type === 'positive' && (
                <CheckCircleIcon size={16} sx={iconStyling} />
              )}
              {notification.type === 'negative' && (
                <CheckCircleBrokenIcon size={16} sx={iconStyling} />
              )}
              <div className="global-notification__text">
                <span>{notification.title}</span>
                {notification.message && (
                  <span className="global-notification__message">
                    {notification.message}
                  </span>
                )}
                <div className="global-notification__links">
                  {notification.learnMoreUrl && (
                    <Link
                      to={notification.learnMoreUrl}
                      target="_blank"
                      className={`global-notification__learn-more global-notification__learn-more--${notification.type}`}
                    >
                      Learn More
                    </Link>
                  )}
                  {notification.viewChangesUrl && (
                    <Link
                      to={notification.viewChangesUrl}
                      target="_blank"
                      className={`global-notification__view-changes global-notification__view-changes--${notification.type}`}
                    >
                      View Changes
                      <ArrowRightIcon sx={{ width: '18px', ml: 1 }} />
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`global-notification__close global-notification__close--${notification.type}`}
              onClick={(e) => {
                e.stopPropagation()
                hideNotification(dispatch, notification.id)
              }}
            >
              ✕
            </div>
          </div>
        ))}
      </div>
      <OutageMessagesAlert />
      {isLoanCalculatorOpen &&
        hasAccess(userData, CLAIMS.CAN_VIEW_ECG_TOOLBOX_LOAN_CALCULATOR) && (
          <LoanCalculator
            onClose={() => toggleLoanCalculator(dispatch, false)}
          />
        )}
    </>
  )
}

export default GlobalModule
