import React, { useEffect } from 'react'
import AdminWrapper from 'modules/admin'
import { useStore } from 'store'
import { getAllOrganizations, getRoles } from 'modules/organizations/actions'
import Users from 'modules/users'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'

const AdminUsers = () => {
  const { state, dispatch } = useStore()
  const { userData } = state.session
  const { userRoles, allOrganizations } = state.orgManagement

  useEffect(() => {
    if (!userRoles.length && hasAccess(userData, CLAIMS.CAN_VIEW_ROLES)) {
      getRoles(dispatch)
    }

    if (
      !allOrganizations.length &&
      hasAccess(userData, [CLAIMS.CAN_VIEW_ORGANIZATIONS])
    ) {
      getAllOrganizations(dispatch)
    }
  }, [])

  return (
    <AdminWrapper>
      <Users />
    </AdminWrapper>
  )
}

export default AdminUsers
