import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import TextField, { INPUT_TYPES } from 'components/input'
import Button from 'components/button'

import { useMediaQuery } from 'common/hooks'
import { requestPhoneNumber } from 'modules/session/actions'
import { TABLET_BREAKPOINT } from 'common/constants'
import { styles } from './style'

const { Checkbox, Dialog, IconButton } = components
const { LockIcon } = assets

export const TwoFactorPhoneSetup = ({
  setIsPinSetup,
  setIsPhoneSetup,
  emailAddress,
  password,
  currentPhoneNumber,
  setChangePhoneNumberToken,
  newPhoneNumber,
  setNewPhoneNumber,
}) => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [loading, setLoading] = useState(false)
  const [termsAgreedChecked, setTermsAndConditions] = useState(false)
  const [isTermsAndConditionsOpen, setIsTermsAndConditionsOpen] =
    useState(false)
  const [error, setError] = useState('')

  const handleRequestPhoneNumber = () => {
    setLoading(true)
    requestPhoneNumber(emailAddress, password, newPhoneNumber)
      .then((res) => {
        if (!res) {
          setError('Something went wrong. Please try again.')
        }
        if (res.is2FACodeSent) {
          setChangePhoneNumberToken(res.changePhoneNumberToken)
          setIsPhoneSetup(false)
          setIsPinSetup(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          maxWidth: '512px',
          height: 'auto',
          margin: isMobileView ? '12px' : 'inherit',
        },
      }}
    >
      <IconButton
        variant={'outlined'}
        sx={{
          marginRight: '8px',
          ':hover': {
            background: 'transparent',
            cursor: 'pointer',
          },
        }}
      >
        <LockIcon />
      </IconButton>

      <div style={styles.phoneSetUpTitle}>Set up 2-Factor Authentication</div>
      <div>
        To proceed, please set up 2-Factor Authentication. Enter your phone
        number to receive a verification code via SMS. This enhances your
        account security.
      </div>
      <TextField
        label={'Phone Number'}
        value={newPhoneNumber ? newPhoneNumber : currentPhoneNumber.slice(2)}
        type={INPUT_TYPES.PHONE}
        fullWidth
        style={{ marginTop: '20px' }}
        onChange={setNewPhoneNumber}
      />
      {error && <div className="error">{error}</div>}
      <div style={{ marginTop: '24px' }}>
        <Checkbox
          style={{ mt: 2 }}
          onClick={(e) => setTermsAndConditions(e.target.checked)}
          checked={termsAgreedChecked}
          label={
            <div>
              I agree to receive messages from Enium at the phone number
              provided above and acknowledge that I have read and accept the
              <span
                onClick={() => {
                  setIsTermsAndConditionsOpen(true)
                }}
                style={styles.termsAndConditions}
              >
                {' '}
                Terms of Use & Privacy Policy .
              </span>
            </div>
          }
          labelProps={{
            fontSize: '12px',
            color: 'inherit',
          }}
        />
      </div>
      <Dialog
        open={isTermsAndConditionsOpen}
        onClose={() => setIsTermsAndConditionsOpen(false)}
        title="Terms of Use & Privacy Policy"
        PaperProps={{
          sx: {
            maxWidth: '512px',
            height: 'auto',
          },
        }}
      >
        <ol style={styles.termsList}>
          <li style={styles.termsItem}>
            Opting In: When you opt in to 2-Factor Authentication with Enium
            Capital, you will receive SMS messages for verification purposes.
            These messages will include one-time codes required to complete the
            login process for enhanced security.{' '}
          </li>
          <li style={styles.termsItem}>
            Cancelling the SMS Service: You can cancel the SMS service at any
            time. Just text “STOP” to the number from which you received the
            message. After you send the SMS message “STOP” to us, we will send
            you an SMS message to confirm that you have been unsubscribed.
            Please note that if you cancel the SMS service, you will no longer
            be able to access the Enium Capital portal as the 2-Factor
            Authentication is required for login.
          </li>
          <li style={styles.termsItem}>
            Assistance and Support: If you are experiencing issues with the
            messaging program, please contact us directly at support@enium.com
            or call our toll-free number (855) 944-5006.{' '}
          </li>
          <li style={styles.termsItem}>
            Carrier Liability: Carriers are not liable for delayed or
            undelivered messages.
          </li>
          <li style={styles.termsItem}>
            Message and Data Rates: As always, message and data rates may apply
            for any messages sent to you from us and to us from you. You will
            receive messages each time you log in or perform account-related
            activities that require verification. If you have any questions
            about your text plan or data plan, it is best to contact your
            wireless provider.
          </li>
          <li style={styles.termsItem}>
            Privacy Policy states that no mobile information will be shared with
            third parties/affiliates for marketing/promotional purposes.
          </li>
        </ol>
      </Dialog>
      <div style={styles.phoneSetUpButtons}>
        {/* <Button
          disabled={loading}
          style={{ width: '100%' }}
          onClick={handleSkipForNow}
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
        >
          Setup Later
        </Button> */}
        <Button
          disabled={loading || !termsAgreedChecked}
          onClick={handleRequestPhoneNumber}
          style={{ width: '100%', marginLeft: '12px' }}
        >
          Proceed
        </Button>
      </div>
    </Dialog>
  )
}

TwoFactorPhoneSetup.propTypes = {
  setIsPinSetup: PropTypes.func.isRequired,
  setIsPhoneSetup: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setChangePhoneNumberToken: PropTypes.func.isRequired,
  newPhoneNumber: PropTypes.string.isRequired,
  setNewPhoneNumber: PropTypes.func.isRequired,
  currentPhoneNumber: PropTypes.string,
  changePhoneNumberToken: PropTypes.string,
}
