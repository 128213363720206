export const CLAIMS_PREFIX = 'e'

export const CLAIMS = {
  CAN_SEE_ALL_LOAN_PRODUCTS_IN_CALCULATOR: `${CLAIMS_PREFIX}.1`,
  CAN_VIEW_NOTIFICATIONS: `${CLAIMS_PREFIX}.2`,
  CAN_VIEW_LOANS_DASHBOARD: `${CLAIMS_PREFIX}.3`,
  CAN_VIEW_SALES_TOOLBOX: `${CLAIMS_PREFIX}.4`,
  CAN_GRANT_FUNDS_BULK: `${CLAIMS_PREFIX}.5`,

  // Loan applications - General
  CAN_VIEW_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.6`,
  CAN_VIEW_OWN_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.7`,
  CAN_VIEW_OWN_LENDER_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.8`,
  CAN_VIEW_ORG_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.9`,
  CAN_VIEW_SUB_ORGS_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.10`,
  CAN_VIEW_TEST_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.11`,

  // Loan Application page - General
  CAN_CREATE_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.12`,
  CAN_UPDATE_SUMMARY_DATA: `${CLAIMS_PREFIX}.13`,
  CAN_CHANGE_DEALERS: `${CLAIMS_PREFIX}.14`,
  CAN_EDIT_LOAN_APPLICATION_STATUS: `${CLAIMS_PREFIX}.15`,
  CAN_VIEW_ECG_TOOLBOX: `${CLAIMS_PREFIX}.16`,
  CAN_CREATE_LOAN_APPLICATION_NOTES: `${CLAIMS_PREFIX}.20`,
  CAN_EDIT_LOAN_APPLICATION_NOTES: `${CLAIMS_PREFIX}.21`,
  CAN_REMOVE_LOAN_APPLICATION_NOTES: `${CLAIMS_PREFIX}.22`,
  CAN_DOWNLOAD_CREDIT_REPORTS: `${CLAIMS_PREFIX}.26`,
  CAN_DOWNLOAD_EXPIRED_CREDIT_REPORTS: `${CLAIMS_PREFIX}.27`,
  CAN_REGENERATE_CREDIT_REPORTS: `${CLAIMS_PREFIX}.28`,
  CAN_RERUN_CREDIT_REPORTS: `${CLAIMS_PREFIX}.29`,
  CAN_VIEW_TU_ERROR_DESCRIPTION: `${CLAIMS_PREFIX}.33`,
  CAN_REQUEST_NTP_CHANGE_ORDER: `${CLAIMS_PREFIX}.35`,
  CAN_EDIT_ACTUAL_DTI: `${CLAIMS_PREFIX}.113`,

  // Loan Application page - info
  CAN_EDIT_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.36`,
  CAN_VIEW_ADDITIONAL_LOAN_APPLICATION_FIELDS: `${CLAIMS_PREFIX}.37`,
  CAN_DECRYPT_SENSITIVE_DATA: `${CLAIMS_PREFIX}.38`,
  CAN_BYPASS_ADDRESS_VALIDATION: `${CLAIMS_PREFIX}.39`,
  CAN_EDIT_UW_FIELDS_IN_ANY_STATE: `${CLAIMS_PREFIX}.40`,
  CAN_UPDATE_ONLY_UW_FIELDS: `${CLAIMS_PREFIX}.41`,
  CAN_UPDATE_APPLICATION_FIRST_STEP_AFTER_IF_REQUESTED: `${CLAIMS_PREFIX}.118`,

  // Loan Application page - evaluation
  CAN_SELECT_LOAN_PRODUCT: `${CLAIMS_PREFIX}.42`,
  CAN_VIEW_DETAILED_EVALUATION: `${CLAIMS_PREFIX}.43`,
  CAN_SEE_FAILED_EVALUATIONS: `${CLAIMS_PREFIX}.44`,
  CAN_OVERRIDE_EVALUATIONS: `${CLAIMS_PREFIX}.45`,
  CAN_SEE_OVERRIDDEN_EVALUATION: `${CLAIMS_PREFIX}.46`,
  CAN_EVALUATE_DIFFERENT_LOAN: `${CLAIMS_PREFIX}.47`,
  CAN_VIEW_LENDER_EVALUATED_LOAN_PRODUCTS: `${CLAIMS_PREFIX}.48`,
  CAN_VIEW_EVALUATION_HISTORY: `${CLAIMS_PREFIX}.49`,
  CAN_VIEW_DTI: `${CLAIMS_PREFIX}.51`,
  CAN_REPULL_RE_EVALUATE: `${CLAIMS_PREFIX}.165`,

  // Loan Application page - agreements
  CAN_ACCEPT_AGREEMENTS: `${CLAIMS_PREFIX}.52`,
  CAN_INSERT_ACH: `${CLAIMS_PREFIX}.53`,
  CAN_CHANGE_ACH: `${CLAIMS_PREFIX}.54`,
  CAN_REVIEW_ACH: `${CLAIMS_PREFIX}.55`,
  CAN_OPT_IN_ACH: `${CLAIMS_PREFIX}.56`,
  CAN_DECRYPT_ACH_SENSITIVE_DATA: `${CLAIMS_PREFIX}.57`,
  CAN_EDIT_ACH_UNTIL_NTP_FUNDS_REQUESTED: `${CLAIMS_PREFIX}.164`,

  // Loan Application page - documents
  CAN_GENERATE_DOCUMENTS: `${CLAIMS_PREFIX}.58`,
  CAN_SEND_DOCUMENTS: `${CLAIMS_PREFIX}.59`,
  CAN_VIEW_DOCUMENTS: `${CLAIMS_PREFIX}.60`,
  CAN_SIGN_DOCUMENTS: `${CLAIMS_PREFIX}.61`,
  CAN_VOID_DOCUMENTS: `${CLAIMS_PREFIX}.62`,
  CAN_DOWNLOAD_ALL_DOCUMENTS: `${CLAIMS_PREFIX}.63`,
  CAN_RE_ATTACH_DOCUMENTS: `${CLAIMS_PREFIX}.64`,

  // Loan Application page - ntps
  CAN_ADD_STIPULATIONS: `${CLAIMS_PREFIX}.65`,
  CAN_UPLOAD_STIPULATIONS: `${CLAIMS_PREFIX}.66`,
  CAN_DELETE_STIPULATIONS: `${CLAIMS_PREFIX}.67`,
  CAN_DELETE_NTPS: `${CLAIMS_PREFIX}.68`,
  CAN_DELETE_NTPS_AFTER_SUBMIT_FOR_APPROVAL: `${CLAIMS_PREFIX}.69`,
  CAN_MARK_NTPS_FOR_REVIEW: `${CLAIMS_PREFIX}.70`,
  CAN_DOWNLOAD_NTPS: `${CLAIMS_PREFIX}.71`,
  CAN_DOWNLOAD_DELETED_NTPS: `${CLAIMS_PREFIX}.112`,
  CAN_USE_ENHANCED_IF: `${CLAIMS_PREFIX}.72`,
  CAN_VIEW_NTP_COMMENTS: `${CLAIMS_PREFIX}.73`,
  CAN_COMMENT_NTP: `${CLAIMS_PREFIX}.74`,
  CAN_REMOVE_NTP_COMMENTS: `${CLAIMS_PREFIX}.75`,
  CAN_SUBMIT_NTPS: `${CLAIMS_PREFIX}.76`,
  CAN_APPROVE_NTPS: `${CLAIMS_PREFIX}.77`,
  CAN_REQUEST_FUNDS: `${CLAIMS_PREFIX}.78`,
  CAN_GRANT_FUNDS: `${CLAIMS_PREFIX}.79`,
  CAN_UPLOAD_ID_STIPULATIONS: `${CLAIMS_PREFIX}.111`,
  CAN_UPDATE_USER_NTP_STATUS: `${CLAIMS_PREFIX}.142`,

  // Loan Application page - Final Inspection
  CAN_VIEW_INSPECTION_COMMENTS: `${CLAIMS_PREFIX}.149`,
  CAN_COMMENT_INSPECTION: `${CLAIMS_PREFIX}.150`,
  CAN_REMOVE_INSPECTION_COMMENTS: `${CLAIMS_PREFIX}.151`,
  CAN_UPLOAD_INSPECTION_ATTACHMENTS: `${CLAIMS_PREFIX}.152`,
  CAN_DELETE_INSPECTION_ATTACHMENTS: `${CLAIMS_PREFIX}.153`,
  CAN_DELETE_INSPECTION_ATTACHMENTS_AFTER_SUBMIT_FOR_APPROVAL: `${CLAIMS_PREFIX}.154`,
  CAN_DOWNLOAD_INSPECTION_ATTACHMENTS: `${CLAIMS_PREFIX}.155`,
  CAN_SUBMIT_INSPECTION: `${CLAIMS_PREFIX}.156`,
  CAN_APPROVE_INSPECTION: `${CLAIMS_PREFIX}.157`,
  CAN_REQUEST_INSPECTION_FUNDS: `${CLAIMS_PREFIX}.158`,
  CAN_GRANT_INSPECTION_FUNDS: `${CLAIMS_PREFIX}.159`,
  CAN_UPDATE_INSPECTION_ATTACHMENT_STATUS: `${CLAIMS_PREFIX}.160`,
  CAN_REQUEST_PTO_FUNDS: `${CLAIMS_PREFIX}.161`,
  CAN_GRANT_PTO_FUNDS: `${CLAIMS_PREFIX}.162`,
  CAN_EDIT_LOAN_APPLICATION_SALES_REPRESENTATIVE: `${CLAIMS_PREFIX}.163`,

  // Loan Application page - PTO
  CAN_SUBMIT_PTO: `${CLAIMS_PREFIX}.120`,
  CAN_APPROVE_PTO: `${CLAIMS_PREFIX}.121`,
  CAN_DECLINE_PTO: `${CLAIMS_PREFIX}.122`,
  CAN_DELETE_PTO_ATTACHMENTS: `${CLAIMS_PREFIX}.125`,

  // Admin
  CAN_EDIT_ADMIN_DOCUMENTS: `${CLAIMS_PREFIX}.80`,
  CAN_VIEW_ADMIN_DOCUMENTS: `${CLAIMS_PREFIX}.81`,
  CAN_EDIT_ADMIN_DECISIONING: `${CLAIMS_PREFIX}.82`,
  CAN_VIEW_ADMIN_DECISIONING: `${CLAIMS_PREFIX}.83`,
  CAN_VIEW_LOAN_PRODUCTS: `${CLAIMS_PREFIX}.84`,
  CAN_VIEW_LOAN_PRODUCTS_PAGE: `${CLAIMS_PREFIX}.119`,
  CAN_EDIT_LOAN_PRODUCTS: `${CLAIMS_PREFIX}.85`,
  CAN_MANAGE_SYSTEM_NTPS: `${CLAIMS_PREFIX}.86`,
  CAN_VIEW_OWN_LENDER: `${CLAIMS_PREFIX}.87`,
  CAN_VIEW_LENDERS: `${CLAIMS_PREFIX}.88`,
  CAN_VIEW_OWN_ORGANIZATION: `${CLAIMS_PREFIX}.89`,
  CAN_VIEW_ORGANIZATIONS: `${CLAIMS_PREFIX}.90`,
  CAN_VIEW_CORPORATE_ORGANIZATIONS: `${CLAIMS_PREFIX}.91`,
  CAN_VIEW_SALES_ORGANIZATIONS: `${CLAIMS_PREFIX}.92`,
  CAN_VIEW_LENDER_ORGANIZATIONS: `${CLAIMS_PREFIX}.93`,
  CAN_EDIT_ORGANIZATIONS: `${CLAIMS_PREFIX}.94`,
  CAN_EDIT_CORPORATE_ORGANIZATIONS: `${CLAIMS_PREFIX}.95`,
  CAN_EDIT_SALES_ORGANIZATIONS: `${CLAIMS_PREFIX}.96`,
  CAN_EDIT_LENDER_ORGANIZATIONS: `${CLAIMS_PREFIX}.97`,
  CAN_DELETE_ORGANIZATIONS: `${CLAIMS_PREFIX}.98`,
  CAN_DECRYPT_ORGANIZATIONS_SENSITIVE_DATA: `${CLAIMS_PREFIX}.99`,
  CAN_CREATE_ORGANIZATIONS: `${CLAIMS_PREFIX}.100`,
  CAN_VIEW_SALES_USERS: `${CLAIMS_PREFIX}.101`,
  CAN_VIEW_LENDING_USERS: `${CLAIMS_PREFIX}.102`,
  CAN_EDIT_SALES_USERS: `${CLAIMS_PREFIX}.103`,
  CAN_EDIT_LENDING_USERS: `${CLAIMS_PREFIX}.104`,
  CAN_DELETE_SALES_USERS: `${CLAIMS_PREFIX}.105`,
  CAN_DELETE_LENDING_USERS: `${CLAIMS_PREFIX}.106`,
  CAN_VIEW_OUTAGES: `${CLAIMS_PREFIX}.107`,
  CAN_EDIT_INSTALLERS_DEALERS_ASSOCIATIONS: `${CLAIMS_PREFIX}.133`,
  CAN_EDIT_INSTALLERS_LOAN_PRODUCTS_ASSOCIATIONS: `${CLAIMS_PREFIX}.134`,
  CAN_VIEW_INSTALLERS_DEALERS_LOAN_PRODUCT_STATES_ASSOCIATIONS: `${CLAIMS_PREFIX}.135`,
  CAN_VIEW_USERS_FROM_CURRENT_AND_SUB_ORGANIZATIONS: `${CLAIMS_PREFIX}.145`,
  CAN_VIEW_CURRENT_AND_SUB_ORGANIZATIONS: `${CLAIMS_PREFIX}.146`,
  CAN_VIEW_SALES_ROLES: `${CLAIMS_PREFIX}.147`,

  // ECG Toolbox
  CAN_VIEW_APPLICANT_INFO_FROM_ECG_TOOLBOX: `${CLAIMS_PREFIX}.32`,
  CAN_VIEW_CREDIT_REPORTS: `${CLAIMS_PREFIX}.25`,
  CAN_VIEW_IDENTITY_INFORMATION: `${CLAIMS_PREFIX}.17`,
  CAN_VIEW_LOAN_APPLICATION_NOTES: `${CLAIMS_PREFIX}.19`,
  CAN_VIEW_LOAN_APPLICATION_HISTORY: `${CLAIMS_PREFIX}.23`,
  CAN_DOWNLOAD_ALL_ATTACHMENTS: `${CLAIMS_PREFIX}.31`,
  CAN_DOWNLOAD_ALL_REMOVED_ATTACHMENTS: `${CLAIMS_PREFIX}.148`,
  CAN_VIEW_PROPERTY_TITLE: `${CLAIMS_PREFIX}.18`,
  CAN_VIEW_INCOME_ESTIMATOR: `${CLAIMS_PREFIX}.30`,
  CAN_VIEW_LOAN_APPLICATION_EMAILS_HISTORY: `${CLAIMS_PREFIX}.24`,
  CAN_RE_EVALUATE_ECG_TOOLBOX: `${CLAIMS_PREFIX}.50`,
  CAN_VIEW_UNDERWRITING_CHECKLIST: `${CLAIMS_PREFIX}.108`,
  CAN_VIEW_LOAN_FUNDING: `${CLAIMS_PREFIX}.34`,
  CAN_EDIT_LOAN_FUNDING: `${CLAIMS_PREFIX}.109`,
  CAN_EXTEND_LOAN_APPLICATION_EXPIRATION_DATE: `${CLAIMS_PREFIX}.123`,
  CAN_EXPIRE_LOAN_APPLICATION: `${CLAIMS_PREFIX}.126`,
  CAN_VIEW_TOOLBOX_ACTIONS: `${CLAIMS_PREFIX}.128`,
  CAN_VIEW_UW_HUB: `${CLAIMS_PREFIX}.129`,
  CAN_VIEW_ECG_TOOLBOX_LOAN_CALCULATOR: `${CLAIMS_PREFIX}.136`,
  CAN_EDIT_APPLICANT_EMAIL: `${CLAIMS_PREFIX}.144`,

  // Dashboard
  CAN_VIEW_DEALER_DASHBOARD: `${CLAIMS_PREFIX}.130`,
  CAN_VIEW_DEALER_DASHBOARD_ADMIN: `${CLAIMS_PREFIX}.131`,

  // API Keys
  CAN_VIEW_API_KEY: `${CLAIMS_PREFIX}.138`,
  CAN_GENERATE_API_KEY: `${CLAIMS_PREFIX}.139`,
  CAN_DELETE_API_KEY: `${CLAIMS_PREFIX}.140`,

  // Common
  CAN_DO_EVERYTHING: `${CLAIMS_PREFIX}.can_do_everything`,
  CAN_VIEW_USERS: `${CLAIMS_PREFIX}.can_view_users`,
  CAN_ADD_USERS: `${CLAIMS_PREFIX}.can_add_users`,
  CAN_EDIT_USERS: `${CLAIMS_PREFIX}.can_edit_users`,
  CAN_EDIT_OWN_USER: `${CLAIMS_PREFIX}.can_edit_own_user`,
  CAN_DELETE_USERS: `${CLAIMS_PREFIX}.can_delete_users`,
  CAN_VIEW_ROLES: `${CLAIMS_PREFIX}.can_view_roles`,
  CAN_EDIT_ROLES: `${CLAIMS_PREFIX}.can_edit_roles`,
  CAN_FUND_LOAN_APPLICATIONS: `${CLAIMS_PREFIX}.132`,
}

export const VIEW_LOAN_APP = [
  CLAIMS.CAN_VIEW_OWN_LOAN_APPLICATIONS,
  CLAIMS.CAN_VIEW_OWN_LENDER_LOAN_APPLICATIONS,
  CLAIMS.CAN_VIEW_ORG_LOAN_APPLICATIONS,
  CLAIMS.CAN_VIEW_LOAN_APPLICATIONS,
]

export const GROUPED_CLAIMS = [
  {
    section: 'General',
    actions: [
      {
        action: 'View',
        claims: [
          {
            claim: 'Notifications',
            claimName: 'CAN_VIEW_NOTIFICATIONS',
            claimCode: `${CLAIMS_PREFIX}.2`,
          },
          {
            claim: 'Loans Dashboard',
            claimName: 'CAN_VIEW_LOANS_DASHBOARD',
            claimCode: `${CLAIMS_PREFIX}.3`,
          },
          {
            claim: 'Sales Toolbox',
            claimName: 'CAN_VIEW_SALES_TOOLBOX',
            claimCode: `${CLAIMS_PREFIX}.4`,
          },
          {
            claim: 'All Loan Products In Calculator',
            claimName: 'CAN_SEE_ALL_LOAN_PRODUCTS_IN_CALCULATOR',
            claimCode: `${CLAIMS_PREFIX}.1`,
          },
          {
            claim: 'Commercial Loan Applications',
            claimName: 'CAN_VIEW_COMMERCIAL_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.115`,
          },
          {
            claim: 'Funding Info in CSV',
            claimName: 'CAN_VIEW_FUNDING_INFO_IN_CSV_EXPORT',
            claimCode: `${CLAIMS_PREFIX}.110`,
          },
          {
            claim: 'Dealer Dashboard',
            claimName: 'CAN_VIEW_DEALER_DASHBOARD',
            claimCode: `${CLAIMS_PREFIX}.130`,
          },
          {
            claim: 'Dealer Dashboard Admin',
            claimName: 'CAN_VIEW_DEALER_DASHBOARD_ADMIN',
            claimCode: `${CLAIMS_PREFIX}.131`,
          },
          {
            claim: 'Funding',
            claimName: 'CAN_FUND_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.132`,
          },
        ],
      },
      {
        action: 'Grant',
        claims: [
          {
            claim: 'Funds Bulk',
            claimName: 'CAN_GRANT_FUNDS_BULK',
            claimCode: `${CLAIMS_PREFIX}.5`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Applications - General',
    actions: [
      {
        action: 'View',
        claims: [
          {
            claim: 'Own Loan Applications',
            claimName: 'CAN_VIEW_OWN_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.7`,
          },
          {
            claim: 'Own Lender Loan Applications',
            claimName: 'CAN_VIEW_OWN_LENDER_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.8`,
          },
          {
            claim: 'Org Loan Applications',
            claimName: 'CAN_VIEW_ORG_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.9`,
          },
          {
            claim: 'Sub Organizations Loan Applications',
            claimName: 'CAN_VIEW_SUB_ORGS_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.10`,
          },
          {
            claim: 'Test Loan Applications',
            claimName: 'CAN_VIEW_TEST_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.11`,
          },
          {
            claim: 'ECG Toolbox',
            claimName: 'CAN_VIEW_ECG_TOOLBOX',
            claimCode: `${CLAIMS_PREFIX}.16`,
          },
          {
            claim: 'TU Error Description',
            claimName: 'CAN_VIEW_TU_ERROR_DESCRIPTION',
            claimCode: `${CLAIMS_PREFIX}.33`,
          },
        ],
      },
      {
        action: 'Create',
        claims: [
          {
            claim: 'Loan Applications',
            claimName: 'CAN_CREATE_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.12`,
          },
          {
            claim: 'Loan Application Notes',
            claimName: 'CAN_CREATE_LOAN_APPLICATION_NOTES',
            claimCode: `${CLAIMS_PREFIX}.20`,
          },
        ],
      },
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Loan Application Status',
            claimName: 'CAN_EDIT_LOAN_APPLICATION_STATUS',
            claimCode: `${CLAIMS_PREFIX}.15`,
          },
          {
            claim: 'Loan Application Notes',
            claimName: 'CAN_EDIT_LOAN_APPLICATION_NOTES',
            claimCode: `${CLAIMS_PREFIX}.21`,
          },
          {
            claim: 'Actual DTI',
            claimName: 'CAN_EDIT_ACTUAL_DTI',
            claimCode: `${CLAIMS_PREFIX}.113`,
          },
          {
            claim: 'Regenerate Credit Reports',
            claimName: 'CAN_REGENERATE_CREDIT_REPORTS',
            claimCode: `${CLAIMS_PREFIX}.28`,
          },
          {
            claim: 'Rerun Credit Reports',
            claimName: 'CAN_RERUN_CREDIT_REPORTS',
            claimCode: `${CLAIMS_PREFIX}.29`,
          },
          {
            claim: 'Dealers',
            claimName: 'CAN_CHANGE_DEALERS',
            claimCode: `${CLAIMS_PREFIX}.14`,
          },
          {
            claim: 'Summary Data',
            claimName: 'CAN_UPDATE_SUMMARY_DATA',
            claimCode: `${CLAIMS_PREFIX}.13`,
          },
          {
            claim: 'Pull Credit Reports with Errors',
            claimName: 'CAN_PULL_CREDIT_REPORTS_WITH_ERRORS_ON_SUBMIT',
            claimCode: `${CLAIMS_PREFIX}.127`,
          },
          {
            claim: 'Skip Address Override from Property Title',
            claimName: 'CAN_SKIP_ADDRESS_OVERRIDE_FROM_PROPERTY_TITLE',
            claimCode: `${CLAIMS_PREFIX}.117`,
          },
        ],
      },
      {
        action: 'Remove',
        claims: [
          {
            claim: 'Loan Application Notes',
            claimName: 'CAN_REMOVE_LOAN_APPLICATION_NOTES',
            claimCode: `${CLAIMS_PREFIX}.22`,
          },
        ],
      },
      {
        action: 'Download',
        claims: [
          {
            claim: 'Credit Reports',
            claimName: 'CAN_DOWNLOAD_CREDIT_REPORTS',
            claimCode: `${CLAIMS_PREFIX}.26`,
          },
          {
            claim: 'Expired Credit Reports',
            claimName: 'CAN_DOWNLOAD_EXPIRED_CREDIT_REPORTS',
            claimCode: `${CLAIMS_PREFIX}.27`,
          },
        ],
      },
      {
        action: 'Request',
        claims: [
          {
            claim: 'NTP Change Order',
            claimName: 'CAN_REQUEST_NTP_CHANGE_ORDER',
            claimCode: `${CLAIMS_PREFIX}.35`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Application page - Info',
    actions: [
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Loan Applications',
            claimName: 'CAN_EDIT_LOAN_APPLICATIONS',
            claimCode: `${CLAIMS_PREFIX}.36`,
          },
          {
            claim: 'Bypass Address Validation',
            claimName: 'CAN_BYPASS_ADDRESS_VALIDATION',
            claimCode: `${CLAIMS_PREFIX}.39`,
          },
          {
            claim: 'Edit UW Fields in Any State',
            claimName: 'CAN_EDIT_UW_FIELDS_IN_ANY_STATE',
            claimCode: `${CLAIMS_PREFIX}.40`,
          },
          {
            claim: 'Update Only UW Fields',
            claimName: 'CAN_UPDATE_ONLY_UW_FIELDS',
            claimCode: `${CLAIMS_PREFIX}.41`,
          },
        ],
      },
      {
        action: 'View',
        claims: [
          {
            claim: 'Loan Application UW Hub',
            claimName: 'CAN_VIEW_UW_HUB',
            claimCode: `${CLAIMS_PREFIX}.129`,
          },
          {
            claim: 'Additional Loan Application Fields',
            claimName: 'CAN_VIEW_ADDITIONAL_LOAN_APPLICATION_FIELDS',
            claimCode: `${CLAIMS_PREFIX}.37`,
          },
          {
            claim: 'Decrypt Sensitive Data',
            claimName: 'CAN_DECRYPT_SENSITIVE_DATA',
            claimCode: `${CLAIMS_PREFIX}.38`,
          },
          {
            claim: 'Update Application First Step After If Requested',
            claimName: 'CAN_UPDATE_APPLICATION_FIRST_STEP_AFTER_IF_REQUESTED',
            claimCode: `${CLAIMS_PREFIX}.118`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Application page - Evaluation',
    actions: [
      {
        action: 'Select',
        claims: [
          {
            claim: 'Loan Product',
            claimName: 'CAN_SELECT_LOAN_PRODUCT',
            claimCode: `${CLAIMS_PREFIX}.42`,
          },
        ],
      },
      {
        action: 'View',
        claims: [
          {
            claim: 'Detailed Evaluation',
            claimName: 'CAN_VIEW_DETAILED_EVALUATION',
            claimCode: `${CLAIMS_PREFIX}.43`,
          },
          {
            claim: 'Failed Evaluations',
            claimName: 'CAN_SEE_FAILED_EVALUATIONS',
            claimCode: `${CLAIMS_PREFIX}.44`,
          },
          {
            claim: 'Overridden Evaluation',
            claimName: 'CAN_SEE_OVERRIDDEN_EVALUATION',
            claimCode: `${CLAIMS_PREFIX}.46`,
          },
          {
            claim: 'Lender Evaluated Loan Products',
            claimName: 'CAN_VIEW_LENDER_EVALUATED_LOAN_PRODUCTS',
            claimCode: `${CLAIMS_PREFIX}.48`,
          },
          {
            claim: 'Evaluation History',
            claimName: 'CAN_VIEW_EVALUATION_HISTORY',
            claimCode: `${CLAIMS_PREFIX}.49`,
          },
          {
            claim: 'DTI',
            claimName: 'CAN_VIEW_DTI',
            claimCode: `${CLAIMS_PREFIX}.51`,
          },
        ],
      },
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Override Evaluations',
            claimName: 'CAN_OVERRIDE_EVALUATIONS',
            claimCode: `${CLAIMS_PREFIX}.45`,
          },
          {
            claim: 'Evaluate Different Loan',
            claimName: 'CAN_EVALUATE_DIFFERENT_LOAN',
            claimCode: `${CLAIMS_PREFIX}.47`,
          },
        ],
      },
      {
        action: 'Re-pull',
        claims: [
          {
            claim: 'Can re-pull & re-evaluate',
            claimName: 'CAN_REPULL_RE_EVALUATE',
            claimCode: `${CLAIMS_PREFIX}.165`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Application page - Agreements',
    actions: [
      {
        action: 'Accept',
        claims: [
          {
            claim: 'Agreements',
            claimName: 'CAN_ACCEPT_AGREEMENTS',
            claimCode: `${CLAIMS_PREFIX}.52`,
          },
        ],
      },
      {
        action: 'Insert',
        claims: [
          {
            claim: 'ACH',
            claimName: 'CAN_INSERT_ACH',
            claimCode: `${CLAIMS_PREFIX}.53`,
          },
        ],
      },
      {
        action: 'Change',
        claims: [
          {
            claim: 'ACH',
            claimName: 'CAN_CHANGE_ACH',
            claimCode: `${CLAIMS_PREFIX}.54`,
          },
          {
            claim: 'Can Edit ACH until NTP Funds Requested',
            claimName: 'CAN_EDIT_ACH_UNTIL_NTP_FUNDS_REQUESTED',
            claimCode: `${CLAIMS_PREFIX}.164`,
          },
        ],
      },
      {
        action: 'Review',
        claims: [
          {
            claim: 'ACH',
            claimName: 'CAN_REVIEW_ACH',
            claimCode: `${CLAIMS_PREFIX}.55`,
          },
        ],
      },
      {
        action: 'Opt-in',
        claims: [
          {
            claim: 'ACH',
            claimName: 'CAN_OPT_IN_ACH',
            claimCode: `${CLAIMS_PREFIX}.56`,
          },
        ],
      },
      {
        action: 'Decrypt',
        claims: [
          {
            claim: 'ACH Sensitive Data',
            claimName: 'CAN_DECRYPT_ACH_SENSITIVE_DATA',
            claimCode: `${CLAIMS_PREFIX}.57`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Application page - Documents',
    actions: [
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Generate Documents',
            claimName: 'CAN_GENERATE_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.58`,
          },
          {
            claim: 'Send Documents',
            claimName: 'CAN_SEND_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.59`,
          },
          {
            claim: 'Sign Documents',
            claimName: 'CAN_SIGN_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.61`,
          },
          {
            claim: 'Void Documents',
            claimName: 'CAN_VOID_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.62`,
          },
          {
            claim: 'Download All Documents',
            claimName: 'CAN_DOWNLOAD_ALL_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.63`,
          },
          {
            claim: 'Re-attach Documents',
            claimName: 'CAN_RE_ATTACH_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.64`,
          },
        ],
      },
      {
        action: 'View',
        claims: [
          {
            claim: 'Documents',
            claimName: 'CAN_VIEW_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.60`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Application page - Stipulations',
    actions: [
      {
        action: 'Upload',
        claims: [
          {
            claim: 'Stipulations',
            claimName: 'CAN_UPLOAD_STIPULATIONS',
            claimCode: `${CLAIMS_PREFIX}.66`,
          },
          {
            claim: 'ID Stipulations',
            claimName: 'CAN_UPLOAD_ID_STIPULATIONS',
            claimCode: `${CLAIMS_PREFIX}.111`,
          },
        ],
      },
      {
        action: 'Delete',
        claims: [
          {
            claim: 'Stipulations',
            claimName: 'CAN_DELETE_STIPULATIONS',
            claimCode: `${CLAIMS_PREFIX}.67`,
          },
          {
            claim: 'NTPs',
            claimName: 'CAN_DELETE_NTPS',
            claimCode: `${CLAIMS_PREFIX}.68`,
          },
          {
            claim: 'NTPs After Submit',
            claimName: 'CAN_DELETE_NTPS_AFTER_SUBMIT_FOR_APPROVAL',
            claimCode: `${CLAIMS_PREFIX}.69`,
          },
          {
            claim: 'NTP Comments',
            claimName: 'CAN_REMOVE_NTP_COMMENTS',
            claimCode: `${CLAIMS_PREFIX}.75`,
          },
        ],
      },
      {
        action: 'Download',
        claims: [
          {
            claim: 'NTPs',
            claimName: 'CAN_DOWNLOAD_NTPS',
            claimCode: `${CLAIMS_PREFIX}.71`,
          },
          {
            claim: 'Deleted NTPs',
            claimName: 'CAN_DOWNLOAD_DELETED_NTPS',
            claimCode: `${CLAIMS_PREFIX}.112`,
          },
        ],
      },
      {
        action: 'View',
        claims: [
          {
            claim: 'NTP Comments',
            claimName: 'CAN_VIEW_NTP_COMMENTS',
            claimCode: `${CLAIMS_PREFIX}.73`,
          },
        ],
      },
      {
        action: 'Edit',
        claims: [
          {
            claim: 'User NTP Status',
            claimName: 'CAN_UPDATE_USER_NTP_STATUS',
            claimCode: `${CLAIMS_PREFIX}.142`,
          },
          {
            claim: 'Approve NTPs',
            claimName: 'CAN_APPROVE_NTPS',
            claimCode: `${CLAIMS_PREFIX}.77`,
          },
          {
            claim: 'Submit NTPs',
            claimName: 'CAN_SUBMIT_NTPS',
            claimCode: `${CLAIMS_PREFIX}.76`,
          },
          {
            claim: 'Comment NTP',
            claimName: 'CAN_COMMENT_NTP',
            claimCode: `${CLAIMS_PREFIX}.74`,
          },
          {
            claim: 'Mark NTPs for Review',
            claimName: 'CAN_MARK_NTPS_FOR_REVIEW',
            claimCode: `${CLAIMS_PREFIX}.70`,
          },
          {
            claim: 'Add Stipulations',
            claimName: 'CAN_ADD_STIPULATIONS',
            claimCode: `${CLAIMS_PREFIX}.65`,
          },
          {
            claim: 'Create ID Verification Session',
            claimName: 'CAN_CREATE_ID_VERIFICATION_SESSION',
            claimCode: `${CLAIMS_PREFIX}.114`,
          },
        ],
      },
      {
        action: 'Funding',
        claims: [
          {
            claim: 'Request Funds',
            claimName: 'CAN_REQUEST_FUNDS',
            claimCode: `${CLAIMS_PREFIX}.78`,
          },
          {
            claim: 'Grant Funds',
            claimName: 'CAN_GRANT_FUNDS',
            claimCode: `${CLAIMS_PREFIX}.79`,
          },
          {
            claim: 'Use Enhanced IF',
            claimName: 'CAN_USE_ENHANCED_IF',
            claimCode: `${CLAIMS_PREFIX}.72`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Application page - Final Inspection',
    actions: [
      {
        action: 'Upload',
        claims: [
          {
            claim: 'Final Inspection Documents',
            claimName: 'CAN_UPLOAD_INSPECTION_ATTACHMENTS',
            claimCode: `${CLAIMS_PREFIX}.152`,
          },
        ],
      },
      {
        action: 'Delete',
        claims: [
          {
            claim: 'Inspection Attachments',
            claimName: 'CAN_DELETE_INSPECTION_ATTACHMENTS',
            claimCode: `${CLAIMS_PREFIX}.153`,
          },
          {
            claim: 'Inspection Attachments after submit',
            claimName:
              'CAN_DELETE_INSPECTION_ATTACHMENTS_AFTER_SUBMIT_FOR_APPROVAL',
            claimCode: `${CLAIMS_PREFIX}.154`,
          },
          {
            claim: 'Inspection Comments',
            claimName: 'CAN_REMOVE_INSPECTION_COMMENTS',
            claimCode: `${CLAIMS_PREFIX}.151`,
          },
        ],
      },
      {
        action: 'Download',
        claims: [
          {
            claim: 'Inspection Attachments',
            claimName: 'CAN_DOWNLOAD_INSPECTION_ATTACHMENTS',
            claimCode: `${CLAIMS_PREFIX}.155`,
          },
        ],
      },
      {
        action: 'View',
        claims: [
          {
            claim: 'Inspection Comments',
            claimName: 'CAN_VIEW_INSPECTION_COMMENTS',
            claimCode: `${CLAIMS_PREFIX}.149`,
          },
        ],
      },
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Inspection Attachment Status',
            claimName: 'CAN_UPDATE_INSPECTION_ATTACHMENT_STATUS',
            claimCode: `${CLAIMS_PREFIX}.160`,
          },
          {
            claim: 'Submit Final Inspection',
            claimName: 'CAN_SUBMIT_INSPECTION',
            claimCode: `${CLAIMS_PREFIX}.156`,
          },
          {
            claim: 'Approve Final Inspection',
            claimName: 'CAN_APPROVE_INSPECTION',
            claimCode: `${CLAIMS_PREFIX}.157`,
          },
          {
            claim: 'Comment',
            claimName: 'CAN_COMMENT_INSPECTION',
            claimCode: `${CLAIMS_PREFIX}.150`,
          },
        ],
      },
      {
        action: 'Funding',
        claims: [
          {
            claim: 'Request Final Inspection Funds',
            claimName: 'CAN_REQUEST_INSPECTION_FUNDS',
            claimCode: `${CLAIMS_PREFIX}.158`,
          },
          {
            claim: 'Grant Final Inspection Funds',
            claimName: 'CAN_GRANT_INSPECTION_FUNDS',
            claimCode: `${CLAIMS_PREFIX}.159`,
          },
        ],
      },
    ],
  },
  {
    section: 'Loan Application page - PTO',
    actions: [
      {
        action: 'Submit',
        claims: [
          {
            claim: 'PTO',
            claimName: 'CAN_SUBMIT_PTO',
            claimCode: `${CLAIMS_PREFIX}.120`,
          },
          {
            claim: 'CAN_REQUEST_PTO_FUNDS',
            claimName: 'CAN_REQUEST_PTO_FUNDS',
            claimCode: `${CLAIMS_PREFIX}.161`,
          },
          {
            claim: 'CAN_GRANT_PTO_FUNDS',
            claimName: 'CAN_GRANT_PTO_FUNDS',
            claimCode: `${CLAIMS_PREFIX}.162`,
          },
          {
            claim: 'CAN_EDIT_LOAN_APPLICATION_SALES_REPRESENTATIVE',
            claimName: 'CAN_EDIT_LOAN_APPLICATION_SALES_REPRESENTATIVE',
            claimCode: `${CLAIMS_PREFIX}.163`,
          },
        ],
      },
      {
        action: 'Approve',
        claims: [
          {
            claim: 'PTO',
            claimName: 'CAN_APPROVE_PTO',
            claimCode: `${CLAIMS_PREFIX}.121`,
          },
        ],
      },
      {
        action: 'Decline',
        claims: [
          {
            claim: 'PTO',
            claimName: 'CAN_DECLINE_PTO',
            claimCode: `${CLAIMS_PREFIX}.122`,
          },
        ],
      },
      {
        action: 'Delete',
        claims: [
          {
            claim: 'PTO Attachments',
            claimName: 'CAN_DELETE_PTO_ATTACHMENTS',
            claimCode: `${CLAIMS_PREFIX}.125`,
          },
        ],
      },
    ],
  },
  {
    section: 'Admin',
    actions: [
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Admin Documents',
            claimName: 'CAN_EDIT_ADMIN_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.80`,
          },
          {
            claim: 'Admin Decisioning',
            claimName: 'CAN_EDIT_ADMIN_DECISIONING',
            claimCode: `${CLAIMS_PREFIX}.82`,
          },
          {
            claim: 'Loan Products',
            claimName: 'CAN_EDIT_LOAN_PRODUCTS',
            claimCode: `${CLAIMS_PREFIX}.85`,
          },
          {
            claim: 'Manage System NTPs',
            claimName: 'CAN_MANAGE_SYSTEM_NTPS',
            claimCode: `${CLAIMS_PREFIX}.86`,
          },
        ],
      },
      {
        action: 'View',
        claims: [
          {
            claim: 'Admin Documents',
            claimName: 'CAN_VIEW_ADMIN_DOCUMENTS',
            claimCode: `${CLAIMS_PREFIX}.81`,
          },
          {
            claim: 'Admin Decisioning',
            claimName: 'CAN_VIEW_ADMIN_DECISIONING',
            claimCode: `${CLAIMS_PREFIX}.83`,
          },
          {
            claim: 'Loan Products',
            claimName: 'CAN_VIEW_LOAN_PRODUCTS',
            claimCode: `${CLAIMS_PREFIX}.84`,
          },
          {
            claim: 'Loan Products Page',
            claimName: 'CAN_VIEW_LOAN_PRODUCTS_PAGE',
            claimCode: `${CLAIMS_PREFIX}.119`,
          },
          {
            claim: 'Can View Users Current And Sub Organizations',
            claimName: 'CAN_VIEW_USERS_FROM_CURRENT_AND_SUB_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.145`,
          },
          {
            claim: 'Can View Current And Sub Organizations',
            claimName: 'CAN_VIEW_CURRENT_AND_SUB_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.146`,
          },
        ],
      },
    ],
  },
  {
    section: 'Common',
    actions: [
      {
        action: 'View',
        claims: [
          {
            claim: 'Lenders',
            claimName: 'CAN_VIEW_LENDERS',
            claimCode: `${CLAIMS_PREFIX}.88`,
          },
          {
            claim: 'Own Organization',
            claimName: 'CAN_VIEW_OWN_ORGANIZATION',
            claimCode: `${CLAIMS_PREFIX}.89`,
          },
          {
            claim: 'Organizations',
            claimName: 'CAN_VIEW_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.90`,
          },
          {
            claim: 'Corporate Organizations',
            claimName: 'CAN_VIEW_CORPORATE_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.91`,
          },
          {
            claim: 'Sales Organizations',
            claimName: 'CAN_VIEW_SALES_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.92`,
          },
          {
            claim: 'Lender Organizations',
            claimName: 'CAN_VIEW_LENDER_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.93`,
          },
          {
            claim: 'Own Lender',
            claimName: 'CAN_VIEW_OWN_LENDER',
            claimCode: `${CLAIMS_PREFIX}.87`,
          },
          {
            claim: 'Sales Users',
            claimName: 'CAN_VIEW_SALES_USERS',
            claimCode: `${CLAIMS_PREFIX}.101`,
          },
          {
            claim: 'Lending Users',
            claimName: 'CAN_VIEW_LENDING_USERS',
            claimCode: `${CLAIMS_PREFIX}.102`,
          },
          {
            claim: 'Outages',
            claimName: 'CAN_VIEW_OUTAGES',
            claimCode: `${CLAIMS_PREFIX}.107`,
          },
          {
            claim: 'View Users',
            claimName: 'CAN_VIEW_USERS',
            claimCode: `${CLAIMS_PREFIX}.can_view_users`,
          },
          {
            claim: 'Toolbox Action',
            claimName: 'CAN_VIEW_TOOLBOX_ACTIONS',
            claimCode: `${CLAIMS_PREFIX}.can_view_toolbox_actions`,
          },
          {
            claim: 'View Roles',
            claimName: 'CAN_VIEW_ROLES',
            claimCode: `${CLAIMS_PREFIX}.can_view_roles`,
          },
          {
            claim: 'View Dealers/Installers States & Loans',
            claimName:
              'CAN_VIEW_INSTALLERS_DEALERS_LOAN_PRODUCT_STATES_ASSOCIATIONS',
            claimCode: `${CLAIMS_PREFIX}.135`,
          },
        ],
      },
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Organizations',
            claimName: 'CAN_EDIT_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.94`,
          },
          {
            claim: 'Corporate Organizations',
            claimName: 'CAN_EDIT_CORPORATE_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.95`,
          },
          {
            claim: 'Sales Organizations',
            claimName: 'CAN_EDIT_SALES_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.96`,
          },
          {
            claim: 'Lender Organizations',
            claimName: 'CAN_EDIT_LENDER_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.97`,
          },
          {
            claim: 'Sales Users',
            claimName: 'CAN_EDIT_SALES_USERS',
            claimCode: `${CLAIMS_PREFIX}.103`,
          },
          {
            claim: 'Lending Users',
            claimName: 'CAN_EDIT_LENDING_USERS',
            claimCode: `${CLAIMS_PREFIX}.104`,
          },
          {
            claim: 'Add Users',
            claimName: 'CAN_ADD_USERS',
            claimCode: `${CLAIMS_PREFIX}.can_add_users`,
          },
          {
            claim: 'Edit Users',
            claimName: 'CAN_EDIT_USERS',
            claimCode: `${CLAIMS_PREFIX}.can_edit_users`,
          },
          {
            claim: 'Edit Own User',
            claimName: 'CAN_EDIT_OWN_USER',
            claimCode: `${CLAIMS_PREFIX}.can_edit_own_user`,
          },
          {
            claim: 'Delete Users',
            claimName: 'CAN_DELETE_USERS',
            claimCode: `${CLAIMS_PREFIX}.can_delete_users`,
          },
          {
            claim: 'Edit Roles',
            claimName: 'CAN_EDIT_ROLES',
            claimCode: `${CLAIMS_PREFIX}.can_edit_roles`,
          },
          {
            claim: 'Edit Installers->Dealers Associations',
            claimName: 'CAN_EDIT_INSTALLERS_DEALERS_ASSOCIATIONS',
            claimCode: `${CLAIMS_PREFIX}.133`,
          },
          {
            claim: 'Edit Installers Loan Product Associations',
            claimName: 'CAN_EDIT_INSTALLERS_LOAN_PRODUCTS_ASSOCIATIONS',
            claimCode: `${CLAIMS_PREFIX}.134`,
          },
          {
            claim: 'Can View Sales Roles',
            claimName: 'CAN_VIEW_SALES_ROLES',
            claimCode: `${CLAIMS_PREFIX}.147`,
          },
        ],
      },
      {
        action: 'Delete',
        claims: [
          {
            claim: 'Organizations',
            claimName: 'CAN_DELETE_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.98`,
          },
          {
            claim: 'Sales Users',
            claimName: 'CAN_DELETE_SALES_USERS',
            claimCode: `${CLAIMS_PREFIX}.105`,
          },
          {
            claim: 'Lending Users',
            claimName: 'CAN_DELETE_LENDING_USERS',
            claimCode: `${CLAIMS_PREFIX}.106`,
          },
        ],
      },
      {
        action: 'Decrypt',
        claims: [
          {
            claim: 'Decrypt Organizations',
            claimName: 'CAN_DECRYPT_ORGS_SENSITIVE_DATA',
            claimCode: `${CLAIMS_PREFIX}.99`,
          },
        ],
      },
      {
        action: 'Create',
        claims: [
          {
            claim: 'Organizations',
            claimName: 'CAN_CREATE_ORGANIZATIONS',
            claimCode: `${CLAIMS_PREFIX}.100`,
          },
        ],
      },
      {
        action: 'Do',
        claims: [
          {
            claim: 'Everything',
            claimName: 'CAN_DO_EVERYTHING',
            claimCode: `${CLAIMS_PREFIX}.can_do_everything`,
          },
        ],
      },
    ],
  },
  {
    section: 'API Keys',
    actions: [
      {
        action: 'View',
        claims: [
          {
            claim: 'View API Key',
            claimName: 'CAN_VIEW_API_KEY',
            claimCode: `${CLAIMS_PREFIX}.138`,
          },
        ],
      },
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Generate API Key',
            claimName: 'CAN_GENERATE_API_KEY',
            claimCode: `${CLAIMS_PREFIX}.139`,
          },
        ],
      },
      {
        action: 'Delete',
        claims: [
          {
            claim: 'Delete API Key',
            claimName: 'CAN_DELETE_API_KEY',
            claimCode: `${CLAIMS_PREFIX}.140`,
          },
        ],
      },
    ],
  },
  {
    section: 'ECG Toolbox',
    actions: [
      {
        action: 'Edit',
        claims: [
          {
            claim: 'Re-Evaluate ECG Toolbox',
            claimName: 'CAN_RE_EVALUATE_ECG_TOOLBOX',
            claimCode: `${CLAIMS_PREFIX}.50`,
          },
          {
            claim: 'Loan Funding',
            claimName: 'CAN_EDIT_LOAN_FUNDING',
            claimCode: `${CLAIMS_PREFIX}.109`,
          },
          {
            claim: 'Extend Loan Application Expiration Date',
            claimName: 'CAN_EXTEND_LOAN_APPLICATION_EXPIRATION_DATE',
            claimCode: `${CLAIMS_PREFIX}.123`,
          },
          {
            claim: 'Expire Loan Application',
            claimName: 'CAN_EXPIRE_LOAN_APPLICATION',
            claimCode: `${CLAIMS_PREFIX}.126`,
          },
          {
            claim: 'Loan Payment Due Date',
            claimName: 'CAN_EDIT_LOAN_PAYMENT_DUE_DATE',
            claimCode: `${CLAIMS_PREFIX}.124`,
          },
          {
            claim: 'Edit Applicant Email',
            claimName: 'CAN_EDIT_APPLICANT_EMAIL',
            claimCode: `${CLAIMS_PREFIX}.144`,
          },
        ],
      },
      {
        action: 'View',
        claims: [
          {
            claim: 'Applicant Info from ECG Toolbox',
            claimName: 'CAN_VIEW_APPLICANT_INFO_FROM_ECG_TOOLBOX',
            claimCode: `${CLAIMS_PREFIX}.32`,
          },
          {
            claim: 'Credit Reports',
            claimName: 'CAN_VIEW_CREDIT_REPORTS',
            claimCode: `${CLAIMS_PREFIX}.25`,
          },
          {
            claim: 'Identity Information',
            claimName: 'CAN_VIEW_IDENTITY_INFORMATION',
            claimCode: `${CLAIMS_PREFIX}.17`,
          },
          {
            claim: 'Loan Application Notes',
            claimName: 'CAN_VIEW_LOAN_APPLICATION_NOTES',
            claimCode: `${CLAIMS_PREFIX}.19`,
          },
          {
            claim: 'Loan Application History',
            claimName: 'CAN_VIEW_LOAN_APPLICATION_HISTORY',
            claimCode: `${CLAIMS_PREFIX}.23`,
          },
          {
            claim: 'Download All Attachments',
            claimName: 'CAN_DOWNLOAD_ALL_ATTACHMENTS',
            claimCode: `${CLAIMS_PREFIX}.31`,
          },
          {
            claim: 'Property Title',
            claimName: 'CAN_VIEW_PROPERTY_TITLE',
            claimCode: `${CLAIMS_PREFIX}.18`,
          },
          {
            claim: 'Income Estimator',
            claimName: 'CAN_VIEW_INCOME_ESTIMATOR',
            claimCode: `${CLAIMS_PREFIX}.30`,
          },
          {
            claim: 'Loan Application Emails History',
            claimName: 'CAN_VIEW_LOAN_APPLICATION_EMAILS_HISTORY',
            claimCode: `${CLAIMS_PREFIX}.24`,
          },
          {
            claim: 'Underwriting Checklist',
            claimName: 'CAN_VIEW_UNDERWRITING_CHECKLIST',
            claimCode: `${CLAIMS_PREFIX}.108`,
          },
          {
            claim: 'Loan Funding',
            claimName: 'CAN_VIEW_LOAN_FUNDING',
            claimCode: `${CLAIMS_PREFIX}.34`,
          },
          {
            claim: 'Loan Calculator',
            claimName: 'CAN_VIEW_ECG_TOOLBOX_LOAN_CALCULATOR',
            claimCode: `${CLAIMS_PREFIX}.136`,
          },
          {
            claim: 'Download Removed Attachments',
            claimName: 'CAN_DOWNLOAD_ALL_REMOVED_ATTACHMENTS',
            claimCode: `${CLAIMS_PREFIX}.148`,
          },
        ],
      },
    ],
  },
]
