import React from 'react'
import PropTypes from 'prop-types'
import FileUpload from 'components/file-upload'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { components } from '@ElementsCapitalGroup/enium-ui'

const { Dialog } = components

const WiresCustomUpload = ({ isOpen, onClose, onUpload, dispatch }) => {
  const onFileSelected = (file) => {
    if (file) {
      onUpload(file)
      onClose()
    }
  }

  return (
    <Dialog
      open={isOpen}
      className="afp__modal"
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '500px',
          paddingBottom: '20px',
        },
      }}
    >
      <FileUpload
        onChange={onFileSelected}
        onDropRejected={() => {
          showNotification(dispatch, {
            type: NOTIFICATION_TYPES.NEGATIVE,
            title: 'Invalid file type. Must provide a .xlsx file.',
          })
        }}
        acceptedFileTypes={{ 'application/vnd.ms-excel': ['.xlsx'] }}
        acceptedFileTypesHelperText="XLSX (max. 30MB)"
      />
    </Dialog>
  )
}

WiresCustomUpload.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default WiresCustomUpload
