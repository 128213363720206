import React from 'react'
import PropTypes from 'prop-types'
import Button, { BUTTON_COLORS } from 'components/button'
import cx from 'classnames'

import './index.scss'

const Modal = ({
  isOpen,
  onClose,
  title,
  containerClass,
  className = '',
  children,
  noWidth,
  confirmButton,
  cancelButton,
  onConfirm,
  noMargin,
  isConfirmButtonDisabled,
  buttonEnd,
  loading,
  extraButtons,
}) => {
  const modalContainerClass = cx('modal__container', containerClass, {
    'modal__container--no-padding': noMargin,
    'modal__container--no-width': noWidth,
  })

  const modalContentClass = cx('modal__container-content', {
    'modal__container-content--no-margin': noMargin,
  })
  const buttonsClass = cx('modal__container-buttons', {
    'modal__container-buttons--end': buttonEnd,
  })

  if (!isOpen) {
    return null
  }
  return (
    <>
      <div className={cx('modal', className)}>
        <div className={modalContainerClass}>
          {title && <div className="modal__title">{title}</div>}
          {onClose && (
            <span className="modal__close" onClick={onClose}>
              ✕
            </span>
          )}
          <div className={modalContentClass}>{children}</div>
          <div className={buttonsClass}>
            {confirmButton && (
              <Button
                onClick={onConfirm}
                disabled={isConfirmButtonDisabled}
                loading={loading}
              >
                {confirmButton}
              </Button>
            )}
            {cancelButton && onClose && (
              <Button
                color={BUTTON_COLORS.SECONDARY}
                onClick={onClose}
                disabled={loading}
              >
                {cancelButton}
              </Button>
            )}
            {extraButtons}
          </div>
        </div>
      </div>
    </>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  confirmButton: PropTypes.string,
  cancelButton: PropTypes.string,
  buttonEnd: PropTypes.bool,
  isConfirmButtonDisabled: PropTypes.bool,
  noWidth: PropTypes.bool,
  noMargin: PropTypes.bool,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
  extraButtons: PropTypes.node,
}

export default Modal
