import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { ExpandableCard } from 'components/expandable-card'
import DtiCalculator from 'components/dti-calculator'
import IncomeContent from './income-content'
import UWApprovalStatus from '../common/uw-approval-status'
import {
  UW_HUB_ACTIONS,
  updateIncomeData,
  getIncomeSectionData,
} from '../actions'

import './style.scss'
import UwDeclineTooltip from '../common/uw-decline-tooltip'
import { expandableCard } from '../common/styles'

const { CalculatorIcon, CheckIcon } = assets
const { Button, LoadingIndicator } = components

const Income = ({
  loanApplicationId,
  borrowerId,
  coBorrowerId,
  incomeData,
  openIncomeCalculator,
}) => {
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const { t: translate } = useTranslation()
  const { dispatch } = useStore()
  const [isDtiCalculatorOpen, setIsDtiCalculatorOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  const updateIncomeApprovalStatus = (approvalStatusId, reason) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_INCOME_DATA,
      payload: {
        ...incomeData,
        uwApprovalStatusId: approvalStatusId,
        uwDeclineReason: reason,
      },
    })
  }

  const handleOnChange = (name, value) => {
    const { [name]: currentValue } = incomeData.updatedData

    if (JSON.stringify(currentValue) === value) {
      return
    }

    setHasChanges(true)

    dispatch({
      type: UW_HUB_ACTIONS.UPDATE_INCOME_DATA,
      payload: {
        [name]: value,
      },
    })
  }

  const updateData = () => {
    setIsLoading(true)
    updateIncomeData(dispatch, loanApplicationId, incomeData.updatedData)
      .then(() => getIncomeSectionData(dispatch, loanApplicationId))
      .then(() => {
        setHasChanges(false)
      })
      .finally(() => setIsLoading(false))
  }

  const collapsibleItems = [
    <div style={{ width: '100%' }} key={0}>
      <UWApprovalStatus
        loanApplicationId={loanApplicationId}
        portalApprovalStatusId={incomeData.portalApprovalStatusId}
        uwApprovalStatusId={incomeData.uwApprovalStatusId}
        sectionTypeId={incomeData.sectionTypeId}
        updateSectionApprovalStatus={(approvalStatusId, reason) =>
          updateIncomeApprovalStatus(approvalStatusId, reason)
        }
        displayPortalApprovalStatus={false}
        displayUwApprovalStatus={isMobileView}
      />
      <div className="uw-hub-page__income-calculators">
        <div className="uw-hub-page__income-verification-method">
          <span>{translate('uwHub.income.verificationMethod.title')}:</span>
          <span>
            {translate(
              `uwHub.income.verificationMethod.${incomeData.verificationMethodId}`
            )}
          </span>
        </div>
        <div>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            onClick={() => setIsDtiCalculatorOpen(true)}
            startIcon={<CalculatorIcon />}
          >
            {translate('uwHub.income.dtiCalculator')}
          </Button>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            onClick={openIncomeCalculator}
            startIcon={<CalculatorIcon />}
            style={{ marginLeft: '8px' }}
          >
            {translate('uwHub.income.incomeCalculator')}
          </Button>
          <DtiCalculator
            loanApplicationId={loanApplicationId}
            borrowerId={borrowerId}
            coBorrowerId={coBorrowerId}
            isOpen={isDtiCalculatorOpen}
            onClose={() => setIsDtiCalculatorOpen(false)}
          />
        </div>
      </div>
      {incomeData?.applicantData?.map((applicantData, index) => {
        return (
          <IncomeContent
            key={index}
            data={applicantData}
            updatedData={incomeData.updatedData}
            loanApplicationId={loanApplicationId}
            applicantTypeId={applicantData.applicantTypeId}
            handleOnChange={(name, value) => handleOnChange(name, value)}
          />
        )
      })}
      <div className="uw-hub-page__income-button">
        <Button
          onClick={() => updateData()}
          variant={BUTTON_VARIANTS.PRIMARY}
          color={BUTTON_COLORS.PRIMARY}
          startIcon={isLoading ? <LoadingIndicator size={14} /> : <CheckIcon />}
          disabled={!hasChanges || isLoading}
        >
          {translate('global.saveChanges')}
        </Button>
      </div>
    </div>,
  ]

  return (
    <div className="uw-hub-page__income">
      <ExpandableCard
        headerProps={expandableCard.headerProps(isMobileView, true)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={
          <div className="uw-hub-page__income-card">
            <div className="align-center uw-hub-page__income__title">
              {translate('uwHub.income.title')}
              <UwDeclineTooltip data={incomeData} />
            </div>
            <UWApprovalStatus
              loanApplicationId={loanApplicationId}
              portalApprovalStatusId={incomeData.portalApprovalStatusId}
              uwApprovalStatusId={incomeData.uwApprovalStatusId}
              sectionTypeId={incomeData.sectionTypeId}
              updateSectionApprovalStatus={(approvalStatusId, reason) =>
                updateIncomeApprovalStatus(approvalStatusId, reason)
              }
              displayPortalApprovalStatus={true}
              displayUwApprovalStatus={!isMobileView}
            />
          </div>
        }
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        alwaysCollapsible={true}
        isCollapsed={isTabletView}
        collapsibleItemsStyles={{
          sx: {
            padding: !isTabletView ? '24px' : '12px 16px',
          },
        }}
      />
    </div>
  )
}

Income.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  borrowerId: PropTypes.string.isRequired,
  coBorrowerId: PropTypes.string,
  incomeData: PropTypes.object.isRequired,
  openIncomeCalculator: PropTypes.func.isRequired,
}

export default Income
