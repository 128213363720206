import { colors, theme } from '@ElementsCapitalGroup/enium-ui'
const {
  lightTheme: { typography },
} = theme

const subtitle = {
  color: colors.grey[900],
  fontSize: typography.h4.fontSize,
  fontWeight: typography.fontWeightBold,
  marginBottom: '16px',
}

const list = {
  padding: '0px 8px 24px 0',
  overflowY: 'auto',
  height: '100%',
}

const listItem = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0',

  name: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
}

const amount = {
  color: colors.green[600],
}

export const styles = {
  list,
  subtitle,
  listItem,
  amount,
}
