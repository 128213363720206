import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { VALIDATION_TYPES, validate } from 'components/validator'

import TextField from 'components/input'
import Textarea from 'components/textarea'
import Button from 'components/button'
import { GROUPED_CLAIMS } from 'common/claims'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'

import { ReactComponent as UsersSvg } from 'assets/users.svg'
import { useStore } from 'store'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'
import {
  getRole,
  editRole,
  addRole,
} from 'modules/admin-roles-management/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { setLoading } from 'modules/global/actions'

import './style.scss'

const { Checkbox, Breadcrumbs } = components

const { ArrowUpIcon, ArrowDownIcon, CheckIcon } = assets

const RolesSettings = ({ isCreate }) => {
  const { dispatch, state: store } = useStore()

  const navigate = useNavigate()

  const { roleTypes } = store.adminRolesManagement
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const [input, setInput] = useState({
    name: '',
    type: '',
    description: '',
    securityLevel: '',
  })
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [openSections, setOpenSections] = useState({})
  const [errors, setErrors] = useState({})

  const { id } = useParams()

  const [searchBy, setSearchBy] = useState('')

  useEffect(() => {
    if (!isCreate) {
      getRole(id).then((res) => {
        const formattedPermissions = res.permissions.map((itm) => itm.claimType)
        setInput({
          name: res.name,
          description: res?.description,
          permissions: formattedPermissions,
          securityLevel: res.securityLevel,
        })
        setSelectedPermissions(formattedPermissions)
      })
    }
  }, [isCreate])

  useEffect(() => {
    if (isCreate) {
      setInput({
        ...input,
        type: roleTypes?.[0]?.name,
      })
    }
  }, [isCreate, roleTypes])

  const filteredClaims = useMemo(() => {
    if (!searchBy) {
      return GROUPED_CLAIMS
    }

    return GROUPED_CLAIMS.map((group) => ({
      ...group,
      actions: group.actions.filter((action) =>
        action.claims.some(
          (claim) =>
            claim.claim.toLowerCase().includes(searchBy.toLowerCase()) ||
            claim.claimName.toLowerCase().includes(searchBy.toLowerCase())
        )
      ),
    })).filter((group) => group.actions.length > 0)
  }, [searchBy])

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }))
  }

  const onSaveChanges = () => {
    const { name, securityLevel } = input

    const [isValid, errors] = validate(
      {
        name: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.NAME],
        securityLevel: [
          VALIDATION_TYPES.REQUIRED,
          VALIDATION_TYPES.POSITIVE_OR_ZERO_INTEGER,
        ],
      },
      { name, securityLevel }
    )

    if (!isValid) {
      return setErrors(errors)
    }
    setLoading(dispatch, true)
    let promise
    if (!isCreate) {
      promise = editRole(id, selectedPermissions, dispatch)
    } else {
      const roleTypeId = roleTypes.find((role) => role.name === input.type).id

      promise = addRole(
        {
          ...input,
          roleTypeId: roleTypeId,
          permissions: selectedPermissions.length
            ? selectedPermissions.map((perm) => perm)
            : [],
        },
        dispatch
      )
    }

    promise
      .then(() => navigate('/admin/roles'))
      .finally(() => {
        setLoading(dispatch, false)
      })
  }

  const items = useMemo(() => {
    const baseItems = [
      { icon: <UsersSvg /> },
      { label: 'Roles', href: '/admin/roles' },
      { label: isCreate ? 'Add New Role' : input.name },
    ]

    return baseItems
  }, [isCreate, input])

  return (
    <>
      <Breadcrumbs items={items} onClick={() => {}} />
      <div className="roles-settings__header">
        <div className="history__title">Roles</div>
        <Button onClick={onSaveChanges} startIcon={<CheckIcon />}>
          {isCreate ? 'Add New Role' : 'Save Changes'}
        </Button>
      </div>
      <div className="loan-product__paper">
        <span className="roles-settings__subheader">Role Info</span>

        <div className="roles-settings__fields">
          <TextField
            label="Role Name"
            value={input.name}
            validate={() => errors.name}
            style={{ marginRight: '16px' }}
            fullWidth
            disabled={id}
            onChange={(newName) => setInput({ ...input, name: newName })}
          />

          <TextField
            label="Security Level"
            validate={() => errors.securityLevel}
            fullWidth
            disabled={id}
            style={{
              marginTop: isTabletView ? '16px' : 0,
              marginRight: '16px',
            }}
            value={input.securityLevel}
            onChange={(newSecurity) =>
              setInput({ ...input, securityLevel: newSecurity })
            }
          />
          <Textarea
            label="Role Description"
            fullWidth
            disabled={id}
            style={{
              marginRight: '16px',
              marginTop: isTabletView ? '16px' : 0,
            }}
            value={input.description}
            onChange={(description) =>
              setInput({ ...input, description: description })
            }
          />
        </div>
      </div>
      {isCreate && (
        <div className="loan-product__paper">
          <div className="roles-settings__subheader-wrapper">
            <span className="roles-settings__subheader">Role Type</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {roleTypes.map((roleType) => (
              <Checkbox
                key={roleType.id}
                uncentered
                label={roleType.friendlyName}
                sx={{ mr: 1 }}
                checked={input.type === roleType.name}
                onClick={() => {
                  if (input.type === roleType.name) {
                    setInput({ ...input, type: '' })
                  } else {
                    setInput({ ...input, type: roleType.name })
                  }
                }}
              />
            ))}
          </div>
        </div>
      )}
      <div className="loan-product__paper">
        <div className="roles-settings__wrapper">
          <span className="roles-settings__subheader">Permissions</span>

          <TextField
            onChange={setSearchBy}
            value={searchBy}
            placeholder={'Search Claims'}
            type="search"
            disabled={false}
            style={{ width: '320px', marginTop: '12px' }}
          />
        </div>

        {filteredClaims.map((group) => (
          <div key={group.section} className="roles-settings__item">
            <div
              onClick={() => toggleSection(group.section)}
              className="roles-settings__section"
            >
              {group.section}
              {openSections[group.section] ? (
                <ArrowDownIcon />
              ) : (
                <ArrowUpIcon />
              )}
            </div>
            {openSections[group.section] && (
              <>
                {group.actions.map((action, index) => (
                  <div key={index} className="roles-settings__action-wrapper">
                    <div className="roles-settings__action">
                      {action.action}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {action.claims.map((claim, idx) => (
                        <Checkbox
                          label={claim.claim}
                          key={idx}
                          checked={selectedPermissions.includes(
                            claim.claimName
                          )}
                          onClick={() => {
                            setSelectedPermissions((prevPermissions) => {
                              if (prevPermissions.includes(claim.claimName)) {
                                return prevPermissions.filter(
                                  (permission) => permission !== claim.claimName
                                )
                              } else {
                                return [...prevPermissions, claim.claimName]
                              }
                            })
                          }}
                        >
                          {claim.claim}
                        </Checkbox>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

RolesSettings.propTypes = {
  isCreate: PropTypes.bool,
}

export default RolesSettings
