import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { assets } from '@ElementsCapitalGroup/enium-ui'

import Loader from 'components/loader/index'
import { getIncomeEstimatorData } from 'modules/loan-application/actions'
import RenderIncomeEstimator from './render-income-estimator'

import './index.scss'

const { CoinsStackedIcon } = assets
const IncomeEstimator = ({ loanApplicationId }) => {
  const { t: translate } = useTranslation()
  const [incomeData, setIncome] = useState()
  const [loading, setLoading] = useState()

  const getIncomeEstimator = () => {
    setLoading(true)
    getIncomeEstimatorData(loanApplicationId)
      .then((res) => setIncome(res))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getIncomeEstimator()
  }, [])

  return (
    <>
      <div className="income-estimator__wrapper">
        <div className="income-estimator__title">
          <CoinsStackedIcon sx={{ mr: 1 }} />
          {translate('loanApplication.ecgToolbox.incomeEstimator.title')}
        </div>
        {loading && <Loader size={100} center noLogo={true} />}
        {incomeData && (
          <RenderIncomeEstimator incomeDataInformation={incomeData} />
        )}
      </div>
    </>
  )
}

IncomeEstimator.propTypes = {
  loanApplicationId: PropTypes.any.isRequired,
}

export default IncomeEstimator
