import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import SearchAndFilterModule from 'components/search-filter-module'
import { useEffectOnUpdate } from 'common/hooks'
import ExpandedRowLenderSettings from './products-expanded-row-contents'
import { formatEffectiveDate } from '../utils'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

const LenderProducts = ({
  list,
  pageCount,
  onFetchList,
  onUpdate,
  loading,
}) => {
  const tableRef = useRef(null)
  const [filters, setFilters] = useState({
    sortBy: undefined,
    searchBy: undefined,
    pageNumber: 0,
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor('lenderName', {
        header: 'Lender',
      }),
      columnHelper.accessor('loanCode', {
        header: 'Loan Code',
      }),
      columnHelper.accessor('serviceCode', {
        header: 'Service Code',
        id: 'serviceCode',
        cell: (cell) => cell.row.original.serviceCode || '-',
      }),
      columnHelper.accessor('discount', {
        header: 'Discount',
      }),
      columnHelper.accessor('effectiveDate', {
        header: 'Effective Date',
        id: 'effectiveDate',
        cell: (cell) => formatEffectiveDate(cell.row.original.effectiveDate),
      }),
    ],
    []
  )

  /** On search/pagination/filters change, re-fetch the list */
  useEffectOnUpdate(() => {
    onFetchList(filters)
  }, [filters])

  const onSearch = (payload) => setFilters({ ...filters, ...payload })

  const onTableFiltersChanged = ({ pageIndex, sort }) => {
    setFilters({ ...filters, pageNumber: pageIndex, sortBy: sort })
  }

  /** On row save we have to re-fetch the list */
  const onRowSave = (data, idx) => {
    onUpdate(data).then(() => {
      setFilters({
        sortBy: undefined,
        searchBy: undefined,
        pageNumber: 0,
      })
      tableRef.current?.table?.getRow(idx).toggleExpanded()
      tableRef.current?.setPageIndex(0)
    })
  }

  const products = useMemo(() => {
    return list.map((product, idx) => ({
      ...product,
      $children: () => (
        <ExpandedRowLenderSettings
          onSave={(data) => onRowSave(data, idx)}
          data={product}
          onCancel={() => {
            tableRef.current?.table?.getRow(idx).toggleExpanded()
          }}
        />
      ),
    }))
  }, [list, tableRef])

  return (
    <>
      <div className="afp__header">
        <SearchAndFilterModule
          fieldsToSearch={['multiple']}
          placeholder="Search"
          noDefaultExtraOptions={true}
          callback={onSearch}
        />
      </div>
      <Table
        title="Products"
        ref={tableRef}
        data={products}
        columns={columns}
        hasPagination={true}
        totalPages={pageCount}
        fetchData={onTableFiltersChanged}
        dataLoading={loading}
      />
    </>
  )
}

LenderProducts.propTypes = {
  list: PropTypes.array.isRequired,
  pageCount: PropTypes.number.isRequired,
  onFetchList: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default LenderProducts
