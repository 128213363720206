import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { typography },
} = theme

const tableTitle = {
  fontSize: typography.h6.fontSize,
  fontWeight: 600, // TODO: Add value in library
  color: colors.grey[900],
}

export const styles = {
  tableTitle,
}
