export const totalsWrapper = {
  display: 'flex',
  marginTop: '24px',
}

export const totalsWrapperMobile = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}

export const paperNoShadow = {
  width: '50%',
  marginRight: '24px',
  boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
}

export const paperNoShadowMobile = {
  marginTop: '24px',
  width: '100%',
}

export const totalColumn = {
  display: 'flex',
  flexDirection: 'column',
  marginRight: '24px',
  width: '100%',
}

export const totalAmount = {
  color: 'black',
  fontWeight: 700,
  marginTop: '8px',
}

export const totalsPaper = {
  width: '50%',
  boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
}

export const totalsPaperMobile = {
  width: '100%',
  boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
  marginTop: '12px',
}

export const adminPageHeader = {
  marginBottom: '24px',
}

export const paperPadding = {
  padding: '24px 24px 0 24px',
}
