import { copyToClipboard } from 'common/utils'

export const copyLoanAppsToClipboard = (apps, dispatch) => {
  let htmlText = ''
  apps.forEach((app, idx) => {
    const {
      customer,
      loanApplicationNumber,
      state,
      progress,
      fullAddress,
      organization,
      salesRepresentative,
      dateCreated,
      lastModified,
      expiresInDays,
    } = app
    if (idx > 0) {
      htmlText += `<br/><br/>`
    }
    htmlText += `
    <strong>Customer: </strong> ${customer}<br/>
    <strong>Loan Application Number: </strong> ${loanApplicationNumber}<br/>
    <strong>State: </strong> ${state}<br/>
    <strong>Status: </strong> ${progress}<br/>
    <strong>Full Address: </strong> ${fullAddress}<br/>
    <strong>Organization: </strong> ${organization}<br/>
    <strong>Sales Rep: </strong> ${salesRepresentative}<br/>
    <strong>Date Created: </strong> ${dateCreated}<br/>
    <strong>Last Modified: </strong> ${lastModified}<br/>
    <strong>Expires In (Days): </strong> ${expiresInDays}<br/>
    `
  })

  copyToClipboard(htmlText, dispatch)
}
