import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  components,
  assets,
  hooks,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'
import { TABLET_BREAKPOINT } from 'common/constants'
import { useStore } from 'store'
import TextField from 'components/input'
import TextArea from 'components/textarea'
import {
  fetchPromotionalTypes,
  fetchFinanceTypes,
  fetchLoanStatuses,
} from '../actions'
import LoanProductTemplates from '../loan-product-templates/index'

import FinancialDetails, { generateMockedPayment } from '../financial-details'

const { Unstable_Grid: Grid } = unstable_components

const LoanProductDetails = ({
  loanProductDetails,
  setLoanProductDetails,
  edit,
  allErrors,
  callback,
  setNeedsRefetch,
  isViewOnly,
}) => {
  const { state } = useStore()
  const [activeTemplate, setActiveTemplate] = useState('')
  const [promoTypes, setPromoTypes] = useState([])
  const [financeTypes, setFinanceTypes] = useState([])
  const { lenders } = state.orgManagement

  const [loanStatuses, setLoanStatuses] = useState([])

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  const formattedLenders = useMemo(() => {
    return lenders.map((el) => ({ id: el.guid, label: el.name }))
  }, [lenders])

  useEffect(() => {
    fetchPromotionalTypes().then(setPromoTypes)
    fetchFinanceTypes().then(setFinanceTypes)
    fetchLoanStatuses().then(setLoanStatuses)
  }, [])

  const generateNewPaymentPeriod = () => {
    setLoanProductDetails({
      ...loanProductDetails,
      paymentPeriodsDetails: [
        ...loanProductDetails.paymentPeriodsDetails,
        generateMockedPayment(),
      ],
    })
  }

  const handleOnChange = (name, value, checkbox = false) => {
    if (checkbox) {
      setLoanProductDetails({
        ...loanProductDetails,
        [name]: value,
      })
    } else {
      setLoanProductDetails({
        ...loanProductDetails,
        [name]: value,
      })
    }
  }

  const handleDropdownChange = (name, event) => {
    const {
      target: { value },
    } = event

    setLoanProductDetails((prevState) => ({
      ...prevState,
      [name]: value.id,
    }))
  }

  const { CheckIcon } = assets

  const { decisioningTemplate, stipulationTemplate, envelopeDefinitions } =
    loanProductDetails || {}

  return (
    <div
      style={{ padding: isMobileView ? '16px' : edit ? '0 32px 32px' : '32px' }}
    >
      {!edit ? (
        <div className="loan-product__header">
          <div className="history__title">Add New Loan Product</div>
          <Button startIcon={<CheckIcon />} onClick={callback}>
            {edit ? 'Edit' : 'Create'} Loan Product
          </Button>
        </div>
      ) : (
        <div className="loan-product__header">
          <div className="history__title">{loanProductDetails.name}</div>
          <Button startIcon={<CheckIcon />} onClick={callback}>
            {edit ? 'Edit' : 'Create'} Loan Product
          </Button>
        </div>
      )}
      <Grid
        className={cx({
          'loan-product__paper': !edit,
          'applicant-info__form-general loan-product__paper': edit,
          disabled: isViewOnly,
        })}
        container
        gap={24}
      >
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            validate={() => allErrors.name}
            label={'Name*'}
            fullWidth
            value={loanProductDetails.name}
            onChange={(val) => handleOnChange('name', val, false)}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          {edit ? (
            <Dropdown
              label={'Loan Status*'}
              className="loan-form__dropdown"
              options={loanStatuses}
              fullWidth
              value={
                loanStatuses?.find(
                  (itm) => itm.id === loanProductDetails.loanStatusId
                ) || ''
              }
              error={!!allErrors.loanStatusId}
              helperText={allErrors.loanStatusId}
              onChange={(val) => {
                handleDropdownChange('loanStatusId', val, false)
              }}
            />
          ) : (
            <TextField
              label={'Loan Code*'}
              validate={() => allErrors.loanCode}
              value={loanProductDetails.loanCode}
              onChange={(val) => handleOnChange('loanCode', val, false)}
              fullWidth
            />
          )}
        </Grid>

        <Grid item mobile={12} tablet={12} desktop={4}>
          <Dropdown
            options={formattedLenders}
            className="loan-form__dropdown"
            label="Lender*"
            value={
              formattedLenders?.find(
                (itm) => itm.id === loanProductDetails.lenderId
              ) || ''
            }
            error={!!allErrors.lenderId}
            helperText={allErrors.lenderId}
            onChange={(val) => handleDropdownChange('lenderId', val, false)}
          />
        </Grid>
        {!edit && (
          <Grid item mobile={12} tablet={12} desktop={12}>
            <Checkbox
              checked={loanProductDetails.isCommercial}
              disabled={edit}
              label={'Commercial Loan Product'}
              onClick={() =>
                handleOnChange(
                  'isCommercial',
                  !loanProductDetails.isCommercial,
                  true
                )
              }
            />
          </Grid>
        )}
        <Grid item>
          <TextArea
            rows={4}
            label={'Description*'}
            validate={() => allErrors.description}
            value={loanProductDetails.description}
            resize="vertical"
            fullWidth
            onChange={(val) => handleOnChange('description', val, false)}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Term Period'}
            validate={() => allErrors.termInMonths}
            value={loanProductDetails.termInMonths}
            onChange={(val) => handleOnChange('termInMonths', val, false)}
            fullWidth
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Promo Period'}
            value={loanProductDetails.promoPeriodInMonths}
            onChange={(val) =>
              handleOnChange('promoPeriodInMonths', val, false)
            }
            validate={() => allErrors.promoPeriodInMonths}
            fullWidth
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <Dropdown
            label={'Promotional Type*'}
            className="loan-form__dropdown"
            options={promoTypes}
            value={
              promoTypes?.find(
                (itm) => itm.id === loanProductDetails.promotionalTypeId
              ) || ''
            }
            error={!!allErrors.promotionalTypeId}
            helperText={allErrors.promotionalTypeId}
            onChange={(val) =>
              handleDropdownChange('promotionalTypeId', val, false)
            }
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'ACH Fee*'}
            validate={() => allErrors.achFee}
            fullWidth
            value={loanProductDetails.achFee}
            onChange={(val) => handleOnChange('achFee', val, false)}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Rate'}
            value={loanProductDetails.rate ?? undefined}
            validate={() => allErrors.rate}
            onChange={(val) => handleOnChange('rate', val, false)}
            fullWidth
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Paydown Percentage*'}
            value={loanProductDetails.paydownPercentage}
            validate={() => allErrors.paydownPercentage}
            fullWidth
            onChange={(val) => handleOnChange('paydownPercentage', val, false)}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Interest Rate Fee'}
            value={loanProductDetails.interestRateFee}
            validate={() => allErrors.interestRateFee}
            onChange={(val) => handleOnChange('interestRateFee', val, false)}
            fullWidth
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Interest Rate Discount'}
            value={loanProductDetails.interestRateDiscount}
            validate={() => allErrors.interestRateDiscount}
            onChange={(val) =>
              handleOnChange('interestRateDiscount', val, false)
            }
            fullWidth
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <Dropdown
            label={'Finance Type*'}
            className="loan-form__dropdown"
            options={financeTypes}
            value={
              financeTypes?.find(
                (itm) => itm.id === loanProductDetails.financeTypeId
              ) || ''
            }
            onChange={(val) =>
              handleDropdownChange('financeTypeId', val, false)
            }
            fullWidth
            error={!!allErrors.financeTypeId}
            helperText={allErrors.financeTypeId}
          />
        </Grid>
        <Grid item>
          <TextField
            label={'Service Code'}
            validate={() => allErrors.serviceCode}
            value={loanProductDetails.serviceCode}
            fullWidth
            onChange={(val) => handleOnChange('serviceCode', val, false)}
          />
        </Grid>
        {edit && (
          <Grid item mobile={12} tablet={6} desktop={4}>
            <TextField
              label={'Loan Code*'}
              validate={() => allErrors.loanCode}
              value={loanProductDetails.loanCode}
              onChange={(val) => handleOnChange('loanCode', val, false)}
              fullWidth
            />
          </Grid>
        )}
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Min Amount'}
            value={loanProductDetails.minAmount}
            fullWidth
            onChange={(val) => handleOnChange('minAmount', val, false)}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={4}>
          <TextField
            label={'Max Amount'}
            fullWidth
            value={loanProductDetails.maxAmount}
            onChange={(val) => handleOnChange('maxAmount', val, false)}
          />
        </Grid>
      </Grid>

      {edit && (
        <div className="loan-product__templates">
          <div className="loan-product__paper">
            <div className="loan-product__templates-header">
              <span className="loan-product__templates-title">
                AUS Template
              </span>
              <Button
                onClick={() =>
                  setActiveTemplate(TEMPLATE_TYPE.DECISIONING_TEMPLATE)
                }
                variant="text"
              >
                + AUS Template
              </Button>
            </div>
            <div>
              {loanProductDetails?.decisioningTemplate && (
                <div className="loan-product__template-paper">
                  <div className="loan-product__template-header">
                    AUS Template
                  </div>
                  <div className="loan-product__template-content">
                    <span>{decisioningTemplate.name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="loan-product__paper">
            <div className="loan-product__templates-header">
              <span className="loan-product__templates-title">
                Stipulation Template
              </span>
              <Button
                onClick={() =>
                  setActiveTemplate(TEMPLATE_TYPE.STIPULATION_TEMPLATE)
                }
                variant="text"
              >
                + Stipulation Template
              </Button>
            </div>
            {stipulationTemplate && (
              <div className="loan-product__template-paper">
                <div className="loan-product__template-header">
                  Stipulation Template
                </div>
                <div className="loan-product__template-content">
                  <span>{stipulationTemplate.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className="loan-product__paper">
            <div className="loan-product__templates-header">
              <span className="loan-product__templates-title">
                Document Template
              </span>
              <Button
                onClick={() =>
                  setActiveTemplate(TEMPLATE_TYPE.DOCUMENT_TEMPLATE)
                }
                variant="text"
              >
                + Document Template
              </Button>
            </div>
            <div>
              {envelopeDefinitions.length > 0 && (
                <div className="loan-product__template-paper">
                  <div className="loan-product__template-header">
                    Document Template
                  </div>
                  {envelopeDefinitions.map((item, key) => {
                    return (
                      <div key={key} className="loan-product__template-content">
                        <span>{item.name}</span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>

          <div className="loan-product__paper">
            <div className="loan-product__templates-header">
              <span className="loan-product__templates-title">
                Financial Details
              </span>
              <Button onClick={() => generateNewPaymentPeriod()} variant="text">
                + Payment
              </Button>
            </div>
            <div>
              <FinancialDetails
                loanProductDetails={loanProductDetails}
                setLoanProductDetails={setLoanProductDetails}
                callback={() => {}}
                isViewOnly={isViewOnly}
              />
            </div>
          </div>
        </div>
      )}
      <LoanProductTemplates
        activeTemplate={activeTemplate}
        setActiveTemplate={setActiveTemplate}
        loanProduct={loanProductDetails}
        lenders={lenders}
        setNeedsRefetch={setNeedsRefetch}
        isViewOnly={isViewOnly}
      />
    </div>
  )
}

LoanProductDetails.propTypes = {
  loanProductDetails: PropTypes.object.isRequired,
  setLoanProductDetails: PropTypes.func.isRequired,
  isLoanCreateVisible: PropTypes.bool,
  callback: PropTypes.func,
  edit: PropTypes.bool,
  allErrors: PropTypes.object.isRequired,
  className: PropTypes.string,
  setNeedsRefetch: PropTypes.func,
  isViewOnly: PropTypes.bool,
}

export default LoanProductDetails

const TEMPLATE_TYPE = {
  STIPULATION_TEMPLATE: 'Stipulation Template',
  DECISIONING_TEMPLATE: 'AUS Template',
  DOCUMENT_TEMPLATE: 'Document Template',
}

const { Dropdown, Button, Checkbox } = components
const { useMediaQuery } = hooks
