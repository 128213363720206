import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import TextField, { INPUT_TYPES } from 'components/input'
import DatePicker from 'components/date-picker'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import { dateFormatServerWithTime, dobDateFormat } from 'common/constants'
import './style.scss'

const { CalendarIcon, EyeIcon, EyeOffIcon } = assets

const DateInput = ({
  value,
  onChange,
  format = dobDateFormat,
  serverDateFormat = dateFormatServerWithTime,
  minDate,
  maxDate,
  isSensitiveField,
  hideSecureValue,
  disabled,
  calendarPlacement,
  ...other
}) => {
  const _value = useMemo(() => {
    const isServerDate = moment(value, serverDateFormat, true).isValid()
    if (isServerDate) {
      return moment(value).format(format)
    }
    return value
  }, [value, format])

  const [textVisible, setTextVisible] = useState(false)
  const isValueHidden = isSensitiveField && !textVisible
  const date = moment(_value, format, true)

  const onDateSelected = (date) => {
    onChange(moment(date).format(format))
  }

  /** Do some date validation on blur if min/max date are provided */
  const onBlur = () => {
    if (maxDate) {
      const crtDate = moment(value)
      if (crtDate.isValid() && crtDate.isAfter(moment(maxDate))) {
        onChange(moment().format(format))
      }
    }
    if (minDate) {
      const crtDate = moment(value)
      if (crtDate.isValid() && crtDate.isBefore(moment(minDate))) {
        onChange(moment().format(format))
      }
    }
  }

  const inputType = useMemo(() => {
    if (isValueHidden) {
      // We set it to text s.t. the "Sensitive" type field works and hides the value
      return INPUT_TYPES.TEXT
    }
    return INPUT_TYPES.DATEPICKER
  }, [isValueHidden])

  return (
    <TextField
      value={isValueHidden ? '•••••' : _value}
      onChange={isValueHidden ? () => {} : onChange}
      onKeyDown={isValueHidden ? (ev) => ev.preventDefault() : null}
      type={inputType}
      placeholder={format}
      disabled={hideSecureValue}
      onBlur={onBlur}
      className="input-date"
      endIcon={
        <>
          <DatePicker
            date={date.isValid() ? date.toDate() : null}
            setDate={onDateSelected}
            customInput={
              <CalendarIcon className={isValueHidden ? 'disabled' : ''} />
            }
            showMonthDropdown={true}
            showYearDropdown={true}
            minDate={minDate}
            maxDate={maxDate}
            placement={calendarPlacement}
          />
          {!hideSecureValue && isValueHidden && (
            <EyeOffIcon onClick={() => setTextVisible(true)} />
          )}
          {!hideSecureValue && isSensitiveField && textVisible && (
            <EyeIcon onClick={() => setTextVisible(false)} />
          )}
        </>
      }
      {...other}
    />
  )
}

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  serverDateFormat: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isSensitiveField: PropTypes.bool,
  hideSecureValue: PropTypes.bool,
  disabled: PropTypes.bool,
  calendarPlacement: PropTypes.string,
}

export default DateInput
