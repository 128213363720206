import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ERRORS, PASSWORD_REGEX } from 'components/validator'
import { modules } from '@ElementsCapitalGroup/enium-ui'
import AuthBg from 'assets/auth-bg-desktop.jpg'
import AuthBgMobile from 'assets/auth-bg-mobile.jpg'
import { useNavigate } from 'react-router-dom'

const { SetPasswordContainer, PasswordResetContainer } = modules

const SetAndResetPassword = ({
  onSubmit,
  title,
  supportingText,
  submittedVerbiage: { titleSuccess, titleFailed, textSuccess, textFailed },
  buttonText,
}) => {
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const onFormSubmit = ({ password }, ev) => {
    ev?.preventDefault()
    setLoading(true)

    onSubmit(password)
      .then((res) => {
        setSubmitted(true)
        if (res && res.firstName) {
          setSubmitted(true)
          setSuccess(true)
        } else {
          setSuccess(false)
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  if (submitted) {
    return (
      <PasswordResetContainer
        title={success ? titleSuccess : titleFailed}
        onContinue={() => {
          if (success) {
            navigate('/login')
          } else {
            setSubmitted(false)
          }
        }}
        supportingText={success ? textSuccess : textFailed}
        failed={!success}
        imageUrl={AuthBg}
        mobileImageUrl={AuthBgMobile}
      />
    )
  }

  return (
    <SetPasswordContainer
      onBack={() => navigate('/login')}
      onSubmit={onFormSubmit}
      loading={loading}
      title={title}
      supportingText={supportingText}
      passwordRegex={PASSWORD_REGEX}
      errorMessages={{
        password: ERRORS.PASSWORD,
        confirmPassword: ERRORS.PASSWORD,
      }}
      imageUrl={AuthBg}
      mobileImageUrl={AuthBgMobile}
      verbiage={{ resetPassword: buttonText }}
    />
  )
}

SetAndResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  supportingText: PropTypes.string.isRequired,
  submittedVerbiage: PropTypes.shape({
    titleSuccess: PropTypes.string.isRequired,
    titleFailed: PropTypes.string.isRequired,
    textSuccess: PropTypes.string.isRequired,
    textFailed: PropTypes.string.isRequired,
  }),
  buttonText: PropTypes.string,
}

export default SetAndResetPassword
