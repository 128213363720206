import React, { useEffect, useState } from 'react'
import { useStore } from 'store'
import { useParams } from 'react-router-dom'
import LoanDocumentTokens from 'modules/loan-document/loan-document-tokens'
import LoanDocumentCreateForm from 'modules/loan-document/loan-document-form'
import { components } from '@ElementsCapitalGroup/enium-ui/'
import PropTypes from 'prop-types'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

import { setLoading } from 'modules/global/actions'

import './index.scss'
import {
  fetchDocuments,
  fetchSingleEnvelope,
  getDocumentTypes,
  getDocumentTokens,
  getDocumentRoles,
  associateStatesWithEnvelopes,
} from '../actions'
import LoanDocumentStates from '../loan-document-states'

const LoanDocumentSettings = ({ isCreate }) => {
  const [dropdownDocumentTypes, setDropdownDocumentTypes] = useState([])
  const [loanDocumentDetails, setLoanDocumentDetails] = useState({})
  const [isLoanDocEditVisible, setIsLoanDocEditVisible] = useState(false)
  const [tokenTypes, setTokenTypes] = useState([])
  const [documents, setDocuments] = useState([])
  const [needsRefetch, setNeedsRefetch] = useState(true)
  const [dropdownDocumentRoles, setDropdownDocumentRoles] = useState([])
  const { id } = useParams()
  const { state, dispatch } = useStore()
  const { lenders } = state.orgManagement
  const { Tabs, Breadcrumbs } = components

  useEffect(() => {
    getDocumentRoles()
      .then((list) => {
        const forDropdown = list.map((el) => ({
          value: el.name,
          guid: el.id,
        }))
        setDropdownDocumentRoles(forDropdown)
      })
      .catch((error) => console.error(error))

    getDocumentTokens().then((res) => setTokenTypes(res))
    getDocumentTypes()
      .then((types) => {
        const forDropdown = types.map((el) => ({
          value: el.name,
          guid: el.id,
        }))
        setDropdownDocumentTypes(forDropdown)
      })
      .catch((error) => console.error(error))
  }, [])

  const onSubmitStates = (states) => {
    associateStatesWithEnvelopes(
      {
        envelopeDefinitionId: loanDocumentDetails.guid,
        states: states.map((itm) => itm.id),
      },
      dispatch
    ).then(() => getSingleEnvelope())
  }

  useEffect(() => {
    if (needsRefetch && !isCreate) {
      getSingleEnvelope()
    }
  }, [needsRefetch])

  const getSingleEnvelope = () => {
    setLoading(dispatch, true)
    fetchSingleEnvelope(id).then((res) => {
      setLoanDocumentDetails({
        ...res,
      })
    })
    fetchDocuments(id)
      .then((res) => {
        setDocuments(res)
      })
      .finally(() => {
        setLoading(dispatch, false)
        setNeedsRefetch(false)
      })
  }

  const items = [
    {
      label: 'Settings',
      value: TABS.SETTINGS,
      children: (
        <>
          <LoanDocumentCreateForm
            dropdownDocumentRoles={dropdownDocumentRoles}
            dropdownDocumentTypes={dropdownDocumentTypes}
            dropdownOrganizations={lenders.map((el) => ({
              value: el.name,
              id: el.guid,
            }))}
            loanDocumentToEdit={loanDocumentDetails}
            edit={!isCreate}
            callback={() => {}}
            isLoanCreateVisible={isLoanDocEditVisible}
            setIsLoanCreateVisible={setIsLoanDocEditVisible}
          />
          {loanDocumentDetails.documents?.length > 0 && (
            <LoanDocumentTokens
              tokenTypes={tokenTypes}
              documents={documents}
              setDocuments={setDocuments}
            />
          )}
        </>
      ),
    },
    {
      label: 'States',
      value: TABS.STATES,
      children: (
        <LoanDocumentStates
          loanDocumentStates={loanDocumentDetails.states}
          onSubmitStates={onSubmitStates}
        />
      ),
    },
  ]

  return (
    <div className="admin-page__container loan-document__wrapper">
      <Breadcrumbs
        maxItems={3}
        items={
          isCreate
            ? [
                { icon: <UsersSvg /> },
                { label: 'Documents', href: '/admin/loan-document' },
                { label: 'Add New Document' },
              ]
            : [
                { icon: <UsersSvg /> },
                { label: 'Documents', href: '/admin/loan-document' },
                { label: 'Settings' },
              ]
        }
        onClick={() => {}}
      />
      {!isCreate ? (
        <Tabs
          items={items}
          sx={{ marginTop: '32px' }}
          panelProps={{
            sx: {
              padding: 0,
            },
          }}
        />
      ) : (
        <LoanDocumentCreateForm
          dropdownDocumentRoles={dropdownDocumentRoles}
          dropdownDocumentTypes={dropdownDocumentTypes}
          dropdownOrganizations={lenders.map((el) => ({
            value: el.name,
            id: el.guid,
          }))}
          loanDocumentToEdit={loanDocumentDetails}
          edit={!isCreate}
          callback={() => setNeedsRefetch(true)}
          isLoanCreateVisible={isLoanDocEditVisible}
          setIsLoanCreateVisible={setIsLoanDocEditVisible}
        />
      )}
    </div>
  )
}

LoanDocumentSettings.propTypes = {
  isCreate: PropTypes.bool,
}

export default LoanDocumentSettings

const TABS = {
  SETTINGS: 'SETTINGS',
  STATES: 'STATES',
}
