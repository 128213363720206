/* eslint-disable react/prop-types */
import moment from 'moment'
import { components, colors } from '@ElementsCapitalGroup/enium-ui'
import StatusComponent from 'components/status'
import { LOAN_STATUS_ID_MAPPING } from 'modules/loan-applications/constants'

import './style.scss'

const { createColumnHelper } = components

const columnHelper = createColumnHelper()

export const useColumns = (fetchWithSortBy) => [
  columnHelper.accessor('customer', {
    header: 'Customer',
    enableSorting: true,
    id: 'customer',
    cell: ({ row }) => (
      <div>
        <div style={{ color: colors.grey[900], fontWeight: 500 }}>
          {row.original?.customer}
        </div>
        <div className="cell-ellipsis">{row.original?.fullAddress}</div>
      </div>
    ),
    size: 250,
  }),
  columnHelper.accessor('loanApplicationNumber', {
    header: 'Application #',
    enableSorting: false,
    size: 200,
  }),
  columnHelper.accessor('state', {
    size: 250,
    id: 'loanApplicationStateId',
    header: 'Application Stage',
    enableSorting: true,
    cell: ({ row }) => (
      <div className="cell-ellipsis">{row.original?.state}</div>
    ),
  }),
  columnHelper.accessor('loanApplicationStatusId', {
    id: 'loanApplicationStatusId',
    size: 175,
    header: 'Status',
    enableSorting: true,
    cell: ({ row }) => {
      const status = row.original?.loanApplicationStatusId

      if (status === null || status === undefined) {
        return null
      }

      return (
        <StatusComponent
          status={LOAN_STATUS_ID_MAPPING[status]}
          textKey={`loanApplication.statusCard.loanApplicationStatus.${status}`}
        />
      )
    },
  }),
  columnHelper.accessor('organization', {
    size: 200,
    id: 'salesOrganizationName',
    header: 'Organization',
    enableSorting: true,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('salesRepresentative', {
    header: 'Sales Rep',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('dateCreated', {
    size: 200,
    header: 'Date Created',
    enableSorting: true,
    cell: ({ row }) => {
      const dateCreated = row.original?.dateCreated
      if (!dateCreated || !moment(dateCreated).isValid()) {
        return null
      }
      // Should consider getting this in a separate function
      return moment(dateCreated).format('MMM Do, YYYY')
    },
  }),
  columnHelper.accessor('lastModified', {
    size: 200,
    id: 'dateLastModified',
    header: 'Last Modified',
    enableSorting: true,
    cell: ({ row }) => {
      const lastModified = row.original?.lastModified
      if (!lastModified || !moment(lastModified).isValid()) {
        return null
      }
      // Should consider getting this in a separate function
      return moment(lastModified).format('MMM Do, YYYY')
    },
  }),
  columnHelper.accessor('expiresInDays', {
    size: 200,
    id: 'expirationDate',
    header: 'Expires In (Days)',
    enableSorting: true,
    cell: ({ row }) => {
      const expiresInDays = row.original?.expiresInDays

      return expiresInDays !== null && expiresInDays !== undefined
        ? expiresInDays === 0
          ? '<1'
          : expiresInDays
        : '-'
    },
  }),
]
