import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { theme } from '@ElementsCapitalGroup/enium-ui'

import { TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import { ExpandableCard } from 'components/expandable-card'
import EvaluationSessionsHistory from 'modules/loan-application/select-loan/evaluation-history'

const {
  lightTheme: { typography },
} = theme

const EvaluationHistory = ({ evaluationSessionsHistory }) => {
  const { t: translate } = useTranslation()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const mobileStyles = {
    borderRadius: 0,
    border: 'none',
  }

  const collapsibleItems = [
    <div key={0} className="uw-hub-page__evaluation-history-item">
      <EvaluationSessionsHistory
        evaluationSessionsHistory={evaluationSessionsHistory}
      />
    </div>,
  ]

  return (
    <div className="uw-hub-page__evaluation-history">
      <ExpandableCard
        headerProps={{
          sx: {
            backgroundColor: '#ffffff',
            padding: isMobileView ? '16px' : '16px 24px',
          },
        }}
        titleTypographyProps={{
          sx: {
            fontWeight: typography.fontWeightBold,
          },
        }}
        title={translate('uwHub.evaluation.history')}
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        isCollapsed={true}
        alwaysCollapsible={true}
        collapsibleItemsStyles={{
          sx: {
            padding: 0,
          },
        }}
        sx={isMobileView ? mobileStyles : {}}
      />
    </div>
  )
}

EvaluationHistory.propTypes = {
  evaluationSessionsHistory: PropTypes.array.isRequired,
}

export default EvaluationHistory
