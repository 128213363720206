import React from 'react'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'

const { ClipboardCheckIcon } = assets
const { Breadcrumbs, Divider } = components

const NewLoanHeader = () => {
  return (
    <div style={{ marginTop: '10px' }}>
      <Breadcrumbs
        sx={{ marginBottom: '20px' }}
        items={[
          {
            icon: <ClipboardCheckIcon />,
          },
          { id: 'newApp', label: 'New Loan Application' },
        ]}
        lastItemClickable={false}
      />
      <div
        style={{
          fontSize: '1.875rem',
          fontWeight: '600',
          color: colors.grey[900],
        }}
      >
        New Loan Application
      </div>
      <div
        style={{ color: colors.grey[600], fontWeight: 400, marginTop: '4px' }}
      >
        Start your loan journey with us, apply now.
      </div>
      <Divider sx={{ mb: '32px', mt: '20px' }} />
    </div>
  )
}

export default NewLoanHeader
