import Api from 'easy-fetch-api'

/** Get list of templates */
export const fetchTemplates = (filters) =>
  Api.get({
    url: '/StipulationTemplate/list',
    query: {
      pageNumber: filters?.pageNumber,
      itemsPerPage: filters?.itemsPerPage,
      sortBy: filters?.sortBy,
      searchBy: filters?.searchBy ? `name ${filters.searchBy}` : undefined,
    },
  })

/** Get list of Stipulation Definitions */
export const fetchDefinitions = () =>
  Api.get({ url: '/StipulationTemplate/list-stipulation-definitions' }).then(
    (list) =>
      list.map((el) => ({
        id: el.guid,
        value: el.friendlyName,
      }))
  )

/** Get list of templates */
export const fetchStipulationsGuidAndName = () => {
  return Api.get({ url: '/StipulationTemplate/list-name-guid' }).catch(
    (error) => console.error(error)
  )
}
