import { useEffect, useMemo, useState } from 'react'
import { validate as uuidValidate } from 'uuid'
import { updateWith, cloneDeep, merge } from 'lodash'

import { JsonViewer } from 'components/json-viewer'
import TabSelector from 'components/tab-selector'
import { editTranslation, getTranslations } from 'modules/translations'
import { getLanguages } from 'modules/organizations/actions'
import { useStore } from 'store'
import { setLoading, showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

export const EditTranslations = () => {
  const { dispatch } = useStore()
  const [selectedTab, setSelectedTab] = useState(null)
  const [languageTabs, setLanguageTabs] = useState([])
  const [organizationId, setOrganizationId] = useState(null)
  const [organizationTranslations, setOrganizationTranslations] = useState(null)
  const [generalTranslations, setGeneralTranslations] = useState(null)
  const [languages, setLanguages] = useState([])

  useEffect(() => {
    const urlParts = window.location.pathname.split('/')
    const orgId = urlParts[urlParts.length - 1]

    if (uuidValidate(orgId)) {
      setOrganizationId(orgId)
    } else {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `Invalid organization id`,
      })
    }
  }, [])

  useEffect(() => {
    setLoading(dispatch, true)
    getLanguages().then((languages) => {
      if (!languages?.length) {
        return
      }

      const languageTabs = languages.map((l) => ({
        value: l.name,
        tooltip: `Translations for ${l.name}`,
      }))
      const defaultLanguageTab = languageTabs.find((t) => t.value === 'English')

      if (!defaultLanguageTab) {
        return
      }

      setLanguageTabs(languageTabs)
      setSelectedTab(defaultLanguageTab.value)
      setLanguages(languages)
      setLoading(dispatch, false)
    })
  }, [])

  useEffect(() => {
    if (!organizationId || !languages?.length) {
      return
    }

    const languageId = languages.find((l) => l.name === selectedTab).id

    getTranslations(languageId).then((res) => {
      setGeneralTranslations(res)
    })

    getTranslations(languageId, organizationId).then((res) => {
      setOrganizationTranslations(res)
    })
  }, [organizationId, selectedTab, languages])

  const handleOnSubmitEdit = async (path, key, data) => {
    const keyPath = `${path}.${key}`
    const languageId = languages.find((l) => l.name === selectedTab).id

    try {
      await editTranslation(
        {
          organizationId: organizationId,
          key: keyPath,
          value: data,
          languageId: languageId,
        },
        dispatch
      )

      // update json locally on success
      const tranlations = cloneDeep(mergedTranslations)
      updateWith(tranlations, path, (n) => {
        return { ...n, [key]: data }
      })
      setOrganizationTranslations(tranlations)
    } catch {
      console.error('Failed to update translation')
    }
  }

  const handleOnTabSelect = (tab) => {
    setGeneralTranslations(null)
    setOrganizationTranslations(null)
    setSelectedTab(tab)
  }

  const mergedTranslations = useMemo(() => {
    if (!generalTranslations) {
      return null
    }

    if (!organizationTranslations) {
      return generalTranslations
    }

    return merge(generalTranslations, organizationTranslations)
  }, [generalTranslations, organizationTranslations])

  const renderTranslationsJson = () => {
    if (!mergedTranslations) {
      return <div>No translations found for {selectedTab}</div>
    }

    return (
      <JsonViewer
        translationJson={mergedTranslations}
        onSubmitEdit={handleOnSubmitEdit}
      />
    )
  }

  if (!selectedTab) {
    return null
  }

  return (
    <div>
      <TabSelector
        tabs={languageTabs}
        selectedTab={selectedTab}
        onTabSelect={handleOnTabSelect}
      />
      {renderTranslationsJson()}
    </div>
  )
}
