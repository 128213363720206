import React, { useState, useEffect, useMemo } from 'react'

import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as UsersSvg } from 'assets/users.svg'

import { useStore } from 'store'
import TextField from 'components/input'
import { initRoles } from 'modules/admin-roles-management/actions'

import Table from 'components/table'
import EmptyList from 'components/empty-list'

import '../aus-templates/style.scss'
import './index.scss'

const { createColumnHelper, Button, Breadcrumbs } = components
const { SearchLgIcon, PlusIcon } = assets

const RolesManagement = () => {
  const columnHelper = createColumnHelper()
  const [searchBy, setSearchBy] = useState('')

  const { dispatch, state: store } = useStore()
  const { loading } = store.global

  const { roles, roleTypes, roleClaims } = store.adminRolesManagement
  const navigate = useNavigate()

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Role Names',
      }),
      columnHelper.accessor('selectedPermissions', {
        id: 'selectedPermissions',
        header: 'Selected Permissions',
        cell: (info) => info.row.original.permissions.length,
      }),
    ]
  }, [])

  useEffect(() => {
    if (!roles.length || !roleTypes.length || !roleClaims) {
      initRoles(dispatch)
    }
  }, [])

  const filteredRoles = useMemo(() => {
    if (!searchBy) {
      return roles
    } else {
      return roles.filter((role) =>
        role.name.toLowerCase().includes(searchBy.toLowerCase())
      )
    }
  }, [roles, searchBy])

  if (loading) {
    return null
  }

  return (
    <>
      <Breadcrumbs
        items={[{ icon: <UsersSvg /> }, { label: 'Roles' }]}
        onClick={() => {}}
        sx={{ marginBottom: '32px' }}
      />
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Roles</div>
          <div>View all your Roles in one place.</div>
        </div>
        <Button
          startIcon={<PlusIcon />}
          onClick={() => navigate('/admin/roles/add-new-role')}
        >
          Add New Role
        </Button>
      </div>
      <TextField
        onChange={setSearchBy}
        value={searchBy}
        placeholder={'Search roles'}
        type="search"
        disabled={false}
        style={{ marginBottom: '32px', minWidth: '300px' }}
        startIcon={<SearchLgIcon fontSize="small" />}
      />
      {filteredRoles?.length ? (
        <Table
          data={filteredRoles}
          columns={columns}
          title={'Roles'}
          hasCheckboxes={false}
          onRowClick={(ev, row) => {
            navigate(`/admin/roles/${row?.original.roleId}`)
          }}
        />
      ) : (
        <EmptyList
          title="No Roles Found"
          description="We couldn't find any data matching your criteria."
        />
      )}
    </>
  )
}

export default RolesManagement
