import { colors, theme } from '@ElementsCapitalGroup/enium-ui'
const {
  lightTheme: { typography },
} = theme

const users = {
  page: {
    'search-module__search': {
      marginTop: 0,
    },
  },
  ellipsisWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  orgDetailsUser: {
    display: 'flex',
    alignItems: 'center',
  },
  orgDetailsUserDetails: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '12px',
    overflow: 'hidden',
  },
  orgDetailsUserName: {
    color: colors.grey[900],
    fontWeight: 500,
    width: '100%',
  },
  orgDetailsTitle: {
    color: typography.h6.color,
    fontSize: typography.h6.fontSize,
    marginBottom: '16px',
    marginTop: 0,
  },
  searchBar: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
}

const subOrgs = {
  searchBar: { display: 'flex', width: '100%' },
  searchInput: {
    minWidth: '300px',
    margin: '0 0 32px',
  },
  createNewBtn: { marginLeft: 'auto' },
}

const userDetails = {
  table: {
    marginTop: '32px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',
  },
}

export const styles = {
  users,
  subOrgs,
  userDetails,
}
