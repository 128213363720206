import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextField, { INPUT_TYPES } from 'components/input'

const ExpandedRowContents = ({ data, onSave, onCancel }) => {
  const { applicationNumber } = data
  const [state, setState] = useState({
    loanAmount: '',
    fundingAmount: '',
    dealerDeposit: '',
    dueEnium: '',
    dealerFeePercentage: '',
    extraFeePercentage: '',
    lenderDiscountPercentage: '',
    extraDiscountPercentage: '',
    stageFundingFeePercentage: '',
  })

  useEffect(() => {
    setState({
      ...state,
      loanAmount: data.loanAmount,
      fundingAmount: data.fundingAmount,
      dealerFeePercentage: data.dealerFeePercentage || 0,
      extraFeePercentage: data.extraFeePercentage || 0,
      lenderDiscountPercentage: data.lenderDiscountPercentage || 0,
      extraDiscountPercentage: data.extraDiscountPercentage || 0,
      stageFundingFeePercentage: data.stageFundingFeePercentage || 0,
    })
  }, [data])

  const updateField = (name) => (value) => setState({ ...state, [name]: value })

  return (
    <div className="afp__expanded">
      <div className="afp__expanded__header">
        <div className="afp__expanded__title">{applicationNumber}</div>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button onClick={() => onSave(state)}>Save Changes</Button>
      </div>
      <div className="afp__grid-inputs">
        <TextField
          label="Loan Amount"
          value={state.loanAmount}
          onChange={updateField('loanAmount')}
          type={INPUT_TYPES.CURRENCY}
          prefix="$"
        />
        <TextField
          label="Funding Amount"
          value={state.fundingAmount}
          onChange={updateField('fundingAmount')}
          type={INPUT_TYPES.CURRENCY}
          prefix="$"
        />
        <TextField
          label="Dealer Fee Percentage"
          value={state.dealerFeePercentage}
          onChange={updateField('dealerFeePercentage')}
          type={INPUT_TYPES.PERCENTAGE}
        />
        <TextField
          label="Extra Fee Percentage"
          value={state.extraFeePercentage}
          onChange={updateField('extraFeePercentage')}
          type={INPUT_TYPES.PERCENTAGE}
        />
        <TextField
          label="Lender Discount Percentage"
          value={state.lenderDiscountPercentage}
          onChange={updateField('lenderDiscountPercentage')}
          type={INPUT_TYPES.PERCENTAGE}
        />
        <TextField
          label="Extra Discount Percentage"
          value={state.extraDiscountPercentage}
          onChange={updateField('extraDiscountPercentage')}
          type={INPUT_TYPES.PERCENTAGE}
        />
        <TextField
          label="Stage Funding Fee Percentage"
          value={state.stageFundingFeePercentage}
          onChange={updateField('stageFundingFeePercentage')}
          type={INPUT_TYPES.PERCENTAGE}
        />
      </div>
    </div>
  )
}

ExpandedRowContents.propTypes = {
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ExpandedRowContents
