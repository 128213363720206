import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './style.scss'

const TabSelector = ({
  tabs,
  selectedTab,
  onTabSelect,
  className = '',
  hasSecondaryStyle,
}) => {
  return (
    <div
      className={cx('tab-selector', className, {
        'tab-selector--secondary': hasSecondaryStyle,
      })}
    >
      {tabs.map((el) => {
        const value = el.value || el

        if (value === 'Edit translations') {
          return null // Hide translations page from tabs
        }

        return (
          <div
            key={value}
            className={cx('tab-selector__tab', {
              'tab-selector__tab--selected': value === selectedTab,
            })}
            onClick={() => value !== selectedTab && onTabSelect(value)}
          >
            <div className="tab-selector__tab__inner">{value}</div>
            {el.tooltip && (
              <div className="tab-selector__tooltip">{el.tooltip}</div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const TabPropType = PropTypes.shape({
  value: PropTypes.string,
  tooltip: PropTypes.string,
})

TabSelector.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, TabPropType])
  ).isRequired,
  selectedTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    TabPropType,
  ]),
  onTabSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  hasSecondaryStyle: PropTypes.bool,
}

export default TabSelector
