import { colors, theme } from '@ElementsCapitalGroup/enium-ui'
const {
  lightTheme: { typography },
} = theme

const users = {
  page: {
    'search-module__search': {
      marginTop: 0,
    },
  },
  orgDetailsUser: {
    display: 'flex',
    alignItems: 'center',
  },
  orgDetailsUserDetails: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '12px',
  },
  orgDetailsUserName: {
    color: colors.grey[900],
    fontWeight: 500,
    width: '100%',
  },
  orgDetailsTitle: {
    color: typography.h6.color,
    fontSize: typography.h6.fontSize,
    marginBottom: '16px',
    marginTop: 0,
  },
  searchBar: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
}
const loanAssociation = {
  subtitle: {
    color: colors.grey[500],
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    marginBottom: 0,
    marginTop: '32px',
  },
  searchBar: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
  },
  submitButton: {
    margin: 0,
    maxWidth: '250px',
    marginLeft: 'auto',
    width: '137px',
  },
  dropdown: { marginBottom: '20px', maxWidth: '400px' },
  chip: { marginRight: '12px', marginTop: '10px' },
}

const organizations = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '25px 16px',
  },
  checkboxes: {
    display: 'grid',
    width: '66%',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '99px',
    margin: '24px 0',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },

  card: {
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      width: '100%',
      fontSize: '12px',
      fontWeight: 500,
      alignItems: 'center',
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      width: '100%',
      fontSize: '14px',
      alignItems: 'center',
      padding: '8px 0',
    },
    rowCell: { paddingLeft: '3px' },
    avatar: {
      marginRight: '10px',
      width: '32px',
      height: '32px',
      fontSize: '1rem',
    },
    avatarWrapper: { display: 'flex', alignItems: 'center' },
  },
}

const subOrgs = {
  searchBar: { display: 'flex', width: '100%' },
  searchInput: {
    minWidth: '300px',
    margin: '0 0 32px',
  },
  createNewBtn: { marginLeft: 'auto' },
}

const funding = {
  paper: {
    padding: '12px',
    border: `1px solid ${colors.grey[200]}`,
  },
  headerContainer: {
    padding: '12px',
    marginBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: '600',
  },
  headerSubtitle: {
    fontSize: '14px',
    marginTop: '4px',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: '0 16px',
  },
  stageHeader: {
    flex: 3,
  },
  percentageHeader: {
    width: '160px',
  },
  inUseHeader: {
    flex: 1,
    textAlign: 'center',
  },
  stageRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0 12px',
    padding: '0 16px',
    fontWeight: 'bold',
  },
  stageName: {
    flex: 3,
    fontSize: '16px',
  },
  inputField: {
    width: '160px',
  },
  checkboxContainer: {
    flex: 1,
    textAlign: 'center',
  },
  remainingValues: {
    padding: '12px',
    paddingTop: '24px',
    marginTop: '24px',
    color: 'black',
    fontWeight: '600',
    textAlign: 'end',
    borderTop: `1px solid ${colors.grey[200]}`,
  },
  remainingValuesError: {
    padding: '12px',
    paddingTop: '24px',
    marginTop: '24px',
    color: 'red',
    fontWeight: '600',
    textAlign: 'end',
    borderTop: `1px solid ${colors.grey[200]}`,
  },
  saveButton: {
    marginTop: '24px',
  },
}

export const styles = {
  users,
  loanAssociation,
  organizations,
  subOrgs,
  funding,
}
