import { useRef, useState } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'

import { useClickOutside, useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { UW_APPROVAL_STATUS_IDS } from 'modules/uw-hub/constants'
import { updateUwApprovalStatus } from 'modules/uw-hub/actions'

import './style.scss'
import DeclineDialog from './decline-dialog'

const { Button } = components
const { ChevronDownIcon, ChevronUpIcon } = assets

const BUTTON_TEXT_COLOR_MAP = {
  [UW_APPROVAL_STATUS_IDS.Approved]: colors.green[700],
  [UW_APPROVAL_STATUS_IDS.Declined]: colors.red[700],
  [UW_APPROVAL_STATUS_IDS.PendingApproval]: colors.yellow[700],
  [UW_APPROVAL_STATUS_IDS.ManualCheck]: colors.yellow[700],
}

const BUTTON_COLOR_MAP = {
  [UW_APPROVAL_STATUS_IDS.Approved]: colors.green[50],
  [UW_APPROVAL_STATUS_IDS.Declined]: colors.red[50],
  [UW_APPROVAL_STATUS_IDS.PendingApproval]: colors.yellow[50],
  [UW_APPROVAL_STATUS_IDS.ManualCheck]: colors.yellow[50],
}

const UWApprovalStatus = ({
  loanApplicationId,
  portalApprovalStatusId,
  uwApprovalStatusId,
  sectionTypeId,
  updateSectionApprovalStatus,
  displayPortalApprovalStatus,
  displayUwApprovalStatus,
  approvalStyles = {},
}) => {
  const { t: translate } = useTranslation()
  const { dispatch } = useStore()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const dropdownRef = useRef(null)

  const updateApprovalStatus = (approvalStatusId, reason = '') => {
    setLoading(true)
    updateUwApprovalStatus(
      dispatch,
      loanApplicationId,
      sectionTypeId,
      approvalStatusId,
      reason
    )
      .then(() => {
        setIsDeclineDialogOpen(false)
        setIsDropdownOpen(false)
        updateSectionApprovalStatus(approvalStatusId, reason)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onClickOutside = () => setIsDropdownOpen(false)
  useClickOutside(dropdownRef, onClickOutside)

  return (
    <>
      <div className="uw-hub-page__uw-approval-buttons">
        {displayPortalApprovalStatus && (
          <div className="uw-hub-page__uw-approval-buttons-portal">
            <Button
              variant={
                isMobileView ? BUTTON_VARIANTS.TEXT : BUTTON_VARIANTS.CONTAINED
              }
              disabled
              style={{
                color: BUTTON_TEXT_COLOR_MAP[portalApprovalStatusId],
                backgroundColor: BUTTON_COLOR_MAP[portalApprovalStatusId],
              }}
            >
              {translate('uwHub.common.portalApproval.title')}:{' '}
              {translate(`uwHub.common.approval.${portalApprovalStatusId}`)}
            </Button>
          </div>
        )}
        {displayUwApprovalStatus && (
          <div
            className="uw-hub-page__uw-approval-buttons-uw"
            style={approvalStyles}
          >
            <Button
              ref={dropdownRef}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
              endIcon={
                isDropdownOpen ? (
                  <ChevronUpIcon
                    style={{ color: '#344054' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsDropdownOpen(false)
                    }}
                  />
                ) : (
                  <ChevronDownIcon
                    style={{ color: '#344054' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsDropdownOpen(true)
                    }}
                  />
                )
              }
              style={{
                color: BUTTON_TEXT_COLOR_MAP[uwApprovalStatusId],
              }}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {translate('uwHub.common.uwApproval.title')}:{' '}
              {translate(`uwHub.common.approval.${uwApprovalStatusId}`)}
            </Button>
            {isDropdownOpen && (
              <div className="uw-hub-page__uw-approval-buttons-dialog">
                <Button
                  color={BUTTON_COLORS.SUCCESS}
                  variant={BUTTON_VARIANTS.TEXT}
                  disableElevation
                  onFocusVisible={() => {}}
                  disabled={
                    uwApprovalStatusId === UW_APPROVAL_STATUS_IDS.Approved
                  }
                  onClick={() =>
                    updateApprovalStatus(UW_APPROVAL_STATUS_IDS.Approved)
                  }
                >
                  {translate('uwHub.common.uwApproval.buttons.approve')}
                </Button>

                <Button
                  color={BUTTON_COLORS.ERROR}
                  variant={BUTTON_VARIANTS.TEXT}
                  disableElevation
                  onFocusVisible={() => {}}
                  disabled={
                    uwApprovalStatusId === UW_APPROVAL_STATUS_IDS.Declined
                  }
                  onClick={() => setIsDeclineDialogOpen(true)}
                >
                  {translate('uwHub.common.uwApproval.buttons.decline')}
                </Button>
                <Button
                  color={BUTTON_COLORS.WARNING}
                  variant={BUTTON_VARIANTS.TEXT}
                  disableElevation
                  onFocusVisible={() => {}}
                  disabled={
                    uwApprovalStatusId ===
                    UW_APPROVAL_STATUS_IDS.PendingApproval
                  }
                  onClick={() =>
                    updateApprovalStatus(UW_APPROVAL_STATUS_IDS.PendingApproval)
                  }
                >
                  {translate('uwHub.common.uwApproval.buttons.pending')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {isDeclineDialogOpen && (
        <DeclineDialog
          open={isDeclineDialogOpen}
          loading={loading}
          onClose={() => setIsDeclineDialogOpen(false)}
          onDecline={(value) =>
            updateApprovalStatus(UW_APPROVAL_STATUS_IDS.Declined, value)
          }
        />
      )}
    </>
  )
}

UWApprovalStatus.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  portalApprovalStatusId: PropTypes.number,
  uwApprovalStatusId: PropTypes.number.isRequired,
  sectionTypeId: PropTypes.number.isRequired,
  updateSectionApprovalStatus: PropTypes.func.isRequired,
  displayPortalApprovalStatus: PropTypes.bool.isRequired,
  displayUwApprovalStatus: PropTypes.bool.isRequired,
  approvalStyles: PropTypes.object,
}

export default UWApprovalStatus
