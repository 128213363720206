import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { debounce } from 'lodash'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import Input from 'components/input'
import { ExpandableCard } from 'components/expandable-card'
import { sumFloat } from 'common/number'
import { dobDateFormat } from 'common/constants'
import IncomeCard from './income-card'
import IncomeChart from './chart'
import IncomeTabs from './tabs'
import {
  getBorrowerState,
  MONTHLY_OPTIONS,
  PERIOD_TABS,
  TABS,
  CALCULATIONS_INNER_STATE,
  EMPLOYER_STATE,
} from './utils'
import { styles } from '../style.js'

const { Card } = components
const { PlusIcon, TrashIcon } = assets

const WageEarnerCalculator = ({ state, setState }) => {
  const incomeCalculatorRef = useRef(null)
  const { data, selectedBorrower, innerSelectedTabs, calculations } = state
  const [collapsed, setCollapsed] = useState({
    borrower: [false],
    coBorrower: [false],
  })

  const setData = (data) => setState((prev) => ({ ...prev, data }))
  const setSelectedBorrower = (selectedBorrower) =>
    setState((prev) => ({ ...prev, selectedBorrower }))
  const setInnerSelectedTabs = (innerSelectedTabs) =>
    setState((prev) => ({ ...prev, innerSelectedTabs }))
  const setCalculationsForBorrower = (selectedBorrower, calculations) => {
    setState((prev) => ({
      ...prev,
      calculations: {
        ...prev.calculations,
        [selectedBorrower]: calculations,
      },
    }))
  }

  const borrowersWithData = Object.keys(data).filter(
    (borrowerType) => !!data[borrowerType]
  )
  const crtBorrower = data[selectedBorrower]

  const onFieldChange = (employerIdx, fieldKey) => (value) => {
    setData({
      ...data,
      [selectedBorrower]: crtBorrower.map((borrower, idx) => {
        if (idx === employerIdx) {
          return { ...borrower, [fieldKey]: value }
        }
        return borrower
      }),
    })
  }

  const onAddBorrower = () => {
    setData({ ...data, coBorrower: getBorrowerState() })
    setSelectedBorrower(TABS.CO_BORROWER)
  }
  const onRemoveBorrower = () => {
    setData({ ...data, coBorrower: undefined })
    setSelectedBorrower(TABS.BORROWER)
  }

  const onAddEmployer = () => {
    const existingState = data[selectedBorrower]
    const container = incomeCalculatorRef.current
    setCollapsed({
      ...collapsed,
      [selectedBorrower]: [
        ...collapsed[selectedBorrower].map(() => true),
        false,
      ],
    })
    setInnerSelectedTabs({
      ...innerSelectedTabs,
      [selectedBorrower]: [
        ...innerSelectedTabs[selectedBorrower],
        { basePay: PERIOD_TABS.YEARLY },
      ],
    })
    setData({
      ...data,
      [selectedBorrower]: [...existingState, { ...EMPLOYER_STATE }],
    })

    setTimeout(() => {
      container
        ?.querySelector(`#employer-${existingState.length}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 100)
  }

  const onRemoveEmployer = (employerIdx) => {
    const existingState = data[selectedBorrower]
    setCollapsed({
      ...collapsed,
      [selectedBorrower]: [
        ...collapsed[selectedBorrower].slice(0, employerIdx),
        ...collapsed[selectedBorrower].slice(employerIdx + 1),
      ],
    })
    setInnerSelectedTabs({
      ...innerSelectedTabs,
      [selectedBorrower]: [
        ...innerSelectedTabs[selectedBorrower].slice(0, employerIdx),
        ...innerSelectedTabs[selectedBorrower].slice(employerIdx + 1),
      ],
    })
    setData({
      ...data,
      [selectedBorrower]: [
        ...existingState.slice(0, employerIdx),
        ...existingState.slice(employerIdx + 1),
      ],
    })
  }

  const performCalculations = useCallback(
    debounce((selectedBorrower, borrowerData, innerSelectedTabBorrower) => {
      const borrowerCalculations = { total: 0, employers: [] }

      for (const [employerIdx, employer] of borrowerData.entries()) {
        const employerCalculations = { ...CALCULATIONS_INNER_STATE }
        ;['basePay', 'overtime', 'bonus', 'commission', 'other'].forEach(
          (field) => {
            const section = employer[field]
            // Case for Monthly Setup (currently only for Base Pay)
            if (
              section.monthly &&
              innerSelectedTabBorrower[employerIdx][field] ===
                PERIOD_TABS.MONTHLY
            ) {
              employerCalculations[field] = { total: 0 }
              const { isChecked, rateOfPay, payFrequency, weeklyHours } =
                section.monthly
              if (!isChecked || !rateOfPay || !payFrequency) {
                return
              }
              const rate = parseFloat(rateOfPay)
              let adjustment = 1
              if (payFrequency?.id === MONTHLY_OPTIONS.HOURLY.id) {
                adjustment = parseFloat(weeklyHours) || 0
              }

              const total =
                rate *
                adjustment *
                MONTHLY_OPTIONS[payFrequency?.id].amountInMonth

              employerCalculations[field].total = parseFloat(total.toFixed(2))
            } else {
              employerCalculations[field] = Object.keys(section).reduce(
                (acc, year) => {
                  const { from, through, totalEarnings, isChecked } =
                    section[year]

                  if (!isChecked || !from || !through) {
                    return acc
                  }

                  const startDate = moment(from, dobDateFormat)
                  const endDate = moment(through, dobDateFormat)
                  const days = endDate.diff(startDate, 'days') + 1
                  const daysInMonth = []
                  for (
                    let i = startDate.month() + 1;
                    i <= endDate.month() + 1;
                    i++
                  ) {
                    daysInMonth.push(
                      moment(`${year}-${i}`, 'YYYY-M').daysInMonth()
                    )
                  }
                  const averageDaysInMonth =
                    daysInMonth.reduce((acc, crt) => acc + crt, 0) /
                    daysInMonth.length

                  const months = parseFloat(
                    (days / averageDaysInMonth).toFixed(2)
                  )
                  acc[year] = {
                    monthly: parseFloat(sumFloat(totalEarnings / months)),
                    months: months,
                    totalEarnings,
                  }

                  return acc
                },
                {}
              )
              const totalSum = Object.values(
                employerCalculations[field]
              ).reduce((acc, crt) => acc + crt.totalEarnings, 0)
              const totalMonths = Object.values(
                employerCalculations[field]
              ).reduce((acc, crt) => acc + crt.months, 0)
              employerCalculations[field].total =
                parseFloat((totalSum / totalMonths).toFixed(2)) || 0
            }
            employerCalculations.total = parseFloat(
              (
                employerCalculations.total + employerCalculations[field].total
              ).toFixed(2)
            )
          }
        )

        borrowerCalculations.employers.push(employerCalculations)
      }

      borrowerCalculations.total = borrowerCalculations.employers.reduce(
        (acc, crt) => parseFloat(sumFloat(acc + crt.total)),
        0
      )

      setCalculationsForBorrower(selectedBorrower, { ...borrowerCalculations })
    }, 200),
    []
  )

  /** Re-do calculations for the Current Borrower */
  useEffect(() => {
    performCalculations(
      selectedBorrower,
      crtBorrower,
      innerSelectedTabs[selectedBorrower]
    )
  }, [crtBorrower, innerSelectedTabs[selectedBorrower]])

  return (
    <div style={styles.incomePage} ref={incomeCalculatorRef}>
      <div style={{ ...styles.section, display: 'flex' }}>
        <div style={styles.sectionLeft}>
          <IncomeTabs
            selectedTab={selectedBorrower}
            onTabChanged={setSelectedBorrower}
            tabs={borrowersWithData.map((borrowerType, idx) => ({
              id: borrowerType,
              label: `Borrower #${idx + 1}`,
            }))}
            onPlusClicked={borrowersWithData.length < 2 ? onAddBorrower : null}
            onRemoveClicked={
              borrowersWithData.length > 1 ? onRemoveBorrower : null
            }
          />
          {crtBorrower.map((employerValues, employerIdx) => {
            const employerCalculations =
              calculations[selectedBorrower].employers[employerIdx] || {}

            return (
              <ExpandableCard
                key={employerIdx}
                id={`employer-${employerIdx}`}
                title={
                  <div className="title title--inline">
                    <div>Employer #{employerIdx + 1}</div>
                    {crtBorrower.length > 1 && (
                      <div
                        style={{ ...styles.plusTab, marginLeft: '8px' }}
                        onClick={() => onRemoveEmployer(employerIdx)}
                      >
                        <TrashIcon sx={styles.plusTabSvg} />
                      </div>
                    )}
                    <div
                      style={{ ...styles.plusTab, marginLeft: '8px' }}
                      onClick={onAddEmployer}
                    >
                      <PlusIcon sx={styles.plusTabSvg} />
                    </div>
                  </div>
                }
                headerProps={{
                  sx: { ...styles.expandableCard.header },
                }}
                sx={styles.card}
                isCollapsable={true}
                alwaysCollapsible={true}
                items={[]}
                isCollapsed={collapsed[selectedBorrower][employerIdx]}
                collapsibleItems={[
                  <div key={employerIdx} style={{ width: '100%' }}>
                    <Card title="Borrower Details" sx={styles.card}>
                      <div className="align-center">
                        <Input
                          label="Borrower Name"
                          placeholder="Enter your borrower name"
                          value={employerValues.name}
                          onChange={onFieldChange(employerIdx, 'name')}
                          style={{ marginRight: '24px', width: '300px' }}
                        />
                        <Input
                          label="Employer"
                          placeholder="Enter your employer"
                          value={employerValues.employer}
                          onChange={onFieldChange(employerIdx, 'employer')}
                          style={{ width: '300px' }}
                        />
                      </div>
                    </Card>
                    <IncomeCard
                      title="Base Pay"
                      description="Enter the employment base pay manually or use by the active work period within the past year(s)."
                      data={employerValues.basePay}
                      setData={onFieldChange(employerIdx, 'basePay')}
                      calculations={employerCalculations.basePay}
                      defaultExpanded={true}
                      hasMonthlySetup={true}
                      selectedTab={
                        innerSelectedTabs[selectedBorrower][employerIdx].basePay
                      }
                      setSelectedTab={(tab) =>
                        setInnerSelectedTabs({
                          ...innerSelectedTabs,
                          [selectedBorrower]: innerSelectedTabs[
                            selectedBorrower
                          ].map((el, idx) => {
                            if (idx === employerIdx) {
                              return { basePay: tab }
                            }
                            return el
                          }),
                        })
                      }
                    />
                    <IncomeCard
                      title="Overtime"
                      description="You can add overtime from your past year(s)."
                      data={employerValues.overtime}
                      setData={onFieldChange(employerIdx, 'overtime')}
                      calculations={employerCalculations.overtime}
                    />
                    <IncomeCard
                      title="Bonus"
                      description="You can add bonus earnings from your past year(s)."
                      data={employerValues.bonus}
                      setData={onFieldChange(employerIdx, 'bonus')}
                      calculations={employerCalculations.bonus}
                    />
                    <IncomeCard
                      title="Commission"
                      description="You can add commissions from your past year(s)."
                      data={employerValues.commission}
                      setData={onFieldChange(employerIdx, 'commission')}
                      calculations={employerCalculations.commission}
                    />
                    <IncomeCard
                      title="Other income"
                      description="You can add other income(s) from your past year(s)."
                      data={employerValues.other}
                      setData={onFieldChange(employerIdx, 'other')}
                      calculations={employerCalculations.other}
                    />
                  </div>,
                ]}
              ></ExpandableCard>
            )
          })}
        </div>
        <div style={styles.sectionRight}>
          <IncomeChart
            calculations={
              data.coBorrower
                ? calculations
                : { borrower: calculations.borrower }
            }
          />
        </div>
      </div>
    </div>
  )
}

WageEarnerCalculator.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
}

export default WageEarnerCalculator
