import { colors } from '@ElementsCapitalGroup/enium-ui'

export const expandableCard = {
  headerProps: (isMobileView, shouldAddMargin = false) => ({
    sx: {
      backgroundColor: '#ffffff',
      padding: '16px 24px',
      '.MuiCardHeader-action': {
        marginTop: shouldAddMargin ? (isMobileView ? '-2px' : '7px') : '0px',
      },
    },
  }),
  titleProps: (isMobileView) => ({
    sx: {
      fontSize: isMobileView ? '0.875rem' : '1.125rem',
      fontWeight: '700',
    },
  }),
}

export const dataReview = {
  wrapper: { display: 'flex', flexWrap: 'wrap', width: '100%' },
  expandable: { marginTop: '8px' },
  label: {
    fontSize: '0.75rem',
    color: colors.grey[600],
    fontWeight: 500,
    marginBottom: '5px',
  },
  value: {
    fontSize: '0.875rem',
    color: colors.grey[900],
    fontWeight: 500,
    marginBottom: '10px',
  },
}
