import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2'
import { colors } from '@ElementsCapitalGroup/enium-ui'
import { formatInUSFormat } from 'common/number'
import { TABS } from './utils'
import { styles } from '../style'

const BORROWER_COLOR = colors.red[500]
const COBORROWER_COLOR = colors.green[500]
const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
}

const IncomeChart = ({ calculations }) => {
  const data = useMemo(() => {
    const borrowerData = calculations[TABS.BORROWER].employers
    const coBorrowerData = calculations[TABS.CO_BORROWER]?.employers
    let labels = borrowerData.map(
      (_, idx) => `Borrower #1 - Employer #${idx + 1}`
    )
    let values = borrowerData.map((employer) => employer.total)
    let bgColors = new Array(borrowerData.length).fill(BORROWER_COLOR)
    if (coBorrowerData) {
      labels = labels.concat(
        coBorrowerData.map((_, idx) => `Borrower #2 - Employer #${idx + 1}`)
      )
      values = values.concat(coBorrowerData.map((employer) => employer.total))
      bgColors = bgColors.concat(
        new Array(coBorrowerData.length).fill(COBORROWER_COLOR)
      )
    }
    const total = values.reduce((acc, value) => acc + value, 0)
    return {
      labels,
      datasets: [
        {
          label: 'Total Qualifying Income',
          data: values,
          backgroundColor: bgColors,
          hoverOffset: 4,
          borderJoinStyle: 'bevel',
          cutout: 70,
        },
      ],
      total,
    }
  }, [calculations])

  return (
    <div style={styles.chart.bg}>
      <div style={{ width: '200px', height: '200px' }}>
        <Doughnut data={data} options={chartOptions} />
      </div>
      <div style={styles.chart.label}>Total Qualifying Income</div>
      <div style={styles.chart.total}>${formatInUSFormat(data.total)}</div>
      <div style={styles.chart.label}>Qualifying Income Breakdown</div>
      <div style={styles.chart.legend}>
        {Object.keys(calculations).map((borrower, idx) => {
          return (
            <div key={idx}>
              <div style={styles.chart.legendLi}>
                <span
                  style={{
                    ...styles.chart.bullet,
                    backgroundColor:
                      idx === 0 ? colors.red[600] : colors.green[600],
                  }}
                ></span>
                Borrower #{idx + 1}
                <span style={styles.chart.legendTotal}>
                  ${formatInUSFormat(calculations[borrower].total)}
                </span>
              </div>
              {calculations[borrower].employers.map((employer, employerIdx) => (
                <div
                  key={`${idx}-${employerIdx}`}
                  style={{ ...styles.chart.legendLi, paddingLeft: '24px' }}
                >
                  <span
                    style={{
                      ...styles.chart.bullet,
                      backgroundColor:
                        idx === 0 ? BORROWER_COLOR : COBORROWER_COLOR,
                    }}
                  ></span>
                  Employer #{employerIdx + 1}
                  <span
                    style={{
                      ...styles.chart.legendTotal,
                      fontWeight: 'normal',
                    }}
                  >
                    $
                    {formatInUSFormat(
                      calculations[borrower].employers[employerIdx].total
                    )}
                  </span>
                </div>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

IncomeChart.propTypes = {
  calculations: PropTypes.object.isRequired,
}

export default IncomeChart
