import React from 'react'
import PropTypes from 'prop-types'
import { Editor, RichUtils } from 'draft-js'
import './index.scss'

function RichTextEditor({ value, onChange }) {
  const editor = React.useRef(null)

  function focusEditor() {
    editor.current.focus()
  }

  const StyleButton = (prop) => {
    const onClickButton = (e) => {
      e.preventDefault()
      prop.onToggle(prop.style)
    }
    return <button onMouseDown={onClickButton}>{prop.label}</button>
  }

  const BlockStyleControls = (prop) => {
    const { editorState } = prop
    const selection = editorState.getSelection()
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType()

    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) => (
          <div
            key={type.label}
            className={
              type.style !== blockType
                ? `RichEditor-styleButton`
                : `RichEditor-styleButton RichEditor-styleButton--active`
            }
          >
            <StyleButton
              label={type.label}
              onToggle={prop.onToggle}
              style={type.style}
            />
          </div>
        ))}
      </div>
    )
  }

  const InlineStyleControls = (prop) => {
    const currentStyle = prop.editorState.getCurrentInlineStyle()

    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) => (
          <div
            key={type.label}
            className={
              !currentStyle.has(type.style)
                ? `RichEditor-styleButton`
                : `RichEditor-styleButton RichEditor-styleButton--active`
            }
          >
            <StyleButton
              key={type.label}
              label={type.label}
              onToggle={prop.onToggle}
              style={type.style}
            />
          </div>
        ))}
      </div>
    )
  }

  const onInlineClick = (e) => {
    const nextState = RichUtils.toggleInlineStyle(value, e)
    onChange(nextState)
  }

  const onBlockClick = (e) => {
    const nextState = RichUtils.toggleBlockType(value, e)
    onChange(nextState)
  }

  return (
    <div className="rich-text" onClick={focusEditor}>
      <div>
        <BlockStyleControls editorState={value} onToggle={onBlockClick} />
        <InlineStyleControls editorState={value} onToggle={onInlineClick} />
      </div>
      <div>
        <Editor ref={editor} editorState={value} onChange={onChange} />
      </div>
    </div>
  )
}

RichTextEditor.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default RichTextEditor

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
]

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
]
