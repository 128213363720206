import Api from 'easy-fetch-api'

import {
  getEvaluationSessions,
  getNotes,
  getNTPs,
} from 'modules/loan-application/actions'
import { downloadFileBase64 } from 'common/utils'
import { showNotification } from 'modules/global/actions'
import { getNtpTypeId } from 'modules/loan-application/ntp/helpers'
import { NTP_SECTIONS_IDS } from 'modules/loan-application/ntp/constants'

export const UW_HUB_ACTIONS = {
  SET_LOADING: 'uwHub.setLoading',
  SET_LOAN_APPLICATION_ID: 'uwHub.setLoanApplicationId',
  SET_LOAN_APPLICATION_DETAILS: 'uwHub.setLoanApplicationDetails',
  SET_LOAN_APPLICATION_STATE: 'uwHub.setLoanApplicationState',
  SET_SOLAR_CONTRACTS_DATA: 'uwHub.setSolarContractsData',
  SET_CREDIT_REPORTS_DATA: 'uwHub.setCreditReportsData',
  SET_EVALUATION_SESSIONS: 'uwHub.setEvaluationSessions',
  SET_STIPULATIONS: 'uwHub.setStipulations',
  SET_FINAL_STIPULATIONS: 'uwHub.setFinalStipulations',
  UPDATE_NTP_STATUS: 'uwHub.updateNtpStatus',
  SET_NOTES: 'uwHub.setNotes',
  SET_PROPERTY_TITLE_DETAILS: 'uwHub.setPropertyTitleDetails',
  SET_INCOME_DATA: 'uwHub.setIncomeData',
  SET_DATA_REVIEW_DATA: 'uwHub.setDataReviewData',
  SET_GENERAL_DATA: 'uwHub.setGeneralData',
  SET_EMPLOYMENT_DATA: 'uwHub.setEmploymentData',
  SET_IDENTIFICATION_DATA: 'uwHub.setIdentificationData',
  UPDATE_INCOME_DATA: 'uwHub.updateIncomeData',
}

export const init = (dispatch, loanApplicationId) => {
  dispatch({
    type: UW_HUB_ACTIONS.SET_LOAN_APPLICATION_ID,
    payload: loanApplicationId,
  })
  dispatch({
    type: UW_HUB_ACTIONS.SET_LOADING,
    payload: true,
  })

  Promise.all([
    getLoanApplicationDetails(dispatch, loanApplicationId),
    getCreditReportsData(dispatch, loanApplicationId),
    getNotesList(dispatch, loanApplicationId),
    getPropertyTitleReport(dispatch, loanApplicationId),
    getEvaluationSessionsList(dispatch, loanApplicationId),
    getSolarContractsData(dispatch, loanApplicationId),
    getIncomeSectionData(dispatch, loanApplicationId),
    getDataReviewData(dispatch, loanApplicationId),
    getGeneralData(dispatch, loanApplicationId),
    getStipulations(dispatch, loanApplicationId, false),
    getEmploymentData(dispatch, loanApplicationId),
    getIdentificationData(dispatch, loanApplicationId),
  ])
    .then((response) => {
      if (!response || !Array.isArray(response) || !response.length) {
        return
      }

      return (
        getNtpTypeId(response[0].loanApplicationStateId) ===
        NTP_SECTIONS_IDS.FINAL
      )
    })
    .then((isFinal) => {
      if (isFinal) {
        getStipulations(dispatch, loanApplicationId, isFinal)
      }
    })
    .finally(() => {
      dispatch({
        type: UW_HUB_ACTIONS.SET_LOADING,
        payload: false,
      })
    })
}

export const getNotesList = (dispatch, loanApplicationId) => {
  return getNotes(loanApplicationId).then(
    (response) =>
      response &&
      Array.isArray(response) &&
      dispatch({
        type: UW_HUB_ACTIONS.SET_NOTES,
        payload: response,
      })
  )
}

export const getLoanApplicationDetails = (dispatch, loanApplicationId) =>
  Api.get({
    url: `/UnderwritingHub/general-details/${loanApplicationId}`,
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_LOAN_APPLICATION_DETAILS,
      payload: data,
    })

    return data
  })

export const getStipulations = (dispatch, loanApplicationId, isFinal) =>
  getNTPs(loanApplicationId, isFinal).then((result) => {
    if (isFinal) {
      dispatch({
        type: UW_HUB_ACTIONS.SET_FINAL_STIPULATIONS,
        payload: result.applicationNTPs,
      })
    } else {
      dispatch({
        type: UW_HUB_ACTIONS.SET_STIPULATIONS,
        payload: result.applicationNTPs,
      })
    }

    return result
  })

export const updateUserNtpStatus = (dispatch, result, isInitial) =>
  dispatch({
    type: UW_HUB_ACTIONS.UPDATE_NTP_STATUS,
    payload: result,
    isInitial,
  })

export const getCreditReportsData = (dispatch, loanApplicationId) =>
  Api.get({
    url: `/UnderwritingHub/credit-report-details/${loanApplicationId}`,
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_CREDIT_REPORTS_DATA,
      payload: data,
    })
  })

export const getPropertyTitleReport = (dispatch, loanApplicationId) =>
  Api.get({
    url: `/UnderwritingHub/property-title-report/${loanApplicationId}`,
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_PROPERTY_TITLE_DETAILS,
      payload: data,
    })
  })

export const getEvaluationSessionsList = (dispatch, loanApplicationId) =>
  getEvaluationSessions(loanApplicationId).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_EVALUATION_SESSIONS,
      payload: data,
    })
  })

export const getSolarContractsData = (dispatch, loanApplicationId) =>
  Api.get({
    url: `/UnderwritingHub/solar-contract-details/${loanApplicationId}`,
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_SOLAR_CONTRACTS_DATA,
      payload: data,
    })
  })

export const updateSolarContractsData = (dispatch, loanApplicationId, data) =>
  Api.put({
    url: '/LoanApplication/solar-data',
    data: {
      solarData: {
        loanApplicationId,
        pricePerWatt: data.ppw,
        systemSize: data.kwSize,
        nonSolarAmount: data.nonSolarAmount,
        batteryCount: data.batteryCount,
        insuranceType: data.insuranceType,
      },
    },
  })
    .then((response) => {
      dispatch({
        type: UW_HUB_ACTIONS.SET_SOLAR_CONTRACTS_DATA,
        payload: {
          ppw: response.pricePerWatt,
          kwSize: response.systemSize,
          nonSolarAmount: response.nonSolarAmount,
          batteryCount: response.batteryCount,
          insuranceType: response.insuranceType,
        },
      })
    })
    .then(() => {
      showNotification(dispatch, {
        title: `Successfully updated loan application solar data.`,
      })
    })

export const updateUwApprovalStatus = (
  dispatch,
  loanApplicationId,
  underwritingSectionTypeId,
  approvalStatusId,
  declineReason
) =>
  Api.put({
    url: '/UnderwritingHub/update-uw-approval',
    data: {
      loanApplicationId,
      underwritingSectionTypeId,
      approvalStatusId,
      declineReason,
    },
  }).then((response) => {
    showNotification(dispatch, {
      title: `Successfully updated approval status.`,
    })

    return response
  })

export const getDataReviewData = (dispatch, loanApplicationId) =>
  Api.put({
    url: '/UnderwritingHub/build-data-review-section',
    data: {
      loanApplicationId,
    },
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_DATA_REVIEW_DATA,
      payload: data,
    })
  })

export const getGeneralData = (dispatch, loanApplicationId) =>
  Api.put({
    url: '/UnderwritingHub/build-general-section',
    data: {
      loanApplicationId,
    },
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_GENERAL_DATA,
      payload: data,
    })
  })

export const getEmploymentData = (dispatch, loanApplicationId) =>
  Api.put({
    url: '/UnderwritingHub/build-employemnt-section',
    data: {
      loanApplicationId,
    },
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_EMPLOYMENT_DATA,
      payload: data,
    })
  })

export const getIdentificationData = (dispatch, loanApplicationId) =>
  Api.get({
    url: `/UnderwritingHub/identification-details/${loanApplicationId}`,
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_IDENTIFICATION_DATA,
      payload: data,
    })
  })

export const updateIdentificationData = (
  dispatch,
  loanApplicationId,
  applicantsData
) =>
  Api.put({
    url: '/LoanApplication/identification-data',
    data: {
      loanApplicationId,
      identificationData: applicantsData.map((applicantData) => ({
        ...applicantData,
        issuanceDate: applicantData.issueDate,
        idTypeId: applicantData.idType,
        number: applicantData.idNumber,
        addressId: applicantData.address?.id,
        motherMaidenName: applicantData.mothersMaidenName,
        idIssueStateId: applicantData.stateOfIssue?.id,
      })),
    },
  })
    .then((response) => {
      showNotification(dispatch, {
        title: `Successfully updated loan application identification data.`,
      })
      return response
    })
    .then(() => getDataReviewData(dispatch, loanApplicationId))
    .catch(console.error)

export const getIncomeSectionData = (dispatch, loanApplicationId) =>
  Api.put({
    url: '/UnderwritingHub/build-income-section',
    data: {
      loanApplicationId,
    },
  }).then((data) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_INCOME_DATA,
      payload: data,
    })
  })

export const updateIncomeData = (dispatch, loanApplicationId, data) =>
  Api.put({
    url: '/LoanApplication/income-data',
    data: {
      incomeData: {
        loanApplicationId,
        ...data,
      },
    },
  }).then((response) => {
    showNotification(dispatch, {
      title: `Successfully updated loan application income data.`,
    })

    return response
  })

export const downloadLoanDocuments = (dispatch, loanApplicationId) =>
  Api.post({
    url: '/UnderwritingHub/download-all-documents',
    data: {
      loanApplicationId,
    },
  }).then((res) => {
    if (res) {
      downloadFileBase64(res)
      showNotification(dispatch, {
        title: `Successfully downloaded loan documents.`,
      })
    }
  })
