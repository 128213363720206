import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

function Radio({ checked, onCheck, label, ...rest }) {
  return (
    <div className="radio-wrapper" onClick={onCheck} {...rest}>
      <label>
        <input
          type="radio"
          tabIndex="0"
          className="radio"
          checked={checked}
          onChange={onCheck}
        />
        <div className="radio__label">{label}</div>
      </label>
    </div>
  )
}

Radio.propTypes = {
  checked: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default Radio
