import { VALIDATION_TYPES } from 'components/validator'
import { ORGANIZATION_TYPE_IDS } from 'common/constants'

export const SALES_ORG_INITIAL_STATE = {
  name: '',
  type: { id: ORGANIZATION_TYPE_IDS.DEALER, label: 'Sales' },
  emailAddresses: '',
  phoneNumber: '',
  address: '',
  bankABA: '',
  bankAccount: '',
  taxId: '',
  initialFunding: '',
  ecgLimit: '',
  status: '',
  language: '',
  accountManagers: [],
  dealerCode: '',
  checking: false,
  matureAccount: false,
  achForm: false,
  parentId: null,
}

export const LENDER_ORG_INITIAL_STATE = {
  name: '',
  type: { id: ORGANIZATION_TYPE_IDS.LENDER, label: 'Sales' },
  parentId: null,
  phoneNumber: '',
  address: '',
  emailAddresses: '',
  status: '',
  dtiPercentage: '',
  ficoVersion: '',
  fallbackHardPullCredentialsName: '',
  hardPullCredentialsName: '',
  applicantDecisioningName: '',
  lssCode: '',
  lssPortfolio: '',
  minimumIncomeThreshold: 0,
  organizationId: '',
  lenderAgreement: '',
  availableZIPCodes: '',
  achOptOutVerbiage: '',
  achAgreement: '',
  tradelineNames: '',
  membershipApplicationVerbiage: '',
  skipInitialFundingPeriod: false,
  incomeEstimatorCriteria: [],
  organizationStates: [],
  applicationExpirationInDays: '30',
  applicationExpirationExtensionInDays: '15',
  useLoanPaymentInDti: true,
}

export const SALES_ORG_VALIDATION_MAP = {
  name: [VALIDATION_TYPES.REQUIRED],
  emailAddresses: [VALIDATION_TYPES.REQUIRED],
  status: [VALIDATION_TYPES.REQUIRED],
  address: [VALIDATION_TYPES.REQUIRED],
  phoneNumber: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.PHONE],
}

export const LENDER_ORG_VALIDATION_MAP = {
  name: [VALIDATION_TYPES.REQUIRED],
  emailAddresses: [VALIDATION_TYPES.REQUIRED],
  status: [VALIDATION_TYPES.REQUIRED],
  address: [VALIDATION_TYPES.REQUIRED],
  phoneNumber: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.PHONE],
  ficoVersion: [VALIDATION_TYPES.REQUIRED],
  fallbackHardPullCredentialsName: [VALIDATION_TYPES.REQUIRED],
  hardPullCredentialsName: [VALIDATION_TYPES.REQUIRED],
  applicantDecisioningName: [VALIDATION_TYPES.REQUIRED],
  minimumIncomeThreshold: [VALIDATION_TYPES.POSITIVE_OR_ZERO_INTEGER],
  applicationExpirationInDays: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
  applicationExpirationExtensionInDays: [
    VALIDATION_TYPES.REQUIRED,
    VALIDATION_TYPES.BIGGER_THAN_ZERO,
  ],
}
