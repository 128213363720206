import React from 'react'
import LoanDocuments from 'modules/loan-document'
import AdminWrapper from 'modules/admin'

const LoanDocumentPage = () => {
  return (
    <AdminWrapper>
      <LoanDocuments />
    </AdminWrapper>
  )
}

export default LoanDocumentPage
