import Api from 'easy-fetch-api'
import { ASSOCIATIONS_STATUS_IDS } from 'modules/admin/constants'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

export const fetchAUSTemplatesGuidAndName = (query = { itemsPerPage: 500 }) =>
  Api.get({
    url: '/DecisioningTemplate/list-name-guid',
    query,
  })

export const fetchDocumentsTemplatesGuidAndName = () =>
  Api.get({ url: '/Envelopes/list/simple' })

/** Get list of templates */
export const fetchUsers = ({ pageNumber, itemsPerPage, filters = {} }) => {
  delete filters.dateCreated

  return Api.get({
    url: '/Users',
    query: {
      pageNumber,
      itemsPerPage,
      sortBy: filters?.sortBy,
      ...filters,
    },
  })
}

/** Get list of templates */
export const fetchUsersByOrganization = ({
  pageNumber,
  itemsPerPage,
  filters = {},
}) => {
  delete filters.dateCreated

  return Api.get({
    url: `/Users/organization/get-users-by-child-organizations`,
    query: {
      pageNumber,
      itemsPerPage,
      sortBy: filters?.sortBy,
      ...filters,
    },
  })
}

export const createUser = (data, dispatch) => {
  return Api.post({
    url: '/LoanProduct/create-loan-product',
    data: data,
  })
    .then((response) => {
      showNotification(dispatch, {
        title: `Successfully created loan product ${data.name}`,
      })
      return Promise.resolve(response)
    })
    .catch((error) =>
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while creating loan product ${data.name}. Please refresh the page and try again. ${error.title}`,
      })
    )
}

export const getUsersForOrganization = ({
  organizationId,
  parentOrganizationId,
  organizationTypeId,
  pageNumber,
  itemsPerPage,
  filters = {},
  searchBy,
}) => {
  const query = {
    pageNumber,
    itemsPerPage,
    organizationTypeId,
    associationStatusId: ASSOCIATIONS_STATUS_IDS.ACTIVE,
  }

  if (parentOrganizationId) {
    query.parentOrganizationId = parentOrganizationId
  } else if (organizationId) {
    query.OrganizationId = organizationId
  } else {
    console.error('An organization or parent organization is needed')
    return Promise.resolve([])
  }

  if (searchBy) {
    query.searchBy = [`firstName ${searchBy}`]
  }

  // Pass along all the filters that are not null
  Object.entries(filters).forEach(([filterKey, filterValue]) => {
    if (filterValue) {
      query[filterKey] = filterValue
    }
  })
  return Api.get({ url: `/Users`, query })
}

export const deleteUser = (dispatch, userId) => {
  return Api.delete({
    url: `/Users/${userId}`,
  })
    .then((deletedUser) => {
      showNotification(dispatch, {
        title: `Successfully deleted ${deletedUser.emailAddress}.`,
      })
      return deletedUser
    })
    .catch((error) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while deleting the user. Please try again. ${error.title}`,
      })
    })
}

export const getUserById = (userId) => Api.get({ url: `/Users/${userId}` })

export const addUser = (dispatch, userToAdd) => {
  const formattedData = {
    ...userToAdd,
    phoneNumber: userToAdd.phoneNumber.startsWith('+')
      ? userToAdd.phoneNumber
      : `+1${userToAdd.phoneNumber}`,
  }
  return Api.post({
    url: '/Users/create-user',
    data: formattedData,
  })
    .then((newUser) => {
      showNotification(dispatch, {
        title: `Successfully created an user with email ${newUser.emailAddress}.`,
      })
      return newUser
    })
    .catch((error) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while creating the user with ${
          userToAdd.emailAddress
        }. Please try again. ${error.title || ''}`,
      })
      return null
    })
}

export const editUser = (dispatch, userToEdit) => {
  const formattedData = {
    ...userToEdit,
    phoneNumber: userToEdit.phoneNumber.startsWith('+')
      ? userToEdit.phoneNumber
      : `+1${userToEdit.phoneNumber}`,
  }
  return Api.put({
    url: '/Users',
    data: formattedData,
    _dontShowErrorToast: true,
  })
    .then((newUser) => {
      showNotification(dispatch, {
        title: `Successfully updated ${newUser.emailAddress}.`,
      })
      return newUser
    })
    .catch((error) => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while updating the user with ${userToEdit.emailAddress}. Please try again. ${error.title}`,
      })
    })
}

export const resendEmailInvitation = (userEmail, dispatch) => {
  return Api.post({
    url: `/Users/invitation/resend`,
    data: { emailAddress: userEmail, _dontShowErrorToast: true },
  })
    .then(() =>
      showNotification(dispatch, {
        title: `Successfully sent email invitation to ${userEmail}.`,
      })
    )
    .catch(() => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `An error occurred while sending an invitation to ${userEmail}. Please refresh the page and try again.`,
      })
    })
}
