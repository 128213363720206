import { INPUT_TYPES } from 'components/input'
import { reverseObject } from 'common/utils'

export const UW_HUB_PROPERTY_TYPES = {
  MilitaryAffiliation: 1,
  Citizenship: 2,
  Occupancy: 3,
  PropertyType: 4,
  Employer: 5,
  JobTitle: 6,
  MonthsEmployed: 7,
  EmploymentStatus: 8,
  YearlyStatedIncome: 9,
  YearlyActualIncome: 10,
  StatedDti: 11,
  ActualDti: 12,
  Fico: 13,
}

export const PROPERTY_TYPE_TO_INPUT_TYPE = {
  [UW_HUB_PROPERTY_TYPES.YearlyStatedIncome]: INPUT_TYPES.CURRENCY,
  [UW_HUB_PROPERTY_TYPES.YearlyActualIncome]: INPUT_TYPES.CURRENCY,
  [UW_HUB_PROPERTY_TYPES.StatedDti]: INPUT_TYPES.PERCENTAGE,
  [UW_HUB_PROPERTY_TYPES.ActualDti]: INPUT_TYPES.PERCENTAGE,
  [UW_HUB_PROPERTY_TYPES.Fico]: INPUT_TYPES.TEXT,
}

export const INCOME_TYPES_IDS = {
  Unknown: 0,
  Stated: 1,
  CreditReport: 2,
}

export const UW_APPROVAL_STATUS_IDS = {
  Approved: 1,
  Declined: 2,
  PendingApproval: 3,
  ManualCheck: 4,
}

export const DATA_VALIDATION_IDS = {
  Valid: 0,
  Invalid: 1,
  ManualCheck: 2,
}

export const DATA_PROPERTY_TYPES = {
  1: 'Name',
  2: 'Address',
  3: 'DOB',
  4: 'SSN',
}

export const SOURCE_TYPE_IDS = {
  1: 'application',
  2: 'creditReport',
  3: 'solarContract',
  4: 'identification',
  5: 'propertyTitle',
}

export const SOURCE_TYPE_MAP = reverseObject(SOURCE_TYPE_IDS)

export const APPLICANT_PROPERTY_IDS = {
  1: 'militaryAffiliation',
  2: 'citizenship',
  3: 'occupancy',
  4: 'propertyType',
  5: 'employer',
  6: 'jobTitle',
  7: 'monthsEmployed',
  8: 'employedStatus',
}
