import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { components, hooks } from '@ElementsCapitalGroup/enium-ui'
import Button from 'components/button'
import { ORGANIZATION_TYPE_IDS } from 'common/constants'
import { useStore } from 'store'

import {
  copyAssociatedLoanProducts,
  getAllOrganizations,
  getAssociatedLoanProducts,
  saveAssociatedLoanProducts,
} from '../../actions'
import { styles } from '../style.js'

const { Chip, Dropdown, Card } = components
const { useDialogConfirm } = hooks

const OrganizationLoans = ({ orgData, dispatch, setLoading }) => {
  const {
    state: {
      orgManagement: { allOrganizations },
    },
  } = useStore()
  const confirm = useDialogConfirm()
  const [allLoans, setAllLoans] = useState(null) // List of all loans from the server
  const [loanOptions, setLoanOptions] = useState([]) // Used for the dropdown
  const [somethingChanged, setSomethingChanged] = useState(false)
  const [orgToCopyFrom, setOrgToCopyFrom] = useState({})

  const getData = () => {
    setLoading(true)
    getAssociatedLoanProducts(orgData.guid)
      .then((res) => {
        if (res) {
          setAllLoans(res)
          const loansForDropdown = res.reduce((arr, crt) => {
            const { guid: lenderGuid, name: lenderName } = crt.lender
            const lenderLoans = crt.loanProducts.map((loan) => {
              return {
                ...loan,
                id: loan.guid,
                label: loan.name,
                lenderGuid,
                lenderName,
              }
            })
            return arr.concat(lenderLoans)
          }, [])
          setLoanOptions(loansForDropdown)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!allOrganizations.length) {
      getAllOrganizations(dispatch)
    }
  }, [allOrganizations])

  const organizations = useMemo(() => {
    return allOrganizations
      .filter(
        (el) =>
          el.type.id !== ORGANIZATION_TYPE_IDS.LENDER &&
          el.type.id !== ORGANIZATION_TYPE_IDS.CORPORATE
      )
      .map((el) => ({
        id: el.guid,
        label: el.name,
      }))
  }, [allOrganizations.length])

  const onRemoveLoan = (loanId) => () => {
    setLoanOptions(
      loanOptions.map((el) => {
        if (el.guid === loanId) {
          return {
            ...el,
            isAssociated: false,
          }
        }
        return el
      })
    )

    setSomethingChanged(true)
  }
  const onLoanChecked = (ev) => {
    if (!somethingChanged) {
      setSomethingChanged(true)
    }
    const selectedLoanOptions = ev.target.value.map((el) => el.guid)
    setLoanOptions(
      loanOptions.map((el) => ({
        ...el,
        isAssociated: selectedLoanOptions.includes(el.guid),
      }))
    )
  }

  const onSaveLoans = () => {
    setLoading(true)
    const toSave = allLoans.map((association) => {
      return {
        ...association,
        loanProducts: association.loanProducts.map((loan) => {
          return {
            ...loan,
            isAssociated: loanOptions.find((el) => el.guid === loan.guid)
              .isAssociated,
          }
        }),
      }
    })
    saveAssociatedLoanProducts(dispatch, {
      organizationAssociatedLoanProducts: toSave,
      organizationGuid: orgData.guid,
      organizationName: orgData.name,
    }).finally(() => {
      setSomethingChanged(false)
      setLoading(false)
    })
  }

  const onCopyLoans = () => {
    confirm({
      description: `Are you sure you want to copy Loan Associations from "${orgToCopyFrom.label}"?`,
      confirmationText: 'Yes',
    })
      .then(() => {
        setLoading(true)
        copyAssociatedLoanProducts(dispatch, orgToCopyFrom.id, orgData.guid)
          .then(getData)
          .catch(() => setLoading(false))
      })
      .catch(() => {})
  }

  // Used to display the Chip components grouped by lender
  const loansMap = useMemo(() => {
    const map = {}
    loanOptions.forEach((loan) => {
      if (!loan.isAssociated) {
        return
      }
      const { lenderGuid, lenderName, ...loanDetails } = loan
      if (!map[lenderGuid]) {
        map[lenderGuid] = {
          lenderGuid,
          lenderName,
          loans: [loanDetails],
        }
      } else {
        map[lenderGuid].loans.push(loanDetails)
      }
    })
    return map
  }, [loanOptions])

  const loans = Object.values(loansMap)

  return (
    <>
      <div style={styles.loanAssociation.searchBar}>
        <Dropdown
          label="Copy Associations from another Organization"
          searchable={true}
          disabled={!organizations.length}
          options={organizations}
          value={orgToCopyFrom}
          onChange={(ev) => setOrgToCopyFrom(ev.target.value)}
          sx={styles.loanAssociation.dropdown}
        />
        <Button
          disabled={!orgToCopyFrom.id}
          onClick={onCopyLoans}
          sx={styles.loanAssociation.submitButton}
        >
          Copy
        </Button>
      </div>

      <div style={{ ...styles.loanAssociation.searchBar, marginTop: '15px' }}>
        <Dropdown
          label="Manual Loan Selection"
          multiple={true}
          searchable={true}
          options={loanOptions || []}
          value={loanOptions.filter((el) => el.isAssociated)}
          onChange={onLoanChecked}
          sx={styles.loanAssociation.dropdown}
        />
        <Button
          disabled={!allLoans || !somethingChanged}
          onClick={onSaveLoans}
          sx={styles.loanAssociation.submitButton}
        >
          Submit
        </Button>
      </div>

      {!!allLoans && loans.length > 0 ? (
        <Card>
          <h1 style={styles.users.orgDetailsTitle}>Associated Loans</h1>
          {loans.map((association, idx) => {
            return (
              <React.Fragment key={idx}>
                <h2 style={styles.loanAssociation.subtitle}>
                  {association.lenderName}
                </h2>
                {association.loans.map((loan) => (
                  <Chip
                    key={loan.guid}
                    label={loan.name}
                    variant="outlined-squared"
                    onDelete={onRemoveLoan(loan.guid)}
                    sx={styles.loanAssociation.chip}
                  />
                ))}
              </React.Fragment>
            )
          })}
        </Card>
      ) : null}
    </>
  )
}

OrganizationLoans.propTypes = {
  orgData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default OrganizationLoans
