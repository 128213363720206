import React, { useState } from 'react'
import { authenticate } from 'modules/session/actions'
import { modules } from '@ElementsCapitalGroup/enium-ui'
import AuthBg from 'assets/auth-bg-desktop.jpg'
import AuthBgMobile from 'assets/auth-bg-mobile.jpg'
import { useNavigate } from 'react-router-dom'
import { SetUpTwoFactor } from 'modules/two-factor-auth'

const { LoginContainer } = modules

const LoginPage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isTwoFactorAuthOpen, setIsTwoFactorAuthOpen] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const [is2FAEnabled, setIs2FAEnabled] = useState(false)
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('')
  const [tfaMethod, setTfaMethod] = useState('')
  const [error, setError] = useState('')

  /** Submit login form handler */
  const onSubmit = ({ email, password }, ev) => {
    ev.preventDefault()
    setLoading(true)

    authenticate(email, password)
      .then((res) => {
        if (!res) {
          setError('Something went wrong. Please try again.')
        } else {
          setEmailAddress(email)
          setPassword(password)
          setIs2FAEnabled(!!res.is2FAEnabled)
          setCurrentPhoneNumber(res.phoneNumber || '')
          setTfaMethod(res.tfaMethodId)
          setIsTwoFactorAuthOpen(true)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      {isTwoFactorAuthOpen && (
        <SetUpTwoFactor
          setIsTwoFactorAuthOpen={setIsTwoFactorAuthOpen}
          emailAddress={emailAddress}
          password={password}
          currentPhoneNumber={currentPhoneNumber}
          is2FAEnabled={is2FAEnabled}
          tfaMethod={tfaMethod}
        />
      )}
      <LoginContainer
        className="login-page"
        title="Solar Financing Portal"
        supportingText="Quick applications, real-time tracking, prompt support."
        onSubmit={onSubmit}
        imageUrl={AuthBg}
        mobileImageUrl={AuthBgMobile}
        loading={loading}
        onContactUs={() => window.open('https://enium.com/contact/', '_blank')}
        onForgotPassword={() => navigate('/forgot-password')}
        errorMessages={{ email: 'Invalid email address' }}
        generalError={error}
      />
    </>
  )
}

export default LoginPage
