import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'common/hooks'
import { confirmUserEmailUpdate } from 'modules/session/actions'
import { modules } from '@ElementsCapitalGroup/enium-ui'
import AuthBg from 'assets/auth-bg-desktop.jpg'
import AuthBgMobile from 'assets/auth-bg-mobile.jpg'

const { CheckEmailContainer, PasswordResetContainer } = modules

const ConfirmEmailUpdate = () => {
  const navigate = useNavigate()
  const userGuid = useQuery().get('emailUpdateId')
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = (ev) => {
    ev?.preventDefault()
    setLoading(true)
    confirmUserEmailUpdate(userGuid)
      .then((res) => {
        setSubmitted(true)
        if (res) {
          setSuccess(true)
        }
      })
      .finally(() => setLoading(false))
  }

  if (submitted) {
    return (
      <PasswordResetContainer
        title={success ? 'Email address confirmed' : 'Something went wrong'}
        onContinue={() => {
          if (success) {
            navigate('/login')
          } else {
            setSubmitted(false)
          }
        }}
        imageUrl={AuthBg}
        mobileImageUrl={AuthBg}
        failed={!success}
      />
    )
  }

  return (
    <CheckEmailContainer
      verbiage={{
        resendEmail: ' ',
        resendEmailCallToAction: ' ',
        openEmail: 'Confirm email address',
      }}
      imageUrl={AuthBg}
      mobileImageUrl={AuthBgMobile}
      onResendEmail={() => {}}
      onOpenEmail={onSubmit}
      onBack={() => navigate('/login')}
      title="Confirm email update"
      supportingText="Please click on the button below to confirm your email address"
      loading={loading}
    ></CheckEmailContainer>
  )
}

export default ConfirmEmailUpdate
