import Cookie from 'js-cookie'
import {
  BASIC_COOKIE_NAME,
  COOKIE_NAME,
  LS_USER_DATA,
  REFRESH_COOKIE_NAME,
} from 'common/constants'
import { clearAllFilters } from 'common/filters'
import { history } from 'components/history'
import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'session'

/** Initial state of the reducer */
const initialState = {
  token: null,
  expiration: null,
  isLoggedIn: false,
  shouldReconnectNotifications: false,
  userData: _readUserDataFromLS(),
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [ACTIONS.LOGIN]: (state, payload) => {
    const { token, refreshToken, expiration, refreshTokenExpiration } =
      payload.data
    // We only set the BASIC_COOKIE because at this point the token is not yet enriched
    Cookie.set(BASIC_COOKIE_NAME, token, { expires: new Date(expiration) })
    Cookie.set(REFRESH_COOKIE_NAME, refreshToken, {
      expires: new Date(refreshTokenExpiration),
      secure: true,
    })

    return {
      ...state,
      ...payload.data,
      isLoggedIn: true,
      shouldReconnectNotifications: true,
    }
  },

  [ACTIONS.UPDATE_NOTIFICATION_RECONNECT]: (state) => {
    return { ...state, shouldReconnectNotifications: false }
  },

  [ACTIONS.LOGOUT]: (state, { refreshPage }) => {
    Cookie.remove(COOKIE_NAME)
    Cookie.remove(BASIC_COOKIE_NAME)
    Cookie.remove(REFRESH_COOKIE_NAME)
    localStorage.removeItem(LS_USER_DATA)
    if (refreshPage) {
      window.location = '/login'
    } else {
      history.replace('/login')
    }
    return { ...state }
  },

  /** Called on /enrich and on /me (setting the user associations). For /me it doesn't have token && expiration  */
  [ACTIONS.SET_USER_DATA]: (state, { data, token, expiration }) => {
    // Enrich only case
    if (token && expiration) {
      // re-set the token because it was enriched with extra data
      Cookie.set(COOKIE_NAME, token, { expires: new Date(expiration) })
      // also expand the expiration of the basic cookie to match the one from the updated session cookie
      const basicCookieValue = Cookie.get(BASIC_COOKIE_NAME)
      Cookie.set(BASIC_COOKIE_NAME, basicCookieValue, {
        expires: new Date(expiration),
      })

      // If the role was changed, remove loan app filters
      if (state.userData.roleId !== data.roleId) {
        clearAllFilters()
      }
    }
    const userData = { ...state.userData, ...data }
    // Update Local Storage
    localStorage.setItem(LS_USER_DATA, JSON.stringify(userData))
    // Update the store
    return { ...state, userData }
  },

  [ACTIONS.REHYDRATE_USER_DATA]: (state) => {
    // Get data from Local Storage
    const userData = JSON.parse(localStorage.getItem(LS_USER_DATA))

    if (!userData) {
      return state
    }
    // Update the store
    return { ...state, userData }
  },
}

/**
 * Helper method to read user data from LocalStorage
 * @return {{
 *   firstName: String,
 *   lastName: String,
 *   email: String,
 *   roleId: String,
 *   role: String,
 *   organizationId: String,
 *   organizationName: String,
 *   claims: Array<String>
 * }}
 * @private
 */
function _readUserDataFromLS() {
  let userData = {}
  const isLoggedIn = Cookie.get(COOKIE_NAME)
  try {
    userData = JSON.parse(localStorage.getItem(LS_USER_DATA))
  } catch (e) {
    console.error(e)
  }
  if (!isLoggedIn) {
    localStorage.removeItem(LS_USER_DATA)
    userData = {}
  }
  return userData
}

export default { name, initialState, reduce }
