import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { assets, colors } from '@ElementsCapitalGroup/enium-ui'
import Table from 'components/table'
import FundingDetails from 'modules/automated-funding/utils/funding-details'
import { useStore } from 'store'
import TextField from 'components/input'
import { dailyFundingColumns } from '../utils/constants'
import { getFundingDetailsObject } from '../utils'

const { SearchLgIcon } = assets

const DailyFundingProcess = ({ apps }) => {
  const { state } = useStore()
  const user = state.session.userData
  const [searchText, setSearchText] = useState('')
  const [filteredApps, setFilteredApps] = useState([])

  const details = useMemo(() => {
    return getFundingDetailsObject(apps)
  }, [apps])

  useEffect(() => {
    if (!searchText.trim()) {
      return setFilteredApps(
        apps.map((el) => ({ ...el, $children: undefined }))
      )
    }
    const search = searchText.toLowerCase()
    setFilteredApps(
      apps
        .filter((app) => {
          if (app.applicationNumber.includes(search)) {
            return true
          } else if (app.borrowerName.toLowerCase().includes(search)) {
            return true
          } else if (app.dealerName.toLowerCase().includes(search)) {
            return true
          } else if (app.lenderName.toLowerCase().includes(search)) {
            return true
          } else if (
            app.fundingType.friendlyName?.toLowerCase().includes(search)
          ) {
            return true
          }
          return false
        })
        .map((app) => ({ ...app, $children: undefined }))
    )
  }, [apps, searchText])

  return (
    <>
      <FundingDetails
        fundedBy={{ friendlyName: `${user.firstName} ${user.lastName}` }}
        totalFunding={details.totalFunding}
        totalFee={details.totalFee}
        totalDeposit={details.totalDeposit}
        style={{ marginBottom: '40px' }}
      />

      <TextField
        onChange={setSearchText}
        value={searchText}
        placeholder="Search by loan app"
        type="search"
        style={{ marginBottom: '32px', minWidth: '400px' }}
        startIcon={
          <SearchLgIcon fontSize="small" sx={{ stroke: colors.grey[400] }} />
        }
      />

      <Table
        title="Loan Applications"
        data={filteredApps}
        columns={dailyFundingColumns}
        onSortingChange={() => {}}
        fetchData={() => {}}
        showColumnSelector={true}
        centerCellContent={true}
      />
    </>
  )
}

DailyFundingProcess.propTypes = {
  apps: PropTypes.array.isRequired,
}

export default DailyFundingProcess
