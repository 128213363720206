const black = '#000'
const linkBlue = '#175cd3'

export const styles = {
  phoneSetUpTitle: {
    marginTop: '12px',
    color: black,
    marginBottom: '6px',
    fontWeight: 800,
  },

  phoneSetUpButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    marginBottom: '12px',
  },

  termsList: {
    listStylePosition: 'inside',
    paddingLeft: 0,
  },

  termsItem: {
    marginBottom: '24px',
  },

  termsAndConditions: {
    color: linkBlue,
  },
}
