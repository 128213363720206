import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'components/table'
import { formatInUSFormat } from 'common/number'
import { components } from '@ElementsCapitalGroup/enium-ui'
import StatusComponent from '../../../components/status'
import { DAILY_FUNDING_STATUS_ID_MAPPING } from '../utils/constants'

const { createColumnHelper } = components
const columnHelper = createColumnHelper()

const ExpandedWires = ({ data }) => {
  const loanApps =
    data.dailyFundingLoanApplications?.map((app) => ({
      ...app,
      id: app.loanApplicationId,
    })) || []
  const [selectedApps, setSelectedApps] = useState({})

  const _renderTotalSection = () => {
    if (!selectedApps.length) {
      return null
    }
    const totalFundingAmount = selectedApps.reduce(
      (acc, curr) => acc + (curr.fundingAmount || 0),
      0
    )
    const totalPromissoryNotes = selectedApps.reduce(
      (acc, curr) => acc + (curr.promissoryNotes || 0),
      0
    )
    return (
      <div className="wires__footer">
        <p className="wires__footer__total">
          <span>Total:</span>
          <span>${(totalFundingAmount - totalPromissoryNotes).toFixed(2)}</span>
        </p>
        <p>
          <span>Total Wires Selected:</span>
          <span>${totalFundingAmount.toFixed(2)}</span>
        </p>
        <p>
          <span>Promissory Notes:</span>
          <span>- ${totalPromissoryNotes.toFixed(2)}</span>
        </p>
      </div>
    )
  }

  return (
    <div className="afp__expanded">
      <Table
        title="Loan Applications"
        data={loanApps}
        columns={columns}
        hasCheckboxes={true}
        onTableRowsSelectChange={setSelectedApps}
        hideEditButton={true}
        hideRemoveButton={true}
        multiRowSelection={true}
        emptyDescription="No items to show"
      />
      {_renderTotalSection()}
    </div>
  )
}

const columns = [
  columnHelper.accessor('type', {
    header: 'Type',
    cell: (cell) => cell.row.original.fundingType?.friendlyName,
  }),
  columnHelper.accessor('approvalStatus', {
    header: 'Status',
    cell: (cell) => {
      const statusId = cell?.row.original.fundingStatus.id
      if (!statusId) {
        return ''
      }

      return (
        <StatusComponent
          status={DAILY_FUNDING_STATUS_ID_MAPPING[statusId]}
          label={cell.row.original.fundingStatus.friendlyName}
        />
      )
    },
  }),
  columnHelper.accessor('applicationNumber', {
    header: 'Application #',
    cell: (cell) => cell.row.original.loanApplication?.loanApplicationNumber,
  }),
  columnHelper.accessor('organizationName', {
    header: 'Sales Org',
    cell: (cell) => cell.row.original.loanApplication?.salesOrganizationName,
  }),
  columnHelper.accessor('borrowerName', {
    header: 'Borrower Name',
    cell: (cell) => cell.row.original.borrowersNames ?? '',
  }),
  columnHelper.accessor('dueEnium', {
    header: 'Due Enium $',
    cell: (cell) => `$${formatInUSFormat(cell.row.original.dueEnium)}`,
  }),
  columnHelper.accessor('fundingAmount', {
    header: 'Funding Amount $',
    cell: (cell) => `$${formatInUSFormat(cell.row.original.fundingAmount)}`,
  }),
]

ExpandedWires.propTypes = { data: PropTypes.object.isRequired }

export default ExpandedWires
