import {
  STATES_ORDER,
  STATES_ORDER_COMMERCIAL,
  STATE_ID_TO_NAME,
  STATE_IDS,
  ATTACHMENT_STATUS_IDS,
} from '../constants'
import { NTP_SECTIONS_IDS } from './constants'

export const areNtpsReadyForSubmission = (
  stateOrder,
  isInitialNtp,
  isCommercial = false
) => {
  const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  return isCommercial
    ? stateOrder >= statesOrder.CommercialDocumentationReview
    : isInitialNtp
    ? stateOrder >= statesOrder.DocumentsSigned
    : stateOrder >= statesOrder.NTPFundsGranted
}

export const areNtpSubmitted = (
  stateOrder,
  isInitialNtp,
  isCommercial = false
) => {
  if (isCommercial) {
    return true
  }
  const statesOrder = STATES_ORDER
  return isInitialNtp
    ? stateOrder >= statesOrder.NTPDocsPendingApproval
    : stateOrder >= statesOrder.InstallCompleteDocsPendingApproval
}

export const areNtpsApproved = (
  stateOrder,
  isInitialNtp,
  isCommercial = false
) => {
  const statesOrder = isCommercial ? STATES_ORDER_COMMERCIAL : STATES_ORDER

  return isCommercial
    ? stateOrder >= statesOrder.CommercialStipulationsApproved
    : isInitialNtp
    ? stateOrder >= statesOrder.NTPDocsApproved
    : stateOrder >= statesOrder.InstallCompleteDocsApproved
}

export const areFundsRequested = (
  stateOrder,
  isInitial,
  isCommercial = false
) => {
  if (isCommercial) {
    return null
  }
  const statesOrder = STATES_ORDER
  return isInitial
    ? stateOrder >= statesOrder.NTPFundsRequested
    : stateOrder >= statesOrder.InstallCompleteFundsRequested
}

export const areFundsGranted = (
  stateOrder,
  isInitial,
  isCommercial = false
) => {
  if (isCommercial) {
    return null
  }
  const statesOrder = STATES_ORDER
  return isInitial
    ? stateOrder >= statesOrder.NTPFundsGranted
    : stateOrder >= statesOrder.InstallCompleteFundsGranted
}

export const getNtpTypeId = (loanApplicationStateId, isCommercial = false) => {
  if (isCommercial) {
    return NTP_SECTIONS_IDS.COMMERCIAL
  }

  const stateName = STATE_ID_TO_NAME[loanApplicationStateId]

  const step = STATES_ORDER[stateName]

  return step < STATES_ORDER.NTPFundsGranted ||
    step === STATES_ORDER.NTPDocsReSubmitted ||
    step === STATES_ORDER.NTPDocsRejected
    ? NTP_SECTIONS_IDS.INITIAL
    : NTP_SECTIONS_IDS.FINAL
}

export const areNtpAttachmentsApproved = (documents, attachmentField) => {
  // Each group of NTPs has to have a status (approved, rejected, needs review) and at least one approved userNTP
  for (const document of documents) {
    let atLeastOneApproved = false

    const attachments = document.userNTPs || document[attachmentField]
    for (const attachment of attachments) {
      if (attachment.statusId === ATTACHMENT_STATUS_IDS.APPROVED) {
        atLeastOneApproved = true
      } else if (
        attachment.statusId === null ||
        attachment.statusId === undefined
      ) {
        // Make sure all have a valid status
        return false
      }
    }

    // If all have a valid status, make sure at least one is approved
    if (!atLeastOneApproved) {
      return false
    }
  }

  return true
}

export const getNtpStates = (isInitial, isCommercial) => {
  if (isCommercial) {
    return [
      STATE_IDS.CommercialDocumentationReview,
      STATE_IDS.CommercialStipulationsApproved,
    ]
  }

  if (isInitial) {
    return [
      STATE_IDS.NTPDocsPendingApproval,
      STATE_IDS.NTPDocsApproved,
      STATE_IDS.NTPFundsRequested,
      STATE_IDS.NTPDocsRejected,
      STATE_IDS.NTPDocsReSubmitted,
    ]
  }

  return [
    STATE_IDS.NTPFundsGranted,
    STATE_IDS.InstallCompleteDocsPendingApproval,
    STATE_IDS.InstallCompleteDocsApproved,
    STATE_IDS.InstallCompleteFundsRequested,
    STATE_IDS.InstallCompleteFundsGranted,
    STATE_IDS.InstallCompleteDocsRejected,
    STATE_IDS.InstallCompleteDocsReSubmitted,
  ]
}
