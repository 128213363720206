import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useTranslation } from 'react-i18next'
import withScrolling from 'react-dnd-scrolling'
import { fetchDefinitions } from 'modules/stipulation-templates/actions'
import StipulationList from 'modules/stipulation-templates/stipulation-templates-settings/stipulation-list'
import Loader from 'components/loader'
import { v4 as uuidv4 } from 'uuid'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

const { Dialog } = components

const NTPStipulations = ({
  isOpen,
  onClose,
  onAddStipulations,
  isInitial,
  isCommercial,
}) => {
  const { t: translate } = useTranslation()
  const [definitions, setDefinitions] = useState([])
  const [stipulations, setStipulations] = useState([
    { definition: '', guid: uuidv4() },
  ])
  const [selected, setSelected] = useState({
    initialStipulations: [],
    finalStipulations: [],
    commercialStipulations: [],
  })
  const [loading, setLoading] = useState(true)
  const selectedStipulations = isCommercial
    ? selected.commercialStipulations
    : isInitial
    ? selected.initialStipulations
    : selected.finalStipulations

  const ScrollingComponent = withScrolling('div')

  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  useEffect(() => {
    if (isOpen && !definitions.length) {
      fetchDefinitions()
        .then((res) => {
          setDefinitions(res)
        })
        .finally(() => setLoading(false))
    }
  }, [isOpen])

  /* On Modal close handler - reset fields and close the modal */
  const onModalClose = () => {
    setSelected({
      initialStipulations: [],
      finalStipulations: [],
      commercialStipulations: [],
    })
    setStipulations([{ definition: '', guid: uuidv4() }])
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onModalClose}
      title={translate('loanApplication.ntpStepsCommon.addStipulation')}
      PaperProps={{
        sx: {
          minWidth: '400px',
        },
      }}
      actions={
        <>
          <Button
            onClick={onModalClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={selectedStipulations.length === 0}
            onClick={() => {
              onAddStipulations(selectedStipulations)
              onModalClose()
            }}
          >
            {translate('buttons.submit')}
          </Button>
        </>
      }
    >
      <DndProvider
        backend={isMobileView ? TouchBackend : HTML5Backend}
        options={isMobileView ? { enableMouseEvents: true } : {}}
      >
        {loading ? (
          <Loader size={100} />
        ) : (
          <ScrollingComponent className="stipulation-list-wrapper">
            <StipulationList
              stipulationDefinitions={definitions}
              list={stipulations}
              onListChange={setStipulations}
              selected={selected}
              setSelected={setSelected}
              errors={[]}
              parentId="ntp-stipulations"
              initialStipulation={isInitial}
              commercialStipulation={isCommercial}
            />
          </ScrollingComponent>
        )}
      </DndProvider>
    </Dialog>
  )
}

NTPStipulations.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAddStipulations: PropTypes.func.isRequired,
  isInitial: PropTypes.bool,
  isCommercial: PropTypes.bool,
}

export default NTPStipulations
