import React from 'react'
import {
  setUserPassword,
  setUserPasswordForSales,
} from 'modules/session/actions'
import SetAndResetPassword from 'modules/set-reset-password'
import { useLocation } from 'react-router-dom'

import SetPasswordForSales from 'modules/set-password-for-sales'

const SetPasswordPage = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const userId = params.get('userId')
  const isNotECGLender = params.get('isNotECGLender')
  const invitationId = params.get('invitationId')

  const onSubmit = (data) => {
    if (isNotECGLender === 'True') {
      return setUserPasswordForSales(
        userId,
        data.fullName,
        data.password,
        data.phoneNumber,
        true,
        invitationId
      )
    } else {
      return setUserPassword(userId, data, invitationId)
    }
  }
  if (isNotECGLender === 'True') {
    return (
      <SetPasswordForSales
        onSubmit={onSubmit}
        title="Welcome to Enium"
        supportingText="Let’s set a password for your account."
        submittedVerbiage={{
          titleSuccess: 'Password is set',
          titleFailed: 'Set password failed',
          textSuccess:
            'Your password has been successfully set. Click continue to log in.',
          textFailed: 'Your password could not be set. Please try again later.',
        }}
        buttonText="Set password"
      />
    )
  }
  return (
    <SetAndResetPassword
      onSubmit={onSubmit}
      title="Welcome to Enium"
      supportingText="Let’s set a password for your account."
      submittedVerbiage={{
        titleSuccess: 'Password is set',
        titleFailed: 'Set password failed',
        textSuccess:
          'Your password has been successfully set. Click continue to log in.',
        textFailed: 'Your password could not be set. Please try again later.',
      }}
      buttonText="Set password"
    />
  )
}

export default SetPasswordPage
