import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import {
  DATA_PROPERTY_TYPES,
  SOURCE_TYPE_IDS,
  SOURCE_TYPE_MAP,
} from '../constants'
import { dataReview } from '../common/styles'
import { renderCell } from '../common/render-cell'

const { Collapse, IconButton } = components
const { ChevronDownIcon, ChevronUpIcon } = assets

const DataReviewMobileContent = ({ columns, data, selectedSection }) => {
  const [expanded, setExpanded] = useState({ application: true })

  const setColumnExpanded = (columnKey, isExpanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [columnKey]: isExpanded,
    }))
  }

  const sources = useMemo(() => {
    return data.applicantSections.find(
      (el) => el.applicantTypeId.toString() === selectedSection
    )?.sources
  }, [data, selectedSection])

  const dataForSource = (sourceType) => {
    const source = sources?.find(
      (el) => el.sourceTypeId.toString() === SOURCE_TYPE_MAP[sourceType]
    )
    return {
      label: columns.find((column) => column.accessorKey === sourceType)
        ?.header,
      values: Object.entries(DATA_PROPERTY_TYPES).map(
        ([propertyId, propertyLabel]) => {
          const sourceValue =
            source?.properties.find(
              (property) => property.propertyTypeId.toString() === propertyId
            ) || {}
          return {
            label: propertyLabel,
            value: renderCell({
              ...sourceValue,
              value: sourceValue?.stringValue,
            }),
          }
        }
      ),
    }
  }

  return (
    <div style={dataReview.wrapper}>
      {Object.values(SOURCE_TYPE_IDS).map((sourceType) => {
        const { values, label } = dataForSource(sourceType)
        return (
          <React.Fragment key={sourceType}>
            <div
              className="align-center"
              style={{ width: '100%', flexGrow: 1 }}
            >
              {label}
              <IconButton
                size="small"
                onClick={() =>
                  setColumnExpanded(sourceType, !expanded[sourceType])
                }
                style={{ marginLeft: 'auto' }}
              >
                {expanded[sourceType] ? (
                  <ChevronUpIcon fontSize="small" />
                ) : (
                  <ChevronDownIcon fontSize="small" />
                )}
              </IconButton>
            </div>
            <Collapse
              key="application"
              in={expanded[sourceType]}
              timeout="auto"
            >
              {values.map((child) => (
                <div key={child.label} style={dataReview.expandable}>
                  <div style={dataReview.label}>{child.label}</div>
                  <div style={dataReview.value}>{child.value}</div>
                </div>
              ))}
            </Collapse>
          </React.Fragment>
        )
      })}
    </div>
  )
}

DataReviewMobileContent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  selectedSection: PropTypes.string.isRequired,
}

export default DataReviewMobileContent
