import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { fetchLenders } from 'modules/organizations/actions'
import { useStore } from 'store'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'

import './style.scss'

const AdminPageWrapper = ({ children }) => {
  const { dispatch, state } = useStore()
  const { lenders } = state.orgManagement

  /** Fetch lenders on all admin pages */
  useEffect(() => {
    !lenders.length &&
      hasAccess(state.session.userData, [CLAIMS.CAN_VIEW_LENDERS]) &&
      fetchLenders(dispatch)
  }, [])

  return (
    <div className="admin-page">
      <div className="admin-page__content">{children}</div>
    </div>
  )
}

AdminPageWrapper.propTypes = {
  children: PropTypes.node,
}

export default AdminPageWrapper
