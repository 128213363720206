import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Table from 'components/table'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

import { columns } from './columns'

const PropertyTitleDesktop = ({ details, viewFullDetails }) => {
  const { t: translate } = useTranslation()

  const translatedColumns = columns.map((column) => ({
    ...column,
    header: translate(`uwHub.propertyTitle.${column.accessorKey}`),
  }))

  const fullDetailsButton = (
    <Button
      color={BUTTON_COLORS.INHERIT}
      variant={BUTTON_VARIANTS.OUTLINED}
      onClick={viewFullDetails}
    >
      {translate('uwHub.propertyTitle.viewFullDetailsButton')}
    </Button>
  )

  return (
    <Table
      columns={translatedColumns}
      data={[
        {
          landUse: details.landUse,
          ownerName: details.ownerName,
          propertyType: details.propertyType,
          address: details.address,
        },
      ]}
      selectable={false}
      paginated={false}
      sortable={false}
      hideColumnsVisibilityButton={true}
      hideEditButton={true}
      hideRemoveButton={true}
      title={translate('uwHub.propertyTitle.title')}
      toolbarRightContent={
        details.propertyTitleReportId ? fullDetailsButton : null
      }
    />
  )
}

PropertyTitleDesktop.propTypes = {
  details: PropTypes.object.isRequired,
  viewFullDetails: PropTypes.func.isRequired,
}

export default PropertyTitleDesktop
