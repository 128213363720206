import React from 'react'
import AdminWrapper from 'modules/admin'
import HistoryTracking, {
  HISTORY_TRACKING_TYPES,
} from 'components/history-tracking/index'

const AdminHistoryPage = () => {
  return (
    <AdminWrapper>
      <HistoryTracking type={HISTORY_TRACKING_TYPES.ADMIN} />
    </AdminWrapper>
  )
}

export default AdminHistoryPage
