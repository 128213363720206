import i18n, { changeLanguage } from 'i18next'
import { initReactI18next } from 'react-i18next'

const isDevelopment = process.env.NODE_ENV === 'development'

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  debug: isDevelopment,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  returnObjects: true,
})

export const addTranslations = (language, translations) =>
  i18n.addResourceBundle(language, 'translation', translations)

export const switchLanguage = (language) => changeLanguage(language)
