import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'automatedFunding'

const initialState = {
  dailyFundingApps: [],
  dailyFundingPages: 0,
  isProcessedForToday: false,
  dailyPendingApps: [],
  dailyPendingPages: 0,
  dailyUnfundedApps: [],
  dailyUnfundedPages: 0,
  fundedApps: [],
  fundedAppsPages: 0,
  hasFundedApps: false,
}

const actionsMap = {
  [ACTIONS.SET_DAILY_FUNDING_APPS]: (state, { list, totalPages }) => {
    return {
      ...state,
      dailyFundingApps: list.map((app) => {
        // Manually map the "id" property in order for the Table selection to work across different pages
        return { ...app, id: app.dailyFundingLoanApplicationId }
      }),
      dailyFundingPages: totalPages,
    }
  },
  [ACTIONS.SET_DAILY_FUNDING_PENDING_APPS]: (
    state,
    { list, totalPages, isInitialPageLoad }
  ) => {
    return {
      ...state,
      // isProcessedForToday: isInitialPageLoad
      //   ? list.length > 0
      //   : state.isProcessedForToday,
      dailyPendingApps: list.map((app) => {
        // Manually map the "id" property in order for the Table selection to work across different pages
        return { ...app, id: app.dailyFundingLoanApplicationId }
      }),
      dailyPendingPages: totalPages,
    }
  },
  [ACTIONS.SET_FUNDED_APPS]: (state, { list, totalPages }) => {
    return {
      ...state,
      fundedApps: list.map((app) => {
        // Manually map the "id" property in order for the Table selection to work across different pages
        return { ...app, id: app.dailyFundingLoanApplicationId }
      }),
      fundedAppsPages: totalPages,
      hasFundedApps: state.hasFundedApps || list.length > 0,
    }
  },
  [ACTIONS.SET_DAILY_FUNDING_UNPROCESSED_APPS]: (
    state,
    { list, totalPages }
  ) => {
    return {
      ...state,
      dailyUnfundedApps: list.map((app) => {
        // Manually map the "id" property in order for the Table selection to work across different pages
        return { ...app, id: app.dailyFundingLoanApplicationId }
      }),
      dailyUnfundedPages: totalPages,
    }
  },
  [ACTIONS.UPDATE_DAILY_FUNDING_APP]: (state, { data: updated }) => {
    if (!updated) {
      return state
    }
    return {
      ...state,
      dailyFundingApps: state.dailyFundingApps.map((app) => {
        if (
          app.dailyFundingLoanApplicationId !==
          updated.dailyFundingLoanApplicationId
        ) {
          return app
        }
        return { ...app, ...updated }
      }),
    }
  },
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

export default { name, initialState, reduce }
