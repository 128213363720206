import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {
  assets,
  components,
  unstable_components,
} from '@ElementsCapitalGroup/enium-ui'

import TextField from 'components/input'

import './decision-element.scss'

const { Dropdown } = components
const { Unstable_Grid: Grid } = unstable_components
const { PlusIcon, TrashIcon, DotsGridIcon, ArrowDownIcon, ArrowUpIcon } = assets

const DecisionElement = ({
  element,
  index,
  onElementChange,
  onElementRemove,
  onNewElement,
  canBeRemoved = true,
  nestUnder, // If specified, renders the Nest Under parent button
  unNestFrom, // If specified, renders the Un-nest from parent button
  children,
  errors = {},
  criteria,
  comparators,
}) => {
  const renderIcons = () => {
    return (
      <div
        className={cx('aus-decision__icons', {
          'aus-decision__icons--wide': !!unNestFrom,
        })}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '38px' }}
        >
          <DotsGridIcon
            sx={{
              mr: 3,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
          <PlusIcon
            onClick={onNewElement}
            sx={{
              mr: 3,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
          {index !== 0 && nestUnder ? (
            <div onClick={() => nestUnder(index)}>
              <ArrowUpIcon
                sx={{
                  mr: 3,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            </div>
          ) : null}
          {unNestFrom ? (
            <ArrowDownIcon
              sx={{
                mr: 3,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => unNestFrom(element, index)}
            />
          ) : null}
          {canBeRemoved && (
            <TrashIcon
              onClick={(ev) => {
                ev.stopPropagation()
                onElementRemove(index)
              }}
              sx={{
                mr: 3,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="aus-decision__element">
      <Grid container gap={24}>
        <Grid item mobile={12} tablet={6} desktop={3}>
          <Dropdown
            fullWdith
            options={criteria}
            label="Field *"
            value={criteria?.find((itm) => itm.id === element?.field) || ''}
            onChange={(ev) => {
              onElementChange({ ...element, field: ev.target.value.id })
            }}
            error={!!errors.field}
            helperText={errors.field}
            searchable={true}
          />
        </Grid>
        <Grid item mobile={12} tablet={6} desktop={3}>
          <Dropdown
            fullWidth
            options={comparators.map((item) => {
              return {
                id: item.id,
                label: item.value,
              }
            })}
            label="Operator *"
            value={
              comparators
                .map((item) => {
                  return {
                    id: item.id,
                    label: item.value,
                  }
                })
                ?.find((itm) => itm.id === element?.comparator) || ''
            }
            onChange={(ev) => {
              onElementChange({ ...element, comparator: ev.target.value.id })
            }}
            error={!!errors.comparator}
            helperText={errors.comparator}
          />
        </Grid>
        <Grid
          sx={{
            order: [-1, -1, 0],
            alignSelf: 'center',
          }}
          item
          mobile={12}
          tablet={12}
          desktop={3}
        >
          {renderIcons()}
        </Grid>
        <Grid item mobile={12} tablet={12} desktop={6}>
          <TextField
            label={'Value'}
            onChange={(value) =>
              onElementChange(
                {
                  ...element,
                  value,
                },
                true
              )
            }
            fullWidth
            value={element.value}
            validate={() => errors.value}
          />
        </Grid>
      </Grid>

      {children ? <div className="aus-decision__child">{children}</div> : null}
    </div>
  )
}

DecisionElement.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    field: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    comparator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.string,
    decisions: PropTypes.array,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onElementChange: PropTypes.func.isRequired,
  onElementRemove: PropTypes.func.isRequired,
  onNewElement: PropTypes.func.isRequired,
  canBeRemoved: PropTypes.bool,
  nestUnder: PropTypes.func,
  unNestFrom: PropTypes.func,
  children: PropTypes.node,
  errors: PropTypes.object,
  criteria: PropTypes.array,
  comparators: PropTypes.array,
}

export default DecisionElement
