import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { ExpandableCard } from 'components/expandable-card'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { VALIDATION_TYPES, validate } from 'components/validator'
import IdentityVerification from 'modules/loan-application/ecg-toolbox/identity-verification/index'

import { expandableCard } from '../common/styles'
import UwDeclineTooltip from '../common/uw-decline-tooltip'
import UWApprovalStatus from '../common/uw-approval-status'
import {
  getIdentificationData,
  updateIdentificationData,
  UW_HUB_ACTIONS,
} from '../actions'
import { getIDTypes } from '../../loan-application/actions'
import { getStates } from '../../organizations/actions'
import ApplicantContent from './applicant-content'

const { LoadingIndicator, Dialog } = components
const { CheckIcon, FileShieldIcon } = assets

const Identification = ({ loanApplicationId, data }) => {
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [idTypes, setIdTypes] = useState([])
  const [stateList, setStateList] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({ ...ERRORS_INITIAL_STATE })
  const [showIdVerification, setShowIdVerification] = useState(false)
  const [identificationData, setIdentificationData] = useState({
    borrower: {},
    coBorrower: {},
  })
  const { t: translate } = useTranslation()
  const { dispatch } = useStore()

  useEffect(() => {
    setIdentificationData({
      borrower: data.borrowerIdDetail,
      coBorrower: data.coBorrowerIdDetail,
    })
  }, [data])

  useEffect(() => {
    const promises = []
    if (!idTypes.length) {
      promises.push(
        getIDTypes().then((idTypes) => {
          setIdTypes(
            idTypes.map((v) => ({
              ...v,
              label: translate(v.translationKey),
            }))
          )
        })
      )
    }
    if (!stateList.length) {
      promises.push(getStates().then(setStateList))
    }
    if (promises.length) {
      setLoading(true)
      Promise.all(promises).finally(() => setLoading(false))
    }
  }, [])

  const updateApprovalStatus = (approvalStatusId, reason) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_IDENTIFICATION_DATA,
      payload: {
        ...data,
        uwApprovalStatusId: approvalStatusId,
        uwDeclineReason: reason,
      },
    })
  }

  const validateForm = () => {
    const DEFAULT_ADDRESS = {
      address: {
        addressFirstLine: '',
        city: '',
        state: '',
        zipCode: '',
      },
    }

    const errors = { ...ERRORS_INITIAL_STATE }
    let [isValid, errorsBorrower] = validate(ERROR_MAP, {
      ...DEFAULT_ADDRESS,
      ...identificationData.borrower,
    })
    errors.borrower = errorsBorrower
    if (identificationData.coBorrower) {
      const [isValidCoBorrower, errorsCoBorrower] = validate(ERROR_MAP, {
        ...DEFAULT_ADDRESS,
        ...identificationData.coBorrower,
      })
      if (!isValidCoBorrower) {
        isValid = false
        errors.coBorrower = errorsCoBorrower
      }
    }
    setErrors(errors)
    return isValid
  }

  const onSave = () => {
    if (!validateForm()) {
      return
    }
    setLoading(true)
    const applicantsData = Object.values(identificationData).filter(
      (applicantData) => !!applicantData
    )
    updateIdentificationData(dispatch, loanApplicationId, applicantsData)
      .then(() => getIdentificationData(dispatch, loanApplicationId))
      .then(() => setHasChanges(false))
      .finally(() => setLoading(false))
  }

  const _renderApprovalStatus = (renderApprovalStatusForMobile = false) => (
    <UWApprovalStatus
      loanApplicationId={loanApplicationId}
      uwApprovalStatusId={data.uwApprovalStatusId}
      sectionTypeId={data.sectionTypeId}
      updateSectionApprovalStatus={(approvalStatusId, reason) =>
        updateApprovalStatus(approvalStatusId, reason)
      }
      displayPortalApprovalStatus={false}
      displayUwApprovalStatus={renderApprovalStatusForMobile || !isMobileView}
      approvalStyles={
        renderApprovalStatusForMobile ? { margin: '12px 16px 16px' } : {}
      }
    />
  )

  return (
    <div className="uw-hub-page__income">
      <ExpandableCard
        headerProps={expandableCard.headerProps(false, true)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={
          <div className="uw-hub-page__income-card">
            <div className="align-center uw-hub-page__income__title">
              {translate('uwHub.identification.title')}
              <UwDeclineTooltip data={data} />
            </div>
            <Button
              variant={BUTTON_VARIANTS.OUTLINED}
              color={BUTTON_COLORS.INHERIT}
              onClick={() => setShowIdVerification(true)}
              startIcon={<FileShieldIcon />}
              style={{ marginLeft: 'auto', marginRight: '12px' }}
            >
              {translate('loanApplication.ecgToolbox.sidebar.idVerification')}
            </Button>
            {_renderApprovalStatus()}
          </div>
        }
        collapsibleItems={[
          <div style={{ width: '100%' }} key={0}>
            {isMobileView ? _renderApprovalStatus(true) : null}
            <ApplicantContent
              isBorrower={true}
              identificationData={identificationData.borrower}
              setIdentificationData={(data) => {
                setIdentificationData({ ...identificationData, borrower: data })
                setHasChanges(true)
              }}
              idTypes={idTypes}
              loading={loading}
              stateList={stateList}
              loanApplicationId={loanApplicationId}
              errors={errors.borrower}
              setErrors={(borrower) => setErrors({ ...errors, borrower })}
            />
            {identificationData.coBorrower ? (
              <ApplicantContent
                isBorrower={false}
                identificationData={identificationData.coBorrower}
                setIdentificationData={(data) => {
                  setIdentificationData({
                    ...identificationData,
                    coBorrower: data,
                  })
                  setHasChanges(true)
                }}
                idTypes={idTypes}
                loading={loading}
                stateList={stateList}
                loanApplicationId={loanApplicationId}
                errors={errors.coBorrower}
                setErrors={(coBorrower) => setErrors({ ...errors, coBorrower })}
              />
            ) : null}

            <div className="uw-hub-page__income-button">
              <Button
                onClick={onSave}
                color={BUTTON_COLORS.PRIMARY}
                startIcon={
                  loading ? <LoadingIndicator size={14} /> : <CheckIcon />
                }
                disabled={!hasChanges || loading}
              >
                {translate('global.saveChanges')}
              </Button>
            </div>
          </div>,
        ]}
        isCollapsable={true}
        alwaysCollapsible={true}
        isCollapsed={isTabletView}
        collapsibleItemsStyles={{
          sx: {
            width: '100%',
            padding: !isTabletView ? '24px' : '12px 16px',
          },
        }}
      />
      <Dialog
        open={showIdVerification}
        onClose={() => setShowIdVerification(false)}
        title={translate('loanApplication.ecgToolbox.sidebar.idVerification')}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '850px',
          },
        }}
      >
        <IdentityVerification
          loanApplicationId={loanApplicationId}
          borrower={identificationData.borrower}
          coBorrower={identificationData.coBorrower}
          hideTitle={true}
        />
      </Dialog>
    </div>
  )
}

const ERROR_MAP = {
  idType: [VALIDATION_TYPES.REQUIRED],
  idNumber: [VALIDATION_TYPES.REQUIRED],
  firstName: [VALIDATION_TYPES.REQUIRED],
  lastName: [VALIDATION_TYPES.REQUIRED],
  mothersMaidenName: [VALIDATION_TYPES.REQUIRED],
  issueDate: [VALIDATION_TYPES.REQUIRED],
  stateOfIssue: [VALIDATION_TYPES.REQUIRED],
  expirationDate: [VALIDATION_TYPES.REQUIRED],
  dateOfBirth: [VALIDATION_TYPES.REQUIRED],
  'address.addressFirstLine': [VALIDATION_TYPES.REQUIRED],
  'address.city': [VALIDATION_TYPES.REQUIRED],
  'address.state': [VALIDATION_TYPES.REQUIRED],
  'address.zipCode': [VALIDATION_TYPES.REQUIRED],
}

const ERRORS_INITIAL_STATE = {
  borrower: { address: {} },
  coBorrower: { address: {} },
}

Identification.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default Identification
