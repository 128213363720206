import React, { useEffect, useState } from 'react'
import LoanAppsModule from 'modules/loan-applications'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import { useStore } from 'store'
import {
  getPrerequisiteData,
  getSalesOrganizationsForFilters,
  getSalesReps,
} from 'modules/loan-applications/actions'
import { getLoanAppStatuses, resetFlow } from 'modules/loan-application/actions'
import { useNavigate } from 'react-router-dom'

const LoanApplicationsPage = () => {
  const { state, dispatch } = useStore()
  const navigate = useNavigate()
  const { userData } = state.session

  const [loanAppStatuses, setLoanAppStatuses] = useState([])
  const [salesOrganizations, setSalesOrganizations] = useState([])
  const [salesReps, setSalesReps] = useState([])
  const [prerequisiteDataLoading, setPrerequisiteDataLoading] = useState(false)

  /** On mount, fetch required data */
  useEffect(() => {
    const promises = []
    setPrerequisiteDataLoading(true)
    if (hasAccess(userData, CLAIMS.CAN_VIEW_ORGANIZATIONS)) {
      promises.push(
        getSalesOrganizationsForFilters().then(
          (res) => res && setSalesOrganizations(res)
        )
      )
    }
    promises.push(getPrerequisiteData(dispatch, userData).catch(console.error))
    promises.push(getLoanAppStatuses().then(setLoanAppStatuses))
    if (hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)) {
      promises.push(getSalesReps().then(setSalesReps))
    }
    Promise.all(promises).finally(() => setPrerequisiteDataLoading(false))
  }, [JSON.stringify(userData.claims)])

  const handleOnClick = () => {
    resetFlow(dispatch)
    navigate(`/loan-application/new/new-loan-app`)
  }

  return (
    <div className="applications-page">
      <LoanAppsModule
        salesOrganizations={salesOrganizations}
        loanAppStatuses={loanAppStatuses}
        salesReps={salesReps}
        onCreate={handleOnClick}
        prerequisiteDataLoading={prerequisiteDataLoading}
      />
    </div>
  )
}
export default LoanApplicationsPage
