import React from 'react'
import PropTypes from 'prop-types'
import { ExpandableCard } from 'components/expandable-card'
import { formatInUSFormat } from 'common/number'
import './style.scss'

const FundingDetails = ({
  fundedBy,
  fundedByLabel = 'Total Funded By',
  totalFunding = 0,
  totalDeposit = 0,
  ...rest
}) => {
  const name = fundedBy.friendlyName
  return (
    <div className="afp-funding" {...rest}>
      <ExpandableCard
        title={
          <div className="afp-funding__grid">
            <div>{fundedByLabel}</div>
            <div>Total Funding</div>
            <div>Total Deposit</div>
          </div>
        }
        items={[
          <div key={0} className="afp-funding__grid afp-funding__grid--row">
            <div style={{ paddingLeft: '3px' }}>{name}</div>
            <div style={{ paddingLeft: '4px' }}>
              $ {formatInUSFormat(totalFunding)}
            </div>
            <div style={{ paddingLeft: '6px' }}>
              $ {formatInUSFormat(totalDeposit)}
            </div>
          </div>,
        ]}
      />
    </div>
  )
}

FundingDetails.propTypes = {
  fundedBy: PropTypes.object.isRequired,
  fundedByLabel: PropTypes.string,
  totalFunding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalDeposit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default FundingDetails
