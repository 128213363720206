import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import TextField, { INPUT_TYPES } from 'components/input'
import { dateFormatServer } from 'common/constants'
import { scrollToClosestError } from 'common/utils'
import LateRevFee from '../utils/late-rev-fee'
import InputDate from '../../../components/input-date'

const { Dropdown } = components

const ExpandedRowLenderFees = ({
  data,
  onSave,
  onCancel,
  fundingTypes,
  revFees,
  lateFees,
}) => {
  const expandedRef = useRef(null)
  const [state, setState] = useState({
    contractDate: '',
    effectiveDate: '',
    savingsBorrower: '',
    savingsCoBorrower: '',
    donationBorrower: '',
    donationCoBorrower: '',
    stageFundingFee: '',
    fundingTypeId: '',
    lssCode: '',
    lssPortfolio: '',
  })
  const [localRevFees, setLocalRevFees] = useState([])
  const [localLateFees, setLocalLateFees] = useState([])
  const [errors, setErrors] = useState({})

  // On props update, update the local state
  useEffect(() => {
    setState({
      ...state,
      ...data,
      contractDate: moment(data.contractDate).format(dateFormatServer),
      effectiveDate: moment(data.effectiveDate).format(dateFormatServer),
      lenderFeeType: undefined,
      timing: undefined,
      fundingTypeId: data.timing.id,
      lenderFundingFeeId: data.lenderFundingFeesId,
    })
  }, [data])

  useEffect(() => {
    setLocalRevFees(cloneDeep(revFees))
  }, [JSON.stringify(revFees)])

  useEffect(() => {
    setLocalLateFees(cloneDeep(lateFees))
  }, [JSON.stringify(lateFees)])

  const updateField = (name) => (value) => setState({ ...state, [name]: value })

  /** Validate form and save */
  const validateAndSave = () => {
    setErrors({})
    const errors = {
      reversalFees: {},
      lateFees: {},
    }
    let isValid = true
    localRevFees?.forEach((revFee, index) => {
      if (!revFee.numberOfDays || !revFee.percentageApplied) {
        errors.reversalFees[index] = 'Both fields are required'
        isValid = false
      }
    })
    localLateFees?.forEach((lateFee, index) => {
      if (!lateFee.numberOfDays || !lateFee.percentageApplied) {
        errors.lateFees[index] = 'Both fields are required'
        isValid = false
      }
    })

    if (isValid) {
      onSave(state, localRevFees, localLateFees)
    } else {
      setErrors(errors)
      scrollToClosestError(expandedRef.current)
    }
  }

  return (
    <div className="afp__expanded" ref={expandedRef}>
      <div className="afp__expanded__header">
        <div className="afp__expanded__title">
          <p>{data.lenderName}</p>
        </div>
        <Button
          variant={BUTTON_VARIANTS.OUTLINED}
          color={BUTTON_COLORS.INHERIT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button onClick={validateAndSave}>Save Changes</Button>
      </div>
      <div className="afp__grid-inputs">
        <TextField label="Lender" disabled={true} value={data.lenderName} />
        <InputDate
          label="Contract Date"
          value={state.contractDate}
          onChange={updateField('contractDate')}
          serverDateFormat="YYYY-MM-DD"
        />
        <InputDate
          label="Effective Date"
          value={state.effectiveDate}
          onChange={updateField('effectiveDate')}
          serverDateFormat="YYYY-MM-DD"
        />
        <TextField
          label="Type"
          disabled={true}
          value={data.lenderFeeType?.friendlyName}
        />
        <TextField
          label="Savings Borrower"
          value={state.savingsBorrower}
          onChange={updateField('savingsBorrower')}
          type={INPUT_TYPES.NUMBER}
        />
        <TextField
          label="Savings Co-Borrower"
          value={state.savingsCoBorrower}
          onChange={updateField('savingsCoBorrower')}
          type={INPUT_TYPES.NUMBER}
        />
        <TextField
          label="Donation Borrower"
          value={state.donationBorrower}
          onChange={updateField('donationBorrower')}
          type={INPUT_TYPES.NUMBER}
        />
        <TextField
          label="Donation Co-Borrower"
          value={state.donationCoBorrower}
          onChange={updateField('donationCoBorrower')}
          type={INPUT_TYPES.NUMBER}
        />
        <TextField
          label="Stage Funding Fee"
          value={state.stageFundingFee}
          onChange={updateField('stageFundingFee')}
          prefix="%"
          type={INPUT_TYPES.NUMBER}
        />
        <Dropdown
          label="Timing"
          options={fundingTypes}
          value={
            fundingTypes.find((itm) => itm.id === state.fundingTypeId) || ''
          }
          fullWidth={true}
          onChange={(ev) => {
            updateField('fundingTypeId')(ev.target.value.id)
          }}
        />
        <TextField
          label="LSS Code"
          value={state.lssCode}
          onChange={updateField('lssCode')}
        />
        <TextField
          label="LSS Portofolio"
          value={state.lssPortfolio}
          onChange={updateField('lssPortfolio')}
        />
      </div>
      <div className="afp__grid-inputs" style={{ marginTop: '30px' }}>
        <div>
          <div className="afp__dam__subtitle">Rev Fee</div>
          <LateRevFee
            state={localRevFees}
            onStateChange={setLocalRevFees}
            errors={errors.reversalFees}
            clearErrors={() => setErrors({ ...errors, reversalFees: {} })}
          />
        </div>
        <div>
          <div className="afp__dam__subtitle">Late Fee</div>
          <LateRevFee
            state={localLateFees}
            onStateChange={setLocalLateFees}
            errors={errors.lateFees}
            clearErrors={() => setErrors({ ...errors, lateFees: {} })}
          />
        </div>
      </div>
    </div>
  )
}

ExpandedRowLenderFees.propTypes = {
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fundingTypes: PropTypes.array.isRequired,
  revFees: PropTypes.array.isRequired,
  lateFees: PropTypes.array.isRequired,
}

export default ExpandedRowLenderFees
