import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { updateBorrowerName } from '../../actions'

const { Dialog } = components

const ChangePropertyTitleModal = ({
  dispatch,
  loanApplicationId,
  isOpen,
  onClose,
  borrowerInfo,
  propertyTitleName,
  handleGetSelfServiceCriterias,
}) => {
  const { t: translate } = useTranslation()
  const [firstName, setFirstName] = useState(borrowerInfo?.firstName)
  const [lastName, setLastName] = useState(borrowerInfo?.lastName)
  const [loading, setLoading] = useState(false)

  const handleOnClose = (event, reason) => {
    // Disable on background click for closing the Dialog
    if (reason !== 'closeButtonClicked') {
      return
    }
    onClose()
  }

  const handleSubmit = () => {
    setLoading(true)
    updateBorrowerName(
      dispatch,
      loanApplicationId,
      borrowerInfo.applicantId,
      firstName,
      lastName
    ).finally(() => {
      handleGetSelfServiceCriterias()
      setLoading(false)
    })
  }

  return (
    <Dialog
      open={isOpen}
      title={translate('loanApplication.blocked.adjustPropertyTitle')}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '400px',
          '& .MuiDialogTitle-root': {
            alignItems: 'center',
            fontSize: '1.125rem',
          },
        },
      }}
      disableEscapeKeyDown={true}
      onClose={handleOnClose}
      actions={
        <>
          <Button
            onClick={onClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            disabled={loading}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={!firstName}
            loading={loading}
            onClick={handleSubmit}
          >
            {translate('global.saveChanges')}
          </Button>
        </>
      }
      actionsProps={{
        sx: {
          paddingTop: '8px',
          width: '100%',
          justifyContent: 'space-between',
          '& button': {
            width: '50%',
          },
        },
      }}
    >
      <TextField
        style={{ marginTop: '24px' }}
        label={translate('loanApplication.blocked.currentPropertyTitle')}
        value={propertyTitleName}
        className="grid-item"
        fullWidth={true}
        disabled={true}
      />
      <TextField
        style={{ marginTop: '24px' }}
        label={translate('userDetails.firstName')}
        value={firstName}
        onChange={setFirstName}
        className="grid-item"
        fullWidth={true}
        disabled={loading}
      />
      <TextField
        style={{ marginTop: '24px' }}
        label={translate('userDetails.lastName')}
        value={lastName}
        onChange={setLastName}
        className="grid-item"
        fullWidth={true}
        disabled={loading}
      />
    </Dialog>
  )
}

ChangePropertyTitleModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  propertyTitleName: PropTypes.string.isRequired,
  borrowerInfo: PropTypes.object.isRequired,
  handleGetSelfServiceCriterias: PropTypes.func.isRequired,
}

export default ChangePropertyTitleModal
