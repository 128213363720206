import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

import flowReducer from 'modules/loan-application/reducers'
import globalReducer from 'modules/global/reducers'
import sessionReducer from 'modules/session/reducers'
import organizationsReducer from 'modules/organizations/reducers'
import adminRolesReducer from 'modules/admin-roles-management/reducers'
import adminLoanApplicationsReducer from 'modules/loan-applications/reducers'
import outageMessagesReducer from 'modules/outage-messages/reducers'
import automatedFundingReducer from 'modules/automated-funding/reducers'
import uwHubReducer from 'modules/uw-hub/reducers'

import { configureApiMiddleware } from './api-middleware'

/** Import all reducers here */
const reducers = [
  sessionReducer,
  flowReducer,
  organizationsReducer,
  globalReducer,
  adminRolesReducer,
  adminLoanApplicationsReducer,
  outageMessagesReducer,
  automatedFundingReducer,
  uwHubReducer,
]

/** Construct initial state from reducers */
const initialState = {}

/** Create the store */
const store = createContext(initialState)
reducers.forEach(
  (reducer) => (initialState[reducer.name] = reducer.initialState)
)

/** Create useStore hook */
const useStore = () => useContext(store)

/** Export StateProvider as a wrapper to provide the Store for the whole app */
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    let newState = { ...state }
    reducers.forEach((reducer) => {
      if (reducer.name) {
        newState = {
          ...newState,
          [reducer.name]: reducer.reduce(newState[reducer.name], action),
        }
      } else {
        console.error(
          'Reducer "name" field is required. Error for reducer',
          reducer
        )
      }
    })

    return newState
  }, initialState)

  configureApiMiddleware(dispatch)

  return <store.Provider value={{ state, dispatch }}>{children}</store.Provider>
}

StateProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { store, StateProvider, useStore }
