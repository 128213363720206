import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { ORGANIZATION_TYPE_IDS } from 'common/constants'
import SalesOrgAddEditForm from './sales'
import { LENDER_ORG_INITIAL_STATE, SALES_ORG_INITIAL_STATE } from './constants'
import LenderOrgAddEditForm from './lender'

const { Card } = components

const OrgAddEditForm = ({
  isNew,
  parentOrg,
  orgData,
  orgTypes,
  orgStatuses,
  onSave,
  onCancel,
  userData,
}) => {
  const [state, setState] = useState(SALES_ORG_INITIAL_STATE)
  const [somethingChanged, setSomethingChanged] = useState(false)
  const isLenderOrg = state.type?.id === ORGANIZATION_TYPE_IDS.LENDER

  const fixedOrgType = useMemo(() => {
    if (!isNew) {
      return orgData.type.id
    }
    if (
      parentOrg.isRoot ||
      parentOrg.type?.id === ORGANIZATION_TYPE_IDS.CORPORATE
    ) {
      return null
    }
    return ORGANIZATION_TYPE_IDS.DEALER
  }, [parentOrg, isNew])

  useEffect(() => {
    orgData && setState(orgData)
  }, [orgData])

  const onUpdateField = (field) => (value) => {
    if (field === 'type' && value !== state.type) {
      // Special case when Organization Type changes, we reset the state
      const newState =
        value.id === ORGANIZATION_TYPE_IDS.LENDER
          ? LENDER_ORG_INITIAL_STATE
          : SALES_ORG_INITIAL_STATE
      setState({ ...newState, type: value })
    } else {
      setState({ ...state, [field]: value })
    }
    if (!somethingChanged) {
      setSomethingChanged(true)
    }
  }

  return (
    <Card
      title={
        isNew ? 'Organization Details' : `Organization ${orgData.name} Details`
      }
    >
      {isLenderOrg ? (
        <LenderOrgAddEditForm
          isNew={isNew}
          state={state}
          updateField={onUpdateField}
          fixedOrgType={fixedOrgType}
          orgTypes={orgTypes}
          orgStatuses={orgStatuses}
          onSave={onSave}
          onCancel={onCancel}
          somethingChanged={somethingChanged}
          parentOrg={parentOrg}
        />
      ) : (
        <SalesOrgAddEditForm
          isNew={isNew}
          state={state}
          updateField={onUpdateField}
          fixedOrgType={fixedOrgType}
          orgTypes={orgTypes}
          orgStatuses={orgStatuses}
          onSave={onSave}
          onCancel={onCancel}
          somethingChanged={somethingChanged}
          userData={userData}
        />
      )}
    </Card>
  )
}

OrgAddEditForm.propTypes = {
  isNew: PropTypes.bool,
  parentOrg: PropTypes.object.isRequired,
  orgData: PropTypes.object,
  orgTypes: PropTypes.array.isRequired,
  orgStatuses: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
}

export default OrgAddEditForm
