import React from 'react'
import PropTypes from 'prop-types'
import GlobalModule from 'modules/global'

function NonAuthWrapper({ children }) {
  return (
    <>
      <GlobalModule />
      <main>{children}</main>
    </>
  )
}

NonAuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default NonAuthWrapper
