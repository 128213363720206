import { components } from '@ElementsCapitalGroup/enium-ui'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'

const { Dialog, Dropdown, Chip } = components

export const RegenerateModal = ({ envelopes, onSubmit, onClose }) => {
  const { t: translate } = useTranslation()

  const [selectedEnvelopes, setSelectedEnvelopes] = useState([])

  const onRemoveEnvelope = (id) => () => {
    setSelectedEnvelopes((prev) =>
      prev.filter((envelope) => envelope.envelopeDefinitionId !== id)
    )
  }

  const onDropdownChange = (ev) => setSelectedEnvelopes(ev.target.value)

  const handleOnSubmit = () => {
    onSubmit(selectedEnvelopes.map((el) => el.envelopeDefinitionId)).then(
      () => {
        setSelectedEnvelopes([])
        onClose()
      }
    )
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      title={translate('loanApplication.step4.regenerateDocument')}
      PaperProps={{
        sx: {
          minWidth: '400px',
        },
      }}
      actions={
        <>
          <Button
            onClick={onClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button disabled={!selectedEnvelopes.length} onClick={handleOnSubmit}>
            {translate('loanApplication.step4.regenerate')}
          </Button>
        </>
      }
    >
      <Dropdown
        multiple={true}
        searchable={true}
        options={
          envelopes?.map((el) => ({
            ...el,
            id: el.envelopeDefinitionId,
            label: el.name,
          })) || []
        }
        value={selectedEnvelopes}
        onChange={onDropdownChange}
        sx={{ marginBottom: '12px' }}
      />

      {selectedEnvelopes.map((envelope) => (
        <Chip
          key={envelope.envelopeDefinitionId}
          label={envelope.name}
          variant="outlined-squared"
          onDelete={onRemoveEnvelope(envelope.envelopeDefinitionId)}
          sx={{ marginRight: '12px' }}
        />
      ))}
    </Dialog>
  )
}

RegenerateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  envelopes: PropTypes.array,
}
