import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useMediaQuery } from 'common/hooks'
import { LARGE_MOBILE_BREAKPOINT } from 'common/constants'

const LoanCalculatorResults = ({
  isSpecialBank,
  results,
  monthlyWrapperStyles = {},
}) => {
  const isMobileView = useMediaQuery(`(max-width:${LARGE_MOBILE_BREAKPOINT}px)`)

  const renderDesktopView = () => {
    return (
      <div
        className={cx('loan-calculator__table', {
          'loan-calculator__table--small': isSpecialBank,
        })}
      >
        <div className="loan-calculator__table__header loan-calculator__table__header--left">
          Rate
        </div>
        <div className="loan-calculator__table__header">Promo</div>
        {!isSpecialBank && (
          <div className="loan-calculator__table__header">Paydown</div>
        )}

        <div className="loan-calculator__table__header">
          {isSpecialBank ? 'Payment' : 'No Paydown'}
        </div>

        <div className="loan-calculator__table__heading">
          <span className="loan-calculator__table__value">
            {results.interestRate}%
          </span>{' '}
          (Auto Pay)
        </div>
        <div className="loan-calculator__table__value">
          {isSpecialBank || !results.promoPayment
            ? '-'
            : `$${results.promoPayment}`}
        </div>
        {!isSpecialBank && (
          <div className="loan-calculator__table__value">
            {results.paymentWithAutoPayAndPaydown
              ? `$${results.paymentWithAutoPayAndPaydown}`
              : '-'}
          </div>
        )}
        <div className="loan-calculator__table__value">
          {results.paymentWithAutoPay ? `$${results.paymentWithAutoPay}` : '-'}
        </div>

        <div className="loan-calculator__table__line"></div>

        <div className="loan-calculator__table__heading">
          <span className="loan-calculator__table__value">
            {results.interestRateWithoutAutopay}%
          </span>{' '}
          (No Auto Pay)
        </div>
        <div className="loan-calculator__table__value">
          {isSpecialBank || !results.promoPaymentWithoutAutopay
            ? '-'
            : `$${results.promoPaymentWithoutAutopay}`}
        </div>
        {!isSpecialBank && (
          <div className="loan-calculator__table__value">
            {results.paymentNoAutoPayAndPaydown
              ? `$${results.paymentNoAutoPayAndPaydown}`
              : '-'}
          </div>
        )}
        <div className="loan-calculator__table__value">
          {results.paymentNoAutoPay ? `$${results.paymentNoAutoPay}` : '-'}
        </div>
      </div>
    )
  }

  const renderMobileView = () => {
    return (
      <div className="loan-calculator__table loan-calculator__table--mobile">
        <div className="loan-calculator__table__header loan-calculator__table__header--left">
          Rate
        </div>
        <div className="loan-calculator__table__header loan-calculator__table__header--left">
          Rate
        </div>
        <div className="loan-calculator__table__mobile-row loan-calculator__table__heading">
          <span className="loan-calculator__table__value">
            {results.interestRate}%
          </span>{' '}
          (Auto Pay)
        </div>
        <div className="loan-calculator__table__mobile-row loan-calculator__table__heading">
          <span className="loan-calculator__table__value">
            {results.interestRateWithoutAutopay}%
          </span>{' '}
          (No Auto Pay)
        </div>

        <div className="loan-calculator__table__header loan-calculator__table__header--left">
          Promo
        </div>
        <div className="loan-calculator__table__header loan-calculator__table__header--left">
          Promo
        </div>
        <div className="loan-calculator__table__mobile-row">
          <div className="loan-calculator__table__value">
            {isSpecialBank || !results.promoPayment
              ? '-'
              : `$${results.promoPayment}`}
          </div>
        </div>
        <div className="loan-calculator__table__mobile-row">
          <div className="loan-calculator__table__value">
            {isSpecialBank || !results.promoPaymentWithoutAutopay
              ? '-'
              : `$${results.promoPaymentWithoutAutopay}`}
          </div>
        </div>

        {!isSpecialBank && (
          <>
            <div className="loan-calculator__table__header loan-calculator__table__header--left">
              Paydown
            </div>
            <div className="loan-calculator__table__header loan-calculator__table__header--left">
              Paydown
            </div>
            <div className="loan-calculator__table__mobile-row">
              <div className="loan-calculator__table__value">
                {results.paymentWithAutoPayAndPaydown
                  ? `$${results.paymentWithAutoPayAndPaydown}`
                  : '-'}
              </div>
            </div>
            <div className="loan-calculator__table__mobile-row">
              <div className="loan-calculator__table__value">
                {results.paymentNoAutoPayAndPaydown
                  ? `$${results.paymentNoAutoPayAndPaydown}`
                  : '-'}
              </div>
            </div>
          </>
        )}

        <div className="loan-calculator__table__header loan-calculator__table__header--left">
          {isSpecialBank ? 'Payment' : 'No Paydown'}
        </div>
        <div className="loan-calculator__table__header loan-calculator__table__header--left">
          {isSpecialBank ? 'Payment' : 'No Paydown'}
        </div>

        <div className="loan-calculator__table__mobile-row">
          <div className="loan-calculator__table__value">
            {results.paymentWithAutoPay
              ? `$${results.paymentWithAutoPay}`
              : '-'}
          </div>
        </div>
        <div className="loan-calculator__table__mobile-row">
          <div className="loan-calculator__table__value">
            {results.paymentNoAutoPay ? `$${results.paymentNoAutoPay}` : '-'}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className="loan-calculator__monthly-wrapper"
        style={monthlyWrapperStyles.wrapper}
      >
        <span style={monthlyWrapperStyles.asLowAs}>For as low as*</span>
        <span
          className="loan-calculator__monthly-amount"
          style={monthlyWrapperStyles.amount}
        >
          $
          {isSpecialBank
            ? results.paymentWithAutoPay
            : results.paymentWithAutoPayAndPaydown}
          /mo
        </span>
        <span
          className="loan-calculator__disclaimer"
          style={monthlyWrapperStyles.disclaimer}
        >
          *with {!isSpecialBank ? 'Paydown and' : ''} Auto Pay (
          {results.interestRate}%)
        </span>
      </div>

      {isMobileView ? renderMobileView() : renderDesktopView()}
    </>
  )
}

LoanCalculatorResults.propTypes = {
  isSpecialBank: PropTypes.bool,
  results: PropTypes.object.isRequired,
  monthlyWrapperStyles: PropTypes.object,
}

export default LoanCalculatorResults
