import React from 'react'

import BulkFunding from 'modules/automated-funding/bulk-funding'

const BulkFundingPage = () => {
  return (
    <div className="admin-page afp">
      <div className="admin-page__header" style={{ marginBottom: '24px' }}>
        Bulk Funding
      </div>

      <BulkFunding />
    </div>
  )
}

export default BulkFundingPage
