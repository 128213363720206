import React, { useState } from 'react'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'common/constants'
import { ExpandableCard } from 'components/expandable-card'
import AddNewNote from 'components/loan-application/notes/add-new-note'
import NotesList from 'components/loan-application/notes/list'
import { getNotesList, UW_HUB_ACTIONS } from 'modules/uw-hub/actions'
import { expandableCard } from '../common/styles'

import './style.scss'

const Notes = ({ loanApplicationId, notes }) => {
  const { t: translate } = useTranslation()
  const { dispatch } = useStore()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const isDesktopView = useMediaQuery(`(min-width:${DESKTOP_BREAKPOINT}px)`)
  const [loading, setLoading] = useState(false)

  const updateNotesOnDeletion = (updatedNotes) => {
    dispatch({
      type: UW_HUB_ACTIONS.SET_NOTES,
      payload: updatedNotes,
    })
  }

  const getNotes = () => {
    setLoading(true)
    getNotesList(dispatch, loanApplicationId).finally(() => {
      setLoading(false)
    })
  }

  const collapsibleItems = [
    <div className="uw-hub-page__notes-wrapper" key={0}>
      <div className="uw-hub-page__notes-new-note">
        <AddNewNote loanApplicationId={loanApplicationId} getNotes={getNotes} />
      </div>
      <div className="uw-hub-page__notes-list">
        <span>{translate('uwHub.notes.title')}</span>
        <NotesList
          notes={notes}
          loanApplicationId={loanApplicationId}
          updateNotesOnDeletion={updateNotesOnDeletion}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </div>,
  ]

  return (
    <div className="uw-hub-page__notes">
      <ExpandableCard
        headerProps={expandableCard.headerProps(isMobileView)}
        titleTypographyProps={expandableCard.titleProps(isMobileView)}
        title={translate('uwHub.notes.title')}
        collapsibleItems={collapsibleItems}
        isCollapsable={true}
        isCollapsed={!isDesktopView}
        alwaysCollapsible={true}
      />
    </div>
  )
}

Notes.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      note: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ),
}

export default Notes
