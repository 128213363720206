import { VALIDATION_TYPES } from 'components/validator'

export const MESSAGE_TYPES = [
  { id: 'alert', label: 'Alert' },
  { id: 'banner', label: 'Banner' },
]

export const MESSAGES_TABLE = 'pv3-outage-messages'
export const SYSTEM_CONFIG_TABLE = 'pv3-outage-config'
export const USER_TO_MESSAGE_TABLE = 'pv3-user-to-message'
export const SYSTEM_CONFIG_ID = 'pv3'

export const INITIAL_MESSAGE_STATE = {
  title: '',
  text: '',
  type: MESSAGE_TYPES[0].label,
  dismissible: false,
}
export const INITIAL_CONFIG_STATE = {
  displayedAlertId: '',
  displayedBannerId: '',
}

export const ERROR_MAP = {
  title: VALIDATION_TYPES.REQUIRED,
  text: VALIDATION_TYPES.REQUIRED,
}
