import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { components, assets, colors } from '@ElementsCapitalGroup/enium-ui'
import {
  TABLET_BREAKPOINT,
  dateFormatUSWithTimestamps,
  dateFormatUS,
} from 'common/constants'
import Card from 'components/card'
import Loader from 'components/loader'
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/button'
import { downloadFileBase64 } from 'common/utils'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import TextArea from 'components/textarea'
import { useMediaQuery } from 'common/hooks'
import DeleteModal from 'components/modal/delete-modal'
import { v4 as uuidv4 } from 'uuid'
import FileUploadV2 from 'components/file-upload-v2'
import DatePicker from 'components/date-picker'

import {
  approvePTO,
  declinePTO,
  downloadSingleAttachment,
  getPTOData,
  grantPtoFunds,
  requestPtoFunds,
  submitPTO,
} from '../actions'
import InspectionButtons from '../ntp-utils/inspection-buttons'
import { STATE_IDS, PTO_STATUS_IDS, STATES_ORDER } from '../constants'
import { styles } from './style.js'
import { styles as ntpStyles } from '../ntp/style'
import { styles as uploadedStyles } from '../ntp/uploaded-stipulation/style'
import './style.scss'
import { convertDateToClient } from '../../../common/date'

const { Paper, IconButton, Tooltip, Dialog, Alert } = components
const { DownloadIcon, TrashIcon02, AlertCircleIcon } = assets

export const PTOSubmission = ({
  ptoId,
  loanApplicationId,
  currentState,
  dispatch,
  userData,
}) => {
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(new Date())
  const [ptoData, setPtoData] = useState(null)
  const [allAttachments, setAllAttachments] = useState([])
  const [declineModalOpen, setDeclineModalOpen] = useState(false)
  const [attachmentToDelete, setAttachmentToDelete] = useState(null)
  const [reason, setReason] = useState('')
  const isApproved = ptoData?.status.id === PTO_STATUS_IDS.APPROVED
  const { name: currentStateName } = currentState
  const crtStateOrder = STATES_ORDER[currentStateName]
  const hasBothAttachments =
    allAttachments.some(
      (el) => el.attachmentTypeId === FILE_TYPES.PTO_DOCUMENT
    ) &&
    allAttachments.some((el) => el.attachmentTypeId === FILE_TYPES.MONITORING)

  const areFundsRequested = crtStateOrder >= STATES_ORDER.PTOFundsRequested
  const areFundsGranted = crtStateOrder >= STATES_ORDER.PTOFundsGranted
  const isSubmitted = crtStateOrder >= STATES_ORDER.PTODocsPendingApproval
  const isPendingApproval = currentState.id === STATE_IDS.PTODocsPendingApproval

  const { t: translate } = useTranslation()

  const canSubmitPto = useMemo(
    () => hasAccess(userData, CLAIMS.CAN_SUBMIT_PTO),
    [userData]
  )

  const canDeleteAttachments = useMemo(
    () => hasAccess(userData, CLAIMS.CAN_DELETE_PTO_ATTACHMENTS),
    [userData]
  )

  useEffect(() => {
    if (ptoId) {
      getData()
    }
  }, [ptoId])

  const title = useMemo(() => {
    if (!ptoId) {
      return translate('loanApplication.step7.submitPTO')
    }

    switch (ptoData?.status.id) {
      case PTO_STATUS_IDS.SUBMITTED:
        return translate('loanApplication.step7.ptoSubmitted')
      case PTO_STATUS_IDS.DECLINED:
        return translate('loanApplication.step7.ptoDeclined')
      default:
        return translate('loanApplication.step7.pto')
    }
  }, [ptoId, ptoData?.status.id])

  const getData = (idOfPto) => {
    setLoading(true)
    getPTOData(idOfPto || ptoId)
      .then((ptoData) => {
        setPtoData(ptoData)

        if (ptoData?.submittedDate) {
          const submittedDate = moment(ptoData?.submittedDate).isValid()
            ? moment(ptoData?.submittedDate).toDate()
            : null
          setDate(submittedDate)
        }

        ptoData?.attachments &&
          setAllAttachments(
            ptoData.attachments.map((el) => ({ ...el, isUploaded: true }))
          )
      })
      .finally(() => setLoading(false))
  }

  const onFileChange = (files, attachmentTypeId) => {
    for (const file of files) {
      file.attachmentId = uuidv4()
      file.attachmentTypeId = attachmentTypeId
      setAllAttachments((prevFiles) => {
        file.name = getNextAvailableFileName(
          prevFiles,
          file.name.split('.')[0],
          file.name.split('.')[1]
        )
        return [...prevFiles, file]
      })
    }
  }

  const onRemoveFile = (attachment) => {
    setAllAttachments((prev) =>
      prev.filter((file) => file.attachmentId !== attachment.attachmentId)
    )
  }

  const handleSubmit = () => {
    return submitPTO(
      {
        loanApplicationId,
        submittedDate: date,
        proofAttachments: allAttachments,
      },
      loanApplicationId,
      dispatch
    ).then(({ ptoId }) => getData(ptoId))
  }

  const handleApprove = () => {
    return approvePTO(loanApplicationId, dispatch).finally(() => {
      getData()
    })
  }

  const onDecline = (declineReason) => {
    setLoading(true)

    setReason('')
    setDeclineModalOpen(false)

    declinePTO(loanApplicationId, declineReason, dispatch).finally(() => {
      setLoading(false)
      getData()
    })
  }

  const _renderButtons = () => {
    return (
      <div style={ntpStyles.ntpButtonsWrapper}>
        <InspectionButtons
          canApprove={isPendingApproval}
          isApproved={isApproved}
          isSubmitted={isSubmitted}
          areFundsRequested={areFundsRequested}
          areFundsGranted={areFundsGranted}
          canSubmitForApproval={hasBothAttachments && allAttachments.length > 0}
          showDeclineButton={isSubmitted}
          canDecline={isPendingApproval}
          onApprove={handleApprove}
          onDecline={() => setDeclineModalOpen(true)}
          onSubmit={handleSubmit}
          approveLabel={translate('loanApplication.step6.approvePtoDocs')}
          declineLabel={translate('loanApplication.step6.declinePtoDocs')}
          requestLabel={translate('loanApplication.step6.requestPtoFunds')}
          grantLabel={translate('loanApplication.step6.grantPtoFunds')}
          hasSubmitAccess={canSubmitPto}
          hasApproveAccess={hasAccess(userData, CLAIMS.CAN_APPROVE_PTO)}
          hasDeclineAccess={hasAccess(userData, CLAIMS.CAN_DECLINE_PTO)}
          hasRequestAccess={hasAccess(userData, CLAIMS.CAN_REQUEST_PTO_FUNDS)}
          hasGrantAccess={hasAccess(userData, CLAIMS.CAN_GRANT_PTO_FUNDS)}
          onRequest={() => requestPtoFunds(dispatch, loanApplicationId)}
          onGrant={(amount) =>
            grantPtoFunds(dispatch, loanApplicationId, amount, navigate)
          }
          isPtoStep={true}
          grantTitleLabel={translate('loanApplication.step6.grantPtoFunds')}
          grantInputLabel={translate('loanApplication.step6.ptoFundingAmount')}
        />
      </div>
    )
  }

  const handleDownloadAttachment = (attachment) => {
    if (attachment.isUploaded) {
      downloadSingleAttachment(dispatch, attachment.attachmentId).then(
        (res) => res && downloadFileBase64(res)
      )
    } else {
      downloadFileBase64(attachment)
    }
  }

  const renderAttachments = (fileType) => {
    const attachments = allAttachments.filter(
      (el) => el.attachmentTypeId === fileType
    )

    return attachments.map((attachment, index) => {
      return (
        <Paper key={index} style={uploadedStyles.paper}>
          <div style={uploadedStyles.wrapper}>
            <div style={uploadedStyles.attachmentInfoWrapper}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DownloadIcon
                  style={uploadedStyles.attachmentInfo}
                  onClick={() => handleDownloadAttachment(attachment)}
                />
                <div style={uploadedStyles.attachmentInfo}>
                  <div style={uploadedStyles.attachmentName}>
                    {attachment.name}
                  </div>
                  <div style={uploadedStyles.attachmentDate}>
                    {moment(attachment.dateCreated).format(dateFormatUS)}
                  </div>
                </div>
              </div>
              <div className="ntp-upload">
                {!isApproved &&
                  (currentState.id === STATE_IDS.InstallCompleteFundsGranted ||
                    canDeleteAttachments) && (
                    <Tooltip title={translate('buttons.delete')}>
                      <IconButton
                        size={BUTTON_SIZES.SMALL}
                        onClick={() => setAttachmentToDelete(attachment)}
                        sx={{ ml: 2 }}
                      >
                        <TrashIcon02 sx={{ marginBottom: '3px' }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </div>
            </div>
          </div>
        </Paper>
      )
    })
  }

  if (loading) {
    return (
      <Card className="ntp">
        <div className="ntp__wrapper">
          <Loader size={130} center={true} />
        </div>
      </Card>
    )
  }

  return (
    <div style={ntpStyles.ntpWrapper}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}
      >
        <div style={{ ...ntpStyles.header }}>{title}</div>
        {!isMobileView && _renderButtons()}
      </div>
      <div className="pto-submission__content">
        <div>
          <Paper
            className="paper paper--no-shadow-box"
            sx={{ mb: '16px', padding: '16px 24px 0 24px' }}
          >
            <div
              className={cx('ntp__upload', {
                disabled: !canSubmitPto,
              })}
            >
              <FileUploadV2
                key="pto-document"
                multiple={true}
                onChange={(file) => onFileChange(file, FILE_TYPES.PTO_DOCUMENT)}
                canUpload={canSubmitPto && !isApproved}
                title={translate('loanApplication.step6.ptoDocument')}
              />
            </div>

            <div style={styles.attachmentsWrapper}>
              {renderAttachments(FILE_TYPES.PTO_DOCUMENT)}
            </div>
          </Paper>

          <Paper
            className="paper paper--no-shadow-box"
            sx={{ mb: '16px', padding: '16px 24px 0 24px' }}
          >
            <div
              className={cx('ntp__upload', {
                disabled: !canSubmitPto,
              })}
            >
              <FileUploadV2
                key="monitoring"
                multiple={true}
                onChange={(file) => onFileChange(file, FILE_TYPES.MONITORING)}
                canUpload={canSubmitPto && !isApproved}
                title={translate('loanApplication.step6.monitoring')}
              />
            </div>

            <div style={styles.attachmentsWrapper}>
              {renderAttachments(FILE_TYPES.MONITORING)}
            </div>
          </Paper>
        </div>

        <Paper
          className={cx('paper pto-submission__date', {
            'pto-submission__date--disabled': isApproved || !canSubmitPto,
          })}
        >
          <div className="pto-submission__title">
            {translate('loanApplication.step6.ptoDate')}
          </div>
          <DatePicker
            key={date.getTime()} // little hack because the calendar is kept open it doesn't properly refresh the actual date
            setDate={setDate}
            date={date}
            maxDate={new Date()}
            customInput={<div></div>}
            alwaysOpen={true}
            placement="bottom"
            disabled={isApproved || !canSubmitPto}
            disableMobilePortal={true}
          />
        </Paper>
      </div>
      {isMobileView && _renderButtons()}

      {ptoData?.declineReason && (
        <Alert
          sx={{ mt: '24px', flexDirection: 'row !important' }}
          severity="error"
          title={
            <div style={{ fontWeight: 'bold', color: colors.red[700] }}>
              {translate('loanApplication.step6.declineReason')}
            </div>
          }
          icon={<AlertCircleIcon />}
        >
          <>
            <div>{ptoData?.declineReason}</div>
            <div style={styles.declineReasonDate}>
              {moment(convertDateToClient(ptoData?.declineDate)).format(
                dateFormatUSWithTimestamps
              )}
            </div>
          </>
        </Alert>
      )}

      <Dialog
        open={declineModalOpen}
        onClose={() => {
          setReason('')
          setDeclineModalOpen(false)
        }}
        PaperProps={{
          sx: {
            minWidth: '350px',
          },
        }}
        title={
          <div>
            <div> {translate('loanApplication.step6.declineReason')}</div>
            <div style={styles.declineModalSubtitle}>
              {translate('loanApplication.step6.declineReasonSubtitle')}
            </div>
          </div>
        }
        actions={
          <>
            <Button
              onClick={() => {
                setReason('')
                setDeclineModalOpen(false)
              }}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
              fullWidth
            >
              {translate('buttons.cancel')}
            </Button>
            <Button
              onClick={() => onDecline(reason)}
              fullWidth
              disabled={!reason.length}
            >
              {translate(`buttons.submit`)}
            </Button>
          </>
        }
      >
        <TextArea
          label={'Description'}
          rows={isMobileView ? 2 : 4}
          fullWidth
          onChange={setReason}
          value={reason}
          resize="vertical"
        />
      </Dialog>

      {attachmentToDelete && (
        <DeleteModal
          title={translate('loanApplication.step3.areYouSure')}
          confirmButtonText={translate('buttons.delete')}
          cancelButtonText={translate('buttons.cancel')}
          onSubmitModal={() => {
            onRemoveFile(attachmentToDelete)
            setAttachmentToDelete(null)
          }}
          setModalOpen={() => setAttachmentToDelete(null)}
        />
      )}
    </div>
  )
}

function getNextAvailableFileName(
  allFiles,
  fileName,
  fileExtension,
  index = 0
) {
  const name = `${fileName}${index === 0 ? '' : ` (${index})`}.${fileExtension}`
  if (!allFiles.find((f) => f.name === name)) {
    return name
  }
  return getNextAvailableFileName(allFiles, fileName, fileExtension, index + 1)
}

const FILE_TYPES = {
  PTO_DOCUMENT: 2,
  MONITORING: 3,
}

PTOSubmission.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  ptoId: PropTypes.string,
  currentState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
}
