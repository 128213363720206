import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { shadows },
} = theme

const loanDataWrapper = {
  display: 'flex',
  flexDirection: 'column',
}

const loanTitle = {
  color: colors.grey[900],
  fontWeight: 600,
  fontSize: '1.125rem',
  marginBottom: '12px',
}

const loanDataValue = {
  marginTop: '8px',
  color: colors.grey[700],
  fontWeight: 500,
  fontSize: '1rem',
}

const loanDataTitle = {
  color: colors.grey[500],
  fontWeight: 500,
  fontSize: '0.875rem',
}

const loanGrid = {
  marginTop: '24px',
}

const loanProductPaper = {
  padding: '24px',
  border: `1px solid ${colors.grey[200]}`,
  borderRadius: '12px',
  background: colors.white,
  marginBottom: '32px',
  boxShadow: shadows[1],
  marginTop: '12px',
}

export const styles = {
  loanDataWrapper,
  loanGrid,
  loanDataValue,
  loanDataTitle,
  loanTitle,
  loanProductPaper,
}
