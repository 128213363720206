import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoanApplication from 'modules/loan-application'
import {
  getLoanApplication,
  getLoanAppStatuses,
  getLoanAppStatusReasons,
  setLoading,
  goToStep,
  initFlow,
  resetFlow,
  switchTranslationsLanguage,
  initFlowCommercial,
  getLoanApplicationType,
} from 'modules/loan-application/actions'
import {
  STEP_URLS,
  STEPS_MAP,
  STEP_URLS_COMMERCIAL,
  STEP_PATH,
  STEPS_MAP_COMMERCIAL,
  LOAN_APP_TYPES_ID,
} from 'modules/loan-application/constants'
import { loadTranslationsForLoanApp, resetLanguage } from 'modules/translations'
import { useStore } from 'store'
import { getLanguageByOrgId } from 'modules/organizations/actions'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import { getLoanApplicationUrl } from 'common/utils'

const LoanAppPage = () => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const { statuses, reasons, selectedTranslationsLanguage } = state.flow
  const { loanApplicationId, stepPath } = useParams()

  const [isCommercialApp, setIsCommercialApp] = useState(
    stepPath === STEP_PATH.COMMERCIAL
  )
  const [stepUrls, setStepUrls] = useState(
    isCommercialApp ? STEP_URLS_COMMERCIAL : STEP_URLS
  )
  const [loanApplicationType, setLoanApplicationType] = useState(null)
  const isNew = loanApplicationId === 'new'

  const isECG = hasAccess(state.session.userData, CLAIMS.CAN_DO_EVERYTHING)

  /** On mount fetch some required data */
  useEffect(() => {
    !statuses.length && getLoanAppStatuses(dispatch)
    !reasons.length && getLoanAppStatusReasons(dispatch)

    /** On Loan App Unmount reset all fields & reset language to default */
    return () => {
      resetFlow(dispatch)
      resetLanguage()
    }
  }, [])

  useEffect(() => {
    !isNew &&
      getLoanApplicationType(loanApplicationId).then((res) => {
        setIsCommercialApp(
          res.loanApplicationType.id === LOAN_APP_TYPES_ID.COMMERCIAL
            ? true
            : false
        )

        setLoanApplicationType(res.loanApplicationType)
      })
  }, [isNew])

  useEffect(() => {
    setStepUrls(isCommercialApp ? STEP_URLS_COMMERCIAL : STEP_URLS)
  }, [isCommercialApp])

  // Every time the step URL changes, compute the current step based on it
  const crtStep = useMemo(
    () =>
      Object.keys(stepUrls).find(
        (stepKey) => stepUrls[stepKey].path === stepPath
      ),
    [stepPath, stepUrls]
  )

  const initComponent = async () => {
    setLoading(dispatch, true)
    _initializeLoanApplication(
      loanApplicationId,
      isNew,
      crtStep,
      selectedTranslationsLanguage
    ).then(() => setLoading(dispatch, false))
  }

  /**
   * This is the useEffect that handles a valid flow routing and initialization
   * and marks the Loan Application as Loaded
   *  - check for a valid flow ID
   *  - check for a valid step
   *  - redirect to proper step or to /404
   * */
  useEffect(() => {
    if (!loanApplicationType && !isNew) {
      return
    }

    initComponent()

    return () => {
      setLoading(dispatch, false)
    }
  }, [
    loanApplicationId,
    isNew,
    crtStep,
    selectedTranslationsLanguage,
    loanApplicationType,
  ])

  /** Local async method used by the above useEffect to properly load the current loan app */
  const _initializeLoanApplication = async (
    loanApplicationId,
    isNew,
    crtStep,
    selectedTranslationsLanguage
  ) => {
    // If it's an existing flow, load it via API call
    if (!isNew) {
      const loanApp = await getLoanApplication(dispatch, loanApplicationId)

      if (loanApp) {
        // If user is ECG and has a selected translation language, use that for the loan app. Otherwise get from org id.
        let languageAbbreviation
        if (isECG && selectedTranslationsLanguage?.abbreviation) {
          languageAbbreviation = selectedTranslationsLanguage?.abbreviation
        } else {
          const language = await getLanguageByOrgId(loanApp.salesOrganizationId)
          languageAbbreviation = language.abbreviation

          if (isECG) {
            // if ECG user doesn't have a selected translation, initially set it to the one from the loan app
            switchTranslationsLanguage(dispatch, language)
          }
        }

        // Load custom translations for the App (if found)
        await loadTranslationsForLoanApp({
          language: languageAbbreviation,
          orgId: loanApp.salesOrganizationId,
        })

        // If we have a specific step requested, go to it
        if (crtStep) {
          if (isCommercialApp) {
            initFlowCommercial(
              dispatch,
              stepUrls[crtStep].idx,
              loanApplicationId
            )
          } else {
            initFlow(dispatch, stepUrls[crtStep].idx, loanApplicationId)
          }
        } else {
          // If there is no step requested
          // Compute last available step based on the current stateId and redirect to it
          navigate(
            getLoanApplicationUrl(
              loanApp.loanApplicationState.id,
              loanApp.loanApplicationId,
              true,
              isCommercialApp
            ),
            { replace: true }
          )
        }
      } else {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title:
            'The specified loan application does not exist or you do not have the required permissions to view it',
        })
        navigate('/applications', { replace: true })
      }
    } else {
      const orgId = state.session?.userData?.organizationId
      const currentStep = stepUrls[crtStep].idx

      let languageAbbreviation
      if (isECG && selectedTranslationsLanguage?.abbreviation) {
        languageAbbreviation = selectedTranslationsLanguage?.abbreviation
      } else {
        const language = await getLanguageByOrgId(orgId)
        languageAbbreviation = language.abbreviation

        if (isECG) {
          switchTranslationsLanguage(dispatch, language)
        }
      }

      await loadTranslationsForLoanApp({
        language: languageAbbreviation,
        orgId,
      })

      // If it's a new flow, always redirect to first step
      if (currentStep !== 0) {
        goToStep(
          dispatch,
          navigate,
          isCommercialApp
            ? STEPS_MAP_COMMERCIAL.NEW_LOAN_APPLICATION.idx
            : STEPS_MAP.NEW_LOAN_APPLICATION.idx,
          isCommercialApp
        )
      } else {
        if (isCommercialApp) {
          initFlowCommercial(dispatch, currentStep, null, true)
        } else {
          initFlow(dispatch, currentStep, null, true)
        }
      }
    }
  }

  return <LoanApplication isCommercialApp={isCommercialApp} />
}

export default LoanAppPage
