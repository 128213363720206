import React from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

const { LoadingIndicator } = components

const Loader = ({
  size = LOADER_SIZE.MEDIUM,
  text,
  textTypographyProps = {},
  center,
  style = {},
}) => {
  const loaderStyle = {
    ...(center && { width: '100%', textAlign: 'center' }),
    ...style,
  }
  return (
    <div style={loaderStyle}>
      <LoadingIndicator
        size={size}
        label={text}
        labelTypographyProps={textTypographyProps}
      />
    </div>
  )
}

export const LOADER_SIZE = {
  SMALL: 50,
  MEDIUM: 100,
  LARGE: 150,
  XLARGE: 250,
}

Loader.propTypes = {
  size: PropTypes.number,
  text: PropTypes.string,
  textTypographyProps: PropTypes.object,
  center: PropTypes.bool,
  style: PropTypes.object,
}

export default Loader
