import { createBrowserHistory } from 'history'
import { ROLE_TYPES } from 'modules/admin/constants'

export const history = createBrowserHistory()

/** Methods to handle page redirect after login if user is not logged in */
const LS_REDIRECT_LOCATION = 'enium-v3-redirect'
export const saveLocationToRedirectTo = (location) => {
  if (location !== '/login' && location !== '/404') {
    localStorage.setItem(LS_REDIRECT_LOCATION, location)
  }
}
export const getPostAuthenticationRedirect = (roleTypeId) => {
  const savedLocation = localStorage.getItem(LS_REDIRECT_LOCATION)
  if (savedLocation) {
    localStorage.removeItem(LS_REDIRECT_LOCATION)
    if (savedLocation !== 'undefined') {
      return savedLocation
    }
  }

  if (
    roleTypeId === ROLE_TYPES.SALES ||
    roleTypeId === ROLE_TYPES.SALES_ADMIN ||
    roleTypeId === ROLE_TYPES.SALES_MANAGER
  ) {
    return '/dashboard'
  }

  return '/'
}
