import { UW_HUB_ACTIONS } from './actions'
import { UW_HUB_PROPERTY_TYPES } from './constants'

const initialState = {
  loading: true,
  loanApplicationId: null,
  loanApplication: {},
  solarContracts: {},
  creditReports: {},
  evaluationSessions: {},
  stipulations: [],
  finalStipulations: [],
  notes: [],
  propertyTitle: {},
  incomeData: {},
  reviewData: {},
  generalData: {},
  employmentData: {},
  identificationData: {},
}

const name = 'uwHub'

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [UW_HUB_ACTIONS.SET_LOADING]: (state, { payload }) => {
    return {
      ...state,
      loading: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_LOAN_APPLICATION_ID]: (state, { payload }) => {
    return {
      ...state,
      loanApplicationId: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_LOAN_APPLICATION_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      loanApplication: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_LOAN_APPLICATION_STATE]: (state, { payload }) => {
    return {
      ...state,
      loanApplication: {
        ...state.loanApplication,
        loanApplicationStateId: payload,
      },
    }
  },
  [UW_HUB_ACTIONS.SET_SOLAR_CONTRACTS_DATA]: (state, { payload }) => {
    return {
      ...state,
      solarContracts: { ...state.solarContracts, ...payload },
    }
  },
  [UW_HUB_ACTIONS.SET_CREDIT_REPORTS_DATA]: (state, { payload }) => {
    return {
      ...state,
      creditReports: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_EVALUATION_SESSIONS]: (state, { payload }) => {
    return {
      ...state,
      evaluationSessions: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_STIPULATIONS]: (state, { payload }) => {
    return {
      ...state,
      stipulations: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_FINAL_STIPULATIONS]: (state, { payload }) => {
    return {
      ...state,
      finalStipulations: payload,
    }
  },
  [UW_HUB_ACTIONS.UPDATE_NTP_STATUS]: (state, { payload, isInitial }) => {
    const { userNTPId, rejectedDate, statusId } = payload

    const stipulationState = isInitial
      ? state.stipulations
      : state.finalStipulations
    const updatedNtps = [...stipulationState].map((stipulation) => {
      const updatedUserNTPs = [...stipulation.userNTPs].map((userNTP) => {
        if (userNTP.userNTPId === userNTPId) {
          return {
            ...userNTP,
            statusId,
            rejectedDate,
          }
        }
        return userNTP
      })

      return { ...stipulation, userNTPs: updatedUserNTPs }
    })
    return {
      ...state,
      ...(isInitial
        ? { stipulations: updatedNtps }
        : { finalStipulations: updatedNtps }),
    }
  },
  [UW_HUB_ACTIONS.SET_NOTES]: (state, { payload }) => {
    return {
      ...state,
      notes: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_PROPERTY_TITLE_DETAILS]: (state, { payload }) => {
    return {
      ...state,
      propertyTitle: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_DATA_REVIEW_DATA]: (state, { payload }) => {
    return {
      ...state,
      reviewData: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_GENERAL_DATA]: (state, { payload }) => {
    return {
      ...state,
      generalData: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_EMPLOYMENT_DATA]: (state, { payload }) => {
    return {
      ...state,
      employmentData: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_IDENTIFICATION_DATA]: (state, { payload }) => {
    return {
      ...state,
      identificationData: payload,
    }
  },
  [UW_HUB_ACTIONS.SET_INCOME_DATA]: (state, { payload }) => {
    let updatedData = {}
    payload.applicantData.forEach((d) => {
      const actualIncome = d.properties.find(
        (p) => p.propertyTypeId === UW_HUB_PROPERTY_TYPES.YearlyActualIncome
      )
      const actualDti = d.properties.find(
        (p) => p.propertyTypeId === UW_HUB_PROPERTY_TYPES.ActualDti
      )

      if (d.applicantTypeId === 0) {
        updatedData = {
          ...updatedData,
          borrowerActualIncome: actualIncome.value,
          borrowerActualDti: actualDti.value,
        }
      } else if (d.applicantTypeId === 1) {
        updatedData = {
          ...updatedData,
          coBorrowerActualIncome: actualIncome.value,
          coBorrowerActualDti: actualDti.value,
        }
      } else {
        updatedData = {
          ...updatedData,
          combinedActualIncome: actualIncome.value,
          combinedActualDti: actualDti.value,
        }
      }
    })

    return {
      ...state,
      incomeData: {
        ...payload,
        updatedData,
      },
    }
  },
  [UW_HUB_ACTIONS.UPDATE_INCOME_DATA]: (state, { payload }) => {
    return {
      ...state,
      incomeData: {
        ...state.incomeData,
        updatedData: {
          ...state.incomeData.updatedData,
          ...payload,
        },
      },
    }
  },
}

export default { name, initialState, reduce }
