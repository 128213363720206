import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const RenderIncomeEstimator = ({ incomeDataInformation }) => {
  const { t: translate } = useTranslation()
  const {
    dti,
    incomeValue,
    incomeType,
    ficoScore,
    rangeMaxValue,
    rangeMinValue,
    isIncomeVerificationRequired,
    isDtiRecalculated,
  } = incomeDataInformation

  return (
    <div className="income-estimator__paper">
      <div className="income-estimator__fields">
        <div
          className="income-estimator__item-wrapper"
          style={{ marginTop: 0 }}
        >
          <span className="income-estimator__item">
            {translate('loanApplication.ecgToolbox.incomeEstimator.dti')}
          </span>
          <span className="income-estimator__result"> {dti || '-'}</span>
        </div>
        <div className="income-estimator__item-wrapper">
          <span className="income-estimator__item">
            {translate(
              'loanApplication.ecgToolbox.incomeEstimator.recalculatedDTI'
            )}
          </span>
          <span className="income-estimator__result">
            {isDtiRecalculated?.toString() || '-'}{' '}
          </span>
        </div>
        <div className="income-estimator__item-wrapper">
          <span className="income-estimator__item">
            {translate('loanApplication.ecgToolbox.incomeEstimator.income')}
          </span>
          <span className="income-estimator__result">{incomeValue}</span>
        </div>
      </div>
      <div className="income-estimator__fields">
        <div className="income-estimator__item-wrapper">
          <span className="income-estimator__item">
            {translate(
              'loanApplication.ecgToolbox.incomeEstimator.incomeTypeName'
            )}
          </span>
          <span className="income-estimator__result">
            {incomeType
              ? translate(
                  `loanApplication.ecgToolbox.incomeEstimator.incomeType.description.${incomeType.id}`
                )
              : '-'}
          </span>
        </div>

        <div className="income-estimator__item-wrapper">
          <span className="income-estimator__item">
            {translate(
              'loanApplication.ecgToolbox.incomeEstimator.incomeVerificationRequired'
            )}
          </span>
          <span className="income-estimator__result">
            {isIncomeVerificationRequired?.toString() || '-'}{' '}
          </span>
        </div>

        <div className="income-estimator__item-wrapper">
          <span className="income-estimator__item">
            {translate('loanApplication.ecgToolbox.incomeEstimator.ficoScore')}
          </span>
          <span className="income-estimator__result">{ficoScore || '-'} </span>
        </div>
      </div>
      <div className="income-estimator__fields income-estimator__fields--no-space-between">
        <div className="income-estimator__item-wrapper">
          <span className="income-estimator__item">
            {translate(
              'loanApplication.ecgToolbox.incomeEstimator.ficoRangeMin'
            )}
          </span>
          <span className="income-estimator__result">
            {rangeMinValue || '-'}{' '}
          </span>
        </div>
        <div className="income-estimator__item-wrapper">
          <span className="income-estimator__item">
            {translate(
              'loanApplication.ecgToolbox.incomeEstimator.ficoRangeMax'
            )}
          </span>
          <span className="income-estimator__result">
            {rangeMaxValue || '-'}{' '}
          </span>
        </div>
      </div>
    </div>
  )
}

RenderIncomeEstimator.propTypes = {
  incomeDataInformation: PropTypes.object.isRequired,
}

export default RenderIncomeEstimator
