import { ORGANIZATION_TYPE_IDS } from 'common/constants'
import {
  LENDER_ORG_INITIAL_STATE,
  SALES_ORG_INITIAL_STATE,
} from './organization-details/new-and-edit-form/constants'

export const fromLocalOrgToServerOrg = (localOrg) => {
  const isLender = localOrg.type?.id === ORGANIZATION_TYPE_IDS.LENDER
  return {
    ...localOrg,
    organizationId: localOrg.organizationId || localOrg.guid,
    statusId: localOrg.status.id,
    relatedOrganizationUnitIDs: [],
    organizationTypeId: localOrg.type.id,
    emailAddresses: Array.isArray(localOrg.emailAddresses)
      ? localOrg.emailAddresses
      : localOrg.emailAddresses?.split(',').map((el) => el.trim()),
    ...(!isLender && {
      languageId: localOrg.language.id,
      accountManagersIds: localOrg.accountManagers?.map((el) => el.id) ?? [],
    }),
    ...(isLender && {
      organizationStatesIds: localOrg.organizationStates?.map((el) => el.id),
      ficoVersionId: localOrg.ficoVersion.id,
    }),
  }
}

export const fromServerOrgToLocalOrg = (serverOrg) => {
  const isLender = serverOrg.type.id === ORGANIZATION_TYPE_IDS.LENDER
  const initialState = isLender
    ? LENDER_ORG_INITIAL_STATE
    : SALES_ORG_INITIAL_STATE
  return {
    ...initialState,
    ...serverOrg,
    type: {
      id: serverOrg.type.id,
      label: serverOrg.type.name,
      friendlyName: serverOrg.type.friendlyName,
    },
    status: {
      id: serverOrg.status.id,
      label: serverOrg.status.name,
      friendlyName: serverOrg.status.friendlyName,
    },
    emailAddresses: serverOrg.emailAddresses?.join(', ') || '',
    ...(!isLender && {
      language: { id: serverOrg.language.id, label: serverOrg.language.name },
      accountManagers: serverOrg.accountManagers?.map((el) => ({
        id: el.guid,
        label: el.name,
      })),
    }),
    ...(isLender && {
      ficoVersion: {
        id: serverOrg.ficoVersionId,
        label: serverOrg.ficoVersion?.name,
      },
    }),
  }
}
