import React, { useState } from 'react'
import { useStore } from 'store'
import { listWires } from 'modules/automated-funding/actions'
import { AF_PAGE_SIZE } from 'modules/automated-funding/utils/constants'
import WiresModule from 'modules/automated-funding/wires'
import FundingBreadcrumbs from 'modules/automated-funding/utils/breadcrumbs'

const AFPWiresPage = () => {
  const { dispatch } = useStore()
  const [state, setState] = useState({
    list: [],
    totalPages: 0,
  })

  const fetchList = (query = {}) => {
    return listWires(query).then((result) => {
      result?.wires &&
        setState({
          ...state,
          list: result.wires,
          totalPages: Math.ceil(result.itemCount / AF_PAGE_SIZE),
        })
    })
  }

  return (
    <div className="admin-page afp">
      <FundingBreadcrumbs items={[{ id: 'wires', label: 'Wires' }]} />
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Wires</div>
          <div>View all your wires in one place.</div>
        </div>
      </div>
      <div className="admin-page__content">
        <WiresModule
          list={state.list}
          pageCount={state.totalPages}
          onFetchList={fetchList}
          dispatch={dispatch}
        />
      </div>
    </div>
  )
}

export default AFPWiresPage
