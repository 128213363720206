import React from 'react'
import PropTypes from 'prop-types'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'

import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Loader from 'components/loader'

import './index.scss'

const { Button } = components

const EcgActions = ({
  loanApplicationId,
  handleReEvaluateLoanApp,
  isReEvaluateDisabled,
  downloadAllFiles,
  setLoading,
  loading,
  loanFormData,
  extendLoanApplicationExpirationDate,
  expireApplication,
  downloadRemovedAttachments,
}) => {
  const { t: translate } = useTranslation()

  return (
    <div className="actions">
      <AccessWrapper claims={CLAIMS.CAN_DOWNLOAD_ALL_ATTACHMENTS}>
        <div className="actions__items">
          <div className="actions__items-wrapper">
            <span className="actions__items-name">
              {translate('loanApplication.ecgToolbox.sidebar.downloadAll')}
            </span>
            {loading.downloadAll && (
              <Loader
                className="toolbox__icon__loader"
                size={33.5}
                noLogo={true}
              />
            )}
          </div>
          <Button
            disabled={loading.downloadAll}
            onClick={() => {
              setLoading({ ...loading, downloadAll: true })
              downloadAllFiles(loanApplicationId).finally(() =>
                setLoading({ ...loading, downloadAll: false })
              )
            }}
          >
            Download All
          </Button>
        </div>
      </AccessWrapper>

      <AccessWrapper claims={CLAIMS.CAN_RE_EVALUATE_ECG_TOOLBOX}>
        <div className="actions__items">
          <div className="actions__items-wrapper">
            <span className="actions__items-name">
              {translate('loanApplication.ecgToolbox.sidebar.reEvaluate')}
            </span>
            {loading.reEvaluate && (
              <Loader
                className="toolbox__icon__loader"
                size={33.5}
                noLogo={true}
              />
            )}
          </div>
          <Button
            onClick={handleReEvaluateLoanApp}
            disabled={loading.reEvaluate || isReEvaluateDisabled}
          >
            {translate('loanApplication.ecgToolbox.sidebar.reEvaluate')}
          </Button>
        </div>
      </AccessWrapper>

      {loanFormData.canExtendExpirationPeriod && (
        <AccessWrapper
          claims={CLAIMS.CAN_EXTEND_LOAN_APPLICATION_EXPIRATION_DATE}
        >
          <div className="actions__items">
            <div className="actions__items-wrapper">
              <span className="actions__items-name">
                {translate(
                  'loanApplication.ecgToolbox.sidebar.extendExpirationDate'
                )}
              </span>
              {loading.extendExpiration && (
                <Loader
                  className="toolbox__icon__loader"
                  size={33.5}
                  noLogo={true}
                />
              )}
            </div>

            <Button
              onClick={extendLoanApplicationExpirationDate}
              disabled={loading.extendExpiration}
            >
              {translate(
                'loanApplication.ecgToolbox.sidebar.extendExpirationDate'
              )}
            </Button>
          </div>
        </AccessWrapper>
      )}

      {loanFormData.canExpire && (
        <AccessWrapper claims={CLAIMS.CAN_EXPIRE_LOAN_APPLICATION}>
          <div className="actions__items">
            <div className="actions__items-wrapper">
              <span className="actions__items-name">
                {translate(
                  'loanApplication.ecgToolbox.sidebar.expireApplication'
                )}
              </span>
              {loading.expireApplication && (
                <Loader
                  className="toolbox__icon__loader"
                  size={33.5}
                  noLogo={true}
                />
              )}
            </div>

            <Button
              onClick={expireApplication}
              disabled={loading.expireApplication}
            >
              {translate(
                'loanApplication.ecgToolbox.sidebar.expireApplication'
              )}
            </Button>
          </div>
        </AccessWrapper>
      )}
      <AccessWrapper claims={CLAIMS.CAN_DOWNLOAD_ALL_REMOVED_ATTACHMENTS}>
        <div className="actions__items">
          <div className="actions__items-wrapper">
            <span className="actions__items-name">
              {translate(
                'loanApplication.ecgToolbox.sidebar.downloadRemovedAttachments'
              )}
            </span>
          </div>

          <Button
            onClick={() => {
              setLoading({ ...loading, downloadRemovedAttachments: true })
              downloadRemovedAttachments(loanApplicationId).finally(() =>
                setLoading({ ...loading, downloadRemovedAttachments: false })
              )
            }}
            disabled={loading.downloadRemovedAttachments}
          >
            {translate('loanApplication.ecgToolbox.sidebar.downloadAll')}
          </Button>
        </div>
      </AccessWrapper>
    </div>
  )
}

EcgActions.propTypes = {
  loanApplicationId: PropTypes.string.isRequired,
  handleReEvaluateLoanApp: PropTypes.func.isRequired,
  isReEvaluateDisabled: PropTypes.bool,
  isCommercialApp: PropTypes.bool,
  loanFormData: PropTypes.object.isRequired,
  downloadAllFiles: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  extendLoanApplicationExpirationDate: PropTypes.func.isRequired,
  expireApplication: PropTypes.func.isRequired,
  downloadRemovedAttachments: PropTypes.func.isRequired,
}

export default EcgActions
