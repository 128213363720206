import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import withScrolling from 'react-dnd-scrolling'

import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import Button from 'components/button'
import TextField, { INPUT_TYPES } from 'components/input'
import DraggableList from 'components/draggable-list'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'

import {
  getAssociatedLendersPriority,
  updateLendersPriority,
} from '../../actions'
import { styles } from '../style.js'

const { Card } = components
const { DotsGridIcon } = assets

const OrganizationPrioritiseLenders = ({ orgData, dispatch, setLoading }) => {
  const [list, setList] = useState([])
  const [errors, setErrors] = useState({})
  const [somethingChanged, setSomethingChanged] = useState(false)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const ScrollingComponent = withScrolling('div')

  useEffect(() => {
    setLoading(true)
    getAssociatedLendersPriority(orgData.guid)
      .then((res) => setList(res.map((el) => ({ ...el, id: el.lenderId }))))
      .finally(() => setLoading(false))
  }, [])

  const updatePriorityLevel = (lenderId, newPriorityLevel) => {
    setList((prevState) =>
      prevState.map((el) =>
        el.lenderId === lenderId
          ? { ...el, priorityLenderOfferingLevel: newPriorityLevel }
          : { ...el }
      )
    )
    if (!somethingChanged) {
      setSomethingChanged(true)
    }
  }

  const onListChange = (newPriorities, initialIndex, currentIndex) => {
    const movedIndex = currentIndex < initialIndex ? 1 : -1
    newPriorities[currentIndex].priorityLenderOfferingLevel =
      newPriorities[currentIndex + movedIndex].priorityLenderOfferingLevel

    setList(newPriorities)
    if (!somethingChanged) {
      setSomethingChanged(true)
    }
  }

  const isValid = () => {
    const errors = {}
    let isValid = true

    list.forEach((el, index) => {
      if (parseInt(el.priorityLenderOfferingLevel) < 0) {
        errors[index] = 'Must be greater than 0'
        isValid = false
      }
    })

    setErrors(errors)
    return isValid
  }

  const onSavePriorities = () => {
    if (isValid()) {
      setLoading(true)
      updateLendersPriority(list)
        .then(() => {
          showNotification(dispatch, {
            title: `Successfully updated lenders priorities.`,
          })
        })
        .catch((err) => {
          showNotification(dispatch, {
            type: NOTIFICATION_TYPES.NEGATIVE,
            title: `An error occurred while updating lenders priorities. Please refresh the page and try again. ${err.title}`,
          })
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <DndProvider
      backend={isMobileView ? TouchBackend : HTML5Backend}
      options={isMobileView ? { enableMouseEvents: true } : {}}
    >
      <ScrollingComponent>
        <div
          style={{ ...styles.loanAssociation.searchBar, marginBottom: '32px' }}
        >
          <h1
            style={{
              ...styles.users.orgDetailsTitle,
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            Prioritise Lenders
          </h1>

          <Button
            disabled={!somethingChanged}
            onClick={onSavePriorities}
            sx={styles.loanAssociation.submitButton}
          >
            Submit
          </Button>
        </div>

        <DraggableList
          type="PRIORITISE_LENDERS"
          list={list}
          renderElement={(lenderPriority, index) => (
            <Card>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DotsGridIcon sx={{ margin: '26px 16px 0 0' }} />
                <TextField
                  label="Priority Level"
                  style={{ marginRight: '25px' }}
                  value={lenderPriority.priorityLenderOfferingLevel}
                  type={INPUT_TYPES.NUMBER}
                  onChange={(newPriorityValue) =>
                    updatePriorityLevel(
                      lenderPriority.lenderId,
                      newPriorityValue
                    )
                  }
                  validate={() => errors[index]}
                />
                <span style={{ marginTop: '26px' }}>
                  {lenderPriority.lenderName}
                </span>
              </div>
            </Card>
          )}
          onListChange={onListChange}
        />
      </ScrollingComponent>
    </DndProvider>
  )
}

OrganizationPrioritiseLenders.propTypes = {
  orgData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default OrganizationPrioritiseLenders
