import React from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import TextField from 'components/input'

import './index.scss'

const { Dropdown, Chip } = components

const TokenElement = ({
  tokens,
  tokenTypes,
  tokensData,
  handleSelectedData,
  handleRemoveTokenType,
  isMobileView,
  editStaticText,
}) => {
  const formatTokenTypes = (itm, token) => {
    if (itm.mapList?.length) {
      return itm.mapList.map((tokenType) => {
        return {
          id: tokenType.id,
          label: tokenType.friendlyName,
        }
      })
    } else if (tokensData) {
      const matchedTokens = tokenTypes.filter((tkn) =>
        tokensData.some(
          (data) =>
            data.tokenTypeIds.includes(tkn.id) && token?.guid === data.guid
        )
      )

      return matchedTokens || null
    }
  }

  return tokens.map((token) => {
    const tokenInEdit = tokensData.find((el) => el.guid === token.guid)

    return (
      <div className="loan-token__wrapper" key={token.guid}>
        <div className="loan-product__paper" style={{ width: '100%' }}>
          <div className="loan-token__key">{token.key}</div>
          <div className="loan-token__fields">
            <div className="loan-token__dropdown">
              <Dropdown
                multiple={true}
                searchable={true}
                options={tokenTypes}
                value={formatTokenTypes(tokenInEdit || token, token) || []}
                fullWidth={isMobileView}
                onChange={(ev) => {
                  handleSelectedData(ev.target.value, token)
                }}
                sx={{ mr: 2 }}
              />
            </div>

            <TextField
              placeholder="Add a custom tag"
              onChange={(text) => editStaticText(token, text)}
              value={tokenInEdit?.staticValue || token.staticValue || ''}
            />
          </div>

          {formatTokenTypes(tokenInEdit || token, token)?.map((itm) => {
            return (
              <Chip
                key={itm.id}
                label={itm.label}
                variant="outlined-squared"
                onDelete={() => {
                  handleRemoveTokenType(itm, token)
                }}
                sx={{ marginRight: '12px', marginTop: '12px' }}
              />
            )
          })}
        </div>
      </div>
    )
  })
}

TokenElement.propTypes = {
  tokens: PropTypes.array,
  tokenTypes: PropTypes.array,
  tokensData: PropTypes.array.isRequired,
  handleSelectedData: PropTypes.func.isRequired,
  editStaticText: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool,
  handleRemoveTokenType: PropTypes.func,
}

export default TokenElement
