import React from 'react'
import { useStore } from 'store'
import { isSalesUserType } from 'components/navigation'

import DashboardPage from './dashboard'
import LoanApplicationsPage from './loan-applications'

const DefaultPage = () => {
  const { state } = useStore()

  if (isSalesUserType(state.session.userData)) {
    return <DashboardPage />
  }

  return <LoanApplicationsPage />
}

export default DefaultPage
