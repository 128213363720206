import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import WageEarnerCalculator from './wage-earner-calculator'
import SelfEmployedCalculator from './self-employed-calculator'
import { WAGE_CALCULATOR_STATE } from './wage-earner-calculator/utils'
import { SELF_EMPLOYED_STATE } from './self-employed-calculator/constants'
import { styles } from './style'

const { EniumTabList, Dialog } = components

const IncomeCalculator = ({ isOpen, onClose }) => {
  const [wageEarnerState, setWageEarnerState] = useState({
    ...WAGE_CALCULATOR_STATE,
  })
  const [selfEmployedState, setSelfEmployedState] = useState({
    ...SELF_EMPLOYED_STATE,
  })
  const [tab, setTab] = useState(CALCULATOR_TABS.WAGE_EARNER)

  const onReset = () => {
    setWageEarnerState({ ...WAGE_CALCULATOR_STATE })
    setSelfEmployedState({ ...SELF_EMPLOYED_STATE })
  }

  if (!isOpen) {
    return null
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={styles.paperProps}
      titleProps={styles.titleStyle}
      title={
        <div style={styles.header}>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <EniumTabList
              selectedTab={tab}
              setSelectedTab={setTab}
              items={[
                {
                  label: 'Wage Earner',
                  value: CALCULATOR_TABS.WAGE_EARNER,
                },
                {
                  label: 'Self Employed',
                  value: CALCULATOR_TABS.SELF_EMPLOYED,
                },
              ]}
              listProps={{}}
            />
          </div>

          <Button
            onClick={onReset}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            style={{ marginLeft: 'auto' }}
          >
            Reset Workbook
          </Button>
        </div>
      }
    >
      {tab === CALCULATOR_TABS.WAGE_EARNER ? (
        <WageEarnerCalculator
          state={wageEarnerState}
          setState={setWageEarnerState}
        />
      ) : (
        <SelfEmployedCalculator
          state={selfEmployedState}
          setState={setSelfEmployedState}
        />
      )}
    </Dialog>
  )
}

const CALCULATOR_TABS = {
  WAGE_EARNER: 'WAGE_EARNER',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
}

IncomeCalculator.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default IncomeCalculator
