import { theme, colors } from '@ElementsCapitalGroup/enium-ui'
const {
  lightTheme: { typography },
} = theme

const chipStyle = {
  fontWeight: typography.fontWeightBold,
  marginRight: '12px',
  marginTop: { xs: '10px', sm: 0 },
}

const extraChipsDropdown = {
  position: 'absolute',
  top: '32px',
  right: '0',
  border: `1px solid ${colors.grey[200]}`,
  borderRadius: '16px',
  padding: '8px',
  background: 'white',
  zIndex: 2,
}

const extraChipsWrapper = {
  position: 'relative',
}

const chipAddMore = {
  ...chipStyle,
  cursor: 'pointer',
  marginRight: 0,
  marginTop: '-4px',
}

const iconsWrapper = { display: 'flex', alignItems: 'center', flexWrap: 'wrap' }

export const styles = {
  chipStyle,
  iconsWrapper,
  extraChipsDropdown,
  extraChipsWrapper,
  chipAddMore,
}
