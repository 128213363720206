/**
 * Checks the type of al element
 * @param {*} element
 * @return {string}
 *
 *  checkType([]) === 'array'; // true
 *  checkType({}) === 'object'; // true
 *  checkType(1) === 'number'; // true
 *  checkType('') === 'string'; // true
 *  checkType(null) === 'null'; // true
 */
import { saveAs } from 'file-saver'
import {
  STATE_ID_TO_NAME,
  STEP_URLS,
  STEPS_MAP_REVERSED,
  STEP_URLS_COMMERCIAL,
  STEPS_MAP_REVERSED_COMMERCIAL,
  STATE_ID_TO_NAME_COMMERCIAL,
} from 'modules/loan-application/constants'
import { showNotification } from '../modules/global/actions'
import { NOTIFICATION_TYPES } from '../modules/global/notifications'
import { getStatesToSteps } from '../modules/loan-application/states'
import { logout } from '../modules/session/actions'

export const checkType = (element) =>
  Object.prototype.toString
    .call(element)
    .replace(/\[|object\s|]/g, '')
    .toLowerCase()

/** Compare 2 strings ignoring the case and checking for null value */
export const compareIgnoreCase = (a, b, desc) => {
  if (a === '' && b !== '') {
    return desc ? -1 : 1
  }
  if (a !== '' && b === '') {
    return desc ? 1 : -1
  }
  if (a === '' && b === '') {
    return 0
  }
  const value1 = a ? a.toString().toLowerCase() : null
  const value2 = b ? b.toString().toLowerCase() : null

  if (value1 === null) {
    return 1
  }
  if (value2 === null) {
    return -1
  }
  return value1 <= value2 ? -1 : 1
}

/** Get base 64 of a file (used when uploading) */
export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = ''
    // Make new FileReader
    const reader = new FileReader()

    // Convert the file to base64 text
    reader.readAsDataURL(file)

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result.split(',')[1]
      resolve(baseURL)
    }
  })
}

/** Generic helper to download base 64 content retrieved from the server */
export const downloadFileBase64 = ({ name, content: base64URL }) => {
  const binary = atob(base64URL.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)

  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i)
  }

  const blob = new Blob([view])
  saveAs(blob, name)
}

/** Reverse object keys with their values */
export function reverseObject(obj) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ((acc[value] = key), acc), // eslint-disable-line
    {}
  )
}

export const formatAddress = (address) =>
  `${address?.addressFirstLine} ${address?.city} ${address?.state} ${
    address?.county || ''
  } ${address?.zipCode}`

/**
 * Copy some rich text to the clipboard
 * @param {String} htmlText HTML rich-text wrapped as a string
 * @param {Function} dispatch used to show UI notifications
 */
export function copyToClipboard(htmlText, dispatch) {
  // Create a DIV and append the text in it
  const el = document.createElement('div')
  el.style.position = 'fixed'
  el.style.opacity = '0'
  el.innerHTML = htmlText
  document.body.appendChild(el)

  // Select contents of the div
  const sel = window.getSelection()
  const range = document.createRange()
  range.selectNodeContents(el) // sets Range
  sel.removeAllRanges() // remove all ranges from selection
  sel.addRange(range)

  try {
    document.execCommand('copy')
    showNotification(dispatch, {
      type: NOTIFICATION_TYPES.POSITIVE,
      title: 'Copied to clipboard!',
    })
  } catch (ex) {
    // Security exception may be thrown by some browsers.
    prompt('Copy to clipboard: Ctrl+C, Enter', htmlText)
  } finally {
    document.body.removeChild(el)
  }
}

/**
 * Construct Loan Application URL from the current State
 * @return {string}
 */
export function getLoanApplicationUrl(
  stateId,
  loanApplicationId,
  canViewLoans,
  isCommercialApp,
  isPtoNotMandatory
) {
  let url = '#'
  if (canViewLoans) {
    const step = getLoanApplicationLastAvailableStep(
      stateId,
      isCommercialApp,
      isPtoNotMandatory
    )
    url = `/loan-application/${loanApplicationId}/${step.path}`
  }

  return url
}

/** Get the last available step of a loan application */
export function getLoanApplicationLastAvailableStep(
  stateId,
  isCommercialApp,
  isPtoNotMandatory
) {
  const stepUrls = isCommercialApp ? STEP_URLS_COMMERCIAL : STEP_URLS
  const stateIdToName = isCommercialApp
    ? STATE_ID_TO_NAME_COMMERCIAL
    : STATE_ID_TO_NAME
  const stepsReversed = isCommercialApp
    ? STEPS_MAP_REVERSED_COMMERCIAL
    : STEPS_MAP_REVERSED
  const statesToSteps = getStatesToSteps({ isCommercialApp, isPtoNotMandatory })

  const state = stateIdToName[stateId]
  return (
    stepUrls[stepsReversed[statesToSteps[state]]] ||
    stepUrls.NEW_LOAN_APPLICATION
  )
}

/**
 * Formats a phone number to a US format
 * @return {string}
 */
export const formatPhoneNumber = (value) => {
  const cleaned = `${value}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return value
}

/*
 * Converts a word to title case
 * @param {String} str
 * @return {string}
 */
export const convertWordToTitleCase = (str) => {
  if (!str) {
    return ''
  }

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

/** Format SSN in the format xxx-xx-xxxx */
export const formatSSN = (ssn) => {
  if (ssn && ssn.length === 9) {
    return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(
      5,
      9
    )}`
  }
  return ssn
}

export const scrollToClosestError = (scrollTarget) => {
  setTimeout(() => {
    if (scrollTarget) {
      scrollTarget
        .querySelector('.error')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, 10)
}

export const scrollToTop = (scrollTarget) => {
  const target = scrollTarget || document.getElementsByTagName('main')[0]
  target?.scrollIntoView()
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Attempt to force a logout & redirect to the login page
 * @param {Function} [dispatch]
 */
export const forceLogout = (dispatch) => {
  if (dispatch) {
    logout(dispatch, true)
  } else {
    setTimeout(() => {
      window.location = '/login'
    }, 100)
  }
}

export function isLevelPayment(loan = {}) {
  return (
    loan.promoType === 'Level Payment' ||
    loan.promoType === 'Level Payments' ||
    loan.promotionalType?.friendlyName === 'Level Payment' ||
    loan.promotionalType?.friendlyName === 'Level Payments'
  )
}

export function isMinimumPayment(loan = {}) {
  return loan.promotionalType?.name === 'MinimumPayment'
}

export function isSpecialCaseBank(lenderId) {
  return (
    lenderId === process.env.REACT_APP_ENIUM_CAPITAL_PR ||
    lenderId === process.env.REACT_APP_ORIENTAL_BANK ||
    lenderId === process.env.REACT_APP_USVI
  )
}
