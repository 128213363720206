import React from 'react'
import { useStore } from 'store'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import useOutageMessages from './index'
import { dismissMessage } from './actions'

import './style.scss'

const { Dialog } = components
const OutageMessagesAlert = () => {
  const { alert } = useOutageMessages()
  const { dispatch, state } = useStore()

  return alert ? (
    <Dialog
      title={alert.title}
      open={true}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '350px',
        },
      }}
      onClose={
        alert.dismissible ||
        hasAccess(state.session?.userData, [CLAIMS.CAN_DO_EVERYTHING])
          ? () =>
              dismissMessage(dispatch, alert.id, state.session.userData.email)
          : null
      }
      isOpen={true}
    >
      <div>{alert.text}</div>
    </Dialog>
  ) : null
}

export default OutageMessagesAlert
