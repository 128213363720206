import { SCHEMA_TYPES, ACTION_TYPES } from 'components/form-builder/constants'
import { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from 'components/button'

const COUNTIES = [
  'loanApplication.step3.membership.firstLight.countyOptions.elPaso',
  'loanApplication.step3.membership.firstLight.countyOptions.dona',
]

const QUALIFICATION_OPTIONS = [
  'loanApplication.step3.membership.firstLight.qualificationOptions.live',
  'loanApplication.step3.membership.firstLight.qualificationOptions.work',
  'loanApplication.step3.membership.firstLight.qualificationOptions.worship',
  'loanApplication.step3.membership.firstLight.qualificationOptions.attendSchool',
  'loanApplication.step3.membership.firstLight.qualificationOptions.volunteer',
  'loanApplication.step3.membership.firstLight.qualificationOptions.familyMember',
]

export default {
  titleKey: 'loanApplication.step3.membership.firstLight.title',
  fields: {
    qualification: '',
    county: '',
  },
  sections: [
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'scrollable padding-24',
      sections: [
        {
          type: SCHEMA_TYPES.PARAGRAPH,
          textKey: 'loanApplication.step3.membership.firstLight.body',
        },
      ],
    },
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'scrollable form-row padding-24',
      sections: [
        {
          type: SCHEMA_TYPES.DROPDOWN,
          field: 'qualification',
          extraClass: 'margin-top-12',
          labelKey: 'loanApplication.step3.membership.firstLight.qualification',
          options: QUALIFICATION_OPTIONS,
        },
        {
          type: SCHEMA_TYPES.DROPDOWN,
          options: COUNTIES,
          extraClass: 'margin-top-12',
          field: 'county',
          labelKey: 'loanApplication.step3.membership.firstLight.county',
        },
      ],
    },
    {
      type: SCHEMA_TYPES.WRAPPER,
      extraClass: 'sticky',
      sections: [
        {
          type: SCHEMA_TYPES.BUTTONS,
          buttons: [
            {
              action: ACTION_TYPES.SUBMIT,
              size: BUTTON_SIZES.SMALL,
              labelKey: 'loanApplication.step3.membership.agreed',
              disabled: (state) => !state.qualification || !state.county,
            },
            {
              action: ACTION_TYPES.DECLINE,
              labelKey: 'buttons.decline',
              className: 'margin-left-10',
              size: BUTTON_SIZES.SMALL,
              color: BUTTON_COLORS.ERROR,
              variant: BUTTON_VARIANTS.OUTLINED,
              textColor: '#B42318',
            },
          ],
        },
      ],
    },
  ],
}
