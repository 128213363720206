import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button/index'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'

import BorrowersModal from './edit-loan-modals/borrowers'
import SalesRepsModal from './edit-loan-modals/sales-reps'
import LoanSummaryModal from './edit-loan-modals/summary'

const { Dialog } = components

const LoanSummary = ({
  isCommercialApp,
  borrowerDetails,
  coBorrowerDetails,
  serviceAddress,
  userData,
  loanData,
  setIsSummaryModalOpen,
  areInputsDisabled,
  isSummaryModalOpen,
  achStatus,
  dispatch,
  currentState,
}) => {
  const [isBorrowersModalOpen, setIsBorrowersModalOpen] = useState(false)
  const [isSalesRepsModalOpen, setIsSalesRepsModalOpen] = useState(false)
  const [isLoanSummaryModalOpen, setIsLoanSummaryModalOpen] = useState(false)

  return (
    <>
      <Dialog
        open={isSummaryModalOpen && !isBorrowersModalOpen}
        onClose={() => setIsSummaryModalOpen(false)}
        title="Edit Loan"
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            sx={{ mb: 1, width: '320px', color: 'black' }}
            onClick={() => setIsBorrowersModalOpen(true)}
          >
            Adjust Borrower Details
          </Button>
          {hasAccess(
            userData,
            CLAIMS.CAN_EDIT_LOAN_APPLICATION_SALES_REPRESENTATIVE
          ) && (
            <Button
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
              onClick={() => setIsSalesRepsModalOpen(true)}
              sx={{ mb: 1, width: '320px', color: 'black', fontWeight: '600' }}
            >
              Adjust Sales Rep Details
            </Button>
          )}
          <Button
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            onClick={() => setIsLoanSummaryModalOpen(true)}
            sx={{ mb: 1, width: '320px', color: 'black' }}
          >
            Edit General Loan Information
          </Button>
        </div>
      </Dialog>
      {isBorrowersModalOpen && (
        <BorrowersModal
          isOpen={isBorrowersModalOpen}
          borrowerDetails={borrowerDetails}
          coBorrowerDetails={coBorrowerDetails}
          serviceAddress={serviceAddress}
          userData={userData}
          loanData={loanData}
          dispatch={dispatch}
          setIsModalOpen={setIsBorrowersModalOpen}
          areInputsDisabled={areInputsDisabled}
          isCommercialApp={isCommercialApp}
          achStatus={achStatus}
          currentState={currentState}
        />
      )}
      {isSalesRepsModalOpen && (
        <SalesRepsModal
          userData={userData}
          loanData={loanData}
          dispatch={dispatch}
          setIsModalOpen={setIsSalesRepsModalOpen}
          areInputsDisabled={areInputsDisabled}
          isModalOpen={isSalesRepsModalOpen}
        />
      )}
      {isLoanSummaryModalOpen && (
        <LoanSummaryModal
          loanData={loanData}
          setIsModalOpen={setIsLoanSummaryModalOpen}
          achStatus={achStatus}
          dispatch={dispatch}
        />
      )}
    </>
  )
}

LoanSummary.propTypes = {
  isCommercialApp: PropTypes.bool,
  borrowerDetails: PropTypes.object,
  coBorrowerDetails: PropTypes.object,
  serviceAddress: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  loanData: PropTypes.object.isRequired,
  setIsSummaryModalOpen: PropTypes.func.isRequired,
  areInputsDisabled: PropTypes.bool,
  isSummaryModalOpen: PropTypes.bool,
  achStatus: PropTypes.number,
  currentState: PropTypes.object.isRequired,
}

export default LoanSummary
