import { colors } from '@ElementsCapitalGroup/enium-ui'

const linksWrapper = {
  display: 'grid',
  gridRowGap: '8px',
  fontWeight: 600,
  color: colors.blue[700],
  marginLeft: '6px',
  height: '80px',
  fontSize: '0.875rem',
}

const checkboxesWrapper = {
  marginTop: '10px',
  marginLeft: '2px',
}

export const styles = {
  linksWrapper,
  checkboxesWrapper,
}
