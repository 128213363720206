import { useEffect } from 'react'
import { useStore } from 'store'
import {
  loadFirebaseData,
  listenForConfigChanges,
  updateConfig,
  isMessageLocallyDismissed,
} from './actions'

import './style.scss'

const useOutageMessages = () => {
  const {
    dispatch,
    state: {
      session: { userData },
      outageMessages: { loading, list, userAssociations, config },
    },
  } = useStore()
  const { displayedAlertId: alertId, displayedBannerId: bannerId } = config

  useEffect(() => {
    loadFirebaseData(dispatch)
  }, [])

  // Subscribe to config changes in firebase
  useEffect(() => {
    const unsubscribe = listenForConfigChanges((doc) => {
      const updatedConfig = doc.data()
      if (
        !loading &&
        updatedConfig &&
        (updatedConfig.displayedBannerId !== bannerId ||
          updatedConfig.displayedAlertId !== alertId)
      ) {
        updateConfig(dispatch, updatedConfig)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [alertId, bannerId, loading])

  // Ensures the message to be displayed (alert/banner) was not already dismissed by the current user
  const isMessageDismissed = (msgId) => {
    return (
      isMessageLocallyDismissed(msgId) ||
      userAssociations.some(
        (userMessage) =>
          userMessage.messageId === msgId &&
          userMessage.email === userData.email &&
          userMessage.dismissed
      )
    )
  }

  if (loading) {
    return { alert: null, banner: null }
  }

  return {
    alert: list.find((el) => el.id === alertId && !isMessageDismissed(el.id)),
    banner: list.find((el) => el.id === bannerId && !isMessageDismissed(el.id)),
  }
}

export default useOutageMessages
