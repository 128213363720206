import { colors } from '@ElementsCapitalGroup/enium-ui'

export const styles = {
  checkboxProps: {
    marginTop: '16px',
    marginLeft: 0,
    padding: '7px',
    border: `2px solid ${colors.blue[600]}`,
    borderRadius: '12px',
    backgroundColor: colors.blue[50],
    width: '100%',
  },
  checkboxLabelProps: { color: colors.blue[800] },
  modalProps: {
    sx: {
      maxWidth: '100%',
      width: '600px',
    },
  },
  actionItem: {
    width: '100%',
    borderBottom: `1px solid ${colors.grey[200]}`,
    padding: '10px 16px',
    fontSize: '0.875rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actionCardProps: {
    position: 'absolute',
    top: '40px',
    right: '5px',
    zIndex: '10',
  },
  sectionHeader: {
    color: colors.grey[900],
    fontWeight: 500,
    marginTop: '20px',
  },
}
