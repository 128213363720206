import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'components/validator'
import cx from 'classnames'
import { assets } from '@ElementsCapitalGroup/enium-ui'
import Button from 'components/button'
import TextField from 'components/input'
import { useClickOutside, useEffectOnUpdate } from 'common/hooks'

import './style.scss'

const { XCloseIcon } = assets

SearchBar.propTypes = {
  searchData: PropTypes.arrayOf(
    PropTypes.shape({
      id: (props) => {
        if (isEmpty(props.id) && isEmpty(props.guid)) {
          return new Error('Either "id" or "guid" are required')
        }
      },
      guid: (props) => {
        if (isEmpty(props.id) && isEmpty(props.guid)) {
          return new Error('Either "id" or "guid" are required')
        }
      },
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  containerClass: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  onSearchInputChange: PropTypes.func,
  onSearch: PropTypes.func,
  showResultsList: PropTypes.bool,
  withBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  manualSearchInputValue: PropTypes.string,
}

function SearchBar({
  searchData = [],
  onChange,
  initialValue,
  containerClass,
  placeholder,
  multiple = false,
  onSearchInputChange,
  onSearch,
  showResultsList = true,
  withBorder = true,
  disabled,
  error,
  className,
  label,
  fullWidth,
  manualSearchInputValue,
  ...rest
}) {
  const [possibleOptions, setPossibleOptions] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [selected, setSelected] = useState([])
  const idFieldRef = useRef('guid')
  const idField = idFieldRef.current

  const { t: translate } = useTranslation()

  /** On searchData change -> update */
  useEffect(() => {
    // Set the id field
    if (searchData.length) {
      idFieldRef.current = !isEmpty(searchData[0].guid) ? 'guid' : 'id'
    }
    // Set the possible values
    if (!initialValue || !Array.isArray(initialValue)) {
      setPossibleOptions([...searchData])
    } else {
      setPossibleOptions(
        searchData.filter(
          (el) =>
            !initialValue.find(
              (existing) =>
                existing[idFieldRef.current] === el[idFieldRef.current]
            )
        )
      )
    }
  }, [JSON.stringify(searchData)])

  useEffectOnUpdate(() => {
    debouncedSetManualInput(manualSearchInputValue)
  }, [manualSearchInputValue])

  const debouncedSetManualInput = useCallback(
    debounce((manualSearchInputValue) => {
      if (typeof manualSearchInputValue === 'string') {
        setSearchInput(manualSearchInputValue)
      }
    }, 100),
    []
  )

  /** When initialValue prop changes, update the component */
  useEffect(() => {
    if (initialValue === undefined) {
      return
    }

    if (!multiple) {
      // For single select just set the input value
      let _initialValue = searchData.find(
        (el) => el.id === initialValue || el.guid === initialValue
      )
      _initialValue = _initialValue
        ? _initialValue.name || _initialValue.value
        : null
      if (_initialValue && _initialValue !== searchInput) {
        setSearchInput(_initialValue)
      }
    } else {
      // For multiple select mark the selection
      if (!selected?.length) {
        setSelected(initialValue)
      }
      // Remove all the values from the possibleValues array

      const filteredOptions = searchData.filter(
        (el) =>
          !initialValue?.find((option) => option[idField] === el[idField]) &&
          !selected?.find((s) => s[idField] === el[idField])
      )

      setPossibleOptions(filteredOptions)
    }
  }, [JSON.stringify(initialValue), searchInput, searchData])

  /** Click outside handler to close the expanded options */
  const ref = useRef()
  const onClickOutside = () => setShowResults(false)
  useClickOutside(ref, onClickOutside)

  /** Trigger external change on search input change if someone needs to listen to it */
  useEffectOnUpdate(() => {
    onSearchInputChange && onSearchInputChange(searchInput)
  }, [searchInput])

  /** Triggered on input change */
  const handleInputChanged = (newInput) => {
    if (!newInput) {
      // if clear input trigger on change
      onChange && onChange(null)
    }

    setSearchInput(newInput)
    if (newInput && newInput.trim()) {
      showResults && setShowResults(true)
    }
  }

  /** Triggered on option selected -> sets selected item(s) and triggers external onChange handler */
  const onOptionSelected = (option) => {
    // For single just trigger onChange
    if (!multiple) {
      onChange(option)
    } else {
      if (!selected) {
        setSelected([option])
      } else {
        // For multiple update selected array
        const selectedCopy = selected.concat([option])
        setSelected(selectedCopy)
        onChange(selectedCopy)
      }

      // and remove the element from the possibleValues
      const optionsCopy = [...possibleOptions]
      const idx = optionsCopy.findIndex((el) => el[idField] === option[idField])
      optionsCopy.splice(idx, 1)
      setPossibleOptions(optionsCopy)
      setSearchInput('')
    }
  }

  /** Triggered on option removed -> removes it from selected items and triggers external onChange handler */
  const onOptionRemoved = (e, option) => {
    e.stopPropagation()
    // Remove the element from the selected array
    const selectedCopy = [...selected]
    const idx = selectedCopy.findIndex((el) => el[idField] === option[idField])
    selectedCopy.splice(idx, 1)
    setSelected(selectedCopy)
    onChange(selectedCopy)

    // Add the element to the possibleValues
    setPossibleOptions(possibleOptions.concat(option))
  }

  const handleClearAll = (e) => {
    e.stopPropagation()

    setSelected([])
    onChange && onChange([])
  }

  const filteredOptions = searchInput
    ? possibleOptions.filter((option) => {
        const fieldToFilter = option.name || option.value
        return fieldToFilter?.toLowerCase().includes(searchInput.toLowerCase())
      })
    : [...possibleOptions]

  const _renderOptionText = (option) => {
    const fieldText = option?.name || option?.value
    if (!fieldText) {
      return null
    }
    if (!searchInput) {
      return fieldText
    }

    const startIndex = fieldText
      .toLowerCase()
      .indexOf(searchInput.toLowerCase())
    const endIndex = searchInput.length + startIndex

    let text = `${fieldText.substring(0, startIndex)}`
    text += `<strong>${fieldText.substring(startIndex, endIndex)}</strong>`
    text += `${fieldText.substring(endIndex, fieldText.length)}`

    return text
  }

  return (
    <div
      ref={ref}
      className={cx(containerClass, className, 'search-bar', {
        'search-bar--disabled': disabled,
      })}
      {...rest}
    >
      <TextField
        label={label}
        value={searchInput}
        onEnterKey={onSearch}
        onChange={handleInputChanged}
        disabled={disabled}
        fullWidth
        placeholder={placeholder}
        onFocus={() => !disabled && setShowResults(true)}
        validate={() => error}
      />

      {showResultsList && showResults && filteredOptions.length ? (
        <div className="searchbar-options">
          {filteredOptions.map((option) => (
            <div
              key={option[idField]}
              role="option"
              aria-selected="true"
              tabIndex={0}
              onClick={(ev) => {
                ev.stopPropagation()
                if (!multiple) {
                  if (typeof manualSearchInputValue === 'undefined') {
                    setSearchInput(option.name || option.value)
                  }
                  setShowResults(false)
                }
                onOptionSelected(option)
              }}
              className="searchbar-options__item"
            >
              <div
                dangerouslySetInnerHTML={{ __html: _renderOptionText(option) }}
              ></div>
            </div>
          ))}
        </div>
      ) : null}

      {multiple && selected?.length ? (
        <div className="search-bar__selected">
          {selected.map((option) => (
            <div
              key={option[idField]}
              className="search-bar__selected-wrapper"
              onClick={(e) => onOptionRemoved(e, option)}
            >
              <span className="search-bar__selected-item">
                {option.name || option.value}
              </span>
              <span className="search-bar__selected-remove">
                <XCloseIcon
                  sx={{
                    height: '14px',
                  }}
                />
              </span>
            </div>
          ))}

          <Button
            onClick={handleClearAll}
            variant="text"
            className="search-bar__clear"
          >
            {translate('global.clearAll')}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default SearchBar
