import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Table from 'components/table'
import { ExpandableCard } from 'components/expandable-card'
import { APPLICANT_TYPE, TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import { colors, unstable_components } from '@ElementsCapitalGroup/enium-ui'
import { useTranslation } from 'react-i18next'
import { useStore } from 'store'
import UwDeclineTooltip from '../uw-decline-tooltip'
import UWApprovalStatus from '../uw-approval-status'
import { expandableCard } from '../styles'
import { renderCell } from '../render-cell'
import { APPLICANT_PROPERTY_IDS } from '../../constants'
import MobileContent from './mobile-content'

const { Unstable_ToggleButtonGroup: ToggleButtonGroup } = unstable_components

/**
 * Generic component for displaying applicant details
 * Renders a Table on desktop and an ExpandableCard on mobile
 * Currently used by General and Employment sections
 * @return {Node}
 */
const ApplicantDetailsSection = ({
  titleKey,
  loanApplicationId,
  data,
  columns,
  updateApprovalStatusType,
  loading,
  hasAttachments = false,
}) => {
  const { dispatch } = useStore()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [selectedSection, setSelectedSection] = useState(
    APPLICANT_TYPE.BORROWER.toString()
  )

  const { t: translate } = useTranslation()
  const translatedColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      header: translate(`uwHub.general.${column.accessorKey}`),
    }))
  }, [loading])

  const tableData = useMemo(() => {
    const applicantData = data.applicantData.find(
      (el) => el.applicantTypeId.toString() === selectedSection
    )
    const row = {
      attachmentsIds: applicantData.attachmentsIds,
    }
    Object.entries(APPLICANT_PROPERTY_IDS).forEach(
      ([propertyId, propertyName]) => {
        const sourceValue = applicantData?.properties.find(
          (item) => item.propertyTypeId.toString() === propertyId
        )
        if (sourceValue) {
          row[propertyName] = {
            value: sourceValue.stringValue || '-',
            validationReason: sourceValue.validationReason,
            dataValidationId: sourceValue.dataValidationId,
          }
        }
      }
    )
    return [row]
  }, [data, selectedSection])

  const hasActionsColumn =
    tableData[0]?.attachmentsIds?.length > 0 || hasAttachments
  const columnsWithoutAttachments = translatedColumns.slice(0, 4)

  const updateApprovalStatus = (approvalStatusId, reason) => {
    dispatch({
      type: updateApprovalStatusType,
      payload: {
        ...data,
        uwApprovalStatusId: approvalStatusId,
        uwDeclineReason: reason,
      },
    })
  }

  const _renderToggle = () =>
    data.applicantData?.length > 1 ? (
      <ToggleButtonGroup
        key="toggle"
        style={isMobileView ? { margin: '12px 24px' } : { marginRight: '16px' }}
        active={APPLICANT_TYPE.BORROWER.toString()}
        buttons={[
          {
            id: APPLICANT_TYPE.BORROWER.toString(),
            children: 'Borrower',
            variant: 'text',
            color: colors.grey[700],
            activeColor: 'white',
          },
          {
            id: APPLICANT_TYPE.COBORROWER.toString(),
            children: 'Co-Borrower',
            variant: 'text',
            color: colors.grey[700],
            activeColor: 'white',
          },
        ]}
        onChange={setSelectedSection}
      />
    ) : null

  const _renderTitle = () => (
    <div className="align-center">
      {translate(titleKey)}
      <UwDeclineTooltip data={data} />
    </div>
  )

  const _renderApprovalStatus = (renderApprovalStatusForMobile = false) => (
    <UWApprovalStatus
      loanApplicationId={loanApplicationId}
      portalApprovalStatusId={data.portalApprovalStatusId}
      uwApprovalStatusId={data.uwApprovalStatusId}
      sectionTypeId={data.sectionTypeId}
      updateSectionApprovalStatus={updateApprovalStatus}
      displayPortalApprovalStatus={!renderApprovalStatusForMobile}
      displayUwApprovalStatus={renderApprovalStatusForMobile || !isMobileView}
      approvalStyles={
        renderApprovalStatusForMobile ? { margin: '12px 16px 16px' } : {}
      }
    />
  )

  const collapsibleItems = [
    _renderApprovalStatus(true),
    _renderToggle(),
    <MobileContent
      key="data-review-mobile"
      columns={columnsWithoutAttachments}
      data={tableData}
      renderCell={renderCell}
    />,
  ].filter((el) => !!el)

  return (
    <div style={{ marginBottom: '32px' }}>
      {!isMobileView ? (
        <Table
          style={{ overflow: 'visible' }}
          title={_renderTitle()}
          columns={
            hasActionsColumn ? translatedColumns : columnsWithoutAttachments
          }
          data={tableData}
          toolbarRightContent={
            <>
              {_renderToggle()}
              {_renderApprovalStatus()}
            </>
          }
        />
      ) : (
        <ExpandableCard
          headerProps={expandableCard.headerProps(isMobileView)}
          titleTypographyProps={expandableCard.titleProps}
          title={
            <div className="uw-hub-page__income-card">
              {_renderTitle()}
              {_renderApprovalStatus()}
            </div>
          }
          isCollapsable={true}
          alwaysCollapsible={true}
          collapsibleItems={[
            <div style={{ width: '100%' }} key={0}>
              {collapsibleItems}
            </div>,
          ]}
          collapsibleItemsStyles={{
            sx: {
              padding: 0,
            },
          }}
        />
      )}
    </div>
  )
}

ApplicantDetailsSection.propTypes = {
  titleKey: PropTypes.string.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  updateApprovalStatusType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  hasAttachments: PropTypes.bool,
}

export default ApplicantDetailsSection
