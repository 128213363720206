import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { components, assets, hooks } from '@ElementsCapitalGroup/enium-ui'
import StatusComponent from 'components/status'
import Table from 'components/table'
import { isEmpty } from 'components/validator'
import { formatPhoneNumber, copyToClipboard } from 'common/utils'
import EmptyList from 'components/empty-list'
import SearchAndFilterModule from 'components/search-filter-module'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { useMediaQuery } from 'common/hooks'
import { ROLE_TYPES } from 'modules/admin/constants'
import { ORGANIZATION_TYPE_IDS, TABLET_BREAKPOINT } from 'common/constants'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import { sendResetPasswordEmail } from 'modules/organizations/actions'

import {
  USERS_PAGE_SIZE,
  USER_STATUS,
  USER_STATUS_IDS,
  USER_STATUS_ID_MAPPING,
} from 'modules/organizations/constants'
import { useStore } from 'store'

import {
  getUsersForOrganization,
  deleteUser,
  fetchUsers,
  resendEmailInvitation,
  fetchUsersByOrganization,
} from './actions'

import { styles } from './style.js'

const { createColumnHelper, Avatar, IconButton, Tooltip, Dialog } = components
const { PlusIcon, ClipboardCheckIcon, MailIcon, RefreshSingleArrowIcon } =
  assets
const { useBoolean } = hooks
const columnHelper = createColumnHelper()
const isBeta = window.location.pathname.indexOf('-beta') > -1

const Users = ({ orgId, orgData }) => {
  const navigate = useNavigate()
  const [users, setUsers] = useState({
    list: [],
    itemCount: 0,
  })
  const { state, dispatch } = useStore()
  const { userData } = state.session
  const [filters, setFilters] = useState({})

  const [loading, setLoading] = useState(false)
  const [
    isResetPasswordModalOn,
    { off: setResetPasswordModalOff, on: setResetPasswordModalOn },
  ] = useBoolean()
  const [
    isSendInvitationEmailModalOn,
    { off: setSendInvitationEmailModalOff, on: setSendInvitationEmailModalOn },
  ] = useBoolean()

  const { list } = users
  const isSearch = filters.searchBy?.length > 0
  const tableRef = useRef(null)
  const searchFilterRef = useRef(null)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const canViewSalesUsers = hasAccess(userData, CLAIMS.CAN_VIEW_SALES_USERS)
  const canViewLendingUsers = hasAccess(userData, CLAIMS.CAN_VIEW_LENDING_USERS)
  const canDoEverything = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)

  const { userRoles: roles, allOrganizations } = state.orgManagement

  const [selectedRows, setSelectedRows] = useState(false)

  const resetPage = () => {
    tableRef.current?.setPageIndex?.(0)
  }

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'User',
        size: 200,
        cell: ({ row }) => {
          const { fullName, emailAddress } = row.original
          return (
            <div style={styles.users.orgDetailsUser}>
              <Avatar>{fullName}</Avatar>
              <Tooltip title={emailAddress}>
                <div style={styles.users.orgDetailsUserDetails}>
                  <div style={styles.users.orgDetailsUserName}>{fullName}</div>
                  <div>{emailAddress}</div>
                </div>
              </Tooltip>
            </div>
          )
        },
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        size: 100,
        cell: ({ row }) => {
          const status = USER_STATUS_IDS[row.original.statusId]
          return (
            <>
              <StatusComponent
                status={USER_STATUS_ID_MAPPING[status]}
                label={status}
              />
            </>
          )
        },
      }),
      columnHelper.accessor('role', {
        header: 'Roles',
        size: 200,
        cell: ({ row }) => {
          const associations = row.original.associations || []
          const userRoles = associations
            .reduce((acc, el) => {
              if (!acc.includes(el.roleName)) {
                acc.push(el.roleName)
              }
              return acc
            }, [])
            .filter((el) => !!el)
          return (
            <Tooltip title={userRoles.join(', ')}>
              <div style={styles.users.ellipsisWrapper}>
                {userRoles.join(', ')}
              </div>
            </Tooltip>
          )
        },
      }),
      columnHelper.accessor('organization', {
        header: 'Organization',
        size: 200,
        cell: ({ row }) => {
          const associations = row.original.associations || []

          const userAssociations = associations
            .reduce((acc, el) => {
              if (!acc.includes(el.organizationName)) {
                acc.push(el.organizationName)
              }
              return acc
            }, [])
            .filter((el) => !!el)
          return (
            <Tooltip title={userAssociations.join(', ')}>
              <div style={styles.users.ellipsisWrapper}>
                {userAssociations.join(', ')}
              </div>
            </Tooltip>
          )
        },
      }),
      columnHelper.accessor('phoneNumber', {
        header: 'Phone Number',
        cell: ({ row }) => formatPhoneNumber(row.original.phoneNumber),
      }),
    ]
  }, [roles, allOrganizations])

  const getData = (
    pageIndex = 0,
    pageSize = USERS_PAGE_SIZE,
    filters = {},
    filterSearch
  ) => {
    setLoading(true)
    if (orgId) {
      handleGetDataForOrg(pageIndex, pageSize, filters, filterSearch)
    } else {
      handleGetUsers(pageIndex, pageSize, filters, filterSearch)
    }
  }

  const handleGetDataForOrg = (
    pageIndex = 0,
    pageSize = USERS_PAGE_SIZE,
    filters = {},
    filterSearch
  ) => {
    getUsersForOrganization({
      organizationId: orgId,
      organizationTypeId: orgData.type.id,
      itemsPerPage: pageSize,
      pageNumber: pageIndex,
      filters,
    })
      .then((res) => {
        setUsers({ list: res.users, itemCount: res.itemCount })
      })
      .finally(() => {
        setFilters(filters)
        setLoading(false)
        if (filterSearch) {
          tableRef.current?.setPageIndex(0)
        }
      })
  }

  const handleGetUsers = (
    pageIndex = 0,
    pageSize = USERS_PAGE_SIZE,
    filters = {},
    filterSearch
  ) => {
    const fetchUsersPromise = hasAccess(state.session?.userData, [
      CLAIMS.CAN_VIEW_USERS_FROM_CURRENT_AND_SUB_ORGANIZATIONS,
    ])
      ? fetchUsersByOrganization
      : fetchUsers

    fetchUsersPromise({
      itemsPerPage: pageSize,
      pageNumber: pageIndex,
      filters,
    })
      .then((res) => {
        setUsers({
          list: res?.users,
          itemCount: res.itemCount,
        })
      })
      .finally(() => {
        setFilters(filters)
        setLoading(false)
        if (filterSearch) {
          tableRef.current?.setPageIndex(0)
        }
      })
  }

  // /** Initial data fetch */
  useEffect(() => {
    if (orgId) {
      !isEmpty(orgData) && getData()
    } else {
      getData()
    }
  }, [orgId, orgData])

  const onPaginationChange = ({ pageIndex }) => {
    getData(pageIndex, USERS_PAGE_SIZE, filters)
  }

  const goToUserDetails = (ev, userRow) => {
    const user = Array.isArray(userRow) ? userRow[0] : userRow.original
    if (orgId) {
      navigate(
        `/admin/organization${isBeta ? '-beta' : ''}/${orgId}/user/${
          user.userGuid
        }`
      )
    } else {
      navigate(`/admin/users/${user.userGuid}`)
    }
  }

  const onUserDelete = (ev, rowNumber, rows) => {
    setLoading(true)
    const promises = []
    rows.forEach((row) => {
      promises.push(deleteUser(dispatch, row.userGuid))
    })
    Promise.all(promises)
      .then(resetSearch)
      .finally(() => setLoading(false))
  }

  const onEmailResend = (data) => {
    setLoading(true)
    resendEmailInvitation(data.emailAddress, dispatch).finally(() =>
      setLoading(false)
    )
  }

  const onSendPasswordReset = (data) => {
    setLoading(true)
    sendResetPasswordEmail(data.emailAddress).finally(() => setLoading(false))
  }

  const resetSearch = () => {
    resetPage()
    setFilters({})
    searchFilterRef && searchFilterRef.current?.resetComponentState()
    getData({ pageIndex: 0, filters: {} })
  }

  const goToNewUserPage = () => {
    if (orgId) {
      navigate(`/admin/organization${isBeta ? '-beta' : ''}/${orgId}/new-user`)
    } else {
      navigate(`/admin/users/add-new-user`)
    }
  }

  const onCopyToClipboard = (user) => {
    if (!user) {
      return
    }
    const phoneNumber = formatPhoneNumber(user.phoneNumber)
    const clipboardText = `
      <strong>Full Name:</strong>
      ${user.firstName} ${user.lastName}
      <br>
      <strong>Email:</strong>
      ${user.emailAddress}
      <br>
      <strong>Phone:</strong>
      ${phoneNumber}
    `
    copyToClipboard(clipboardText, dispatch)
  }

  const _renderList = () => {
    if (!loading && list?.length === 0) {
      return (
        <EmptyList
          title={isSearch ? 'No data found' : 'No Users Yet'}
          description={
            isSearch
              ? `We couldn't find any data matching your criteria.`
              : 'You don’t have any users yet. Feel free to add one!'
          }
          actionButtonLabel={isSearch ? 'Reset search & filters' : '+ Add User'}
          actionButtonOnClick={isSearch ? resetSearch : goToNewUserPage}
        />
      )
    }

    return (
      <Table
        ref={tableRef}
        title="Users"
        data={users.list}
        columns={columns}
        onRowClick={goToUserDetails}
        onRowsEdit={goToUserDetails}
        onRowsRemove={onUserDelete}
        totalPages={Math.ceil(users.itemCount / USERS_PAGE_SIZE)}
        hasCheckboxes={true}
        hasPagination={true}
        fetchData={onPaginationChange}
        pageSize={USERS_PAGE_SIZE}
        dataLoading={loading}
        onTableRowsSelectChange={setSelectedRows}
        toolbarRightContent={
          orgId ? (
            <Tooltip title="Copy to clipboard">
              <IconButton
                disabled={!Object.keys(selectedRows)?.length > 0}
                variant={'outlined'}
                sx={{ borderRadius: '10px', marginRight: '8px' }}
                onClick={() => onCopyToClipboard(selectedRows[0])}
              >
                <ClipboardCheckIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Resend Email Invitation">
                <IconButton
                  variant="outlined"
                  rounded={true}
                  disabled={
                    !Object.keys(selectedRows)?.length > 0 ||
                    USER_STATUS_IDS?.[selectedRows[0]?.statusId] ===
                      USER_STATUS.ACTIVE
                  }
                >
                  <MailIcon onClick={() => setSendInvitationEmailModalOn()} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send Reset Password">
                <IconButton
                  disabled={
                    !Object.keys(selectedRows)?.length > 0 ||
                    USER_STATUS_IDS?.[selectedRows[0]?.statusId] !==
                      USER_STATUS.ACTIVE
                  }
                  variant={'outlined'}
                  sx={{ borderRadius: '10px' }}
                  onClick={() => setResetPasswordModalOn()}
                >
                  <RefreshSingleArrowIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        }
      />
    )
  }

  const getFilteredOrganizations = () => {
    let organizationTypes = []

    if (canDoEverything) {
      return allOrganizations
    } else if (canViewSalesUsers && !canViewLendingUsers) {
      organizationTypes = [ORGANIZATION_TYPE_IDS.DEALER]
    } else if (!canViewSalesUsers && canViewLendingUsers) {
      organizationTypes = [ORGANIZATION_TYPE_IDS.LENDER]
    } else if (canViewSalesUsers && canViewLendingUsers) {
      organizationTypes = [
        ORGANIZATION_TYPE_IDS.DEALER,
        ORGANIZATION_TYPE_IDS.LENDER,
      ]
    }

    return allOrganizations.filter((org) => {
      return organizationTypes.includes(org.type.id)
    })
  }

  const getFilteredRoles = () => {
    let allowedRoleIds = []

    if (canDoEverything) {
      allowedRoleIds = roles.map((role) => role.roleTypeId)
    }
    if (canViewSalesUsers && !canViewLendingUsers) {
      allowedRoleIds.push(
        ROLE_TYPES.SALES,
        ROLE_TYPES.SALES_ADMIN,
        ROLE_TYPES.SALES_MANAGER
      )
    } else if (!canViewSalesUsers && canViewLendingUsers) {
      allowedRoleIds.push(ROLE_TYPES.LENDER)
    } else if (canViewSalesUsers && canViewLendingUsers) {
      allowedRoleIds.push(
        ROLE_TYPES.SALES,
        ROLE_TYPES.LENDER,
        ROLE_TYPES.SALES_ADMIN,
        ROLE_TYPES.SALES_MANAGER
      )
    }

    const filteredRoles = roles.filter((role) =>
      allowedRoleIds.includes(role.roleTypeId)
    )

    return filteredRoles
  }

  const filterOptions = useMemo(() => {
    const extraOptions = []

    const filteredOrganizations = getFilteredOrganizations()
    const filteredRoles = getFilteredRoles()

    if (
      hasAccess(userData, [
        CLAIMS.CAN_VIEW_ORGANIZATIONS,
        CLAIMS.CAN_VIEW_LENDERS,
      ])
    ) {
      extraOptions.push({
        key: 'OrganizationId',
        secondaryKey: 'v2OrganizationId',
        secondaryValue: 'v2Guid',
        placeholder: 'Organization',
        options: filteredOrganizations?.map((el) => ({
          id: el.guid,
          v2Guid: el.v2Guid,
          label: el.name,
          value: el.name,
        })),
        isMultiSelect: true,
      })
    }
    if (hasAccess(userData, CLAIMS.CAN_VIEW_ROLES)) {
      extraOptions.push({
        key: 'roleId',
        secondaryKey: 'v2RoleId',
        placeholder: 'Roles',
        options: filteredRoles?.map((el) => ({
          id: el.roleId,
          label: el.name,
          value: el.name,
        })),
        isMultiSelect: true,
      })
    }

    return extraOptions
  }, [allOrganizations, roles])

  return (
    <>
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Users</div>
          <div>View all your Users in one place.</div>
        </div>

        <Button
          onClick={goToNewUserPage}
          startIcon={<PlusIcon />}
          fullWidth={isMobileView}
          sx={{ mt: isMobileView ? 2 : 0 }}
        >
          Add User
        </Button>
      </div>
      <SearchAndFilterModule
        ref={searchFilterRef}
        fullWidth={true}
        placeholder="Search for Users"
        callback={(payload) => {
          getData(0, USERS_PAGE_SIZE, payload, true)
        }}
        style={{ marginBottom: orgId ? '24px' : 0 }}
        fieldsToSearch={['multiple']}
        noDefaultExtraOptions
        onlySearch={false}
        extraOptions={!orgId ? filterOptions : null}
        isSearchable={true}
        initialFiltersIds={filters}
      />
      <Dialog
        open={isResetPasswordModalOn}
        onClose={setResetPasswordModalOff}
        PaperProps={{
          sx: {
            width: '500px',
          },
        }}
        title="Are you sure?"
        actions={
          <>
            <Button
              onClick={() => {
                setResetPasswordModalOff()
              }}
              color={BUTTON_COLORS.ERROR}
              variant={BUTTON_VARIANTS.CONTAINED}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSendPasswordReset(selectedRows[0])
                setResetPasswordModalOff()
              }}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              Confirm
            </Button>
          </>
        }
      />
      <Dialog
        open={isSendInvitationEmailModalOn}
        onClose={setSendInvitationEmailModalOff}
        PaperProps={{
          sx: {
            width: '500px',
          },
        }}
        title="Are you sure?"
        actions={
          <>
            <Button
              onClick={() => {
                setSendInvitationEmailModalOff()
              }}
              color={BUTTON_COLORS.ERROR}
              variant={BUTTON_VARIANTS.CONTAINED}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onEmailResend(selectedRows[0])
                setSendInvitationEmailModalOff()
              }}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              Confirm
            </Button>
          </>
        }
      />

      {_renderList()}
    </>
  )
}

Users.propTypes = {
  orgId: PropTypes.string.isRequired,
  orgData: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default Users
