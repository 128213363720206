import React from 'react'
import PropTypes from 'prop-types'
import Input, { INPUT_TYPES } from 'components/input'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Checkbox from 'components/checkbox'
import DateInput from 'components/input-date'
import { ExpandableCard } from 'components/expandable-card'
import { formatInUSFormat } from 'common/number'
import { years, getYearLabel, PERIOD_TABS, MONTHLY_OPTIONS } from './utils'
import IncomeTabs from './tabs'
import { styles } from '../style'

const { Dropdown } = components

const IncomeCard = ({
  title,
  description,
  data,
  setData,
  calculations = {},
  defaultExpanded = false,
  hasMonthlySetup = false,
  selectedTab,
  setSelectedTab,
}) => {
  const isMonthlySetup = hasMonthlySetup && selectedTab === PERIOD_TABS.MONTHLY

  const onFieldChange = (field, year) => (value) => {
    const fieldValue = field === 'totalEarnings' ? parseFloat(value) : value
    if (year) {
      setData({
        ...data,
        [year]: {
          ...(data[year] || {}),
          [field]: fieldValue,
        },
      })
    } else {
      setData({
        ...data,
        monthly: {
          ...(data.monthly || {}),
          [field]: fieldValue,
          ...(field === 'payFrequency' &&
            value?.id !== MONTHLY_OPTIONS.HOURLY.id && { weeklyHours: '' }),
        },
      })
    }
  }

  const _renderYearlySetup = () => {
    return years.map((year) => {
      const { isChecked, from, through, totalEarnings } = data[year] || {}
      const monthlyEarnings =
        isChecked && calculations[year]?.monthly
          ? `$${formatInUSFormat(calculations[year].monthly)}`
          : '-'
      return (
        <div key={year} style={styles.expandableCard.row}>
          <div style={{ marginTop: '24px' }}>
            <Checkbox
              checked={isChecked}
              onClick={onFieldChange('isChecked', year)}
              label={getYearLabel(year)}
            />
          </div>
          <DateInput
            label="From"
            minDate={getStartDate(year)}
            maxDate={getEndDate(year, through)}
            calendarPlacement="top"
            value={from}
            onChange={onFieldChange('from', year)}
          />
          <DateInput
            label="Through"
            minDate={getStartDate(year, from)}
            maxDate={getEndDate(year)}
            calendarPlacement="top"
            value={through}
            onChange={onFieldChange('through', year)}
          />
          <Input
            label="Total Earnings"
            type={INPUT_TYPES.CURRENCY}
            value={(totalEarnings || '').toString()}
            onChange={onFieldChange('totalEarnings', year)}
          />
          <div style={styles.expandableCard.monthly}>
            Monthly Earnings
            <br />
            {monthlyEarnings}
          </div>
        </div>
      )
    })
  }

  const _renderMonthlySetup = () => {
    const { isChecked, rateOfPay, payFrequency, weeklyHours } =
      data.monthly || {}
    const monthlyEarnings =
      isChecked && calculations.total
        ? `$${formatInUSFormat(calculations.total)}`
        : '-'
    return (
      <div
        style={{
          ...styles.expandableCard.row,
          gridTemplateColumns: '20px 2fr 2fr 2fr 1fr',
        }}
      >
        <div style={{ marginTop: '30px' }}>
          <Checkbox
            checked={isChecked}
            onClick={onFieldChange('isChecked', null)}
          />
        </div>
        <Input
          label="Rate of Pay"
          placeholder="Enter your rate"
          type={INPUT_TYPES.CURRENCY}
          value={(rateOfPay || '').toString()}
          onChange={onFieldChange('rateOfPay', null)}
        />
        <Dropdown
          label="Pay Frequency"
          options={Object.values(MONTHLY_OPTIONS)}
          value={payFrequency}
          onChange={(ev) => {
            onFieldChange('payFrequency', null)(ev.target.value)
          }}
        />
        <Input
          label="Weekly Work Hours"
          placeholder="Enter your hours"
          type={INPUT_TYPES.NUMBER}
          value={(weeklyHours || '').toString()}
          onChange={onFieldChange('weeklyHours', null)}
          disabled={payFrequency?.id !== MONTHLY_OPTIONS.HOURLY.id}
        />
        <div style={styles.expandableCard.monthly}>
          Monthly Earnings
          <br />
          {monthlyEarnings}
        </div>
      </div>
    )
  }

  return (
    <ExpandableCard
      title={
        <>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </>
      }
      headerProps={{
        sx: { ...styles.expandableCard.header },
      }}
      sx={styles.card}
      isCollapsable={true}
      alwaysCollapsible={true}
      items={[]}
      isCollapsed={!defaultExpanded}
      collapsibleItems={[
        <div key={title} style={{ width: '100%' }}>
          {hasMonthlySetup && (
            <IncomeTabs
              tabs={Object.values(PERIOD_TABS).map((label) => ({
                id: label,
                label,
              }))}
              selectedTab={selectedTab}
              onTabChanged={setSelectedTab}
            />
          )}
          {isMonthlySetup ? _renderMonthlySetup() : _renderYearlySetup()}
          <hr style={{ ...styles.hr, margin: '24px 0' }} />
          <div style={styles.expandableCard.footer}>
            Qualifying {title}: ${formatInUSFormat(calculations.total)}
          </div>
        </div>,
      ]}
    ></ExpandableCard>
  )
}

function getStartDate(year, startDate) {
  if (startDate) {
    return new Date(startDate)
  }
  return new Date(year, 0, 1)
}
function getEndDate(year, endDate) {
  if (endDate) {
    return new Date(endDate)
  }
  return new Date(year, 11, 31)
}

IncomeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  calculations: PropTypes.object.isRequired,
  defaultExpanded: PropTypes.bool,
  hasMonthlySetup: PropTypes.bool,
  selectedTab: PropTypes.string,
  setSelectedTab: PropTypes.func,
}

export default IncomeCard
