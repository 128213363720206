import React from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button/index'

import './style.scss'

const { Dialog } = components

const DeleteModal = ({
  title,
  confirmationQuestion,
  onSubmitModal,
  isOpen = true,
  setModalOpen,
  confirmButtonText = 'Delete',
  cancelButtonText = 'Cancel',
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setModalOpen(false)}
      title={title}
      PaperProps={{
        sx: {
          maxWidth: '90%',
          width: '450px',
        },
      }}
      actions={
        <>
          <Button
            color={BUTTON_COLORS.ERROR}
            onClick={() => {
              setModalOpen(false)
              onSubmitModal()
            }}
          >
            {confirmButtonText}
          </Button>
          <Button
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            onClick={() => setModalOpen(false)}
          >
            {cancelButtonText}
          </Button>
        </>
      }
    >
      {confirmationQuestion && (
        <div className="delete-modal__question">{confirmationQuestion}</div>
      )}
    </Dialog>
  )
}

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  confirmationQuestion: PropTypes.string,
  isOpen: PropTypes.bool,
  onSubmitModal: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
}

export default DeleteModal
