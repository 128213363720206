import { colors, theme } from '@ElementsCapitalGroup/enium-ui'

const {
  lightTheme: { typography },
} = theme

export const styles = {
  dialogPaper: {
    maxWidth: '100%',
    width: '1024px',
    '& .MuiDialogTitle-root': {
      alignItems: 'center',
      fontSize: '1.125rem',
    },
    height: 'auto',
  },
  dialogContainer: (isTabletView, isMobileView) => ({
    display: isTabletView || isMobileView ? 'flex' : 'inherit',
  }),
  stepContainer: (isTabletView, isMobileView) => ({
    display: 'flex',
    flexDirection: isTabletView ? 'column' : 'row',
    backgroundColor: isMobileView ? 'transparent' : '#F9FAFB',
    width: '100%',
    padding: isMobileView ? 0 : isTabletView ? '16px' : '12px 0 12px 12px',
    borderRadius: '16px',
  }),
  stepItem: (isTabletView, activeStep, key) => ({
    display: isTabletView ? 'inherit' : 'flex',
    cursor: 'pointer',
    color: activeStep === key ? colors.blue[700] : 'black',
  }),
  currentNextHeader: {
    fontWeight: typography.fontWeightMedium,
    fontSize: '0.625rem',
    color: colors.grey[500],
    lineHeight: 1.5,
  },
  stepTitle: {
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  mobileStepContent: {
    marginTop: '16px',
    color: 'black',
  },
  arrowWrapper: {
    marginTop: '12px',
    marginBottom: '8px',
  },
  listContent: {
    color: 'black',
    marginLeft: '12px',
    fontSize: '14px',
  },
  stepSubtitle: {
    color: 'black',
    fontWeight: '500',
    marginBottom: '24px',
    fontSize: '14px',
  },
  arrow: {
    background: '#f2f4f7',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px',
  },
  contentWrapper: (isTabletView) => ({
    marginTop: isTabletView ? '0' : '24px',
    marginLeft: isTabletView ? '24px' : 0,
  }),
}
