import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { colors, assets, components } from '@ElementsCapitalGroup/enium-ui'
import { ReactComponent as IconMinus } from 'assets/icon-minus-no-borders.svg'
import { loanCardStyle } from './styles'

const { Chip } = components
const { XCloseIcon, CheckIcon } = assets

const LoanCardChip = ({
  isFailed,
  isBranch = false,
  label,
  style = loanCardStyle.chip,
  iconStyle = {},
}) => {
  const { t: translate } = useTranslation()

  if (isBranch) {
    return (
      <Chip
        size="small"
        icon={<IconMinus />}
        label={label || translate('loanApplication.step2.branch')}
        style={{
          color: colors.grey[700],
          background: colors.grey[100],
          ...style,
        }}
      />
    )
  }

  return isFailed ? (
    <Chip
      size="small"
      label={label || translate('global.failed')}
      icon={<XCloseIcon sx={{ width: '13px' }} />}
      color="error"
      style={style}
    />
  ) : (
    <Chip
      size="small"
      label={label || translate('global.approved')}
      icon={
        <CheckIcon
          sx={{ stroke: colors.green[500], width: '13px', ...iconStyle }}
        />
      }
      color="success"
      style={style}
    />
  )
}

LoanCardChip.propTypes = {
  isFailed: PropTypes.bool,
  isBranch: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
}

export default LoanCardChip
