import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button from 'components/button'
import { TABLET_BREAKPOINT } from 'common/constants'
import { useMediaQuery } from 'common/hooks'
import { styles } from './style'

const { Dialog } = components

export const TwoFactorReminder = ({ setIsPhoneSetup, setIsReminder }) => {
  const [loading] = useState(false)
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          maxWidth: '512px',
          height: 'auto',
          margin: isMobileView ? '12px' : 'inherit',
        },
      }}
    >
      <div style={styles.reminderTitle}>
        Mandatory 2-Factor Authentication Now in Effect
      </div>
      <div>
        As of August 29th, 2-factor authentication (2FA) is now mandatory for
        all users in compliance with the latest regulations. Each time you log
        in, you will be required to enter a code sent via text message. This
        additional layer of security is essential for protecting your account.
        If you have not yet set up 2FA, you will be required to do so the next
        time you log in.
      </div>
      <div style={styles.reminderButtons}>
        <Button
          disabled={loading}
          onClick={() => {
            setIsPhoneSetup(true)
            setIsReminder(false)
          }}
          style={{ width: '100%', marginLeft: '12px' }}
        >
          Setup 2-Factor
        </Button>
      </div>
    </Dialog>
  )
}

TwoFactorReminder.propTypes = {
  setIsPhoneSetup: PropTypes.func.isRequired,
  setIsReminder: PropTypes.func.isRequired,
}
