import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { removeOverrideOnDecision } from 'modules/loan-application/actions'

const { Dialog } = components

const RemoveOverride = ({
  removeOverrideData,
  setRemoveOverrideData,
  reFetchDecisioning,
  currentLoans,
}) => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState(false)
  const onClose = () => setRemoveOverrideData(null)

  /** Called on confirming the Removal of the Override */
  const onConfirm = async () => {
    setLoading(true)
    await removeOverrideOnDecision(removeOverrideData, currentLoans)
    await reFetchDecisioning()
    setLoading(false)
    onClose()
  }

  return (
    <Dialog
      open={!!removeOverrideData}
      title={translate('loanApplication.step3.areYouSure')}
      PaperProps={{
        sx: {
          width: '400px',
          maxWidth: '100%',
          margin: 0,
        },
      }}
      actions={
        <>
          <Button
            disabled={loading}
            onClick={onClose}
            variant={BUTTON_VARIANTS.OUTLINED}
            color={BUTTON_COLORS.INHERIT}
            fullWidth={true}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button disabled={loading} onClick={onConfirm} fullWidth={true}>
            {translate('loanApplication.step2.removeOverride')}
          </Button>
        </>
      }
      loading={loading}
    ></Dialog>
  )
}

RemoveOverride.propTypes = {
  removeOverrideData: PropTypes.object,
  setRemoveOverrideData: PropTypes.func.isRequired,
  reFetchDecisioning: PropTypes.func.isRequired,
  currentLoans: PropTypes.array.isRequired,
}

export default RemoveOverride
