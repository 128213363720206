import { CLAIMS } from 'common/claims'

export const TABS = {
  ORGANIZATIONS: {
    access: [CLAIMS.CAN_DO_EVERYTHING, CLAIMS.CAN_EDIT_ORGANIZATIONS],
  },
  DOCUMENTS: {
    access: [CLAIMS.CAN_DO_EVERYTHING, CLAIMS.CAN_EDIT_ADMIN_DOCUMENTS],
  },
  AUS_TEMPLATES: {
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  STIPULATION_TEMPLATES: {
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  ROLES: {
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  EDIT_TRANSLATIONS: {
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  HISTORY: {
    access: [CLAIMS.CAN_DO_EVERYTHING],
  },
  OUTAGE_MESSAGES: {
    access: [CLAIMS.CAN_VIEW_OUTAGES],
  },
  USERS: {
    access: [CLAIMS.CAN_VIEW_USERS],
  },
  PRODUCTS: {
    access: [CLAIMS.CAN_DO_EVERYTHING, CLAIMS.CAN_VIEW_LOAN_PRODUCTS_PAGE],
  },
}

export const ROLE_TYPES = {
  CORPORATE: 1,
  SALES: 2,
  SERVICE: 3,
  LENDER: 4,
  SYSTEM_ADMINISTRATOR: 5,
  ENIUM_X: 6,
  SALES_MANAGER: 7,
  SALES_ADMIN: 8,
  DEVELOPER: 9,
}

export const PORTAL_ROLE_TYPES = [
  ROLE_TYPES.CORPORATE,
  ROLE_TYPES.SALES,
  ROLE_TYPES.SERVICE,
  ROLE_TYPES.LENDER,
  ROLE_TYPES.SYSTEM_ADMINISTRATOR,
  ROLE_TYPES.SALES_MANAGER,
  ROLE_TYPES.SALES_ADMIN,
  ROLE_TYPES.DEVELOPER,
]

export const ASSOCIATIONS_STATUS_IDS = {
  ACTIVE: 1,
}
